import { HttpClient, HttpEventType } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import * as angular from 'angular';
import { Subscription, throwError } from 'rxjs';
import { finalize, catchError } from 'rxjs/operators';
import { pathToApi } from '../../core/constants/global-constants';
import { GlobalSpinnerService } from '../../shared/components/spinner/spinner.service';
import { MessagingService } from './messaging.service';
import { OmcLoadingService } from './omc-loading.service';

export interface ConflictStrategy {
    renameFileIfExist?: boolean;
    renamingStrategy?: string;
    customNamingStrategy?: any;
}

@Injectable({
    providedIn: 'root'
  })
export class FileUploadService {
    uploadSub: Subscription;

    constructor(
        private messagingService: MessagingService,
        private globalSpinnerService: GlobalSpinnerService,
        private omcLoadingService: OmcLoadingService,
        private http: HttpClient
    ) {}

    uploadFile(file = null, url = 'uploadFile', globalSpinner = true) {
        let promise = new Promise((resolve, reject) => {
            const formData: FormData = new FormData();
            formData.append('file', file);

            const upload$ = this.http
                .post(pathToApi + url, formData, {
                    reportProgress: true,
                    observe: 'events',
                })
                .pipe(
                    finalize(() => {
                        this.resetUpload(file, globalSpinner, url);
                    }),
                    catchError(error => {
                        if (globalSpinner) {
                            this.globalSpinnerService.removeSpinner(url);
                        }
                        this.messagingService.displayExceptionError(error);
                        reject(error);
                        return throwError(error);
                    })
                )

            this.uploadSub = upload$.subscribe((event: { type: any; loaded: any; total: any; body: any }) => {
                if (event.type == HttpEventType.UploadProgress) {
                    file.progress = Math.round(100 * (event.loaded / event.total));
                }
                if (event.body) {
                    resolve(event.body);
                }
            });
        });

        if (globalSpinner) {
            this.globalSpinnerService.addSpinner(url);
        } else {
            this.omcLoadingService.registerCall(url, promise);
        }

        return promise;
    }

    uploadFileToPath(file, location, url = 'uploadFile', conflictStrategy: ConflictStrategy = {}, showSpinner = true) {
        if (showSpinner) {
            this.globalSpinnerService.addSpinner(url);
        }
        // setting defaults
        conflictStrategy = this.conflictStrategySettings(conflictStrategy);
        let metaData = {
            location: location,
            renameFile: conflictStrategy.renameFileIfExist,
            renamingStrategy: conflictStrategy.renamingStrategy,
            customNamingStrategy: conflictStrategy.customNamingStrategy,
        };

        let promise = new Promise((resolve, reject) => {
            const formData: FormData = new FormData();
            formData.append('file', file.file);
            Object.keys(metaData).forEach((key) => formData.append(key, String(metaData[key])));

            const upload$ = this.http
                .post(url, formData, {
                    reportProgress: true,
                    observe: 'events',
                })
                .pipe(
                    finalize(() => {
                        this.resetUpload(file, showSpinner, url);
                    })
                )
                // .catch((error: any) => {
                //     if (showSpinner) {
                //         this.globalSpinnerService.removeSpinner(url);
                //     }
                //     this.messagingService.displayExceptionError(error);
                //     reject(error);
                //     return Observable.throw(error);
                // });

            this.uploadSub = upload$.subscribe(
                (event: { type: any; loaded: any; total: any; config: any; file: any }) => {
                    if (event.type == HttpEventType.UploadProgress && event.total) {
                        file.progress = Math.round(100 * (event.loaded / event.total));
                    }
                    if ((event.type = HttpEventType.Response && file.progress === 100 && !event.file)) {
                        Object.assign(event, { file: file.file });
                        resolve(event);
                    }
                }
            );
        });

        return promise;
    }

    private conflictStrategySettings(conflictStrategy: ConflictStrategy) {
        conflictStrategy = Object.assign(
            {
                renameFileIfExist: true,
                renamingStrategy: 'date',
                customNamingStrategy: null,
            },
            conflictStrategy
        );
        return conflictStrategy;
    }

    private resetUpload(file: any, globalSpinner: boolean, url: string) {
        file.progress = null;
        this.uploadSub = null;
        if (globalSpinner) {
            this.globalSpinnerService.removeSpinner(url);
        }
    }
}

angular.module('omc').factory('fileUploadService', downgradeInjectable(FileUploadService));
