import * as angular from 'angular';
import { downgradeComponent } from '@angular/upgrade/static';
import { OnInit, Component, EventEmitter, Input, Output } from '@angular/core';
import { pathToApp } from '../../../../core/constants/global-constants';

@Component({
    selector: 'artefactListComponent',
    templateUrl: pathToApp + 'shared/dialogs/batch-confirm-dialog/artefact-list/artefact-list.component.html',
})
export class ArtefactListComponent implements OnInit {
    @Input() artefacts: any;
    @Output() onChangeSelected: any = new EventEmitter<any>();
    selected: [];

    constructor() {}

    ngOnInit(): void {
        this.onChangeSelected.emit(this.artefacts);
    }

    onChange() {
        this.selected = this.artefacts.filter((artefact) => artefact.selected === true);
        this.onChangeSelected.emit(this.selected);
    }
}
angular.module('omc').directive(
    'artefactListComponent',
    downgradeComponent({
        component: ArtefactListComponent,
        inputs: ['artefacts'],
        outputs: ['onChangeSelected'],
    })
);
