//https://www.codeproject.com/Articles/1121469/Serialize-Deserialize-objects-by-reference-to-tran

/* Service will probably not be needed when moving to HTTPClient in Angular [TM 13/06/22] */

import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import * as angular from 'angular';

@Injectable({
    providedIn: 'root'
  })
export class SerialisationService {
    constructor() {}

    deserialiseJsonGraph(jsonData) {
        return this.retrocycle(JSON.parse(jsonData));
    }

    retrocycle(obj) {
        let catalog = [];
        this.findReferences(obj, catalog);
        return this.resolveReferences(obj, catalog);
    }

    findReferences(obj, catalog) {
        // The catalogObject function walks recursively through an object graph
        // looking for $id properties. When it finds an object with that property, then
        // it adds it to the catalog under that key.
        if (obj && typeof obj === 'object') {
            let id = obj.$id;
            if (typeof id === 'string') {
                catalog[id] = obj;
            }
            if (Object.prototype.toString.apply(obj) === '[object Array]') {
                for (let i = 0; i < obj.length; i++) {
                    this.findReferences(obj[i], catalog);
                }
            } else {
                for (let name in obj) {
                    if (obj.hasOwnProperty(name)) {
                        if (typeof obj[name] === 'object') {
                            this.findReferences(obj[name], catalog);
                        }
                    }
                }
            }
        }
    }

    resolveReferences(obj, catalog) {
        if (obj && typeof obj === 'object') {
            if (Object.prototype.toString.apply(obj) === '[object Array]') {
                for (let i = 0; i < obj.length; i += 1) {
                    let item = obj[i];
                    if (item && typeof item === 'object') {
                        let id = item.$ref;
                        if (typeof id === 'string') {
                            obj[i] = catalog[id];
                        } else {
                            obj[i] = this.resolveReferences(item, catalog);
                        }
                    }
                }
            } else if (obj.$values && Object.prototype.toString.apply(obj.$values) === '[object Array]') {
                let arr = new Array();
                for (let i = 0; i < obj.$values.length; i += 1) {
                    let item = obj.$values[i];
                    if (item && typeof item === 'object') {
                        let id = item.$ref;
                        if (typeof id === 'string') {
                            arr[i] = catalog[id];
                        } else {
                            arr[i] = this.resolveReferences(item, catalog);
                        }
                    } else {
                        arr[i] = item;
                    }
                }
                obj = arr;
            } else {
                for (let name in obj) {
                    if (obj.hasOwnProperty(name)) {
                        if (typeof obj[name] === 'object') {
                            let item = obj[name];
                            if (item) {
                                let id = item.$ref;
                                if (typeof id === 'string') {
                                    obj[name] = catalog[id];
                                } else {
                                    obj[name] = this.resolveReferences(item, catalog);
                                }
                            }
                        }
                    }
                }
            }
        }

        this.removeAutoGenProperty(catalog);
        return obj;
    }

    removeAutoGenProperty(catalog) {
        for (let i = 0; i < catalog.length; i += 1) {
            let obj = catalog[i];
            if (obj && typeof obj === 'object') {
                let id = obj['$id'];
                if (typeof id !== 'undefined') {
                    delete obj['$id'];
                }
            }
        }
    }

    serialiseJsonGraph(obj) {
        let catalog = []; // Keep a reference to each unique object or array
        let newObj = this.getDecycledCopy(obj, catalog);
        let transformedObj = this.transformIds(newObj);
        return transformedObj;
    }

    transformIds(obj) {
        if (!obj) return null;

        if (Object.keys(obj).length === 0) return obj;

        return Object.keys(obj).reduce(
            (acc, curr) => {
                if (typeof obj[curr] === 'object') {
                    if (!Array.isArray(obj[curr])) acc[curr] = this.transformIds(obj[curr]);
                    else {
                        acc[curr] = obj[curr].map((x) => {
                            return this.transformIds(x);
                        });
                    }
                } else if (curr !== '$id') acc[curr] = obj[curr];
                return acc;
            },
            { $id: obj['$id'] }
        );
    }

    getDecycledCopy(obj, catalog) {
        // The createReferences function recurses through the object, producing the deep copy.
        let nu; // The new object or array
        switch (typeof obj) {
            case 'object':
                // typeof null === 'object', so get out if this value is not really an object.
                // Also get out if it is a weird builtin object.
                if (
                    obj === null ||
                    obj instanceof Boolean ||
                    obj instanceof Date ||
                    obj instanceof Number ||
                    obj instanceof RegExp ||
                    obj instanceof String
                ) {
                    return obj;
                }
                for (let i = 0; i < catalog.length; i += 1) {
                    if (catalog[i] === obj) {
                        return { $ref: i.toString() };
                    }
                }
                // Otherwise, accumulate the unique value and its id.
                obj.$id = catalog.length.toString();
                if (!Array.isArray(obj)) {
                    catalog.push(obj);
                }
                // If it is an array, replicate the array.
                if (Object.prototype.toString.apply(obj) === '[object Array]') {
                    nu = [];
                    for (let i = 0; i < obj.length; i += 1) {
                        nu[i] = this.getDecycledCopy(obj[i], catalog);
                    }
                } else {
                    // If it is an object, replicate the object.
                    nu = {};
                    for (let name in obj) {
                        if (Object.prototype.hasOwnProperty.call(obj, name)) {
                            nu[name] = this.getDecycledCopy(obj[name], catalog);
                        }
                    }
                }
                return nu;
            case 'number':
            case 'string':
            case 'boolean':
            default:
                return obj;
        }
    }
}
angular.module('omc').factory('serialisationService', downgradeInjectable(SerialisationService));
