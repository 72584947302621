import * as angular from 'angular';
import { OmcComponent } from 'shared/models/omc.types';
import { ArtefactDialogData } from '../../shared/models/dialog-data.model';
import { NotificationDetailsDialogData } from '../../shared/models/dialog-data.model';
import { ValueOptionsWithHashKey } from '../../core/interfaces/constant-interfaces';
import { MatDialogService } from '../../shared/services/mat-dialog.service';

type dropdownOptions = 'user' | 'notifications' | 'settings' | 'help' | null;

let userStatusComponent: OmcComponent = {
    selector: `userStatusComponent`,
    template: require('./user-status.component.html'),
    bindings: {},
    controller: class UserStatusController implements angular.IController {
        applicationThemes = [
            { id: 1, name: 'light', description: 'Light', class: 'bg-darkblue' },
            { id: 2, name: 'dark', description: 'Dark', class: 'bg-black' },
            { id: 3, name: 'contrast', description: 'Contrast', class: 'bg-yellow' },
        ];
        isFirstChange = true;
        notifications = [];
        changePasswordDialogData;

        // for notification dialog
        selectedNotification = null;
        notificationDialogData = null;

        dropdownVisible = false;
        notificationsVisible = false;
        settingsVisible = false;
        helpsVisible = false;

        visibleDropdown: dropdownOptions = null;

        selectedAlertButton = 0;

        selectedCompanyId = null;
        userCompanies = [];

        userSettings =
            (<any>window)?.omc?.user?.userSettings ||
            {}; /* window.omc.user makes no sense, probably needs to be removed [TS 26/01/21] */
        collections = [];
        defaultCollection = null;
        lang: any;
        dialogService: any;
        alertNotificationTypes: any;
        gridSortOrders: ValueOptionsWithHashKey[];
        logLevels: ValueOptionsWithHashKey[];
        showDeletedObjectInitValue: any;
        showMyObjectsInitValue: any;
        defaultGridSortOrderInitValue: any;
        applicationTheme: any;
        companyDialogFormData: ArtefactDialogData;

        constructor(
            private currentUserService,
            private modalDialogService,
            private generalDialogService,
            private collectionsService,
            private omcGlobalsService,
            private dataService,
            private defaultItemService,
            private $rootScope,
            private omcDialogService,
            private language,
            private messagingService,
            private $state,
            private matDialogService: MatDialogService
        ) {
            this.lang = language;
            this.dialogService = omcDialogService;
        }

        $onInit() {
            this.alertNotificationTypes = this.omcGlobalsService.values('alertNotificationTypes').filter((c) => {
                return c.value < 3;
            });
            this.gridSortOrders = this.omcGlobalsService.values('gridSortOrders');
            this.logLevels = this.omcGlobalsService.values('logLevels');

            this.currentUserService.getCurrentUser(true).then((user) => {
                this.onUpdateUserSettings();
            });
            this.$rootScope.$on('serverEvent:NotificationsServerEvent', this.onNotificationsServerEvent.bind(this));
            this.$rootScope.$on('userSettingsUpdated', this.onUpdateUserSettings.bind(this));

            this.getNotifications();

            if (this.$state.params.id) {
                this.getNotifications().then((notifications) => {
                    let notification = notifications.find((f) => f.notificationId === +this.$state.params.id);
                    if (notification) {
                        this.openNotification(notification);
                    } else {
                        var url = this.$state.href('home', {}, { absolute: true });
                        window.location = url;
                    }
                });
            }
        }

        readNotification(notification) {
            if (notification) {
                this.dataService.putData(notification, 'notifications', 'notifications', false).then(() => {
                    this.getNotifications();
                });
            }
        }

        gridSortChange(newValue, oldValue) {
            if (this.userSettings && newValue !== oldValue) {
                this.userSettings.resetSorting = true;
            } else {
                this.userSettings.resetSorting = false;
            }
        }

        onNotificationButtonClick(notification) {
            this.selectedAlertButton = notification.value;
        }

        setInitialValuesForGridSettings(userSettings) {
            this.showDeletedObjectInitValue = userSettings.showDeletedObjects;
            this.showMyObjectsInitValue = userSettings.showMyObjects;
            this.defaultGridSortOrderInitValue = userSettings.defaultGridSortOrder;
            this.applicationTheme = userSettings.applicationTheme;
        }

        changeTheme(applicationTheme) {
            this.userSettings.applicationTheme = applicationTheme.name;
        }
        
        showProfile() {
            this.matDialogService.openUserDetailsDialog();
        }
        openSendNotificationWindow() {
            this.modalDialogService.show(
                'send-custom-notification',
                {
                    cancel: () => {
                        this.modalDialogService.hide();
                    },
                },
                { title: 'Send Notification' }
            );
        }

        clearNotifications() {
            if (this.notifications) {
                this.dataService.getData('ClearAllNotifications', 'ClearAllNotifications', true).then(() => {
                    this.getNotifications();
                });
            }
        }

        onUpdateUserSettings() {
            this.currentUserService.getCurrentUser().then((user) => {
                user.userSettings = user.userSettings || {
                    defaultJobLogLevel: this.omcGlobalsService.values('defaultLogLevel'),
                };

                if (!user.userSettings.applicationTheme || user.userSettings.applicationTheme === '') {
                    user.userSettings.applicationTheme = 'light';
                }
                if (!user.userSettings.applicationTheme || user.userSettings.applicationTheme === '') {
                    user.userSettings.applicationTheme = 'light';
                }
                this.userSettings = user.userSettings;
                this.selectedAlertButton = user.userSettings.alertNotificationTypeId;
                if (this.selectedAlertButton === null) {
                    this.selectedAlertButton = 0;
                }
                console.log(user);
                this.setInitialValuesForGridSettings(this.userSettings);

                this.updateCollections();
            });
        }

        updateCollections() {
            this.collectionsService.getCollections(true).then((collections) => {
                this.collections = collections;
                let findCollections = collections.find((x) => {
                    return x.isUserDefault;
                });
                let defaultCol = findCollections ? angular.copy(findCollections) : null;
                if (defaultCol) {
                    this.defaultCollection = defaultCol ? defaultCol['id'] : null;
                }
            });
        }

        onNotificationsServerEvent(event, payload) {
            var load = payload;
            if (
                load &&
                load.data &&
                this.currentUserService &&
                this.currentUserService.user &&
                this.currentUserService.user.id === load.data.userId
            ) {
                if (load.data.isRead && this.notifications && this.notifications.length > 0) {
                    var notification = this.notifications.filter((x) => {
                        return x.notificationId === load.data.notificationId;
                    })[0];
                    if (notification) {
                        var index = this.notifications.indexOf(notification);
                        if (index >= 0) {
                            this.notifications.splice(index, 1);
                        }
                    }
                } else {
                    var notificationExists = this.notifications.filter((x) => {
                        return x.notificationId === load.data.notificationId;
                    }).length;
                    if (notificationExists === 0) this.notifications.push(load.data);
                }
                this.notifications.reverse();
            }
        }
        saveAutoRefreshSettings() {
            this.currentUserService.setUserSettings(this.userSettings).then(() => {
                // update autorefresh settings across app
                this.$rootScope.$emit('userSettingsUpdated', 'autoRefresh');
            });
        }
        saveUserSettings() {
            if (this.defaultCollection === 0) {
                this.userSettings.defaultCollection = null;
            } else {
                this.userSettings.defaultCollection =
                    this.defaultCollection &&
                    this.collections.find((x) => {
                        return x.id === this.defaultCollection;
                    });
            }

            this.defaultItemService.clearDefaults();

            this.userSettings.alertNotificationTypeId = this.selectedAlertButton;
            this.currentUserService.setUserSettings(this.userSettings).then(() => {
                this.closeDropdown();
                if (this.isGridSettingsValueChange(this.userSettings)) {
                    console.log('Grid Setting Values changed.');
                    if (this.userSettings.resetSorting) {
                        if (this.userSettings.gridSettings && this.userSettings.gridSettings.length > 0) {
                            this.userSettings.gridSettings.forEach((item) => {
                                item.columnsInfo = [];
                            });
                        }
                    }
                    this.$rootScope.$emit('gridSettingsChanged', this.userSettings);
                }
                if (this.isThemeChanged(this.userSettings)) {
                    this.currentUserService.updateApplicationTheme();
                }
            });
        }

        closeDropdown() {
            this.visibleDropdown = null;
        }

        toggleDropdownMenu(e, menu: dropdownOptions) {
            e.preventDefault();
            e.stopPropagation();
            this.visibleDropdown = menu === this.visibleDropdown ? null : menu;

            switch (this.visibleDropdown) {
                case 'notifications':
                    this.getNotifications();
                    break;
                case 'settings':
                    this.currentUserService.getCurrentUser().then((user) => {
                        user.userSettings = user.userSettings || {
                            defaultJobLogLevel: this.omcGlobalsService.values('defaultLogLevel'),
                        };
                        this.userSettings = user.userSettings;
                        this.setInitialValuesForGridSettings(this.userSettings);
                        this.updateCollections();
                    });
                    break;
                case 'user':
                case 'help':
                default:
                    break;
            }
        }

        getNotifications() {
            return this.dataService.getData('notifications', 'notifications', true).then(
                (data) => {
                    this.notifications = data;
                    this.notifications.reverse();
                    return this.notifications;
                },
                (error) => {
                    console.log(error);
                    return null;
                }
            );
        }

        showPasswordChange() {
            this.changePasswordDialogData = {
                isValid: false,
                confirmPassword: null,
                password: null,
                oldPassword: null,
            };
            this.currentUserService.getCurrentUser().then((user) => {
                this.currentUserService.user = user;
                this.omcDialogService.open('change-password', { title: 'Change Password' });
            });
        }

        savePasswordChange() {
            let data = {
                oldPassword: this.changePasswordDialogData.oldPassword,
                password: this.changePasswordDialogData.newPassword,
            };
            this.dataService.putData(data, 'changePassword', 'changePassword', false).then((result) => {
                this.messagingService.displayMessage('Password Changed');
                this.omcDialogService.close('change-password');
            });
        }

        showSignInHistory() {
            this.matDialogService.openSignInHistoryDialog({user: this.currentUserService.user});
        }

        openAboutDialog() {
            this.helpsVisible = false;
            this.omcDialogService.open('about-omc');
        }

        isGridSettingsValueChange(userSettings) {
            if (
                this.showDeletedObjectInitValue !== userSettings.showDeletedObjects ||
                this.showMyObjectsInitValue !== userSettings.showMyObjects ||
                this.defaultGridSortOrderInitValue !== userSettings.defaultGridSortOrder
            ) {
                return true;
            }
            return false;
        }

        isThemeChanged(userSettings) {
            return this.applicationTheme !== userSettings.applicationTheme;
        }

        openNotification(notification) {
            if (notification) {
                this.notificationDialogData = new NotificationDetailsDialogData(notification);
                this.selectedNotification = notification;
                this.omcDialogService.open('notification-details', { title: 'Notification Details' });
            }
        }

        updateJobApproval(status) {
            var approvalData = {
                notificationId: this.selectedNotification.notificationId,
                message: this.notificationDialogData.approvalMessage,
                approvalStatus: status,
            };
            let approveUrl = 'updateJobApproval';
            let notificationUrl = 'notifications';
            Promise.all([
                this.dataService.postData(approvalData, approveUrl, approveUrl, false),
                this.dataService.putData(this.selectedNotification, notificationUrl, notificationUrl, false),
            ]).then(() => {
                this.getNotifications();
                this.dialogService.close('notification-details');
            });
        }

        openChangeCompanyDialog() {
            /* TODO: check if user has more than one company [TS 30/11/20] */
            if (this.userSettings?.companies?.length < 2) return;
            this.companyDialogFormData = new ArtefactDialogData();
            this.selectedCompanyId = this.userSettings.activeCompanyId;
            this.userCompanies = this.userSettings.companies;
            this.dialogService.open('change-company', { title: 'Switch Company' });
        }
        
        updateCompanyId(id) {
            this.selectedCompanyId = id;
            this.companyDialogFormData.hasChanged = true;
            this.companyDialogFormData.isValid = true;
        }
        
        changeCompanyId() {
            this.userSettings.activeCompanyId = this.selectedCompanyId;
            this.currentUserService.setUserSettings(this.userSettings).then(() => {
                this.dialogService.close('change-company');
            });
        }
    },
};

angular.module('omc').component(userStatusComponent.selector, userStatusComponent);
