// Default path for api
export const pathToApi = 'api/';

//Default path to app
export const pathToApp = 'WebApp/src/app/';

// User's Default Logging Level
export const defaultLogLevel = 2;

// List of Job States by value
export const jobRunningStatesValues: number[] = [2, 4, 5, 9, 6, 3, 1];

// Date format for date pipe
export enum DateTimeFormat {
  longDateFormat = 'dd/MM/yyyy HH:mm:ss',
  shortDateFormat = 'dd/MM/yyyy',
}
