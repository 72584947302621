import * as angular from 'angular';
import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { MatDialog, MatDialogRef } from '@angular/material';
import { ConfirmDialogComponent } from '../dialogs/confirm-dialog/confirm-dialog.component';
import { buttons, dialogRefactoredValues } from '../../constants/app.constants';
import { BatchConfirmDialogComponent } from '../dialogs/batch-confirm-dialog/batch-confirm-dialog.component';
import { BatchConfirmDialogData, ButtonConfig, DefaultDialogConfig } from '../models/dialog-data.model';
import { CurrentUserService } from './current-user.service';
import { UserDetailsDialogComponent } from '../dialogs/user-details-dialog/user-details-dialog.component';
import { UsersService } from './users.service';
import { EditDefaultDataConnectionComponent } from '../../admin/data-connection/edit-default-data-connection/edit-default-data-connection.component';
import { EditCollectionComponent } from '../../admin/collections/edit-collection/edit-collection.component';
import { SigninHistoryComponent } from '../../user/user-status/signin-history/signin-history.component';

@Injectable()
export class MatDialogService {
    constructor(
        public dialog: MatDialog,
        private currentUserService: CurrentUserService,
        private usersService: UsersService
    ) {}

    dialogRef: MatDialogRef<any>;
    dialogConfig = dialogRefactoredValues;

    openConfirmDialog(prompt?: string) {
        this.dialogConfig.data = {
            title: 'Confirm',
            message: prompt || 'Are you sure you would like to proceed?',
            cancelButtonText: 'No',
            confirmButtonText: 'Yes',
        };
        this.dialogRef = this.dialog.open(ConfirmDialogComponent, this.dialogConfig);
        return this.dialogRef.afterClosed();
    }

    openBatchConfirmDialog(confirmDialogData: BatchConfirmDialogData) {
        this.dialogConfig.data = confirmDialogData;
        this.dialogRef = this.dialog.open(BatchConfirmDialogComponent, this.dialogConfig);
        return this.dialogRef.afterClosed();
    }

    openUserDetailsDialog(id?) {
        const currentId = id || this.currentUserService.user.id;
        const user = this.usersService.findById(currentId, false);
        user.then((data) => this.dialog.open(UserDetailsDialogComponent, { data: data }));
    }

    openEditDefaultDataConnectionDialog(currentDataConnection) {
        this.dialogConfig.data = new DefaultDialogConfig({
            title: "Set Defaults",
            confirmButton: { text: buttons.SAVE, classes: 'btn-default' },
            cancelButton: { text: buttons.CANCEL, classes: 'btn-default' },
            data: currentDataConnection,
        });
        this.dialogRef = this.dialog.open(EditDefaultDataConnectionComponent, this.dialogConfig);
        return this.dialogRef.afterClosed();
    }

    openSignInHistoryDialog(user) {
        this.dialogConfig.data = new DefaultDialogConfig({
            title: "Sign In History",
            confirmButton: { text: buttons.OK, classes: 'btn-default' },
            data: user,
        });
        this.dialogRef = this.dialog.open(SigninHistoryComponent, this.dialogConfig);
        return this.dialogRef.afterClosed();
    }

    // This handles add collections and view collections dialogs as well
    openEditCollectionsDialog(selectedCollection: {}, collectionDialogData: {}, title = "Collection") {
        this.dialogConfig.data = new DefaultDialogConfig({
            title: title,
            data: { collection: selectedCollection, formData: collectionDialogData },
            confirmButton: { text: buttons.SAVE, classes: 'btn-default' },
            cancelButton: {
                text: title === 'Collection' ? buttons.OK : buttons.CANCEL,
                classes: 'btn-default'
            }
        });

        this.dialogRef = this.dialog.open(EditCollectionComponent, this.dialogConfig);
        return this.dialogRef.afterClosed();
    }
}
angular.module('omc').factory('matDialogService', downgradeInjectable(MatDialogService));
