export class Endpoint {
    url: any;
    loading: boolean;
    promises: any[];
    updatedTime: any;
    finalPromise: any;

    constructor(url, promise) {
        this.url = url;
        this.loading = true;
        this.promises = Array.isArray(promise) ? promise : [promise];
        this.updatedTime = null;
        this.setFinalPromise();
    }

    setFinalPromise() {
        let timestamp = new Date().getTime();
        this.updatedTime = timestamp;

        this.finalPromise = Promise.all(this.promises);

        this.finalPromise.finally(() => {
            if (timestamp === this.updatedTime) {
                this.loading = false;
                this.promises = [];
            }
        });
    }
}
