import * as angular from 'angular'
import { OmcComponent } from 'shared/models/omc.types'
import { StoredProcedureParam } from '../stored-procedure-params-grid/stored-procedure-params-grid.component';

interface StoredProcedure {
    name: string,
    params:StoredProcedureParam[]
}

interface TaskWithStoredProcedure {
    dataConnectionId: string|number|null, 
    schema: string|null, 
    storedProcedureName: string|null, 
    storedProcedureParams?: StoredProcedureParam[], 
    storedProcedureDatabase?: string,
    [s:string]:any
}

console.log('loaded editTaskStoredProcedureFormComponent')
let editTaskStoredProcedureFormComponent: OmcComponent = {
    selector: `editTaskStoredProcedureFormComponent`,
    template: require("./edit-task-stored-procedure-form.component.html"),
    bindings: {
        task: "<",
        onChangeParams: "&?",
        formData: "<?"
    },
    controller: class EditTaskStoredProcedureFormController implements angular.IController {
        lang: any;
        task: TaskWithStoredProcedure;
        formData: {isEdit?: boolean, isEditParams?: boolean, showDataConnection?: boolean}

        errors: {[s:string]:string}

        dataConnections: any[];
        schemas: string[];
        storedProcedures: StoredProcedure[];
        storedProceduresRequest: any;
        selectedStoredProcedure: StoredProcedure;
        selectedStoredProcedureParams: StoredProcedureParam[];
        
        constructor(
            private dataService,
            private apiService,
            language,
        ){
            this.lang = language;
        }
        
        $onInit(){
            if(this.task){
                this.initForm()
            }
        }
        $onChanges(changes){
            if(changes['task']){
                this.initForm()
            }
            if(changes['formData']){
                this.$onInit();
            }
        }
        $doCheck(){
            this.validate()
        }

        initForm(){
            if(this.formData.isEdit || !this.task?.storedProcedureDatabase){
                this.getDataConnections().then(()=>{
                    if(this.task.dataConnectionId && this.formData.isEdit){
                        this.onChangeDataConnection()
                    }
                })
            }
        }
        validate(){
            this.errors = {}
            if(this.formData.isEdit && !this.task?.dataConnectionId){
                this.errors.dataConnectionId = "Please choose a Data Connection";
            }
            if(this.formData.isEdit && this.task?.schema === undefined){
                this.errors.schema = "Please choose a Schema";
            }
            if(this.formData.isEdit && !this.task?.storedProcedureName){
                this.errors.storedProcedure = "Please choose a Stored Procedure";
            }
        }

        onChangeDataConnection(){
            this.task.storedProcedureDatabase = this.dataConnections.find(dc => dc.id === this.task.dataConnectionId)?.initialCatalog
            this.getSchemas().then(()=>{
                this.onChangeSchema()
            })
            
        }
        
        onChangeSchema(){
            this.getStoredProcedures().then(() => this.onChangeStoredProcedure())
        }
        onChangeStoredProcedure(fromControl?:boolean){
            // storedProcedureParams params should update from parent component (via this.task) if editing params is enabled.
            if(fromControl) {
                this.task.storedProcedureName = this.selectedStoredProcedure?.name
            }
            if(this.formData.isEdit && !this.formData.isEditParams){
                this.task.storedProcedureParams = this.task.storedProcedureName ? this.storedProcedures.find(sp => sp.name === this.task.storedProcedureName)?.params : [];
            }
        }

        onEditParams(params:StoredProcedureParam[]){
            if(this.formData.isEditParams){
                this.task.storedProcedureParams = params
            }
        }

        getDataConnections() {
            let url = 'dataConnection?skipCurrentUserFilter=true'
            this.resetDataConnections()
            return this.dataService.getData(url, url, false, false).then((dataConnections)=>{
                this.dataConnections = dataConnections.filter(dc => dc.connectionType !== 2) // Filter only SQL and Oracle
                this.task.dataConnectionId = this.task.dataConnectionId ?? null;
                return this.dataConnections
            })
        }
        getSchemas() {
            if (this.task?.dataConnectionId ?? false) {
                this.resetSchemas()
                const url = 'getSchemaReferences?connectionId=' + this.task.dataConnectionId;
                return this.dataService.getData(url, url, true, false).then((schemas) => {
                    this.schemas = schemas;
                    this.task.schema = this.task.schema ?? null;
                    return this.schemas
                });
            }
            return Promise.reject()
        }
        getStoredProcedures(){
            if (this.task?.dataConnectionId != null) {
                this.resetStoredProcedures();
                const url = 'getStoredProcedures?connectionId='+this.task.dataConnectionId+'&schemaName='+(this.task.schema ?? '');
                this.storedProceduresRequest = this.apiService.get(url, null, false)
                return this.storedProceduresRequest.then((storedProcedures) => {
                    if(!storedProcedures) return [];
                    this.storedProcedures = storedProcedures;
                    this.selectedStoredProcedure = this.storedProcedures.find(x => x.name === this.task.storedProcedureName)
                    return this.storedProcedures
                });
            }
            return Promise.reject()
        }
        resetDataConnections(){
            this.dataConnections = null
            this.resetSchemas()
        }
        resetSchemas(){
            this.schemas = null
            this.resetStoredProcedures()
        }
        resetStoredProcedures(){
            this.storedProcedures = null
            this.storedProceduresRequest?.cancel?.()
        }
        
    }
}

angular
    .module('omc')
    .component(editTaskStoredProcedureFormComponent.selector, editTaskStoredProcedureFormComponent)