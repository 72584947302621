import * as angular from 'angular'
import { OmcComponent } from 'shared/models/omc.types'

let auditGridComponent: OmcComponent = {
    selector: `auditGridComponent`,
    template: require("./audit-grid.component.html"),
    bindings: {
        data: "=",
        openAudit: "&" // function to open audit. sends out object { jobDetails: Object }
    },
    controller: class AuditGridController implements angular.IController {
        openAudit: (obj:{jobDetails: any}) => any;
        
        constructor(){}
        $onInit(){}

        isLinkable(row){
            return row.auditEntryType.name === 'JobExecution' && row.auditEntryFieldDescription !== "Job Started";
        }
        linkToRunJob(row, e){
            e.preventDefault();
            this.openAudit({ jobDetails: row })
        }
    
    }
}

angular
    .module('omc')
    .component(auditGridComponent.selector, auditGridComponent)