import * as angular from 'angular'
import { ArtefactDialogData } from '../../shared/models/dialog-data.model';
import { OmcComponent } from 'shared/models/omc.types'

export class EditReportsDialogData extends ArtefactDialogData {
    isSystemReport: boolean;
    constructor(props:Partial<EditReportsDialogData> = {}){
        super(props)
        this.isSystemReport = props?.isSystemReport;
    }
}

let editReportComponent: OmcComponent = {
    selector: `editReportComponent`,
    template: require("./edit-report.component.html"),
    bindings: {
        report: "=",
        formData: "="
    },
    controller: class EditReportsController implements angular.IController {
        errors;
        formData: EditReportsDialogData;
        report: any;
        auditGridData: any;
        display: 'details'|'collections'|'audits' = 'details';

        constructor(
            private auditService,
            private gridSettingsService,
            private dataService,
        ){
        }
        
        $onInit(){
            this.initAuditGrid();
        }

        initAuditGrid(){
            this.auditGridData = this.gridSettingsService.defaultGridOptions();
            this.auditGridData.appScopeProvider = this;
            this.auditGridData.columnDefs = this.auditService.auditLogColumnDefs();
            if(this.report?.id){
                this.dataService.getData('reportInfo?id=' + this.report.id, 'reportInfo?id=' + this.report.id, true).then((data) => {
                    this.auditGridData.data = data.auditEntries;
                });
            }
        }
        
        isInvalid(keys){
            this.validate();
            let hasErrors = !angular.equals(this.errors, {});
            if (!keys) {
                return hasErrors
            }
            this.formData.isValid = !hasErrors
            var keyArray = Array.isArray(keys) ? keys : [keys];
            return keyArray.some(key => !!this.errors[key])
        };
        
        errorMessageFor(){
            this.validate();
            return this.errors;
        };
        validate() {
            this.errors = {};
            if (!this.report?.name) {
                this.errors.name = "Please enter a name for the report.";
            } else if (this.report && this.formData.usedNames.includes(this.report.name)) {
                this.errors.name = "Report name is already in use, please use a different name";
            }
            if (!this.report?.url) {
                this.errors.url = "Please enter the URL for the report.";
            }
            if (!this.formData.isSystemReport && !(this.report?.collections?.length >= 1))  {
                this.errors.collection = "Please select at least one collection for this task.";
            }
        }
        
    }
}

angular
    .module('omc')
    .component(editReportComponent.selector, editReportComponent)