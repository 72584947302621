import * as angular from 'angular'
import { ValueOptionsWithHashKey } from '../../core/interfaces/constant-interfaces';
import { OmcComponent } from 'shared/models/omc.types';

let platformSettingsComponent: OmcComponent = {
    selector: `platformSettingsComponent`,
    template: require("./platform-settings.component.html"),
    controller: class platformSettingsController implements angular.IController {
        periodicityOptions: { value: number; name: string|number; }[];
        wallpapers: ValueOptionsWithHashKey[];
        settings: any;
        originalSettings: any;
        workerConfigurations: any[];

        constructor(
            private platformSettingsService,
            private modalDialogService,
            private $timeout,
            private $rootScope,
            private dataService,
            private omcGlobalsService,
            private $state
        ){}
        
        $onInit(){
            this.settings = {};
            this.periodicityOptions =  [
                {value: 0,name: 'Disabled'},
                {value: 1,name: 1},
                {value: 2,name: 2},
                {value: 5,name: 5},
                {value: 10,name: 10},
                {value: 30,name: 30},
                {value: 60,name: 60},
            ];
            this.wallpapers = this.omcGlobalsService.values("omcWallpapers");
            this.$rootScope.$on("serverEvent:PlatformAdminSettings", ()=>this.getData());

            this.$timeout(() => {
                if ($('.auto-vertical-scroll').length > 0) {
                    this.autoVerticalScroll();
                    $(window).resize( () => {
                        this.autoVerticalScroll();
                    });
                }
            });
    
    
            (<any>window).omc.currentVm = this;
            (<any>window).omc.currentVm.refresh =  () => {
                this.$state.go(this.$state.current, {}, { reload: true });
                this.getData();
            }
            this.getData();
        }
    
        hasChanges () {
            /* TODO: Replace with formHelper [TS 20/11/20] */
            return this.originalSettings
                && (this.originalSettings.defaultDataInputPath !== this.settings.defaultDataInputPath
                    || this.originalSettings.defaultResultsLocation !== this.settings.defaultResultsLocation
                    || this.originalSettings.fileUploadTargetDirectory !== this.settings.fileUploadTargetDirectory
                    || this.originalSettings.modelInformationFileNamePattern !== this.settings.modelInformationFileNamePattern
                    || this.originalSettings.sessionExpirationTimeoutMinutes !== this.settings.sessionExpirationTimeoutMinutes
                    || this.originalSettings.jobStatusUpdatePeriodSeconds !== this.settings.jobStatusUpdatePeriodSeconds
                    || this.originalSettings.serviceMonitorUri !== this.settings.serviceMonitorUri
                    || this.originalSettings.serviceMonitorUpdateFrequencySeconds !== this.settings.serviceMonitorUpdateFrequencySeconds
                    || this.originalSettings.defaultMaxLogCount !== this.settings.defaultMaxLogCount
                    || this.originalSettings.defaultMaxErrorCount !== this.settings.defaultMaxErrorCount
                    || this.originalSettings.smtpHost !== this.settings.smtpHost
                    || this.originalSettings.smtpPort !== this.settings.smtpPort
                    || this.originalSettings.adminMail !== this.settings.adminMail
                    || this.originalSettings.adminMailPwd !== this.settings.adminMailPwd
                    || this.originalSettings.enableSsl !== this.settings.enableSsl
                    || this.originalSettings.maximumNoOfThreads !== this.settings.maximumNoOfThreads
                    || this.originalSettings.maximumNoOfSplits !== this.settings.maximumNoOfSplits
                    || this.originalSettings.maximumNoOfWorkers !== this.settings.maximumNoOfWorkers
                    || this.originalSettings.jobArtefactAuditTrailDetailReportUrl !== this.settings.jobArtefactAuditTrailDetailReportUrl
                    || this.originalSettings.workerConfigLocation !== this.settings.workerConfigLocation
                    || this.originalSettings.defaultNoOfSplits !== this.settings.defaultNoOfSplits
                    || this.originalSettings.loginWallpaper !== this.settings.wallpaper.value
                    || this.originalSettings.workerConfigDefault !== this.settings.workerConfigDefault
                    //Service Settings
                );
        };
        getData() {
            this.platformSettingsService.getSettings(true).then( (data) => {
                this.settings = data;
                this.settings.wallpaper = this.wallpapers.find((x) => {
                    return x.value === data.loginWallpaper;
                });
                //data.jobTypes.map(x => x.statusVal = this.jobTypeStatuses.find(c => c.id === x.status));
                this.originalSettings = angular.copy(data);
                this.refreshWorkerConfigs(this.originalSettings.workerConfigLocation);
            });
        }
    
        refreshWorkerConfigs(workerConfigLocation) {
                this.workerConfigurations = [];
                this.dataService.getData("getWorkersConfigs?workerConfigurationLocation=" + workerConfigLocation,
                    "getWorkersConfigs?workerConfigurationLocation=" + workerConfigLocation,
                    true).then((data)=> {
                    var workerConfigs = [];
                    if (data) {
                        for (var i = 0; i < data.length; i++) {
                            if (data[i].Configuration) {
                                workerConfigs.push(data[i].Configuration);
                            }
                        }
                    }
                    this.workerConfigurations = workerConfigs;
                    var defaultValue = this.workerConfigurations.find(x => x.Name === this.originalSettings.workerConfigDefault);
                    if (defaultValue)
                        this.settings.workerConfigDefault = defaultValue.Name;
                });
        }
    
        autoVerticalScroll() {
            this.$timeout(() => {
                $('.auto-vertical-scroll').height($(window).height() - 150 + "px");
            });
        }
    
        cancel() {
            this.modalDialogService.hide();
            this.getData();
        }
    
        save() {
            this.settings.loginWallpaper = this.settings.wallpaper.value;
            this.platformSettingsService.saveSettings(this.settings).then(()=>this.cancel());
        }
    }
}

angular
    .module('omc')
    .component(platformSettingsComponent.selector, platformSettingsComponent)