import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import * as angular from 'angular';
import { DataService } from '../../shared/services/data.service';

@Injectable({
    providedIn: 'root'
  })
export class JobExecutionService {
    constructor(private dataService: DataService) {}

    put(data) {
        return this.dataService.putData(data, 'jobExecution', 'jobExecution', false);
    }

    cancel(id) {
        return this.dataService.putData(id, 'jobExecution', 'jobExecution', false);
    }

    getDistributionMethods() {
        return Promise.resolve([{ id: 1, description: 'Master Worker (Default)' }]);
    }

    getResourcePools() {
        return Promise.resolve([{ id: 1, description: 'Internal Calculation Hosts (Default)' }]);
    }

    getBurstCapacityPools() {
        return Promise.resolve([{ id: 1, description: 'RemoteAzureResourcePool (Default)' }]);
    }
}

angular.module('omc').factory('jobExecutionService', downgradeInjectable(JobExecutionService));
