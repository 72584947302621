import * as angular from 'angular';
import {ArtefactDialogData} from '../../../shared/models/dialog-data.model';
import { OmcComponent } from "../../../shared/models/omc.types";

let editTaskTypeComponent: OmcComponent = {
    selector: "editTaskType",
    template: require("./edit-task-type.component.html"),
    bindings: {
        taskType: "=",
        formData: "=",
    },
    controller: class EditTaskTypeController {
        public formData: ArtefactDialogData;
        public taskType;
        public loaded: boolean;
        public status: boolean;

        constructor() {
        }

        $onInit() {
            this.loaded = !!(this.taskType && this.formData);
            // currently no validation is required [TS 03/09/20]
            if (!this.loaded) return;
            this.formData.isValid = true;
            this.status = !this.taskType.isDisabled
        }
        onChange() {
            this.formData.hasChanged = true
        }

        setStatus() {
            this.taskType.isDisabled = !this.status;
            this.onChange()
        }
    }
}

angular
    .module('omc')
    .component(editTaskTypeComponent.selector, editTaskTypeComponent);
