import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import * as angular from 'angular';
import { DataService } from './data.service';

@Injectable({
    providedIn: 'root'
  })
export class UsersService {
    constructor(private dataService: DataService) {}

    putUser(user) {
        return this.dataService.putData(user, 'users', 'users', false);
    }

    getUser(id, force) {
        return this.dataService.getData('user?id=' + id, 'user?id=' + id, force);
    }

    getUsers(force) {
        return this.dataService.getData('users', 'users', force);
    }

    getLightWeightUsers(force, globalSpinner = true) {
        return this.dataService.getData('users?isLightWeight=true', 'users?isLightWeight=true', force, globalSpinner);
    }

    getCompanies(force) {
        return this.dataService.getData('companies', 'companies', force);
    }

    getTeams(force) {
        return this.dataService.getData('teams', 'teams', force);
    }

    getSinginHistory(force) {
        return this.dataService.getData('users', 'signinhistory', force, false);
    }

    activateUser(id, reload) {
        return this.dataService.putData(id, 'users', 'activateUser', reload);
    }

    deactivateUser(id, reload) {
        return this.dataService.putData(id, 'users', 'deactivateUser', reload);
    }

    findById(id, force) {
        return new Promise((resolve) => {
            this.getUser(id, force).then((user) => {
                user = user || { id: id, userName: id };
                resolve(user);
            });
        });
    }
}
angular.module('omc').factory('usersService', downgradeInjectable(UsersService));
