const { OmcFunc } = require("../functions/omc.functions");

angular.module('omc').directive("draggable", function ($timeout) {
    return {
        restrict: "A",
        link: link
        }

    function link(scope, element, attrs) {
        let el = element;
        console.log(`attrs`,attrs)
        let draggableTarget = attrs['draggableHandle']
        let draggableContain = attrs['draggableContain']
        let options = {}
        if(OmcFunc.isSet(draggableTarget, true)){
            options.handle = draggableTarget
        }
        if(draggableContain === "true"){
            options.containment = 'parent'
        }
         $(el).draggable(options);
        
    }
});