import { OmcComponent } from "../../../shared/models/omc.types";
import * as angular from 'angular';

let editDataConnectionComponent: OmcComponent = {
    selector: 'editDataConnectionComponent',
    bindings: {
        dataConnection: '=selectedDataConnection',
        formData: "=",
    },
    template: require('./edit-data-connection.component.html'),
    controller: class editDataConnectionController implements angular.IController {
        dataConnection: any;
        formData: any;
        loaded: boolean;

        constructor(
            private messagingService,
            private dataService,
        ) {
        }

        $onInit() {
            this.loaded = !!(this.dataConnection && this.formData)
        }

        testConnection() {
            var currentDC = this.dataConnection;
            if (currentDC !== null && currentDC !== undefined) {
                currentDC.userId = this.dataConnection.userName;
                //currentDC.encryptedPassword = this.dataConnection.password;
                //currentDC.encryptedPassword = currentDC.password;
                this.dataService.postData(currentDC, 'testDataConnection', 'testDataConnection', false).then((response) => {
                    this.messagingService.displayMessage(response.message, response.result);
                    // TODO: should probably review the response.result to make it more generic
                    if (response.result === "Valid Data Connection") {
                        this.formData.databases = response.databases;
                        if ((!this.dataConnection.initialCatalog || this.dataConnection.initialCatalog === "") && this.formData.databases.length > 0)
                            this.dataConnection.initialCatalog = response.databases[0];
                    }
                });
            }
        }

        securityTypeChanged() {
            if (this.dataConnection.securityType == 0) {
                this.dataConnection.userName = "";
                this.dataConnection.password = "";
            }
        }

        isInvalid(key) {
            let errors = this.validate();
            this.formData.isValid = errors.isValid()
            if (key) {
                return errors[key];
            }
            return !errors.isValid();
        };

        errorMessageFor() {
            return this.validate();
        }

        validate() {
            let hasErrors = false;
            let rtn: any = { isValid: () => !hasErrors };
            if (this.formData.isEdit) {
                if (!this.dataConnection || !this.dataConnection.name) {
                    rtn.name = "Please specify a value for Name";
                    hasErrors = true;
                }
                if (this.dataConnection?.connectionType === undefined) {
                    rtn.name = "Please specify a value for Database Platform";
                    hasErrors = true;
                }
                if (!this.dataConnection?.dataSource && this.dataConnection?.connectionType != 2) {
                    rtn.dataSource = "Please specify a value for Server";
                    hasErrors = true;
                }
                if (!this.dataConnection?.port && this.dataConnection.connectionType != 2) {
                    rtn.port = "Please specify a value for Port";
                    hasErrors = true;
                }
                if (this.dataConnection && this.dataConnection.connectionType == 1) { // oracle
                    if (!this.dataConnection.serviceName) {
                        rtn.serviceName = "Please specify a value for Service Name";
                        hasErrors = true;
                    }
                }
                else if (this.dataConnection && this.dataConnection.connectionType == 0) { // SQL Server
                    if (this.dataConnection.security === 1 && !this.dataConnection.userName) {
                        rtn.serviceName = "Please specify a value for User";
                        hasErrors = true;
                    }
                    if (this.dataConnection.security === 1 && !this.dataConnection.password) {
                        rtn.serviceName = "Please specify a value for Password";
                        hasErrors = true;
                    }
                }
                else if (this.dataConnection && this.dataConnection.connectionType == 2) {
                    if (!this.dataConnection.uncFilePath) {
                        rtn.uncFilePath = "Please specify path for UNC file";
                        hasErrors = true;
                    }
                }

                if (this.dataConnection && this.dataConnection.name) {
                    if (this.formData.usedNames.includes(this.dataConnection.name)) {
                        rtn.name = "Name is already in use, please use a different name";
                        hasErrors = true;
                    }
                }
            }
            return rtn;
        }
    }
}

angular
    .module('omc')
    .component(editDataConnectionComponent.selector, editDataConnectionComponent)
