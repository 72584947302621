import * as angular from "angular";

export const language = {
    general:{
        ordinal_suffix: (n) => {
            var j = n % 10, k = n % 100;
            if (j == 1 && k != 11) return n + "st";
            if (j == 2 && k != 12) return n + "nd";
            if (j == 3 && k != 13) return n + "rd";
            return n + "th";
        },
        NA: 'n/a',
        OK: 'OK',
        CANCEL: 'Cancel',
        SAVE: 'Save',
        SUBMIT: 'Submit',
        IMPORT: 'Import',
        SELECT_OPTION: '[Select]',
    },
    fileRenamingForm :{
        labels: {
            RENAMING_STRATEGY: 'Renaming Strategy',
            CUSTOM: 'Custom',
            IF_FILE_EXISTS: 'If file already exists:',
        },
        options: {
            renamingStrategy:{
                date: {name: 'Date', value: 'date'},
                version: {name: 'Version Number', value: 'version'},
                custom: {name: 'Custom', value: 'custom'},
            },
            fileExists: {
                rename: {name:'Rename', value: false},
                replace:  {name:'Replace', value: true},
            },
        }
    },
    dataSets:{
        options: {
            fileTypes:{
                excel: {id: 0, name: "Excel Workbook (.xlsx, .xlsm)", isDataConnection: false, extensions:  ["xls", "xlsx", "xlsm"]},
                commaDelimitedText: {id: 1, name: "CSV (Comma delimited) (*.csv)", isDataConnection: false, extensions: ["csv"]},
                tabDelimitedText: {id: 2, name: "Text (Tab delimited) (*.txt)", isDataConnection: false, extensions: ["txt"]},
                internal: {id: 3, name: "Internal", isDataConnection: true},
                oracle: {id: 4, name: "Oracle", isDataConnection: true},
                sqlServer: {id: 5, name: "SQL Server", isDataConnection: true},
                odbcSource: {id: 6, name: "ODBC", isDataConnection: true},
            }
        }
    },
    runJob:{
        options:{
            // the ids and descriptions were taken from c# enum DistributionStrategyKey as is. 
            //      The key is for Front End use and does not match the key from the original enum [TS 06/05/20]
            distributionStrategies: {
                none: {id: 3, name: "None"},
                fromOriginal: {id: 0, name: "Use Parallel Settings from Original Task"},
                multipleThreads: {id: 1, name: "Use Multiple Threads"},
                workerNodes: {id: 2, name: "Use Worker Nodes"},
            },
        },
    },
    taskForm:{
        labels:{
            TASK_TYPE: 'Task Type',
            TASK_NAME: 'Task Name',
            ENABLED:'Enabled?',
            TABLESET: 'Tableset',
            BASIS_SET: 'Basis Set',
            BASIS: 'Basis',
            DATASET: 'Dataset',
            dataSamplingStrings:  {
                none: (name) => name,
                nthRecord: (n) => `Every ${n} record`,
                recordsXToY: (x,y) => `Records ${x} to ${y}`,
                recordsUntil: (n) => `Random records until ${n} record${n  != 1 ? 's' : ''}`,
            },
            RECORD_NUMBER: 'Record number',
            NUMBER_OF_RECORDS: 'Records',
            RUN_CONTROL_TABLE: 'Run Control Table',
            DATASET_SAMPLING_STRATEGY: 'Dataset Sampling Strategy',
            DATA_CONNECTION: 'Results Data Connection',
            FILTER_COLUMN: 'Filter Column',
            WORKING_DIRECTORY: 'Working Directory',
            STANDARD_INPUT: 'Standard Input',
            STANDARD_OUTPUT: 'Standard Output',
            STANDARD_ERROR: 'Standard Error',
            SCHEMA: 'Schema',
        },
        options:{
            dataset:{
                internalGroupProjection: {id: 0, name: "<Internal Group Projection Dataset>" },
                internalDataConversion:{ id: 0, name: "<Internal DataConversion Dataset>" },
                usePreviousResults:{id: 1, name: "<Use results from previous task>"}
            },
            dataSamples:{
                none: {id:0, name:"None"},
                nthRecord: {id:1, name:"Every nth record"},
                recordsXToY: {id:2, name:"Records x to y"},
                recordsUntil: {id:3, name:"Random records until x number of records in target"},
            },
            runControlTable:{
                definedFromTask: { id: 0, name: "<as defined in task>", runIdColumn: '' }
            }
        }
    }
}

angular.module('omc').constant('language', language)