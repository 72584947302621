import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import * as angular from 'angular';

@Injectable({
    providedIn: 'root'
  })
export class FilterService {
    constructor() {
    }

    filterOnCollection(allCollections) {
        return (element) => {
            if (allCollections.length === 0 || allCollections.length == null) return true; // filtering not required

            return element.collections.filter((c) => allCollections.indexOf(c.id) >= 0).length > 0;
        };
    }

    filterOnDate(filterType, dates) {
        return (element) => {
            if (dates.length === 0 || dates.length == null) return true; // filtering not required

            const dt = filterType === 'Effective Date' ? element.effectiveDate : element.dateCreated;
            return this.checkDateRange(dates, dt);
        };
    }

    checkDateRange(dates, dt) {
        return dates.some((date) => dt >= date.sDate && dt <= date.eDate);
    }

    filterApply(gridData, filterValues, gridName) {
        if (filterValues != null) {
            //Filter on collections
            if (filterValues.selectedCollections?.length > 0)
                gridData = gridData.filter(this.filterOnCollection(filterValues.selectedCollections));

            //Filter on dates
            gridData = gridData.filter(this.filterOnDate(filterValues.selectedFilterType, filterValues.treeDates));

            //Filter on Task Type
            if (filterValues?.selectedTaskType?.key) {
                if (gridName && gridName === 'dataset')
                    gridData = gridData.filter((x) =>
                        x.dataSetTasks.map((x) => x.taskTypeId).includes(filterValues.selectedTaskType.key)
                    );
                else gridData = gridData.filter((x) => x.taskTypeId === filterValues.selectedTaskType.key);
            }

            //Filter on Created By
            if (filterValues.createdBy && filterValues.createdByUser)
                gridData = gridData.filter((x) => x.createdById === filterValues.createdByUser.id);
        }
        return gridData;
    }

    filterApplyWithModel(gridData, filterValues) {
        if (filterValues != null) {
            //Filter on collections
            if (filterValues.selectedCollections?.length > 0)
                gridData = gridData.filter(this.filterOnCollection(filterValues.selectedCollections));

            //Filter on dates
            gridData = gridData.filter(this.filterOnDate(filterValues.selectedFilterType, filterValues.treeDates));

            //Filter on Task Type
            if (filterValues.selectedTaskType?.key)
                gridData = gridData.filter((x) => x.taskTypeId === filterValues.selectedTaskType.key);

            //Filter on Task
            if (filterValues.selectedTask?.id)
                gridData = gridData.filter((x) => x.modelId === filterValues.selectedTask.id);

            //Filter on Created By
            if (filterValues.createdBy && filterValues.createdByUser)
                gridData = gridData.filter((x) => x.createdById === filterValues.createdByUser.id);
        }
        return gridData;
    }

    filterApplyOnTasks(tasks, filterValues) {
        if (filterValues != null) {
            //Filter on collections
            if (filterValues.selectedCollections?.length > 0)
                tasks = tasks.filter(this.filterOnCollection(filterValues.selectedCollections));

            //Filter on dates
            tasks = tasks.filter(this.filterOnDate(filterValues.selectedFilterType, filterValues.treeDates));

            //Filter on Task Type
            if (filterValues.selectedTaskType?.key)
                tasks = tasks.filter((x) => x.taskTypeId === filterValues.selectedTaskType.key);

            //Filter on Task
            if (filterValues.selectedTask?.id) tasks = tasks.filter((x) => x.id === filterValues.selectedTask.id);

            //Filter on Created By
            if (filterValues.createdBy && filterValues.createdByUser)
                tasks = tasks.filter((x) => x.createdById === filterValues.createdByUser.id);
        }
        return tasks;
    }
}
angular.module('omc').factory('filterService', downgradeInjectable(FilterService));
