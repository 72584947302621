import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import * as angular from 'angular';
import { toastrMessageLevels } from '../../core/constants/toastr.constants';
import { ToastrService } from 'ngx-toastr';

@Injectable({
    providedIn: 'root'
})
export class MessagingService {
    messageLevels = toastrMessageLevels;

    constructor(private toastr: ToastrService) {}

    displayError(response, info = '') {
        let message = 'an error occured';
        if (response && response.data && response.data.message) {
            message = response.data.message;
        }
        this.displayMessage(message + '\n' + info, 'Error', this.messageLevels.error);
    }

    displayExceptionError(response, info = '') {
        let message =
            response?.data?.exceptionMessage ??
            response?.data?.message ??
            response?.error?.message ??
            'an error occurred';
        this.displayMessage(message + '\n' + info, 'Error', this.messageLevels.error);
    }

    displayMessage(message, title = '', level = 'info') {
        this.toastr[level](message, title);
    }
}
angular.module('omc').factory('messagingService', downgradeInjectable(MessagingService));
