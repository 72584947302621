import * as angular from 'angular'
import { OmcComponent } from 'shared/models/omc.types'

let servicesSettingsComponent: OmcComponent = {
    selector: `servicesSettingsComponent`,
    template: require("./services-settings.component.html"),
    controller: class servicesSettingsController implements angular.IController {
        gridHelper: any;
        constructor(
            private platformSettingsService,
            private apiService,
            private dataService,
            private messagingService,
            private gridHelperServiceFactory,
            private gridSettingsService,
            private modalDialogService,
            private redirectionService
        ) { }

        $onInit() {
            this.initGrid();
            this.getData(true);
        }

        initGrid() {
            this.gridHelper = this.gridHelperServiceFactory.gridHelper('servicesSettings', '#services-grid', this, this.columnDefs());
            this.gridHelper.gridData.gridId = 'services-grid';
            this.gridSettingsService.initialiseGridSettings({
                id: this.gridHelper.gridData.gridId,
                name: 'Services-Grid',
                columnsInfo: []
            });
            this.gridHelper.gridData.modifierKeysToMultiSelect = false;
            this.gridHelper.gridData.multiSelect = false;
        }

        gridRowSelected(item) {
            return this.gridHelper.selectedValues().find(x => x === item);
        }

        actionDisabled() {
            return this.gridHelper.selectedValues().length !== 1;
        }

        startDisabled() {
            let serviceSelected = angular.copy(this.gridHelper.selectedValues()[0]);
            if (serviceSelected !== undefined && serviceSelected.status === "Running") {
                return true;
            }
            return false;
        }

        stopDisabled() {
            let serviceSelected = angular.copy(this.gridHelper.selectedValues()[0]);
            if (serviceSelected !== undefined && serviceSelected.status === "Stopped") {
                return true;
            }
            return false;
        }

        restartDisabled() {
            let serviceSelected = angular.copy(this.gridHelper.selectedValues()[0]);
            if (serviceSelected !== undefined && serviceSelected.status === "Stopped") {
                return true;
            }
            return false;
        }

        getData(force) {
            this.platformSettingsService.getServices(force).then((data)=> {
                this.gridHelper.gridData = data;
                this.gridHelper.dataReloaded(data);
            });
        }

        testUrl() {
            let serviceSelected = angular.copy(this.gridHelper.selectedValues()[0]);
            if (serviceSelected != null) {
                let uri = serviceSelected.endpoint;
                this.apiService.get('testUrl?url=' + encodeURIComponent(uri)).then((response)=> {
                    this.messagingService.displayMessage(response.message, response.result);
                });
            }
        }

        startService() {
            let serviceSelected = angular.copy(this.gridHelper.selectedValues()[0]);
            if (serviceSelected != null) {
                this.dataService.postData(serviceSelected, 'StartService', 'StartService', false).then((response)=> {
                    this.messagingService.displayMessage(response.message, response.result);
                    this.getData(true);
                });
            }
        }

        stopService() {
            let serviceSelected = angular.copy(this.gridHelper.selectedValues()[0]);
            if (serviceSelected != null) {
                this.dataService.postData(serviceSelected, 'StopService', 'StopService', false).then((response)=> {
                    this.messagingService.displayMessage(response.message, response.result);
                    this.getData(true);
                });
            }
        }

        restartService() {
            let serviceSelected = angular.copy(this.gridHelper.selectedValues()[0]);
            if (serviceSelected != null) {
                this.dataService.postData(serviceSelected, 'RestartService', 'RestartService', false).then((response)=> {
                    this.messagingService.displayMessage(response.message, response.result);
                    this.getData(true);
                });
            }
        }

        testService() {
            let serviceSelected = angular.copy(this.gridHelper.selectedValues()[0]);
            if (serviceSelected != null) {
                this.dataService.postData(serviceSelected, 'TestService', 'TestService', false).then((response)=> {
                    this.messagingService.displayMessage(response.message, response.result);
                });
            }
        }

        editService(entity, isEdit) {

            let serviceSelected = entity; //angular.copy(this.gridHelper.selectedValues()[0]);
            if (serviceSelected === undefined)
                serviceSelected = angular.copy(this.gridHelper.selectedValues()[0]);
            else
                serviceSelected = angular.copy(entity);
            var reloadRequired = serviceSelected.name === "Mo.net Identity Service" ? false : true;
            //serviceSelected.username = "";
            //serviceSelected.password = "";
            let popupModel = {
                service: serviceSelected,
                display: "",
                isEdit: isEdit,
                cancel: this.cancel,
                save: ()=> { this.saveService(serviceSelected, reloadRequired) }
            };
            const title = isEdit ? "Edit Service" : "Service";
            this.modalDialogService.show("edit-service", popupModel, { title: title });
        }

        saveService(service, reloadRequired) {
            if (service.canEdit === true) {
                this.platformSettingsService.getSettings(true).then((data)=> {
                    if (service.serviceName === "Mo.net OMC Utility Service") {
                        data.OMCUtilityUrl = service.endpoint;
                    } else if (service.serviceName === "Mo.net Execution Service") {
                        data.jobServiceUrl = service.endpoint;
                    }
                    else if (service.serviceName === "Mo.net Activity Monitor") {
                        data.ServiceMonitorUserName = service.username;
                        data.ServiceMonitorPassword = service.password;
                        data.ServiceMonitorUri = service.endpoint;
                    } else if (service.serviceName === "Mo.net Identity Service") {
                        data.IdentityServiceUri = service.endpoint;
                        data.IdentityServiceUserName = service.username;
                        data.IdentityServicePassword = service.password;
                    }

                    this.platformSettingsService.saveSettings(data, reloadRequired).then(()=> {
                        this.modalDialogService.hide();
                        if (reloadRequired) {
                            this.getData(true);
                        } else {
                            this.redirectionService.redirectToLogin("home");
                        }
                    });
                });
            }
        }

        cancel() {
            this.modalDialogService.hide();
        }

        columnDefs() {
            return [
                {
                    name: 'id',
                    displayName: '',
                    cellTemplate: '<div class="grid-row-vertical-bar" ng-click="grid.appScope.gridHelper.toggleSelected(row.entity, $event)" ng-class="{\'grid-row-selected\': grid.appScope.gridRowSelected(row.entity) }" role="button" tabindex="0"></div>',
                    width: this.gridSettingsService.columnWidths.toggleSelect,
                    pinnedLeft: true,
                    cellClass: "omc-select-column"
                },
                {
                    name: 'name',
                    displayName: 'Name',
                    cellTemplate: `
                        <div class ="ui-grid-cell-contents"  ng-class ="{'default-icon': row.entity.isAppDefault, 'from-model':row.entity.createdAutomatically } ">
                            <a href="#" ng-click="grid.appScope.editService(row.entity, false); $event.preventDefault();">{{row.entity.name}}</a>
                        </div>
                    `,
                    width: this.gridSettingsService.columnWidths.medium
                },
                {
                    name: 'status',
                    displayName: 'Status',
                    width: this.gridSettingsService.columnWidths.active
                },
                {
                    name: "endpoint",
                    displayName: 'Endpoint',
                    width: this.gridSettingsService.columnWidths.default
                },
                {
                    name: "version",
                    displayName: 'Version',
                    width: this.gridSettingsService.columnWidths.active
                },
                {
                    name: "serviceStarted",
                    displayName: 'Service Started',
                    width: this.gridSettingsService.columnWidths.dateTime
                },
                {
                    name: "uptime",
                    displayName: 'Uptime',
                    width: this.gridSettingsService.columnWidths.medium
                },
                {
                    name: "details",
                    displayName: 'Details',
                    width: this.gridSettingsService.columnWidths.notes
                }
            ];
        }
    }
}

angular
    .module('omc')
    .component(servicesSettingsComponent.selector, servicesSettingsComponent)