angular
    .module('omc')
    .directive('treeView', function ($compile) {
    return {
        restrict: 'E',
        scope: {
            localNodes: '=model',
        },
        link: function (scope, tElement, tAttrs, transclude) {

            var maxLevels = (angular.isUndefined(tAttrs.maxlevels)) ? 10 : tAttrs.maxlevels;
            var hasCheckBox = (angular.isUndefined(tAttrs.checkbox)) ? false : true;
            scope.showItems = [];

            scope.showHide = function (ulId) {
                // hide/show tree nodes
                var hideThis = document.getElementById(ulId);
                var showHide = angular.element(hideThis).attr('class');
                angular.element(hideThis).attr('class', (showHide === 'show' ? 'hide' : 'show'));

                // hide/show tree icon
                var iconElem = $('#' + ulId).parent().find('>span.show-hide');

                if (showHide.indexOf('hide') >= 0) {
                    $(iconElem).find('.icon-min').show();
                    $(iconElem).find('.icon-plus').hide();
                } else {
                    $(iconElem).find('.icon-min').hide();
                    $(iconElem).find('.icon-plus').show();
                }
            }

            scope.showIcon = function (node) {
                 if (!angular.isUndefined(node.children) && node.children.length > 0) return true;
            }

            scope.checkIfChildren = function (node) {
                if (!angular.isUndefined(node.children) && node.children.length > 0) return true;
            }

            /////////////////////////////////////////////////
            /// SELECT ALL CHILDRENS
            // as seen at: http://jsfiddle.net/incutonez/D8vhb/5/
            function parentCheckChange(item) {
                for (var i in item.children) {
                    item.children[i].checked = item.checked;
                    if (item.children[i].children) {
                        parentCheckChange(item.children[i]);
                    }
                }
            }

            scope.checkChange = function (node) {
                if (node.children) {
                    parentCheckChange(node);
                }

                //call parent method
            }
            /////////////////////////////////////////////////

            function renderTreeView(collection, level, max) {
                var text = '';
                text += '<li ng-repeat="n in ' + collection + '" >';
                //text += '<span ng-show=showIcon(n) class="show-hide" ng-click=showHide(n.id)><p>+</p></span>';
                text += '<span ng-show=showIcon(n) class="show-hide" ng-click=showHide(n.id)> <span class="icon icon-plus">&#x2b;</span> <span class="icon icon-min" style="display:none;">&#x2212;</span></span>';
                //text += '<span ng-show=!showIcon(n) style="padding-right: 13px"></span>';

                if (hasCheckBox) {
                    text += '<input class="tree-checkbox" type=checkbox ng-model=n.checked ng-change=checkChange(n)>';
                }


                //text += '<span class="edit" ng-click=localClick({node:n})><i class="fa fa-pencil"></i></span>'


                text += '<label>{{n.name}}</label>';

                if (level < max) {
                    text += '<ul id="{{n.id}}" class="hide" ng-if=checkIfChildren(n)>' + renderTreeView('n.children', level + 1, max) + '</ul></li>';
                } else {
                    text += '</li>';
                }

                return text;
            }// end renderTreeView();

            try {
                var text = '<ul class="tree-view-wrapper">';
                text += renderTreeView('localNodes', 1, maxLevels);
                text += '</ul>';
                tElement.html(text);
                $compile(tElement.contents())(scope);
            }
            catch (err) {
                tElement.html('<b>ERROR!!!</b> - ' + err);
                $compile(tElement.contents())(scope);
            }
        }
    };
});