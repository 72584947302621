const { OmcFunc } = require("../../functions/omc.functions");

angular.module('omcDialog').directive('omcDialog', function (omcDialogService) {
    return {
        restrict: 'E',
        transclude: true,

        link: function (iScope, iElement, iAttrs, controller, transcludeFn) {
            var dialogData

            transcludeFn(function (clone, scope) {
                dialogData = {
                    id: iAttrs.id || OmcFunc.newGuid(),
                    title: iAttrs.dialogTitle || '',
                    hideClose: iAttrs.hideClose || 'false',
                    onClose: ()=>{},
                    content: clone,
                    scope: scope,
                    show: false,
                }
                omcDialogService.register(dialogData);
                
            });
        },
    }
})