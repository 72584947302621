angular.module('omcDialog').directive('dialogContainer', function (omcDialogService, $rootScope, $compile, $timeout) {
    let dialogs;
    return {
        restrict: 'E',
        scope: false,
        bindToController: {},
        link: function (iScope, iElement) {
            
            let container = $(iElement[0])

            function addDialog(dialogData) {

                let dialogEl = dialogData.content
                if (dialogEl) {
                    let dialogView = $(`<omc-dialog-view-component id="${dialogData.id}" dialog-title="${dialogData.title}" hide-close="${dialogData.hideClose}"></omc-dialog-view-component>`).append(dialogEl)
                    let compiledDialogView = $compile(dialogView)(dialogData.scope)
                    container.append(compiledDialogView);
                }
            }

            function removeDialog(id) {
                let dialogInstance = $(iElement[0].querySelector('omc-dialog-view-component#' + id));
                let prevElement = dialogInstance.prev()
                dialogInstance.addClass('pop-out')
                prevElement.addClass('pop-in')
                $timeout(()=>{
                    dialogInstance.remove()
                    prevElement.removeClass('pop-in')
                },300)
            }

            $rootScope.$on('dialog.show', function (e, dialogId) {
                let dialogData = dialogs.find(d => d.id === dialogId)
                if (dialogData && dialogData.show) {
                    $timeout(()=>{
                        addDialog(dialogData)
                    })
                }
            })
            $rootScope.$on('dialog.hide', function (e, dialogId) {
                let dialogData = dialogs.find(d => d.id === dialogId)
                if (dialogData && !dialogData.show) {
                    removeDialog(dialogId)
                }
            })


        },
        controller: function () {
            vm = this;
            dialogs = omcDialogService.dialogs;
        }
    }

});