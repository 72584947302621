import * as angular from 'angular'
import { OmcComponent } from 'shared/models/omc.types'
import Chart from 'chart.js';

let chartComponent: OmcComponent = {
    selector: `chartComponent`,
    template: require("./chart.component.html"),
    bindings: {
        settings: '=',
        disabled: "@",
        id: '@',
    },
    controller: class ChartController implements angular.IController {
        chartId: string;
        id: any;
        chart: any;

        constructor(
            private $timeout,
            private $rootScope,
        ) {

        }

        $onInit() {
            this.chartId = this.createId();
            console.log(`chart id: ${this.chartId}`);
            this.$rootScope.$on("chartDataUpdated", (event, id) => {
                if (id === this.id) {
                    this.chart && this.chart.update();
                }
            });
            let defaults = {
                height: 400,
                width: 400
            }
            angular.extend(this.settings, defaults);
            this.$timeout(() => this.initialiseChart());
        }
        settings(settings: any, defaults: { height: number; width: number; }) {
            throw new Error('Method not implemented.');
        }

        createId() {
            return `omc-chart-${Math.random().toString(36).substr(2, 10)}`;
        }

        initialiseChart() {
            var ctx = (<HTMLCanvasElement>document.getElementById(this.chartId)).getContext('2d');
            this.chart = new Chart(ctx, this.settings);
        }
    }
}

angular
    .module('omc')
    .component(chartComponent.selector, chartComponent)