import * as angular from 'angular'
import { OmcFunc } from "../functions/omc.functions";
import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { DataService } from './data.service';
import { ApiService } from './api.service';

const EntityTypes = {
    folder: 1,
    file: 0
}

const topLevelNode = {
    expanded: true,
    children: [],
    dateCreated: null,
    dateModified: null,
    entityType: EntityTypes.folder, // 0 - File,  1 - Folder
    fileSize: null,
    id: null,
    isFilled: false,
    name: "Managed Folders",
    sortFn: null // can be an
}

const defaultTreeOptions = {
    isLeaf: (node) => {
        return node.entityType === EntityTypes.file;
    },
    equality: (node1, node2) => {
        return node1 === node2;
    },
    nodeChildren: "children",
    dirSelectable: false,
    injectClasses: {
        liSelected: "selected-node",
        /* Other optional classes */
        // ul: "",
        // li: "",
        // iExpanded: "",
        // iCollapsed: "",
        // iLeaf: "",
        // label: "",
        // labelSelected: ""
    }
}

@Injectable({
    providedIn: 'root'
  })
export class FileBrowserService {

    dataConnections;
    defaultDataInputPath;
    topLevel;
    tree
    constructor(
        private apiService: ApiService,
        private dataService: DataService
    ) {
        this.getDataConnections();
    }


    createTree({
        extensions = [],
        treeOptions = {},
        checkUsed = false,
        sortFoldersFn = OmcFunc.sortByName,
        sortFilesFn = OmcFunc.sortByName
    } = {}) {
        this.topLevel = angular.copy(topLevelNode)
        let options = angular.copy(defaultTreeOptions)
        angular.extend(options, treeOptions)

        this.tree = {
            data: [this.topLevel],
            expanded: [this.topLevel],
            extensions: extensions,
            hostAlive: true,
            treeOptions: options,
            checkUsed: checkUsed,
            sortFn: { folders: sortFoldersFn, files: sortFilesFn },
            initSort: this.initSort.bind(this),
            getNodeChildren: this.getNodeChildren.bind(this),
            suspendRequests: () => {
                /* review implement [TM 13/06/22] */
                this.tree.hostAlive = false
            },
            load: () => this.getNodeChildren(this.topLevel)
        }
        return this.tree
    }

    getNodeChildren(node) {
        if (!node || !OmcFunc.isSet(this.tree.extensions)) {
            let error = `Couldn't get node children. `
            !node && (error += `'node' was undefined. `);
            !OmcFunc.isSet(this.tree.extensions, true) && (error += `'extensions' was undefined. `)
            console.warn(error)
            return Promise.resolve(this)
        } else if (node.isFilled) {
            this.sortNodes(node.children, (node.sortFn || this.tree.sortFn))
            return Promise.resolve(this)
        };
        const requestData = { id: node.id, filterExtensions: this.tree.extensions, checkUsed: this.tree.checkUsed };
        return this.requestNodeChildren(requestData, node)
    }

    requestNodeChildren(requestData, node) {
        return this.apiService.post("getDirectoryEntries", requestData, null, false).then((childrenNodes: Array<any>) => {
            if (!this.tree.hostAlive) return;
            childrenNodes.forEach(this.mapNodes.bind(this));
            this.sortNodes(childrenNodes, (node.sortFn || this.tree.sortFn))
            if (node) {
                node.children = childrenNodes
                node.isFilled = true;
            }
            return this
        })
    }

    // kept this in case we need to use paths to get a node instead of using an id
    // currently not being used [TS 13/07/20]
    // getNodeChildrenByPath(path, node, tree){
    //     if (node.isFilled) return;
    //     const requestData = {path: (path ? this.extractFullPath(path) : null), id: null, filterExtensions: this.fileExtensions  };
    //     this.requestNodeChildren(requestData, node, tree)
    // }

    mapNodes(node, i) {
        node.isFilled = node.entityType === EntityTypes.file ? true : false;
        node.fileSizeString = OmcFunc.isSet(node.fileSize) ? OmcFunc.formatBytes(parseFloat(node.fileSize)) : '';
        let filenameSplit = node.name.split('.')
        node.fileType = filenameSplit[filenameSplit.length - 1]

        /* Test default input [TS 08/12/20] */
        // if(i === 3 && node.entityType === EntityTypes.folder) {
        //     node.isDataConnInputDefault = true
        //     node.name += " [default]"
        // }
        // return node
    }

    /** Sorts a given Node  ({node: Object}) according to given Sort functions (sortFn: {files: function, folders: function}), optionally recursively ({deep: boolean}). 
     * If no Node is given it will sort from the top level, 
     * if deep is not set (deep: false) it will sort only one level, 
     * if no sortFn with either folders or files sort function, the default sort functions for the tree will be used
     * if a sort function is given it will be saved on the sorted node to be used in the future, and if deep is set to true, all 
    */
    sortFn = { folders: OmcFunc.sortByName, file: OmcFunc.sortByName }
    initSort({
        node = Array.isArray(this.topLevel) ? this.topLevel[0] : this.topLevel,
        deep = false,
        sortFn = this.sortFn
    } = {}) {
        this.sortFn = sortFn;
        // Check if any sort functions were provided in sortFn
        if (sortFn?.folders || sortFn?.file) {
            // override the default sort function with any provided sort functions
            node.sortFn = { ...{ folders: OmcFunc.sortByName, file: OmcFunc.sortByName }, ...sortFn }
            sortFn = node.sortFn
        } else {
            // otherwise use the default sortFn
            node.sortFn = null
            sortFn = { folders: OmcFunc.sortByName, file: OmcFunc.sortByName }
        }
        if (node && node.isFilled) {
            this.sortNodes(node.children, sortFn)
        }
        if (deep === true) {
            node.children.forEach(childNode => {
                if (childNode.children?.length > 0) {
                    this.initSort({ node: childNode, deep: true, sortFn: sortFn })
                }
            })
        }
    }

    sortNodes(nodes, sortFn) {
        if (nodes.length > 1 && (sortFn?.folders || sortFn?.files)) {
            // sort folders to top
            nodes.sort((a, b) => {
                return b.entityType - a.entityType
            })
            let fileIndex = nodes.findIndex(x => x.entityType === EntityTypes.file)
            // splice the files to a new array
            let fileNodes = fileIndex >= 0 ? nodes.splice(fileIndex) : [];
            let folderNodes = nodes.splice(0)

            if (typeof sortFn?.files === 'function' && fileNodes.length > 0) {
                // sort files
                fileNodes.sort(sortFn.files)
            }
            if (typeof sortFn?.folders === 'function' && folderNodes.length > 0) {
                // sort folders
                folderNodes.sort(sortFn.folders)
            }
            // add the files back to nodes array
            nodes.push(...folderNodes, ...fileNodes)
        }
    }

    getFullPathAndExtract($path) {
        return new Promise((resolve, reject) => {
            if (typeof $path !== 'function') {
                console.error('Could not extract path. $path is not a function');
                reject();
            }
            if (!this.defaultDataInputPath || !OmcFunc.isSet(this.dataConnections, true)) {
                this.getDataConnections().then(() => {
                    resolve(this.extractFullPath($path))
                })
            } else {
                resolve(this.extractFullPath($path))
            }
        })
    }

    extractFullPath($path) {
        if (!this.defaultDataInputPath || !OmcFunc.isSet(this.dataConnections, true) || typeof $path !== 'function') return;
        let path = $path().map((node) => node.name);
        if (this.defaultDataInputPath[this.defaultDataInputPath.length - 1] !== "\\") {
            this.defaultDataInputPath += "\\";
        }

        const removedParentDir = path.reduce((result, name, i, sourceArr) => {
            if (i < sourceArr.length - 2) {
                result.push(name);
            }
            return result;
        }, []);

        let rootPath = this.dataConnections.find(e => e.name === path[path.length - 2]).uncFilePath;
        if (rootPath[rootPath - 1] !== "\\") {
            rootPath += "\\";
        }

        return rootPath + removedParentDir.reverse().join("\\");
    }

    getDataConnections() {
        return new Promise<void>(resolve => {
            this.dataConnectionsApiCall(true).then(dc => {
                this.dataConnections = dc
                let dataConnInputDefault = this.dataConnections.find(x => x.isDataConnInputDefault && x.connectionType === 2);
                this.defaultDataInputPath = dataConnInputDefault ? dataConnInputDefault.uncFilePath : '';
                resolve();
            })
        })
    }

    dataConnectionsApiCall(force) {
        let url = 'dataConnection?skipCurrentUserFilter=true'
        return this.dataService.getData(url, url, force)
    }

}
angular
    .module('omc')
    .factory('fileBrowserService', downgradeInjectable(FileBrowserService))
