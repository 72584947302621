import * as angular from 'angular'
import { OmcComponent } from 'shared/models/omc.types';

let aboutPageComponent: OmcComponent = {
    selector: `aboutPageComponent`,
    template: require("./about-page.component.html"),
    controller: class AboutPageController implements angular.IController {
        ApplicationVersion: any;
        appBuildVersion: any;
        executionServiceVersion: any;
        executionServiceDate: any;
        utilityServiceVersion: any;
        utilityServiceDate: any;
        identityServiceVersion: any;
        identityServiceDate: any;
        currentYear: number;

        constructor(
            private dataService,
            private appEnvironmentDataService,
        ){}
        
        $onInit(){
            this.appEnvironmentDataService.getApplicationVersion().subscribe(x => {this.ApplicationVersion = x})
            this.appEnvironmentDataService.getAppBuildVersion().subscribe(x => {this.appBuildVersion = x})
            this.appEnvironmentDataService.getExecutionServiceVersion().subscribe(x => {this.executionServiceVersion = x})
            this.appEnvironmentDataService.getExecutionServiceDate().subscribe(x => {this.executionServiceDate = x})
            this.appEnvironmentDataService.getUtilityServiceVersion().subscribe(x => {this.utilityServiceVersion = x})
            this.appEnvironmentDataService.getUtilityServiceDate().subscribe(x => {this.utilityServiceDate = x})
            this.appEnvironmentDataService.getIdentityServiceVersion().subscribe(x => {this.identityServiceVersion = x})
            this.appEnvironmentDataService.getIdentityServiceDate().subscribe(x => {this.identityServiceDate = x})
            this.dataService.getData('license', 'license', true, true).then((data) => {
                $("#licenseContentDiv").prepend(data);
            });
            this.currentYear = new Date().getFullYear();
        }
    }
}
angular
    .module('omc')
    .component(aboutPageComponent.selector, aboutPageComponent)