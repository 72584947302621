import { buttons } from '../../constants/app.constants';
import { OmcFunc } from '../functions/omc.functions';

/** The base class of artefact dialogs data classes,
 * for common flags like isEdit or isValid.
 */
export class ArtefactDialogData {
    isEdit;
    isUsed;
    isAdd;
    isCopy;
    isValid;
    hasChanged;
    display;
    usedNames;
    selectDefaultCollection;
    dialogId;
    constructor(props: Partial<ArtefactDialogData> = {}) {
        this.isEdit = props.isEdit || false;
        this.isUsed = props.isUsed || false;
        this.isAdd = props.isAdd || false;
        this.isCopy = props.isCopy || false;
        this.isValid = props.isValid || false;
        this.hasChanged = props.hasChanged || false;
        this.display = props.display || '';
        this.usedNames = props.usedNames || [];
        this.selectDefaultCollection = props.selectDefaultCollection || false;
        this.dialogId = props.dialogId || '';
    }
    /** assign any property to the object instance */
    assign(props: any = {}) {
        Object.keys(props).forEach((key) => {
            this[key] = props[key];
        });
    }
}

export class DialogButtons {
    text;
    action;
    classes;
    constructor(options: Partial<DialogButtons> = {}) {
        this.text = options.text || 'Button';
        this.action = options.action || function () {};
        this.classes = options.classes || 'btn-default';
    }
}

export class ConfirmDialogOptions {
    title;
    content;
    buttons;
    constructor(options: Partial<ConfirmDialogOptions> = {}) {
        this.title = options.title || 'Title';
        this.content = options.content || 'Content';
        this.buttons = {};
        this.buttons.ok =
            OmcFunc.deepVal(options, ['buttons', 'ok']) ||
            new DialogButtons({
                text: 'Confirm',
                action: function () {},
                classes: 'btn-default',
            });
        this.buttons.cancel =
            OmcFunc.deepVal(options, ['buttons', 'cancel']) ||
            new DialogButtons({
                text: 'Cancel',
                action: function () {},
                classes: 'btn-cancel',
            });
    }
}

export class selectableArtefact {
    name;
    id;
    selected;
    constructor(artefact: Partial<selectableArtefact & { description: string }> = {}) {
        (this.name = artefact.name || artefact.description || ''),
            (this.id = artefact.id || ''),
            (this.selected = OmcFunc.isSet(artefact.selected) ? artefact.selected : true);
    }
}

export class BatchConfirmDialogOptions extends ConfirmDialogOptions {
    artefacts;
    constructor(options: Partial<BatchConfirmDialogOptions> = {}) {
        super(options);
        this.artefacts = (options.artefacts && options.artefacts.map((a) => new selectableArtefact(a))) || [];
    }
}

/**
 * Used for the omcConfirm dialog
 */
export class ConfirmDialogData {
    title;
    content;
    cancelButtonText;
    confirmButtonText;
    cancelButtonClasses;
    confirmButtonClasses;
    cancelCallback;
    confirmCallback;

    constructor(options: Partial<ConfirmDialogOptions> = {}) {
        /* TODO: Move the default settings to directive or template [TS 24/09/20] */
        (this.title = options.title || 'Confirm'),
        (this.content = options.content || 'Are you sure you would like to proceed?');
        this.cancelButtonText = OmcFunc.deepVal(options, ['buttons', 'cancel', 'text']) || 'Cancel';
        this.confirmButtonText = OmcFunc.deepVal(options, ['buttons', 'ok', 'text']) || 'OK';
        this.cancelButtonClasses = OmcFunc.deepVal(options, ['buttons', 'cancel', 'classes']) || 'btn-cancel';
        this.confirmButtonClasses = OmcFunc.deepVal(options, ['buttons', 'ok', 'classes']) || 'btn-default';
        this.cancelCallback = OmcFunc.deepVal(options, ['buttons', 'cancel', 'action']);
        this.confirmCallback = OmcFunc.deepVal(options, ['buttons', 'ok', 'action']);
    }
}

export class ButtonConfig {
    text: string = 'Button';
    classes: string = 'btn-default';
    constructor(buttonConfig: Partial<ButtonConfig>) {
        Object.assign(this, buttonConfig);
    }
}

export class DefaultDialogConfig {
    title: string = 'Dialog';
    confirmButton: ButtonConfig = new ButtonConfig({ text: buttons.CONFIRM, classes: 'btn-default' });
    cancelButton: ButtonConfig = new ButtonConfig({ text: buttons.CANCEL, classes: 'btn-cancel' });
    data: any;
    constructor(dialogConfig: Partial<DefaultDialogConfig> = {}) {
        Object.assign(this, dialogConfig);
    }
}

export class BatchConfirmDialogData extends DefaultDialogConfig {
    title: string = 'Are you sure?';
    message: string = 'Do you want to proceed with this action?';
    artefacts: any[];
    constructor(dialogConfig: Partial<BatchConfirmDialogData> = {}) {
        super(dialogConfig);
        Object.assign(this, dialogConfig);
        this.artefacts = (dialogConfig.artefacts && dialogConfig.artefacts.map((a) => new selectableArtefact(a))) || [];
    }
}

export class NotificationDetailsDialogData {
    isValid;
    isApprovalRequest;
    isApprovalStatus;
    isApprovalNotification;
    isJobNotification;
    constructor(notification: any = {}) {
        let name = notification?.notificationType?.name;
        this.isValid = true;
        this.isApprovalRequest = name === 'JobApprovalRequestNotification';
        this.isApprovalStatus = name === 'JobApprovalStatusNotification';
        this.isApprovalNotification = this.isApprovalRequest || this.isApprovalStatus;
        this.isJobNotification = name === 'JobRunNotification';
    }
}
