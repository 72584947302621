import * as angular from 'angular';
import { OmcComponent } from '../shared/models/omc.types';
import { OmcFunc } from '../shared/functions/omc.functions';
import { ArtefactDialogData, BatchConfirmDialogData, ButtonConfig } from '../shared/models/dialog-data.model';
import * as moment from 'moment';
import { MatDialogService } from '../shared/services/mat-dialog.service';
import { buttons } from '../constants/app.constants';

/* TODO: Move into dataset dialog [TS 24/11/20] */
class DataSetDialogData extends ArtefactDialogData {
    canEditUsed: any;
    filterValues: any;
    defaultDataInputPath: any;
    constructor(props: Partial<DataSetDialogData> = {}) {
        super(props);
        this.canEditUsed = props.canEditUsed || false;
        this.filterValues = props.filterValues || {};
        this.defaultDataInputPath = props.defaultDataInputPath || '';
    }
    get canEdit() {
        return this.isEdit && (!this.isUsed || this.canEditUsed);
    }
}

let datasetsComponent: OmcComponent = {
    selector: `datasetsComponent`,
    template: require('./datasets.component.html'),
    bindings: {},
    controller: class DatasetsController implements angular.IController {
        selectedRows = [];
        gridHelper: any;
        dialogService: any;
        lang: any;
        dataSetDialogData: DataSetDialogData;
        selectedArtefacts = [];
        isDelDisabled = false;
        isRestDisabled = false;
        isEditUsedByArtefactDisabled = false;
        isExtendedEdit = false;
        dataSets: any[] = [];
        selectedDataSet: any = null;
        filteredDataSets: any[] = [];
        selectedCollections: any[] = [];
        filterValues: {
            treeDates: { sDate: string; eDate: string }[];
            selectedCollections: any[];
            selectedTask: { id: number };
        };
        model: any;
        isEdit: boolean = false;
        defaultDataInputPath: any;
        dataConnections: any;

        constructor(
            private dataService,
            private modalDialogService,
            private gridSettingsService,
            private $rootScope,
            private uiActionsService,
            private gridHelperServiceFactory,
            private filterService,
            private $state,
            private omcGlobalsService,
            private omcDialogService,
            private messagingService,
            private generalDialogService,
            language,
            private gridHelperFunctionService,
            private matDialogService: MatDialogService
        ) {
            this.dialogService = omcDialogService;
            this.lang = language;
        }

        $onInit() {
            this.initialise();
            this.initGrid();
            this.getPermissions();
            this.initServerEventListeners();
            (<any>window).omc.currentVm = this;
            (<any>window).omc.currentVm.refresh = () => {
                this.$state.go(this.$state.current, {}, { reload: true });
                this.getAllDataSets(true);
            };
            this.getAllDataSets(true);
        }

        initialise() {
            this.dataSetDialogData = new DataSetDialogData();
            this.filterValues = {
                treeDates: [
                    {
                        sDate: moment().subtract(2, 'year').format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'),
                        eDate: moment().format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'),
                    },
                ],
                selectedCollections: [],
                selectedTask: { id: 0 },
            };
        }
        initGrid() {
            this.gridHelper = this.gridHelperServiceFactory.gridHelper(
                'datasetsController',
                '#dataset-grid',
                this,
                this.columnDefs()
            );
            this.gridHelper.registerRowsRenderingComplete(() => {
                if (this.selectedRows.length > 0) {
                    this.gridHelper.clearSelectedValues();
                    this.selectedRows.forEach((entity) => {
                        if (this.gridHelper.gridData.data.map((x) => x.id).includes(entity.id)) {
                            this.gridHelper.setSelected(this.gridHelper.gridData.data.find((x) => x.id === entity.id));
                        }
                    });
                }
            });

            this.gridHelper.registerRowClickCallBack((row) => {
                this.selectedRows = []; // clear the selection
            });

            this.gridHelper.gridData.gridId = 'datasets-grid';
            this.gridSettingsService.initialiseGridSettings({
                id: this.gridHelper.gridData.gridId,
                name: 'DataSet-Grid',
                columnsInfo: [],
            });
        }

        getPermissions() {
            this.uiActionsService.getPermissionsForName('tables-edit').then((perm) => {
                this.isEdit = perm && !perm.disabled;
            });
            this.uiActionsService.getPermissionsForName('artefact-delete', true).then((perm) => {
                this.isDelDisabled = perm && perm.disabled;
            });
            this.uiActionsService.getPermissionsForName('artefact-restore', true).then((perm) => {
                this.isRestDisabled = perm && perm.disabled;
            });
            this.uiActionsService.getPermissionsForName('edit-used-artefacts', true).then((perm) => {
                this.isEditUsedByArtefactDisabled = perm?.disabled ?? true;
            });
            /* The naming of this permission is confusing [TS 09/06/20]
                this permission allows users to add tasks to a dataset even after it has been run.
                therefor i am renaming this permission extendedEdit
            */
            this.uiActionsService.getPermissionsForName('limited-edit-datasets', true).then((extendedPerm) => {
                if (extendedPerm) this.isExtendedEdit = !extendedPerm.disabled;
                else this.isExtendedEdit = false;
            });
        }

        initServerEventListeners() {
            this.$rootScope.$on('serverEventHandled:DataSetServerEvent', (event, payload) => {
                // unhandled currently
            });

            this.$rootScope.$on('serverEvent:DataSetServerEvent', (event, payload) => {
                if (payload.forceRefresh) {
                    this.getAllDataSets(true);
                } else {
                    var dataSet = payload.data;
                    if (Array.isArray(dataSet)) {
                        dataSet.map((x) => this.updateDataSet(x));
                    } else {
                        this.updateDataSet(dataSet);
                    }
                    this.dataSets = this.filterService.filterApplyWithModel(this.dataSets, this.filterValues);
                    this.gridHelper.dataReloaded(this.dataSets);
                }
            });
        }
        getAllDataSets(force = false) {
            let url = 'dataSets';
            Promise.all([this.dataSetApiCall(url, force), this.dataConnectionsApiCall(force)]).then(
                ([dataSets, dataConnections]) => {
                    dataSets.forEach((x) => (x.collectionsName = x.collections.map((c) => c.name).join(', ')));
                    dataSets = this.filterService.filterApply(dataSets, this.filterValues, 'dataset');
                    dataSets.forEach((dataset) => {
                        var tasksNames =
                            dataset.dataSetTasks &&
                            dataset.dataSetTasks.length > 0 &&
                            dataset.dataSetTasks.map((m) => m.model.name).join(', ');
                        if (tasksNames !== false) {
                            dataset.taskName = tasksNames; //dataset.model.name;
                        } else {
                            dataset.taskName = '';
                        }
                        dataset.dataInputType = this.omcGlobalsService
                            .values('dataInputTypes')
                            .filter((e) => e.value === dataset.dataInputTypeId)
                            .map((e) => e.description)[0];
                    });
                    dataSets.forEach((a) => {
                        a.isUsedBy = this.dataSets.filter((x) => x.artefactID === a.id)[0]?.isUsedBy;
                    });

                    this.dataConnections = dataConnections;
                    dataSets.forEach((x) => {
                        if (x.dataConnectionId) {
                            x.filePath = dataConnections.find((user) => user.id === x.dataConnectionId).name;
                            x.reference = x.table;
                        }
                    });
                    this.dataSets = dataSets;
                    this.gridHelper.dataReloaded(this.dataSets);
                    this.selectedRows = []; // clear the selection
                    if (this.model.dataSet) {
                        this.selectedRows.push(this.model.dataSet);
                    }
                    let dataConnInputDefault = this.dataConnections.find(
                        (x) => x.isDataConnInputDefault && x.connectionType === 2
                    );
                    if (dataConnInputDefault) {
                        this.defaultDataInputPath = dataConnInputDefault.uncFilePath;
                    }
                },
                (error) => {
                    console.log(error);
                }
            );
        }

        updateDataSet(dataset) {
            var tasksNames =
                dataset.dataSetTasks &&
                dataset.dataSetTasks.length > 0 &&
                dataset.dataSetTasks.map((m) => m.model.name).join(', ');
            if (tasksNames !== false) {
                dataset.taskName = tasksNames; //dataset.model.name;
            } else {
                dataset.taskName = '';
            }
            dataset.collectionsName = dataset.collections.map((c) => c.name).join(', ');
            dataset.dataInputType = this.omcGlobalsService
                .values('dataInputTypes')
                .filter((e) => e.value === dataset.dataInputTypeId)
                .map((e) => e.description)[0];

            var idx = this.dataSets.findIndex((x) => {
                return x.id === dataset.id;
            });
            if (idx > -1) {
                this.dataSets[idx] = dataset;
            } else {
                this.dataSets.push(dataset);
            }

            if (this.selectedRows.indexOf(dataset) < 0) {
                this.selectedRows.push(dataset);
            }
        }

        dataSetApiCall(url, force) {
            return this.dataService.getData(url, url, force);
        }

        dataConnectionsApiCall(force) {
            return this.dataService.getData(
                'dataConnection?skipCurrentUserFilter=true',
                'dataConnection?skipCurrentUserFilter=true',
                force
            );
        }

        applyFilterChanges(data) {
            this.filterValues = data;
            this.model = data.selectedTask;
            this.getAllDataSets(true);
        }

        showUserProfile(id: string | number) {
            this.generalDialogService.userDetails(id);
        }

        editsDisabled() {
            var result = this.gridHelper.selectedValues().length !== 1 || this.gridHelper.selectedValues()[0].isDeleted;
            var result2 = this.editUsedByArtefactDisabled();
            return result || result2;
        }

        editUsedByArtefactDisabled() {
            //Disable the edit button if no row is selected
            if (this.gridHelper.selectedValues().length === 0) {
                return true;
            }
            if (this.isEditUsedByArtefactDisabled === false) {
                return false;
            }
            if (this.isExtendedEdit === true) {
                return false;
            }
            if (
                this.gridHelper.selectedValues().length > 0 &&
                this.gridHelper.selectedValues()[0].usedBy.filter((x) => x.isJobRun === true).length > 0
            ) {
                return this.isEditUsedByArtefactDisabled;
            }
            return false;
        }

        gridRowSelected(item) {
            return this.gridHelper.selectedValues().find((x) => x === item);
        }

        canDeleteArtefact() {
            if (!this.isDelDisabled) {
                var anyDeleted = this.gridHelper.selectedValues().filter((x) => x.isDeleted === true).length;
                return this.gridHelper.selectedValues().length !== 0 && anyDeleted === 0;
            }

            return this.isDelDisabled;
        }

        canRestoreArtefact() {
            if (!this.isRestDisabled) {
                var anyNotDeleted = this.gridHelper.selectedValues().filter((x) => x.isDeleted === false).length;
                return this.gridHelper.selectedValues().length !== 0 && anyNotDeleted === 0;
            }

            return this.isRestDisabled;
        }

        confirmDeleteArtefacts() {
            this.selectedArtefacts = this.gridHelper.selectedValues();
            const batchConfirmDialogData = new BatchConfirmDialogData({
                title: 'Delete Dataset(s)',
                message: 'You are about to delete the following datasets. Do you want to continue?',
                confirmButton: new ButtonConfig({ classes: 'btn-danger', text: buttons.DELETE }),
                artefacts: this.selectedArtefacts
            });
            this.matDialogService.openBatchConfirmDialog(batchConfirmDialogData)
                .subscribe((selectedArtefacts) => {
                    if (selectedArtefacts) {
                        this.deleteArtefacts(selectedArtefacts);
                    }
                });
        }


        confirmRestoreArtefacts() {
            this.selectedArtefacts = this.gridHelper.selectedValues();
            const batchConfirmDialogData = new BatchConfirmDialogData({
                title: 'Restore Dataset(s)',
                message: 'You are about to restore the following datasets. Do you want to continue?',
                confirmButton: new ButtonConfig({ text: buttons.RESTORE }),
                artefacts: this.selectedArtefacts
            });
            this.matDialogService.openBatchConfirmDialog(batchConfirmDialogData)
                .subscribe((selectedArtefacts) => {
                    if (selectedArtefacts) {
                        this.restoreArtefacts(selectedArtefacts);
                    }
                });
        }

        deleteArtefacts(selectedArtefacts) {
            var data = { artefacts: selectedArtefacts.map((a) => a.id) };
            this.dataService.putData(data, 'deleteDataset', 'deleteDataset', false).then(() => {
                this.getAllDataSets(true);
            });
        }

        restoreArtefacts(selectedArtefacts) {
            var data = { artefacts: selectedArtefacts.map((a) => a.id) };
            this,
                this.dataService.putData(data, 'restoreDataset', 'restoreDataset', false).then(() => {
                    this.getAllDataSets(true);
                });
        }

        hideModalDialog() {
            this.modalDialogService.hide();
        }

        openDatasetDialog(id = null) {
            let vm = this;
            try {
                let dataSet;
                if (id) {
                    dataSet = vm.dataSets.find((dataset) => dataset.id === id);
                }
                if (!id || !dataSet) {
                    let selected = vm.gridHelper.selectedValues();
                    dataSet = OmcFunc.isSet(selected, true) ? angular.copy(selected[0]) : {};
                }
                let isUsed = dataSet && dataSet.usedBy && dataSet.usedBy.some((x) => x.isJobRun === true);
                let dialogData = new DataSetDialogData({
                    isUsed: isUsed,
                    canEditUsed: vm.isExtendedEdit,
                    defaultDataInputPath: vm.defaultDataInputPath,
                    filterValues: vm.filterValues,
                });

                return {
                    view: view,
                    add: add,
                    edit: edit,
                    copy: copy,
                };

                function view() {
                    openDialog('Dataset');
                }
                function edit() {
                    let tab = dialogData.isUsed && !dialogData.canEditUsed ? 'linkedTasks' : '';
                    dialogData.assign({
                        isEdit: true,
                        display: tab,
                    });
                    openDialog('Edit Dataset');
                }
                function add() {
                    dialogData.assign({
                        isEdit: true,
                        isAdd: true,
                        isUsed: false,
                        selectDefaultCollection: true,
                    });
                    dataSet = {
                        modelId: 0,
                        name: '',
                        filePath: vm.defaultDataInputPath,
                        collections: [],
                    };
                    openDialog('Add Dataset');
                }
                function copy() {
                    dialogData.assign({
                        isEdit: true,
                        isCopy: true,
                        isAdd: true,
                        isUsed: false,
                    });
                    dataSet.copyFromId = dataSet.id;
                    dataSet.id = 0;
                    dataSet.dataSetTasks = dataSet.dataSetTasks.map((task) => {
                        return {
                            taskType: task.taskType,
                            model: task.model,
                            taskTypeId: task.taskTypeId,
                            modelId: task.modelId,
                        };
                    });
                    openDialog('Copy Dataset');
                }
                function openDialog(title) {
                    vm.dataSetDialogData = dialogData;
                    vm.selectedDataSet = dataSet;
                    vm.omcDialogService.open('edit-dataset', { title: title });
                }
            } catch (e) {
                console.error(e);
                vm.messagingService.displayMessage('An exception occurred - ' + e.message);
            }
        }

        closeDatasetDialog() {
            this.omcDialogService.close('edit-dataset');
        }

        saveDataset() {
            // If the table name is 'schema.table', split and assign the schema to selectedDataSet.schema
            if (!this.selectedDataSet.schema && this.selectedDataSet.table.includes('.')) {
                let parts = this.selectedDataSet.table.split('.').map(part => part.trim());
                if (parts.length === 2) {
                    this.selectedDataSet.schema = parts[0];
                    this.selectedDataSet.table = parts[1];
                }
            }
            this.dataService.putData(this.selectedDataSet, 'dataSets', 'dataSets', false).then(() => {
                this.closeDatasetDialog();
                this.getAllDataSets(true);
            });
        }

        columnDefs() {
            return [
                {
                    name: 'id',
                    displayName: '',
                    cellTemplate:
                        '<div class="grid-row-vertical-bar" ng-click="grid.appScope.gridHelper.toggleSelected(row.entity, $event)" ng-class="{\'grid-row-selected\': grid.appScope.gridRowSelected(row.entity) }" role="button" tabindex="0"></div>',
                    width: this.gridSettingsService.columnWidths.toggleSelect,
                    pinnedLeft: true,
                    cellClass: 'omc-select-column',
                },
                this.gridHelperFunctionService.pinColumnDef(),
                {
                    name: 'isDeleted',
                    displayName: '',
                    cellTemplate: `<span class="ui-grid-cell-contents" ng-class="{'deleted-icon': row.entity.isDeleted}"></span>`,
                    width: this.gridSettingsService.columnWidths.icon,
                },
                {
                    name: 'name',
                    displayName: 'Name',
                    cellTemplate: `<div class="ui-grid-cell-contents" ng-class="{'deleted-row-text': row.entity.isDeleted}"><a href="#" ng-click="grid.appScope.openDatasetDialog(row.entity.id).view(); $event.preventDefault();">{{row.entity.name}}</a></div>`,
                    width: this.gridSettingsService.columnWidths.default,
                },
                {
                    name: 'taskName',
                    displayName: 'Associated Task Name',
                    width: this.gridSettingsService.columnWidths.medium,
                },
                //{
                //    name: "isUsedBy", displayName: 'Is Used By', headerCellClass: 'CompiledTask', width: gridSettingsService.columnWidths.default
                //},
                {
                    name: 'dataInputType',
                    displayName: 'Type',
                    width: this.gridSettingsService.columnWidths.default,
                },
                {
                    name: 'filePath',
                    displayName: 'Source',
                    width: 500,
                },
                {
                    name: 'sizeDesc',
                    displayName: 'Size',
                    width: this.gridSettingsService.columnWidths.number,
                },
                {
                    name: 'collectionsName',
                    displayName: 'Collections',
                    headerCellClass: 'CompiledTask',
                    width: this.gridSettingsService.columnWidths.default,
                },
                {
                    name: 'referenceName',
                    displayName: 'Reference',
                    cellTemplate: `<div class="ui-grid-cell-contents" ng-class="{'deleted-row-text': row.entity.isDeleted}">{{row.entity.reference}}</div>`,
                    width: this.gridSettingsService.columnWidths.default,
                },
                {
                    name: 'notes',
                    displayName: 'Notes',
                    width: this.gridSettingsService.columnWidths.notes,
                },
                {
                    name: 'dateCreated',
                    displayName: 'Date Created',
                    headerCellClass: 'DateCreated',
                    cellFilter: 'omcDate',
                    width: this.gridSettingsService.columnWidths.dateTime,
                },
                {
                    name: 'modifiedDateTime',
                    displayName: 'Date Modified',
                    headerCellClass: 'DateCreated',
                    cellFilter: 'omcDate',
                    width: this.gridSettingsService.columnWidths.dateTime,
                },
                {
                    name: 'createdById',
                    displayName: 'Created By',
                    headerCellClass: 'Created-By-Name',
                    width: this.gridSettingsService.columnWidths.userName,
                    cellTemplate:
                        '<div class="ui-grid-cell-contents"><a href="#" ng-click="grid.appScope.showUserProfile(row.entity.createdById); $event.preventDefault();">{{row.entity.createdById}}</a></div>',
                },
                {
                    name: 'checksum',
                    displayName: 'Checksum',
                    width: this.gridSettingsService.columnWidths.default,
                },
                {
                    name: 'effectiveDate',
                    displayName: 'Effective Date',
                    headerCellClass: 'DateCreated',
                    cellFilter: 'omcDate',
                    width: this.gridSettingsService.columnWidths.dateTime,
                },
            ];
        }
    },
};

angular.module('omc').component(datasetsComponent.selector, datasetsComponent);
