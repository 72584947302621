import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import * as angular from 'angular';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
    providedIn: 'root'
  })
export class UserCookieService {
    key = 'usrlgdin';
    userKey = 'useremail';
    trueKey = '!';

    constructor(private cookieService: CookieService) {}

    addUserCookie() {
        this.setUserCookie(this.trueKey);
    }

    deleteUserCookie() {
        this.setUserCookie('');
    }

    rememberUser(val) {
        this.cookieService.set(this.userKey, val);
    }

    getUserEmail() {
        return this.cookieService.get(this.userKey);
    }

    forgetUser() {
        this.cookieService.delete(this.userKey);
    }

    setUserCookie(val) {
        this.cookieService.set(this.key, val);
    }

    userCookieExists() {
        let val = this.cookieService.get(this.key);
        return val === this.trueKey;
    }
}
angular.module('omc').factory('userCookieService', downgradeInjectable(UserCookieService));
