import { ArtefactDialogData } from "../../shared/models/dialog-data.model";
import * as angular from 'angular'
import { OmcComponent } from '../../shared/models/omc.types'

class DialogData extends ArtefactDialogData {
    logLevels: any;
    jobTemplateStatuses: any;
    constructor(props: Partial<DialogData> = {}) {
        super(props)
        this.logLevels = props.logLevels || [];
        this.jobTemplateStatuses = props.jobTemplateStatuses || [];
    }
}

let jobTemplatesComponent: OmcComponent = {
    selector: `jobTemplatesComponent`,
    template: require("./job-templates.component.html"),
    controller: class jobTemplatesController implements angular.IController {
        lang: any;
        dialogService: any;

        logLevels: any;
        jobTemplateStatuses: any;
        jobTempGridHelper: any;
        roleTempGridHelper: any;
        
        jobTemplates: any[];
        roleTemplates: any[];
        selectedJobTemplate: any;
        jobTemplateDialogData: any;
        selectedRoleTemplate: any;
        roleTemplateDialogData: any;

        constructor(
            private dataService,
            private modalDialogService,
            private gridSettingsService,
            private gridHelperServiceFactory,
            private omcDialogService,
            private generalDialogService,
            private language,
        ) {
            this.lang = language;
            this.dialogService = omcDialogService;
        }

        $onInit() {
            this.initJobTemplatesGrid()
            this.initRoleTemplateGrid()
            this.getLogLevels();
            this.getJobTemplateStatuses();
            this.getJobTemplates(true);
            this.getRoleTemplates(true);
        }

        initJobTemplatesGrid() {
            this.jobTempGridHelper = this.gridHelperServiceFactory
                .gridHelper('jobTemplates', '#jobTemplates-grid', this, this.columnDefs());
            this.jobTempGridHelper.gridData.gridId = 'jobs-templates-grid';
            this.gridSettingsService.initialiseGridSettings({
                id: this.jobTempGridHelper.gridData.gridId,
                name: 'JobTemplates-Grid',
                columnsInfo: []
            });
            this.jobTempGridHelper.gridData.modifierKeysToMultiSelect = false;
            this.jobTempGridHelper.gridData.multiSelect = false;
            this.jobTempGridHelper.registerRowsRenderingComplete(() => {
                if (this.selectedJobTemplate?.name) {
                    this.jobTempGridHelper.setSelected(this.jobTemplates.find(x => x.name === this.selectedJobTemplate.name))
                }
            });
        }

        initRoleTemplateGrid() {
            this.roleTempGridHelper = this.gridHelperServiceFactory
                .gridHelper('roleTemplates', '#roleTemplates-grid', this, this.roleColumnDefs());
            this.roleTempGridHelper.gridData.gridId = 'roles-templates-grid';
            this.gridSettingsService.initialiseGridSettings({
                id: this.roleTempGridHelper.gridData.gridId,
                name: 'RoleTemplates-Grid',
                columnsInfo: []
            });
        }

        gridRowSelectedTemplate(item) {
            return this.jobTempGridHelper.selectedValues().find(x => x === item);
        };
        gridRowSelectedRole(item) {
            return this.roleTempGridHelper.selectedValues().find(x => x === item);
        };

        editsDisable() {
            return this.jobTempGridHelper.selectedValues().length !== 1
        };

        setDefault() {
            let selectedValues = this.jobTempGridHelper.selectedValues()
            if(selectedValues.length > 0){
                this.selectedJobTemplate = angular.copy(selectedValues[0]);
                if (selectedValues.length === 1) {
                    selectedValues[0].setAppDefault();
                }
                this.getJobTemplates(true);
            }
        }

        getJobTemplates(force) {
            this.dataService.getData("JobTemplates", "JobTemplates?skipCurrentUserFilter=true", force).then((data)=> {
                this.jobTemplates = data;
                this.jobTempGridHelper.dataReloaded(this.jobTemplates);
            }, (error)=> {
                console.log(error);
            });
        }

        getRoleTemplates(force) {
            this.roleTemplates = [];
            this.dataService.getData("roleTemplates", "roleTemplates", force).then((data)=> {
                this.roleTemplates = data;
                this.roleTempGridHelper.dataReloaded(this.roleTemplates);
            }, (error)=> {
                console.log(error);
            });
        }

        getLogLevels() {
            this.dataService.getData("logLevels", "logLevels", true).then((data)=> {
                this.logLevels = data;
            }, (error)=> {
                console.log(error);
            });
        }

        getJobTemplateStatuses() {
            this.dataService.getData("jobTemplateStatuses", "jobTemplateStatuses", true).then((data)=> {
                this.jobTemplateStatuses = data;
            }, (error)=> {
                console.log(error);
            });
        }

        deleteDisabled() {
        }

        restoreDisabled() {
        }

        // old dialog service
        cancel() {
            this.modalDialogService.hide();
        }

        showUserProfile(id: string | number) {
            this.generalDialogService.userDetails(id)
        }

        columnDefs() {
            return [
                {
                    name: 'id',
                    displayName: '',
                    cellTemplate: '<div class="grid-row-vertical-bar" ng-click="grid.appScope.gridHelper.toggleSelected(row.entity, $event)" ng-class="{\'grid-row-selected\': grid.appScope.gridRowSelectedTemplate(row.entity) }" role="button" tabindex="0"></div>',
                    width: this.gridSettingsService.columnWidths.toggleSelect,
                    pinnedLeft: true,
                    cellClass: "omc-select-column"
                },
                {
                    name: 'name',
                    displayName: 'Name',
                    cellTemplate: '<div class="ui-grid-cell-contents" ng-class="{\'default-icon\': row.entity.isAppDefault,\'deleted-row-text\': row.entity.jobTemplateStatusId !== 0}"><a href="#" ng-click="grid.appScope.jobTemplateDialog(row.entity.id).view(); $event.preventDefault();">{{row.entity.name}}</a></div>',
                    width: this.gridSettingsService.columnWidths.default
                },
                {
                    name: "logLevel.name",
                    displayName: 'Logging Level',
                    width: this.gridSettingsService.columnWidths.medium
                },
                {
                    name: "jobTemplateStatus.name",
                    displayName: "Status",
                    width: this.gridSettingsService.columnWidths.default
                },
                {
                    name: "writeLimit",
                    displayName: "Maximum Number Logs",
                    width: this.gridSettingsService.columnWidths.default
                },
                {
                    name: "abortLimit",
                    displayName: "Maximum Number Errors",
                    width: this.gridSettingsService.columnWidths.default
                },
                {
                    name: "verifyDatasetChecksum",
                    displayName: "Fail job on Data Checksum Changed",
                    width: this.gridSettingsService.columnWidths.default
                },
                {
                    name: "dateCreated",
                    displayName: 'Date Created',
                    headerCellClass: 'DateCreated',
                    cellFilter: 'omcDate',
                    width: this.gridSettingsService.columnWidths.dateTime
                },
                {
                    name: "createdById",
                    displayName: 'Created By',
                    headerCellClass: 'Created-By-Name',
                    width: this.gridSettingsService.columnWidths.userName,
                    cellTemplate: '<div class="ui-grid-cell-contents"><a href="#" ng-click="grid.appScope.showUserProfile(row.entity.createdById); $event.preventDefault();">{{row.entity.createdById}}</a></div>'
                }];
        }

        roleColumnDefs() {
            return [
                {
                    name: "roleId",
                    displayName: "",
                    cellTemplate: '<div class="grid-row-vertical-bar" ng-click="grid.appScope.gridHelper.toggleSelected(row.entity, $event)" ng-class="{\'grid-row-selected\': grid.appScope.gridRowSelectedRole(row.entity) }" role="button" tabindex="0"></div>',
                    width: this.gridSettingsService.columnWidths.toggleSelect,
                    pinnedLeft: true,
                    cellClass: "omc-select-column"
                },
                {
                    name: "roleDescription",
                    displayName: 'Role',
                    width: this.gridSettingsService.columnWidths.default
                },
                {
                    name: "jobTemplatesDesc",
                    displayName: 'Template(s)',
                    width: this.gridSettingsService.columnWidths.medium
                }
            ];
        }

        jobTemplateDialog(id = null) {
            let jobTemplate;
            let vm = this;
            if (id) {
                jobTemplate = angular.copy(vm.jobTemplates.find(t => t.id === id));
            } else {
                jobTemplate = angular.copy(vm.jobTempGridHelper.selectedValues()[0]);
            }
            let dialogData = new DialogData({
                logLevels: vm.logLevels,
                jobTemplateStatuses: vm.jobTemplateStatuses
            });

            return {
                add: add,
                view: view,
                edit: edit,
            }

            function add() {
                jobTemplate = {
                    logLevelId: 0,
                    distributionStrategyId: 0,
                    aggregateWorkerOutput: true,
                }
                dialogData.assign({
                    isEdit: true,
                    isNew: true,
                    usedNames: vm.jobTemplates.map(t => t.name),
                })
                openDialog('Add Job Template')
            }

            function view() {
                if (!jobTemplate) return;
                openDialog('Job Template')
            }

            function edit() {
                if (!jobTemplate) return;
                dialogData.assign({
                    isEdit: true,
                    usedNames: vm.jobTemplates
                        .filter(t => t.id !== jobTemplate.id)
                        .map(t => t.name),
                })
                openDialog('Edit Job Template')
            }
            function openDialog(title) {
                vm.selectedJobTemplate = jobTemplate;
                vm.jobTemplateDialogData = dialogData;
                vm.omcDialogService.open('edit-job-template', { title: title })
            }
        }

        saveJobTemplate() {
            let url = 'jobTemplates'
            this.dataService
                .putData(this.selectedJobTemplate, url, url, false)
                .then(() => {
                    this.omcDialogService.close('edit-job-template')
                    this.getJobTemplates(true);
                });
        }

        editRoleTemplate() {
            let selected = this.roleTempGridHelper.selectedValues()
            if (selected.length !== 1) return;
            this.selectedRoleTemplate = angular.copy(selected[0])
            this.roleTemplateDialogData = { isValid: true, hasChanged: false }
            this.omcDialogService.open('edit-role-template', { title: 'Role Template' })
        }

        saveRoleTemplate() {
            if (!this.selectedRoleTemplate) return
            let url = 'updateRoleTemplate'
            this.dataService.postData(this.selectedRoleTemplate, url, url, false).then(()=> {
                this.dialogService.close('edit-role-template')
                this.getRoleTemplates(true);
            });
        }
    }
}

angular
    .module('omc')
    .component(jobTemplatesComponent.selector, jobTemplatesComponent)