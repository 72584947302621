import './admin.component';
import './audit/audit-entry.component';
import './audit/csv-formatting.service';
import './collections/collections.component';
import './collections/edit-collection/edit-collection.component';
import './data-connection/data-connection.component';
import './data-connection/edit-data-connection/edit-data-connection.component';
import './data-connection/edit-default-data-connection/edit-default-data-connection.component';
import './file-management/file-management.component';
import './file-uploader/file-uploader.component';
import './job-settings/job-settings.component';
import './job-settings/edit-job-priorities/edit-job-priorities.component';
import './job-settings/edit-job-type/edit-job-type.component';
import './job-templates/job-templates.component';
import './job-templates/edit-job-template/edit-job-template.component';
import './job-templates/edit-role-template/edit-role-template.component';
import './platform-settings/platform-settings.component';
import './services-settings/services-settings.component';
import './services-settings/edit-services/edit-services.component';
import './task-types/task-types.component.ts';
import './task-types/edit-task-type/edit-task-type.component';