import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import * as angular from 'angular';

import { OmcComponent } from '../models/omc.types';

class IconData {
    name: string;
    data: string;
    constructor() { };
};

let salIconComponent: OmcComponent = {
    selector: 'salIcon',
    template: '',
    bindings: {
        name: "@"
    },
    controller: class SalIconController {
        private svgIcon: SVGElement;
        name: string;
        constructor(
            private $scope,
            private IconRegistryService,
            private $element,
            private $document,
        ) {
            var vm = this;

            $scope.$watch(
                function () {
                    return vm.name
                },
                this.updateIcon.bind(this)
            )
        }

        updateIcon(iconName: string) {
            if (this.svgIcon) {
                this.$element.nativeElement.removeChild(this.svgIcon)
            }
            const svgData = this.IconRegistryService.getIcon(iconName)
            this.svgIcon = this.svgElementFromString(svgData)
            this.$element.append(this.svgIcon)
        }


        svgElementFromString(svgContent: string | undefined): SVGElement {
            const div = this.$document[0].createElement('DIV')
            div.innerHTML = svgContent || '';
            return (div.querySelector('svg') || this.$document[0].createElementNS('http://www.w3.org/2000/svg', 'path'))
        }
    }
}

@Injectable({
    providedIn: 'root'
  })
export class IconRegistry {  
    private registry = new Map();
  
    constructor(){
  }
      registerIcons(icons: IconData[]): void {
      let service = this;
    icons.forEach(icon => {
      service.registry.set(icon.name, icon.data)
    })
  }
   getIcon(iconName: string): string | undefined {
    if (!this.registry.has(iconName)) {
      console.warn(`Could not find the icon named ` + iconName + ` in the registry`)
    }
    return this.registry.get(iconName)
  }
}

angular.module("iconRegistryModule", [])
    .factory('IconRegistryService', downgradeInjectable(IconRegistry) )
    .component(salIconComponent.selector, salIconComponent)