import * as angular from 'angular'
import * as moment from 'moment';
import { OmcComponent } from 'shared/models/omc.types'
import Chart from 'chart.js';

let systemDashboardComponent: OmcComponent = {
    selector: `systemDashboardComponent`,
    template: require("./system-dashboard.component.html"),
    controller: class SystemDashboardController implements angular.IController {
        dashBoardInfo:any;
        loginInfoChartSettings: any;
        constructor(
            private dataService,
            private $rootScope,
        ){
            
        }
        
        $onInit(){
            this.dashBoardInfo = {
                addedArtefactsLast7Days: [],
                modifiedArtefactsLast7Days: [],
                logins: [],
                totalLogins: 0,
                criticalAlerts: 0,
                warningAlerts: 0
            };
            this.refreshDashboard = this.refreshDashboard;
        
            this.initialiseCharts();
            this.refreshDashboard();
        }
        
        initialiseCharts() {
            this.initialiseLoginsInfoChart();
            this.initialiseAddedArtefactsInfoChart();
            this.initialiseModifiedArtefactsInfoChart();
        }
    
        initialiseLoginsInfoChart() {
            var settings = {
                labels: [],
                values: [],
                type: 'doughnut',
                backgroundColors: [
                    '#ba141a',
                    '#ff8c00',
                    '#fcd116',
                    '#00bbf1'
                ],
                options: {
                    legend: {
                        display: false
                    }
                }
            };
            this.loginInfoChartSettings = this.chartSettings(settings);
        }
       
        initialiseAddedArtefactsInfoChart() {
    
            var labels = this.dashBoardInfo.addedArtefactsLast7Days.map((item) => { return item.label; });
            var values = this.dashBoardInfo.addedArtefactsLast7Days.map((item) => { return item.value; });
    
            var data = {
                labels: labels,
                datasets: [
                    {
                        backgroundColor: '#0072c6',
                        data: values
                    }
                ]
            };
            var option = {
                scales: {
                    yAxes: [{
                        //stacked: true,
                        gridLines: {
                            display: false,
                            color: "rgba(0, 0, 0, 0)"
                        }
                    }],
                    xAxes: [{
                        barPercentage: 1.1,
                        gridLines: {
                            display: false,
                            color: "rgba(0, 0, 0, 0)"
                        },
                        ticks: {
                            autoSkip: true,
                            maxTicksLimit: 4,
                            maxRotation: 0,
                            minRotation: 0
                        }
                    }]
                },
                legend: {
                    display: false
                }
            };
            var settings = {
                type: 'bar',
                data: data,
                options: option
            };
    
            var ctx = (<HTMLCanvasElement>document.getElementById('addedArtefactsChartSettings')).getContext('2d');
            new Chart(ctx, settings);
        }
    
        initialiseModifiedArtefactsInfoChart() {
    
            var labels = this.dashBoardInfo.modifiedArtefactsLast7Days.map((item) => { return item.label; });
            var values = this.dashBoardInfo.modifiedArtefactsLast7Days.map((item) => { return item.value; });
       
            var data = {
                labels: labels,
                datasets: [
                    {
                        backgroundColor: '#0072c6',
                        data: values
                    }
                ]
            };
            var option = {
                scales: {
                    yAxes: [{
                        //stacked: true,
                        gridLines: {
                            display: false,
                            color: "rgba(0, 0, 0, 0)"
                        }
                    }],
                    xAxes: [{
                        barPercentage: 1.1,
                        gridLines: {
                            display: false,
                            color: "rgba(0, 0, 0, 0)"
                        },
                        ticks: {
                            autoSkip: true,
                            maxTicksLimit: 4,
                            maxRotation: 0,
                            minRotation: 0
                        }
                    }]
                },
                legend: {
                    display: false
                }
            };
    
            var settings = {
                type: 'bar',
                data: data,
                options: option
            };
    
            var ctx = (<HTMLCanvasElement>document.getElementById('modifiedArtefactsChartSettings')).getContext('2d');
            new Chart(ctx, settings);
        }
    
        refreshDashboard() {
            this.dataService.getData("systemDashboard", "systemDashboard", true).then((data) => {
                if (data) {
                    this.dashBoardInfo = data;
                    this.dashBoardInfo.jobMonitorDate = moment(this.dashBoardInfo.jobMonitor.monitorDate).format('dddd DD MMMM YYYY');
                    this.initialiseLoginsInfo();
                    this.initialiseAddedArtefactsInfoChart();
                    this.initialiseModifiedArtefactsInfoChart();
                }
            }, (error) => {
                console.log(error);
            });
        }
    
        chartSettings(settings) {
            return {
                type: settings.type,
                data: {
                    labels: settings.labels,
                    datasets: [
                        {
                            fill: true,
                            backgroundColor: settings.backgroundColors,
                            data: settings.values,
                            weight: 1
                        }
                    ]
                },
                options: settings.options
            };
        }
    
        initialiseLoginsInfo() {
            var settings = {
                labels: this.dashBoardInfo.logins.map((item) => { return item.label; }),
                values: this.dashBoardInfo.logins.map((item) => { return item.value; })
            };
    
            this.dashBoardInfo.totalLogins = 0;
            this.dashBoardInfo.totalLogins = settings.values.reduce((x, y) => x + y);
            this.loginInfoChartSettings.data.datasets[0].data = settings.values;
            this.loginInfoChartSettings.data.labels = settings.labels;
            this.$rootScope.$emit("chartDataUpdated", "loginInfoChartSettings");
        }
        
    }
}

angular
    .module('omc')
    .component(systemDashboardComponent.selector, systemDashboardComponent)