import * as angular from 'angular'
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { downgradeComponent } from "@angular/upgrade/static";
import { pathToApp } from '../../core/constants/global-constants';

@Component({
    selector: 'changeCompanyComponent',
    templateUrl: pathToApp + 'user/change-company/change-company.component.html',
})
export class ChangeCompanyComponent {
    @Input() activeCompanyId: any = null;
    @Input() companies: any[] = [];
    @Output() onChangeCompanyId = new EventEmitter<number>();

    constructor() {}
    
    changeCompanyId() {
        this.onChangeCompanyId.emit(this.activeCompanyId)
    }
}
angular
    .module('omc')
    .directive('changeCompanyComponent', downgradeComponent({
        component: ChangeCompanyComponent,
        inputs: ['activeCompanyId', 'companies'],
        outputs: ['onChangeCompanyId']
    }));
