import * as angular from 'angular'
import { OmcComponent } from 'shared/models/omc.types';

export class OmcDialogViewController implements angular.IController {
    id;
    /* hideClose option is not really set so is always shown by default - to review [TM 06/06/22] */
    hideClose: boolean = false;
    dialogTitle;

    constructor(
        private omcDialogService
    ) {
    }

    $onInit() {
    }

    close() {
        this.omcDialogService.close(this.id);
    }

}

const OmcDialogViewComponent: OmcComponent = {
    selector: `omcDialogViewComponent`,
    template: require('./omc-dialog-view.component.html'),
    transclude: { "buttons": "?dialogButtons" },
    bindings: {
        id: "@?",
        dialogTitle: "@?",
        hideClose: "@?"
    },
    controller: OmcDialogViewController
}

angular
    .module('omc')
    .component(OmcDialogViewComponent.selector, OmcDialogViewComponent)

