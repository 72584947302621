import { Component, Input, OnInit } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import * as angular from 'angular';
import { DataService } from '../../../shared/services/data.service';
import { UsersService } from '../../../shared/services/users.service';

@Component({
    selector: 'sendCustomNotificationComponent',
    templateUrl: 'WebApp/src/app/user/user-status/send-notification/send-custom-notification.component.html',
})
export class SendCustomNotificationComponent implements OnInit {
    @Input() model: any;

    availableUsers = [];
    availableUsersSelected = [];
    recipientsSelected = [];
    recipients = [];
    notificationMessage: any;
    canSend = this.recipientsSelected && this.recipientsSelected.length == 0;

    constructor(private usersService: UsersService, private dataService: DataService) {}

    ngOnInit() {
        this.getUsers();
    }

    getUsers() {
        this.usersService.getUsers(true).then((users: Array<any>) => {
            this.availableUsers = [...users.filter((u) => u.isActive)];
            let usersToRemove = [];
            
            for (let availableUser of this.availableUsers) {
                if (availableUser.roles && availableUser.roles.find((x) => x.application === 'OMC') === undefined) {
                    usersToRemove.push(availableUser);
                }
            }

            for (let userToRemove of usersToRemove) {
                let user = this.availableUsers.find((x) => x.userId === userToRemove.userId);
                if (user !== undefined) {
                    let index = this.availableUsers.indexOf(user);
                    this.availableUsers.splice(index, 1);
                }
            }
            this.availableUsers = [...this.availableUsers];
            this.availableUsersSelected = [];
            this.recipientsSelected = [];
        });
    }

    toggleAvailableSelected(user) {
        this.toggleInArray(user, this.availableUsersSelected);
        this.updateCanSend();
    }

    toggleRecipientsSelected(user) {
        this.toggleInArray(user, this.recipientsSelected);
        this.updateCanSend();
    }

    updateCanSend() {
        this.canSend =
            this.recipients &&
            this.notificationMessage &&
            this.recipients.length > 0 &&
            this.notificationMessage !== '';
    }

    sendNotification() {
        let vmSendNotification = {
            recipients: this.recipients.map((c) => {
                return { value: c.id };
            }),
            message: this.notificationMessage,
        };
        this.dataService
            .postData(vmSendNotification, 'sendNotification', 'sendNotification', true)
            .then(() => this.model.cancel());
    }

    recipientsAddSelected(user) {
        if (user && !this.recipientsSelected.includes(user)) {
            this.availableUsersSelected.push(user);
        }

        this.availableUsersSelected.forEach((user) => this.moveUserArray(user, this.availableUsers, this.recipients));
        this.recipientsSelected = [];
        this.availableUsersSelected = [];
        this.updateCanSend();
    }

    recipientsRemoveSelected(user) {
        if (user && !this.recipientsSelected.includes(user)) {
            this.recipientsSelected.push(user);
        }
        this.recipientsSelected.forEach((user) => this.moveUserArray(user, this.recipients, this.availableUsers));
        this.recipientsSelected = [];
        this.availableUsersSelected = [];
        this.updateCanSend();
    }

    toggleInArray(user, array) {
        let idx = array.findIndex((x) => x === user);
        if (idx === -1) {
            array.push(user);
        } else {
            array.splice(idx, 1);
        }
    }

    moveUserArray(user, arrayFrom, arrayTo) {
        let idx = arrayFrom.findIndex((x) => x === user);
        if (idx > -1) {
            arrayFrom.splice(idx, 1);
            arrayTo.push(user);
        }
    }
}

angular.module('omc').directive(
    'sendCustomNotificationComponent',
    downgradeComponent({
        component: SendCustomNotificationComponent,
        inputs: ['model'],
    })
);
