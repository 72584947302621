import * as angular from 'angular'
import { DataService } from './data.service';
import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';

@Injectable({
    providedIn: 'root'
  })
export class PlatformSettingsService {

    platformSettings = null;
    requestSent = false;

    constructor(
        private dataService: DataService
    ) { }

    getSettings(force) {
        return new Promise((resolve) => {
          
        if (!force && this.platformSettings) {
            return this.platformSettings;
        }
    
        if (!this.requestSent) {
            this.requestSent = true;
            this.dataService.getData("platformSettings", "platformSettings", force, false)
                .then((data: {signInMethod: any}) => {
                    data.signInMethod = data.signInMethod || "email";
                    resolve(data);
                    //platformSettings = data;
                })
                .catch((error) => {
                    console.error(
                    "platformSettingsService.getSettings: error - " + error.message
                    );
                })
                .finally(() => {
                    this.requestSent = false;
                });
            }
        });
    }

    saveSettings(settings, reloadRequired) {
        settings.jobTypes.map((jobType) => {
            if (jobType.statusVal) {
                jobType.status = jobType.statusVal.id;
            }
        });

        if (reloadRequired === undefined)
            reloadRequired = true;

        return this.dataService.putData(settings, 'platformSettings', 'platformSettings', reloadRequired);
    }

    getServices(force) {
        return new Promise ((resolve) => {
            this.dataService.getData('getServices', 'getServices', force).then((data) => {
                resolve(data);
            });
        })
    }
}

angular
    .module('omc')
    .factory('platformSettingsService', downgradeInjectable(PlatformSettingsService))
