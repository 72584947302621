import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import * as angular from 'angular';
import { DataService } from './data.service';

@Injectable({
    providedIn: 'root'
  })
export class CollectionsService {

    constructor(
        private dataService: DataService,
        ) {}

    getCollections(force, globalSpinner = true) {
        const url = 'collection?skipCurrentUserFilter=true';
        return this.dataService.getData(url, url, force, globalSpinner);
    }

    /* Unused helper functions removed in OMC - 1321 - getSelectedCollections(collections), updateSelectedCollections(collections, selectedCollections, addNew) [TM 04/07/22] */
}

angular.module('omc').factory('collectionsService', downgradeInjectable(CollectionsService) );
