import * as angular from "angular";
import { Component, Inject, OnInit } from "@angular/core";
import { downgradeComponent } from "@angular/upgrade/static";
import { pathToApp } from "../../../core/constants/global-constants";
import { MAT_DIALOG_DATA } from "@angular/material";
import { DefaultDialogConfig } from "../../../shared/models/dialog-data.model";

@Component({
    selector: `edit-collection-component`,
    templateUrl: pathToApp + 'admin/collections/edit-collection/edit-collection.component.html'
})
export class EditCollectionComponent implements OnInit {

    isLoaded: boolean;
    jobTypeIsActive: boolean;

    constructor(@Inject(MAT_DIALOG_DATA) public matDialogData: DefaultDialogConfig) {}

    ngOnInit(): void {
        this.isLoaded = !!this.matDialogData.data.collection && !!this.matDialogData.data.formData
        this.jobTypeIsActive = this.matDialogData.data.collection?.statusVal?.id === 1;
    }

    isInvalid(key?: string) {
        const errors = this.validate();
        this.matDialogData.data.formData.isValid = errors.isValid();
        if (key) {
            return errors[key];
        }
        return !errors.isValid();
    }

    errorMessageFor() {
        return this.validate();
    }

    setStatus() {
        this.matDialogData.data.collection.status = this.jobTypeIsActive ? 1 : 0;
    }

    setChanged() {
        this.matDialogData.data.formData.hasChanged = true;
    }

    validate() {
        let hasErrors = false;
        let rtn: any = {
            isValid: () => !hasErrors
        };
    
        if (this.matDialogData.data.formData.isEdit && !this.matDialogData.data.collection.name) {
            rtn.name = "Please specify a name for collection";
            hasErrors = true;
        }
    
        if (this.matDialogData.data.formData.isAdd && this.matDialogData.data.collection && this.matDialogData.data.collection.name) {
            if (this.matDialogData.data.formData.usedNames.includes(this.matDialogData.data.collection.name)) {
                rtn.name = "Name is already in use, please use a different name";
                hasErrors = true;
            } 
        }
        return rtn;
    }
}
angular.module('omc').directive(
    'edit-collection-component',
    downgradeComponent({
        component: EditCollectionComponent
    })
)