import { Component, Inject, OnInit } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import * as angular from 'angular';
import { StateService } from '../../ajs-upgraded-providers';
import { pathToApp } from '../../core/constants/global-constants';
import { CurrentUserService } from '../../shared/services/current-user.service';

@Component({
    selector: 'resetPasswordComponent',
    templateUrl: pathToApp + '/user/reset-password/reset-password.component.html',
})
export class ResetPasswordComponent implements OnInit {
    password = '';
    confirmPassword = '';
    notVerifiedPassword = 'passwordNotVerified';
    resetPage = 'resetPage';
    passwordResetSuccess = 'resetPasswordSuccess';
    passwordReset = false;
    tokenVerificationMessage = '';
    forgetPasswordErrorResponse = '';
    strongPasswordMessage =
        'Strong password required. Enter 8-16 characters. Do not include common words or names. Combine uppercase letter, lowercase letters, numbers and symbols';
    token: string;
    showScreen: string;

    constructor(private currentUserService: CurrentUserService, @Inject(StateService) private state) {}

    ngOnInit() {
        this.token = this.state.params['t'];
        this.verifyPasswordToken();
    }

    resetPassword() {
        var resetPasswordVm = {
            token: this.token,
            password: this.password,
            confirmPassword: this.confirmPassword,
        };
        this.currentUserService.resetPassword(resetPasswordVm).then(
            () => {
                this.showScreen = this.passwordResetSuccess;
                this.tokenVerificationMessage = 'Your password has been reset';
            },
            (reason) => {
                this.showScreen = this.resetPage;
                this.forgetPasswordErrorResponse = reason.error.message;
            }
        );
    }
    verifyPasswordToken() {
        this.currentUserService.verifyPasswordToken(this.token).then(
            (response) => {
                if (response === false) {
                    this.showScreen = this.notVerifiedPassword;
                    this.tokenVerificationMessage =
                        'The token has been expired. Kindly send reset password request again.';
                } else if (response === true) {
                    this.showScreen = this.resetPage;
                }
            },
            () => {
                this.showScreen = this.notVerifiedPassword;
            }
        );
    }

    redirectToLogin() {
        this.state.go('login');
    }
}
angular.module('omc').directive(
    'resetPasswordComponent',
    downgradeComponent({
        component: ResetPasswordComponent,
    })
);
