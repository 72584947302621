import * as angular from 'angular'
import { OmcComponent } from 'shared/models/omc.types'

let homeComponent: OmcComponent = {
    selector: `homeComponent`,
    template: require("./home.component.html"),
    controller: class HomeController implements angular.IController {

        homeTotalsSizeInterval: NodeJS.Timeout
        totals: any

        constructor(
        	private uiActionsService,
            private currentUserService,
            private messagingService,
            private redirectionService,
            private dataService,
            private $timeout,
        ){
            
        }
        
        $onInit(){
            this.checkPermissions()
            localStorage.setItem("selectedItem", null); /* TODO: Add to app Store instead [TS 29/12/20] */
            this.totals = JSON.parse(sessionStorage.getItem('homeTotals')) /* TODO: Add to app Store instead [TS 29/12/20] */
            this.getHomeTotals();
            this.$timeout(()=> this.applyFontsize());
            (<any>window).omc.currentVm = this;
            (<any>window).omc.currentVm.refresh = () => this.getHomeTotals();
        }

        checkPermissions(){
            this.currentUserService.getCurrentUser().then((user) => {
                if (user) {
                    this.uiActionsService.uiActions().then((uiActions) => {
                        var action = this.findFirstAction(uiActions);
                        if (action) {
                            //$state.go(action.routeName);
                            return null;
                        } else {
                            this.messagingService.displayMessage("No permission to access site. Please try to log in with a different account", "No Permissions", this.messagingService.messageLevels.warning);
                            console.log("Current user has no available actions. Logging out");
                            this.currentUserService.logOut("");
                        }
                        return null;
                    });
                } else {
                    this.redirectionService.redirectToLogin('home');
                }
            });
        }

        getHomeTotals(){
            this.dataService.getData('homeTotals', 'updateHomeTotals', true).then((totals) => {
                this.totals = totals;
                this.addToSessionStorage(totals); /* TODO: Add to app Store instead [TS 29/12/20] */
                this.$timeout(()=> this.applyFontsize());
            });
        }
    
        addToSessionStorage(homeTotals) {
            sessionStorage.removeItem('homeTotals');
            sessionStorage.setItem('homeTotals', JSON.stringify(homeTotals));
        }
    
        findFirstAction(uiActions) {
            //for (var n = 0; n < uiActions.length; n++) {
            var rtn = uiActions.find(x=>x.routeName);
            if (rtn) {
                return rtn;
            }
            //}
            return null;
        }
    
        applyFontsize() {
            var spans = $('.font-total-number');
            for (var i = 0; i < spans.length; i++) {
                var number = parseInt(spans[i].innerHTML);
                if (number > 99) {
                    $("#" + spans[i].id + "").addClass('home-total-style');
                }
            }
        }
        
    }
}

angular
    .module('omc')
    .component(homeComponent.selector, homeComponent)