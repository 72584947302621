import * as angular from 'angular'
import { OmcComponent } from 'shared/models/omc.types'

export interface StoredProcedureParam {
    id?: string|number,
    name: string,
    type: string,
    value: string | number
}
let warned = false;
let storedProcedureParamsGridComponent: OmcComponent = {
    selector: `storedProcedureParamsGridComponent`,
    template: require("./stored-procedure-params-grid.component.html"),
    bindings: {
        params: "<?",
        editable:"<?",
        onEditParams: "&?",
        showDataConnection: "=?"
    },
    controller: class StoredProcedureParamsGridController implements angular.IController {

        params: StoredProcedureParam[] = []
        editable: boolean = true;

        gridOptions:any = {};
        gridApi: any;

   

        onEditParams = (obj:{params:StoredProcedureParam[]}):any=>{};

        constructor(
            private uiGridConstants
        ){
            
        }
        
        $onInit(){
            this.gridOptions.enableCellEditOnFocus = true
            this.gridOptions.onRegisterApi = this.onRegisterApi.bind(this)
            this.updateColumnDefs()
            this.updateGridData()
        }
        $onChanges(changes:angular.IOnChangesObject){
            if(changes['params']){
                this.updateGridData()
            }
            if(changes['editable']){
                this.updateColumnDefs()
            }
        }
        updateGridData(){
            !warned && (warned = true) && console.warn(`UI-GRID will throw an error when an ancestor of the grid removes it from the DOM (like when using ng-if).
            There doesn't seem to be a cost for the user, and so this is a known error we choose to ignore.`);
            this.gridOptions.data = this.params ?? [];
        }
        updateColumnDefs(){
            this.gridOptions.columnDefs = [
                {name: 'name', displayName: 'Parameter', enableCellEdit: false, width: '40%'},
                {name: 'type', displayName: 'Type', enableCellEdit: false, width: '20%'},
                {
                    name: 'value',
                    displayName: 'Value'+(this.editable ? '(editable)' : ''), 
                    enableCellEdit: this.editable, 
                    width: '40%',  
                    cellTemplate: (this.editable ? '<input type=text ng-input="COL_FIELD" ng-model="MODEL_COL_FIELD" name="value" required></input>' : '') 
                },
            ]
        }

        onRegisterApi(gridApi){
            this.gridApi = gridApi;
            gridApi.edit.on.afterCellEdit(null, () => {
                this.onEditParams(
                    {params: this.params}
                )
            })
        }
    }
}

angular
    .module('omc')
    .component(storedProcedureParamsGridComponent.selector, storedProcedureParamsGridComponent)