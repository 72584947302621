import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import * as angular from 'angular';
import { GridSettingsService } from './grid-helper/grid-settings.service';

@Injectable({
    providedIn: 'root'
  })
export class UsedByService {
    constructor(private gridSettingsService: GridSettingsService) {}

    usedByColumnDefs() {
        const columns = [
            {
                name: 'jobName',
                displayName: 'Job Name',
                width: 250,
            },
            {
                name: 'taskName',
                displayName: 'Task Name',
                width: 250,
            },
            {
                name: 'taskType',
                displayName: 'Task Type',
                width: 250,
            },
            {
                name: 'lastRunByName',
                displayName: 'Last Run By',
                width: 250,
            },
            {
                name: 'startDate',
                displayName: 'Last Run Started',
                headerCellClass: 'lastRunStart',
                cellFilter: 'omcDate',
                width: this.gridSettingsService.columnWidths.dateTime,
            },
            {
                name: 'state',
                displayName: 'State',
                headerCellClass: 'status',
                width: 165,
            },
        ];
        return columns;
    }
}
angular.module('omc').factory('usedByService', downgradeInjectable(UsedByService));
