import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import * as angular from 'angular';
import { pathToApi } from '../../core/constants/global-constants';
import * as moment from 'moment';
import { GlobalSpinnerService } from '../../shared/components/spinner/spinner.service';
import { MessagingService } from './messaging.service';
import { OmcLoadingService } from './omc-loading.service';
import { RedirectionService } from './redirection.service';

@Injectable({
    providedIn: 'root'
  })
export class ApiService {
    constructor(
        private http: HttpClient,
        private messagingService: MessagingService,
        private redirectionService: RedirectionService,
        private globalSpinnerService: GlobalSpinnerService,
        private omcLoadingService: OmcLoadingService
    ) {}

    apiUrl(url) {
        return pathToApi + url;
    }

    get(url, callBackOnError?, globalSpinner = true) {
        let requestTimeStamp = new Date();
        let params = {
            method: 'GET',
            url: this.apiUrl(url),
        };
        this.requestStartLog(globalSpinner, requestTimeStamp, params);
        let promise = new Promise((resolve, reject) => {
            this.http.get(params.url).subscribe(
                (response: { data: any }) => {
                    this.responseCompletionLog(globalSpinner, url, requestTimeStamp, response);
                    resolve(response);
                },
                (err) => {
                    this.onError(globalSpinner, url, err, callBackOnError);
                    reject(err);
                }
            );
        });
        this.registerCall(globalSpinner, url, promise);
        return promise;
    }

    post(url, data, callBackOnError?, globalSpinner = true) {
        let requestTimeStamp = new Date();
        let params = {
            method: 'POST',
            url: this.apiUrl(url),
            data: data,
        };
        this.requestStartLog(globalSpinner, requestTimeStamp, params);
        let promise = new Promise((resolve, reject) => {
            this.http.post(params.url, params.data).subscribe(
                (response: { data: any }) => {
                    this.responseCompletionLog(globalSpinner, url, requestTimeStamp, response);
                    resolve(response);
                },
                (err) => {
                    this.onError(globalSpinner, url, err, callBackOnError);
                    reject(err);
                }
            );
        });
        this.registerCall(globalSpinner, url, promise);
        return promise;
    }

    stringifyParams(params = {}) {
        let paramsString = '';
        const numberOfParams = Object.values(params).length;
        if (numberOfParams > 0) {
            let i = 0;
            for (const [key, value] of Object.entries(params)) {
                paramsString += (i === 0 ? '?' : '&') + `${key}=${value}`;
                i++;
            }
        }
        return paramsString;
    }

    requestStartLog(
        globalSpinner: boolean,
        requestTimeStamp: Date,
        params: { method: string; url: string; data?: any }
    ) {
        console.log(
            globalSpinner ? '[globalSpinner]' : '[omcSpinner]',
            `[${moment(requestTimeStamp).format('DD/MM hh:mm:ss')}]`,
            'REQUEST:',
            params
        );
    }

    onError(globalSpinner: boolean, url: any, err: any, callBackOnError?: any) {
        if (globalSpinner) {
            this.globalSpinnerService.removeSpinner(url);
        }
        this.displayError(err);
        if (callBackOnError) {
            callBackOnError(err);
        }
    }

    responseCompletionLog(globalSpinner: boolean, url: any, requestTimeStamp: Date, response: { data: any }) {
        let responseTimeStamp = new Date();
        if (globalSpinner) {
            this.globalSpinnerService.removeSpinner(url);
        }
        let timeDifference = moment(responseTimeStamp, 'DD/MM/YYYY HH:mm:ss').diff(
            moment(requestTimeStamp, 'DD/MM/YYYY HH:mm:ss')
        );
        let responseReceivedDuration = Number(moment.duration(timeDifference));
        console.log(
            globalSpinner ? '[globalSpinner]' : '[omcSpinner]',
            `[${moment(responseTimeStamp).format('DD/MM hh:mm:ss')}]`,
            'RESPONSE: (' + responseReceivedDuration / 1000 + 's)',
            url,
            response
        );
    }

    registerCall(globalSpinner: boolean, url: any, promise: Promise<any>) {
        if (globalSpinner) {
            this.globalSpinnerService.addSpinner(url);
        } else {
            this.omcLoadingService.registerCall(url, promise);
        }
    }

    displayError(err) {
        let message = err.error && err.error.message;
        if (err.status === 401) {
            this.redirectionService.redirectToLogin();
        } else if (err.status === 400 && message) {
            this.messagingService.displayMessage(message, 'Error', 'error');
        } else if (err.status === 403) {
            this.messagingService.displayMessage(
                message || 'You are not authorised to carry out that action',
                'Forbidden',
                'error'
            );
        } else {
            this.messagingService.displayMessage(
                message || 'An error occured while communicating with the website',
                'Error',
                'error'
            );
        }
    }
}
angular.module('omc').factory('apiService', downgradeInjectable(ApiService));
