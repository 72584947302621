
import { ValueOptions, ValueOptionsWithHashKey } from '../interfaces/constant-interfaces';

// Edit Dataset
export const dataInputTypes: ValueOptions[] = [
    {
        value: 0,
        description: 'Excel Workbook (.xlsx, .xlsm)',
        key: 'Excel',
    },
    {
        value: 1,
        description: 'CSV (Comma delimited) (*.csv)',
        key: 'CommaDelimitedText',
    },
    {
        value: 2,
        description: 'Text (Tab delimited) (*.txt)',
        key: 'TabDelimitedText',
    },
    {
        value: 3,
        description: 'Internal',
        key: 'Internal',
    },
    {
        value: 4,
        description: 'Oracle',
        key: 'Oracle',
    },
    {
        value: 5,
        description: 'SQL Server',
        key: 'SqlServer',
    },
    {
        value: 6,
        description: 'ODBC',
        key: 'OdbcSource',
    },
];

export const dataSamples: ValueOptionsWithHashKey[] = [
    {
        value: 0,
        description: 'None',
        key: 'None',
        $$hashKey: 'object:82',
    },
    {
        value: 1,
        description: '4',
        key: 'Four',
        $$hashKey: 'object:83',
    },
    {
        value: 2,
        description: '6',
        key: 'Six',
        $$hashKey: 'object:84',
    },
];
