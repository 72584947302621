import * as angular from 'angular';
import { OmcComponent } from 'shared/models/omc.types';
import { OmcFunc } from '../shared/functions/omc.functions';
import { ArtefactDialogData, BatchConfirmDialogData, ButtonConfig } from '../shared/models/dialog-data.model';
import { TaskView } from '../shared/models/task-view.model';
import { TaskDependencyView } from '../shared/models/task-dependency-view.model';
import { JobTaskHistory, JobHistoryTaskRecords } from '../shared/models/job-task-execution-history.model';
import { language } from '../shared/language/language.const';
import { MatDialogService } from '../shared/services/mat-dialog.service';
import * as moment from 'moment';
import { buttons } from '../constants/app.constants';

class JobsDialogData extends ArtefactDialogData {
    saveBtnText: string;
    cancelBtnText: string;
    maxRolePriority: any;
    constructor(props: Partial<JobsDialogData> = {}) {
        super(props);
        this.saveBtnText = props.saveBtnText || language.general.SAVE;
        this.cancelBtnText = props.cancelBtnText || language.general.CANCEL;
        this.maxRolePriority = props.maxRolePriority;
    }
}

let jobsComponent: OmcComponent = {
    selector: `jobsComponent`,
    template: require('./jobs.component.html'),
    bindings: {},
    controller: class JobsController implements angular.IController {
        gridApi: any = {};
        lang: any;
        jobCheck: any = undefined;
        jobStatusUpdatePeriodSeconds = 10;
        enableAutoRefresh = true;
        isRefreshingJobStatus = false;
        selectedRows: any[] = [];
        isEdit = false;
        isCopy = false;
        filterValues: any = {
            treeDates: [
                {
                    sDate: moment().subtract(2, 'year').format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'),
                    eDate: moment().format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'),
                },
            ],
            selectedCollections: [],
        };
        isDelDisabled = false;
        isRestDisabled = false;
        jobs: any[] = []; // filtered for view
        allJobs: any[] = []; // unfiltered
        task: any = {};
        selectedJob: any = null; // The selected job as it is passed to the edit job dialog
        selectedJobs: any[] = []; // The selected jobs for run Job dialog
        editJobDialogData = new JobsDialogData(); // Data used for all the edit-job dialogs (view/edit/copy)
        runJobExecution: any = {}; // runJob execution data
        runJobDialogData: any = {}; // runJob dialog data
        selectedArtefacts: [];
        dataConnections: any[];
        maxRolePriority: any;
        defaultNoOfSplits: any;
        defaultNoOfWorkers: number;
        requestApprovalDialogData: {
            isValid: boolean;
            recipients: any[];
            message: string;
            jobId: any;
            approvers: any;
            approvalsRequired: any;
        };
        gridHelper: any;
        gridData: any;

        // Import Job from XML
        importedJobFile: any;
        importJobDialogData: ArtefactDialogData;
        importError: string[];

        constructor(
            private jobsService,
            private jobSettingsService,
            private modalDialogService,
            private messagingService,
            private omcDialogService,
            private currentUserService,
            private $rootScope,
            private platformSettingsService,
            private gridSettingsService,
            private uiActionsService,
            private downloadService,
            private filterService,
            private $state,
            private taskTypeService,
            private dataService,
            private $interval,
            private gridHelperServiceFactory,
            private generalDialogService,
            private fileUploadService,
            private serialisationService,
            private matDialogService: MatDialogService,
            language,
            private gridHelperFunctionService
        ) {
            this.lang = language;
        }

        $onInit() {
            this.initialise();
            this.getDataConnections();
            this.initialiseGrid();
            this.getUserSettings();

            this.taskTypeService.getTaskTypes().then((data) => {
                this.task.taskTypes = data;
                if (this.$state.params.id) {
                    this.jobDialog({ id: this.$state.params.id }).view();
                }
            });
        }
        initialise() {
            this.selectedArtefacts = [];
            this.dataConnections = [];
            //this.runSelectedJobs = runSelectedJobs;

            this.uiActionsService.getPermissionsForName('artefact-delete', true).then((perm) => {
                this.isDelDisabled = perm && perm.disabled;
            });

            this.uiActionsService.getPermissionsForName('artefact-restore', true).then((perm) => {
                this.isRestDisabled = perm && perm.disabled;
            });

            this.uiActionsService.getPermissionsForName('jobs-edit').then((perm) => {
                this.isEdit = perm && !perm.disabled;
            });

            this.uiActionsService.getPermissionsForName('jobs-copy').then((perm) => {
                this.isCopy = perm && !perm.disabled;
            });

            this.jobSettingsService.getMaxPriorityForRole(true).then((maxRolePriority) => {
                this.maxRolePriority = maxRolePriority;
            });

            this.platformSettingsService.getSettings().then((settings) => {
                this.defaultNoOfSplits = settings.defaultNoOfSplits;
                this.defaultNoOfWorkers = 2; // temporary!
                this.jobStatusUpdatePeriodSeconds = settings.jobStatusUpdatePeriodSeconds;
            });

            this.$rootScope.$on('serverEvent:JobsServerEvent', this.jobsServerEvent.bind(this));
            this.$rootScope.$on('serverEvent:ReportsServerEvent', this.reportsServerEvent.bind(this));
            this.$rootScope.$on('userSettingsUpdated', (event, id) => {
                this.getUserSettings();
            });
            this.$rootScope.$on('runJobStarted', (event, ids) => this.setInitialJobDetailsOnRun(ids));

            (<any>window).omc.currentVm = this;
            (<any>window).omc.currentVm.refresh = () => this.getJobs(true);
        }

        $onDestroy() {
            this.stopJobCheck();
        }
        showUserProfile(id: string | number) {
            return this.generalDialogService.userDetails(id);
        }
        stopJobCheck() {
            if (angular.isDefined(this.jobCheck)) {
                console.log('jobsController: cancelling interval...');
                this.$interval.cancel(this.jobCheck);
                this.jobCheck = undefined;
                this.isRefreshingJobStatus = false;
            }
        }
        applyFilterChanges(data) {
            this.filterValues = data;
            this.getJobs(true);
        }
        closeDialog(id) {
            this.omcDialogService.close(id);
        }
        canEditNew() {
            return true;
        }
        canCopy() {
            return !this.copyDisabled();
        }

        editsDisabled() {
            if (this.selectedValues().length === 1) {
                var temp = this.selectedValues()[0];
                var statusResult =
                    temp.status === 'Pending' ||
                    temp.status === 'Preparing' ||
                    temp.status === 'Running' ||
                    temp.status === 'Scheduled';
                return !this.isEdit || !this.jobs || statusResult || this.selectedValues()[0].isDeleted;
            }
            return !this.isEdit || !this.jobs || this.selectedValues().length !== 1;
        }

        copyDisabled() {
            if (this.selectedValues().length === 1) {
                var temp = this.selectedValues()[0];
                var statusResult =
                    temp.status === 'Pending' ||
                    temp.status === 'Preparing' ||
                    temp.status === 'Running' ||
                    temp.status === 'Scheduled';
                return !this.isCopy || !this.jobs || statusResult;
            }
            return !this.isCopy || !this.jobs || this.selectedValues().length !== 1;
        }

        canRunJobs() {
            if (!this.isEdit) return false;
            let jobs = this.selectedValues();
            if (!(jobs && jobs.length >= 1)) return false;
            return jobs.every((t) => {
                if (t.isApprovalRequired) return false;
                if (this.jobsService.isRunning(t)) return false;
                return true;
            });
        }

        approvalRequired() {
            var selectedJobs = this.selectedValues();
            var result =
                selectedJobs.length === 1 &&
                selectedJobs[0].isApprovalRequired &&
                selectedJobs[0].createdById === this.currentUserService.user.id;
            return result;
        }

        canStopJobs() {
            return (
                this.isEdit &&
                this.selectedValues().length &&
                this.selectedValues().every((x) => this.jobsService.isRunningOnly(x))
            );
        }
        
        canDeleteArtefact() {
            if (!this.isDelDisabled) {
                var listSelValues = this.selectedValues();
                var anyDeleted = listSelValues.filter((x) => x.isDeleted === true).length;
                return listSelValues.length !== 0 && anyDeleted === 0;
            }

            return this.isDelDisabled;
        }

        canRestoreArtefact() {
            if (!this.isRestDisabled) {
                var listSelValues = this.selectedValues();
                var anyNotDeleted = listSelValues.filter((x) => x.isDeleted === false).length;
                return listSelValues.length !== 0 && anyNotDeleted === 0;
            }

            return this.isRestDisabled;
        }

        deleteArtefactConfirm() {
            this.selectedArtefacts = this.selectedValues();
            const batchConfirmDialogData = new BatchConfirmDialogData({
                title: 'Delete Job(s)',
                message: 'You are about to delete the following jobs. Do you want to continue?',
                confirmButton: new ButtonConfig({ text: buttons.DELETE }),
                artefacts: this.selectedArtefacts,
            });
            this.matDialogService.openBatchConfirmDialog(batchConfirmDialogData).subscribe((selectedArtefacts) => {
                if (selectedArtefacts) {
                    this.deleteArtefact(selectedArtefacts);
                }
            });
        }

        restoreArtefact() {
            this.selectedArtefacts = this.selectedValues();
            const batchConfirmDialogData = new BatchConfirmDialogData({
                title: 'Restore Job(s)',
                message: 'You are about to restore the following jobs. Do you want to continue?',
                confirmButton: new ButtonConfig({ text: buttons.RESTORE }),
                artefacts: this.selectedArtefacts,
            });
            this.matDialogService.openBatchConfirmDialog(batchConfirmDialogData).subscribe((selectedArtefacts) => {
                if (selectedArtefacts) {
                    this.restoreArtefacts(selectedArtefacts);
                }
            });
        }

        anySelectedJobsRunning() {
            var jobs = this.selectedValues();
            return jobs.some((job) => this.jobsService.isRunning(job));
        }

        stopSelectedJobs() {
            this.matDialogService
                .openConfirmDialog('Are you sure you want to cancel the selected job?')
                .subscribe((confirmed: boolean) => {
                    if (confirmed) {
                        const jobs = this.selectedValues();
                        this.jobsService.cancelJobs(jobs);
                        this.getJobs();
                    }
                });
        }

        canDownloadResults(job) {
            if (job.state === 'Configuring' || !job.hasBeenRun || !job.lastJobExecuted) return false;
            //var lastJobEx = lastJobExecutionHistory(job);
            var lastJobEx = job.lastJobExecuted;
            if (lastJobEx && (lastJobEx.state === 'Finished' || lastJobEx.state === 'FinishedWithErrors'))
                return job.hasResults;
            return false;
        }

        downloadResults(job) {
            //var lastJobEx = lastJobExecutionHistory(job);
            var lastJobEx = job.lastJobExecuted;
            if (lastJobEx && lastJobEx.emServiceJobId) {
                this.downloadService.downloadResults(lastJobEx.emServiceJobId, job.description + '.zip', 'zip');
            }
        }

        canViewReport(job) {
            if (job.reportId == undefined || job.reportId == null || !job.lastJobExecuted) return false;

            //var lastJobEx = lastJobExecutionHistory(job);
            var lastJobEx = job.lastJobExecuted;
            if (job.runReportOnSuccessOnly) {
                return lastJobEx.state === 'Finished';
            } else {
                return lastJobEx.state === 'Finished' || lastJobEx.state === 'FinishedWithErrors';
            }
        }

        viewReport(job) {
            if (job && job.isReportOpenInNewWindow) {
                window.open(job.reportUrl, '_blank');
            } else if (job) {
                window.open(job.reportUrl, '_self');
            }
        }

        reportsServerEvent(event, payload) {
            var data = payload.data;
            if (data && this.jobs) {
                this.jobs.forEach((job) => {
                    if (job && job.reportId === data.id) {
                        job.isReportOpenInNewWindow = data.isOpenNewWindow;
                    }
                });
            }
        }

        canExport() {
            return this.selectedValues().length === 1;
        }

        exportJobs() {
            var selectedJobIds = this.selectedValues().map((c) => c.id);
            var payload = { jobIds: selectedJobIds };
            console.log('Exporting ' + selectedJobIds);
            this.dataService.postData(payload, 'exportJobsToXml', 'exportJobsToXml', false).then((result) => {
                var selectedJob = this.selectedValues()[0];
                this.downloadService.downloadFile(result, selectedJob.description + '.xml', 'xml');
            });
        }

        openJobRequestApprovalDialog() {
            var selectedJob = this.selectedValues()[0];
            this.jobsService.getJobById(selectedJob.id, true).then((job) => {
                this.requestApprovalDialogData = {
                    isValid: false,
                    recipients: [],
                    message: '',
                    jobId: job.id,
                    approvers: job.approvers,
                    approvalsRequired: job.approvalsRequired,
                };
                this.omcDialogService.open('request-job-approval', { title: 'Job Approval' });
            });
        }

        requestJobApproval() {
            var vmSendNotification = {
                jobId: this.requestApprovalDialogData.jobId,
                userIds: this.requestApprovalDialogData.recipients.map((c) => {
                    return { value: c.id };
                }),
                message: this.requestApprovalDialogData.message,
            };
            this.dataService
                .postData(vmSendNotification, 'addJobApprovalRequests', 'addJobApprovalRequests', false)
                .then(() => {
                    this.closeDialog('request-job-approval');
                    this.getJobs(true);
                });
        }

        getUserSettings() {
            this.currentUserService.getCurrentUser().then((user) => {
                if (user && user.userSettings) {
                    this.enableAutoRefresh = user.userSettings.enableAutoRefresh;
                }
            });
        }

        getDataConnections() {
            this.dataService.getData('getAllDataConnections', 'getAllDataConnections', true).then((dataConnections) => {
                this.dataConnections = dataConnections;
            });
        }

        selectedValues() {
            return (this.gridHelper && this.gridHelper.selectedValues()) || [];
        }

        initialiseGrid() {
            this.gridHelper = this.gridHelperServiceFactory.gridHelper('jobsController', '#jobs-grid', this);
            this.gridData = this.gridHelper.gridData;

            this.gridData.gridId = 'jobs-grid';
            this.gridSettingsService.initialiseGridSettings({
                id: this.gridData.gridId,
                name: 'Jobs-Grid',
                columnsInfo: [],
            });

            this.gridData.columnDefs = [
                {
                    name: 'id',
                    displayName: '',
                    cellTemplate:
                        '<div class="grid-row-vertical-bar" ng-click="grid.appScope.gridHelper.toggleSelected(row.entity, $event); " ng-class="{\'grid-row-selected\': grid.appScope.gridRowSelected(row.entity) }" data-id="{{row.entity.id}}" role="button" tabindex="0"></div>',
                    width: this.gridSettingsService.columnWidths.toggleSelect,
                    pinnedLeft: true,
                    cellClass: 'omc-select-column',
                },
                this.gridHelperFunctionService.pinColumnDef(),

                /* gridHelperServiceFactory functions now available via gridHelperFunctionsService[TM 16/08/22] */
                /* 
                    To implement row pinning without gridHelper:
                    1. make sure onRegisterApi adds method 'toggleRowPinned' to api.grid using- gridHelperServiceFactory.rowPinning.togglePinFn(this.gridData.gridId)
                    2. after data is loaded for the grid update the pinned rows by calling-  gridHelperServiceFactory.rowPinning.setPinnedRows(this.gridData)
                    3. add a pinning column columnRef to gridData.columnRefs- gridHelperServiceFactory.rowPinning.columnDef(),
                */
                {
                    name: 'isDeleted',
                    displayName: '',
                    cellTemplate: `<span class="ui-grid-cell-contents" ng-class="{'deleted-icon': row.entity.isDeleted}"></span>`,
                    width: this.gridSettingsService.columnWidths.icon,
                },
                {
                    name: 'description',
                    displayName: 'Name',
                    headerCellClass: 'description',
                    cellTemplate: `<div class="ui-grid-cell-contents" ng-class="{'deleted-row-text': row.entity.isDeleted}" ><a href="#" ng-click="grid.appScope.jobDialog({id:row.entity.id}).view(); $event.preventDefault();">{{row.entity.description}}</a></div>`,
                    width: 350,
                },
                {
                    name: 'jobTasks',
                    displayName: 'Tasks',
                    width: this.gridSettingsService.columnWidths.default,
                },
                {
                    name: 'lastRunStart',
                    displayName: 'Last Run Started',
                    headerCellClass: 'lastRunStart',
                    cellFilter: 'omcDate',
                    width: this.gridSettingsService.columnWidths.dateTime,
                },
                {
                    name: 'lastRunEnd',
                    displayName: 'Last Run Ended',
                    headerCellClass: 'lastRunEnd',
                    cellTemplate: `<span class="ui-grid-cell-contents" ng-class="{'text-italic': row.entity.jobDonePercent > 0 && row.entity.jobDonePercent < 100 && row.entity.state === 'Running'}">{{row.entity.lastRunEnd | omcDate }}</span>`,
                    cellFilter: 'omcDate',
                    width: this.gridSettingsService.columnWidths.dateTime,
                },
                {
                    name: 'lastRunByName',
                    displayName: 'Last Run By',
                    headerCellClass: 'Created-By-Name',
                    cellTemplate:
                        '<div class="ui-grid-cell-contents"><a href="#" ng-click="grid.appScope.showUserProfile(row.entity.lastRunById); $event.preventDefault();">{{row.entity.lastRunByName}}</a></div>',
                    width: this.gridSettingsService.columnWidths.userName,
                },
                {
                    name: 'state',
                    displayName: 'State',
                    headerCellClass: 'status',
                    width: 165,
                    cellTemplate:
                        '<div class="ui-grid-cell-contents"><a href="#" ng-click="grid.appScope.showExecutionHistoryDialog(row.entity); $event.preventDefault();">{{row.entity.state}}</a></div>',
                },
                {
                    name: 'downloadResults',
                    displayName: 'Results',
                    width: 165,
                    cellTemplate:
                        '<div class="ui-grid-cell-contents"><a check-permissions="result-export" href="#" ng-click="grid.appScope.downloadResults(row.entity); $event.preventDefault();" ng-show="grid.appScope.canDownloadResults(row.entity)">Download</a></div>',
                },
                {
                    name: 'viewReport',
                    displayName: 'Report',
                    width: 165,
                    cellTemplate:
                        '<div class="ui-grid-cell-contents"><a href="{{row.entity.reportUrl}}" ng-click="grid.appScope.viewReport(row.entity); $event.preventDefault();" ng-show="grid.appScope.canViewReport(row.entity)">View Report</a></div>',
                },
                {
                    name: 'progress',
                    displayName: 'Progress',
                    headerCellClass: 'job-progress',
                    cellTemplate: '<job-progress-component [job]="row.entity" ></job-progress-component>',
                    width: 200,
                },
                {
                    name: 'collectionsName',
                    displayName: 'Collections',
                    headerCellClass: 'CompiledTask',
                    width: this.gridSettingsService.columnWidths.default,
                },
                {
                    name: 'notes',
                    displayName: 'Notes',
                    width: this.gridSettingsService.columnWidths.notes,
                },
                {
                    name: 'dateCreated',
                    displayName: 'Date Created',
                    headerCellClass: 'DateCreated',
                    cellFilter: 'omcDate',
                    width: this.gridSettingsService.columnWidths.dateTime,
                },
                {
                    name: 'modifiedDateTime',
                    displayName: 'Date Modified',
                    headerCellClass: 'DateCreated',
                    cellFilter: 'omcDate',
                    width: this.gridSettingsService.columnWidths.dateTime,
                },
                {
                    name: 'createdByName',
                    displayName: 'Created By',
                    headerCellClass: 'Created-By-Name',
                    width: this.gridSettingsService.columnWidths.userName,
                    cellTemplate:
                        '<div class="ui-grid-cell-contents"><a href="#" ng-click="grid.appScope.showUserProfile(row.entity.createdById); $event.preventDefault();">{{row.entity.createdByName}}</a></div>',
                },
            ];
        }

        getJobs(force?) {
            this.jobsService.getJobs(force).then((jobs) => {
                // should be unfiltered jobs
                this.allJobs = angular.copy(jobs);
                try {
                    this.updateJobsGrid(jobs, false);
                } catch (e) {
                    console.error(e);
                }
            });
        }

        updateJobsGrid(jobs, updateTasks) {
            this.stopJobCheck();
            this.jobs = this.applyFilters(jobs);

            this.jobs.forEach((job) => {
                this.updateJob(job);
            });
            if (updateTasks) {
                this.jobs.forEach((job) => {
                    this.updateJobTasks(job);
                });
            }
            this.jobCheck = this.$interval(this.refreshJobStatus.bind(this), this.jobStatusUpdatePeriodSeconds * 1000);
            this.gridHelper.dataReloaded(this.jobs);
        }

        /**
         *
         * @param {any} event
         * @param {{jobId:string,state:string,progress:number,eta: number}[]} payload
         */
        jobsServerEvent(event, payload) {
            if (!this.jobs || !this.isGridVisible() || !payload || !payload.data || !Array.isArray(payload.data))
                return;
            payload.data.forEach((jobUpdate) => {
                let job = this.jobs.find((job) => job.id === jobUpdate.id);
                if (job) {
                    job.lastJobExecuted = jobUpdate.lastJobExecuted || {};
                    job.lastRunEnd = job.lastJobExecuted.endTime = jobUpdate.lastRunEnd;
                    job.jobDonePercent = job.lastJobExecuted.jobDonePercent = zeroToHundred(jobUpdate.jobDonePercent);
                    job.state = jobUpdate.state;
                    job.lastRunStart = jobUpdate.lastRunStart;
                    job.lastRunByName = jobUpdate.lastRunByName;
                    job.hasBeenRun = jobUpdate.hasBeenRun;
                    job.hasResults = jobUpdate.hasResults;
                    job.reportUrl = jobUpdate.reportUrl;
                    // Maybe TODO: get jobDetails if job state changed to Finished? [TS 02/06/20]
                } else {
                    console.warn(`jobsServerEvent: Received job id ${jobUpdate.id} but could not find job to update`);
                }
            });
            function zeroToHundred(n) {
                if (typeof n === 'number') {
                    return n > 100 ? 100 : n < 0 ? 0 : OmcFunc.limitDecimals(n, 0);
                } else {
                    return null;
                }
            }
        }

        refreshJobStatus() {
            if (this.enableAutoRefresh && this.isGridVisible() && !this.isRefreshingJobStatus) {
                this.isRefreshingJobStatus = true;
                this.jobsService.refreshJobStatus().then(() => {
                    this.isRefreshingJobStatus = false;
                });
            }
        }

        isGridVisible() {
            return $('#content-jobs').is(':visible');
        }

        updateJob(job) {
            var idx = this.jobs.findIndex((x) => {
                return x.id === job.id;
            });
            if (idx > -1) {
                this.jobs[idx] = job;
            } else {
                this.jobs.push(job);
            }
        }

        openRunJobDialog() {
            if (this.anySelectedJobsRunning()) {
                this.jobRunningAlert();
                return;
            }
            this.runJobDialogData = {
                display: '',
                isEdit: true,
                maxRolePriority: this.maxRolePriority,
            };
            this.runJobExecution = {
                deferExecution: false,
                honourJobDependency: true,
                maxExecutionTimeMins: 0,
                noOfParallelThreads: null,
                noOfSplits: this.defaultNoOfSplits,
                noOfWorkers: this.defaultNoOfWorkers,
                failOnWarning: false,
                verifyDatasetChecksum: true,
                alertOnJobFailure: true,
                alertOnJobCommencement: true,
                alertOnJobCompletion: true,
                workerConfiguration: {},
                workerConfigurationName: '',
                distributionStrategyId: 0,
                aggregateWorkerOutput: true,
            };
            //get jobs
            var jobIds = this.selectedValues()
                .map((x) => x.id.toString())
                .join(',');
            //get tasks for jobs
            return Promise.all([this.jobsService.getJobsByIds(jobIds, true), this.getJobsTasks(jobIds)]).then(
                ([jobs, jobTasks]) => {
                    jobs.forEach((job) => {
                        let obj = jobTasks.find((jobTask) => jobTask.jobId === job.id);
                        job.tasks = obj ? obj.tasks : [];
                    });
                    this.selectedJobs = jobs;
                    let title = jobs.length === 1 ? 'Run Job' : 'Run Jobs (' + jobs.length + ')';
                    this.runJobDialogData;
                    this.omcDialogService.open('run-job', { title: title });
                }
            );
        }

        jobRunningAlert() {
            if (!this.selectedJobs) return;
            let plural = this.selectedJobs.length > 1;
            let title = 'Cannot run Job' + (plural ? 's' : '');
            let msg = 'The job' + (plural ? 's are' : ' is') + ' already running';
            this.messagingService.displayMessage(title, msg, this.messagingService.messageLevels.warning);
        }

        updateJobTasks(job) {
            if (job && job.tasks && job.tasks.length > 0) {
                job.tasks.forEach((task) => {
                    var index = this.task.taskTypes.map((x) => x.key).indexOf(task.model.taskTypeId);
                    task.taskTypeId = this.task.taskTypes.map((m) => m.value)[index];
                });
            }
            if (job && job.taskDependencies && job.taskDependencies.length > 0) {
                job.taskDependencies.forEach((taskDependency) => {
                    var taskIndex = job.tasks.map((e) => e.id).indexOf(taskDependency.taskId);
                    var task = job.tasks[taskIndex];

                    var index = this.task.taskTypes.map((x) => x.key).indexOf(task.model.taskTypeId);
                    taskDependency.taskType = this.task.taskTypes.map((m) => m.value)[index];
                    taskDependency.sNo = taskIndex + 1;
                });
            }
        }

        jobDialog({ id = null, display = '' } = {}) {
            let vm = this;
            id = id || (vm.selectedValues()[0] ? vm.selectedValues()[0].id : 0);
            let usedNames = vm.allJobs.filter((job) => job.id !== id).map((job) => job.description);

            let dialogData = new JobsDialogData({
                display: display,
                usedNames: usedNames,
                maxRolePriority: vm.maxRolePriority,
            });

            return {
                edit: edit,
                add: add,
                copy: copy,
                view: view,
            };

            function edit() {
                if (vm.editsDisabled()) return;
                dialogData.isEdit = true;
                openJobDialog('Edit Job');
            }
            function add(job = null) {
                if (!vm.canEditNew()) return;

                vm.selectedJob = job || {
                    collections: [],
                    taskDependencies: [],
                    tasks: [],
                    visibleTaskDependenciesGrids: 1,
                    reportId: null,
                };
                dialogData.assign({
                    isAdd: true,
                    isEdit: true,
                    selectDefaultCollection: true,
                    usedNames: vm.allJobs.map((job) => job.description),
                });
                vm.editJobDialogData = dialogData;
                vm.omcDialogService.open('edit-job', { title: 'New Job' });
            }
            function copy() {
                if (vm.editsDisabled()) return;
                dialogData.assign({
                    isCopy: true,
                    isEdit: true,
                    hasChanged: true,
                    isValid: true,
                    usedNames: vm.allJobs.map((job) => job.description),
                });
                openJobDialog('Copy Job');
            }
            function view() {
                dialogData.assign({
                    isValid: true,
                    cancelBtnText: language.general.OK,
                });
                openJobDialog('Job');
            }

            function openJobDialog(title) {
                vm.getJobById(id).then((job) => {
                    if (dialogData.isCopy) {
                        job.id = 0;
                        job.description = OmcFunc.renameCopy(job.description, dialogData.usedNames);
                        job.tasks.forEach((task) => {
                            let taskDep = job.taskDependencies.find((td) => td.taskId === task.id);
                            let newTaskId = OmcFunc.newGuid();
                            taskDep.taskId = newTaskId;
                            taskDep.id = OmcFunc.newGuid();
                            task.id = newTaskId;
                        });
                    }
                    vm.selectedJob = job;
                    vm.editJobDialogData = dialogData;
                    vm.omcDialogService.open('edit-job', { title: title });
                });
            }
        }

        setInitialJobDetailsOnRun(jobIds = []) {
            console.log(`Run job started, updating jobs prior to server event`, jobIds);
            let initialPayload = { data: [] };
            jobIds.forEach((jobId) => {
                initialPayload.data.push({
                    id: jobId,
                    lastRunStart: '',
                    lastRunByName: this.currentUserService.user.id,
                    hasBeenRun: true,
                    hasResults: false,
                    reportUrl: '',
                    state: 'Submitted',
                    jobDonePercent: null,
                    lastRunEnd: '',
                    lastJobExecuted: null,
                });
            });
            this.jobsServerEvent(null, initialPayload);
        }
        getJobById(jobId) {
            return Promise.all([this.jobsService.getJobById(jobId, true), this.getJobTasks(jobId)]).then(
                ([job, tasks]) => {
                    // TODO: All these job properties should be set by the Job api, and not loaded and manipulated separately [ts 19/05/20]
                    //       Also need to create a class for JobView model
                    job.collections = job.collections.map((c) => ({ id: c.id }));
                    if (tasks && tasks.length > 0) {
                        job.tasks = tasks;
                        return this.getTaskDependencies(jobId, tasks).then((taskDependencies) => {
                            job.taskDependencies = taskDependencies;
                            return job;
                        });
                    } else {
                        job.tasks = [];
                        job.taskDependencies = [];
                        return job;
                    }
                }
            );
        }
        // Use until job loads with tasks correctly [TS 18/05/20]
        getJobTasks(jobId) {
            let endPoint = 'getTaskViewsByJobId?id=' + jobId;
            return this.dataService
                .getData(endPoint, endPoint, true)
                .then((tasks) => {
                    if (tasks && tasks.length > 0) {
                        return tasks.map((task, i) => new TaskView(task, i));
                    } else {
                        return [];
                    }
                })
                .catch((err) => {
                    throw err;
                });
        }
        getJobsTasks(jobsIds) {
            let endPoint = 'getTaskViewsByJobIds?jobIds=' + jobsIds;
            return this.dataService
                .getData(endPoint, endPoint, true)
                .then((jobsTasks) => {
                    if (jobsTasks && jobsTasks.length > 0) {
                        jobsTasks.forEach((x) => {
                            if (x.tasks && x.tasks.length > 0) {
                                x.tasks = x.tasks.map((task, i) => new TaskView(task, i));
                            }
                        });
                        return jobsTasks;
                    } else {
                        return [];
                    }
                })
                .catch((err) => {
                    throw err;
                });
        }
        // Use until job loads with tasks correctly [TS 18/05/20]
        getTaskDependencies(jobId, tasks) {
            return this.dataService
                .getData('getTaskDependenciesByJobId?id=' + jobId, 'getTaskDependenciesByJobId?id=' + jobId, true)
                .then((data) => {
                    if (data && data.length > 0) {
                        var idsCount = 1;
                        data = data.map((taskDependency) => {
                            let task = tasks.find((task) => task.id === taskDependency.taskId);
                            let taskDep = new TaskDependencyView(task, {
                                id: taskDependency.id,
                                sNo: idsCount,
                                isDeleted: taskDependency.isDeleted,
                                groupNo: taskDependency.groupNo,
                            });
                            idsCount++;
                            return taskDep;
                        });
                    }
                    return data;
                });
        }

        saveJob() {
            let job = angular.copy(this.selectedJob);
            console.log('saveJob received job: ', job);

            let saveJobData = Object.assign(job, {
                collections: job.collections.map((c) => ({ id: c.id })),
                tasks: job.tasks.map(this.mapTasks.bind(this)),
                taskDependencies: job.taskDependencies.map(this.mapTaskDependencies.bind(this)),
            });

            console.log(`Saving job with data:`, saveJobData);
            this.jobsService.put(saveJobData).then(() => {
                this.omcDialogService.close('edit-job');
                // getJobs here should be replaced by a listener to a server event to update the jobs list [TS 03/06/20]
                this.getJobs(true);
            });
        }

        mapTaskDependencies(td) {
            return {
                id: td.id,
                groupNo: td.groupNo,
                taskId: (td.task && td.task.id) || td.taskId,
            };
        }

        idOf(obj) {
            return obj && obj.id;
        }

        mapTasks(task) {
            return {
                id: task.id,
                taskType: task.taskType && task.taskType.key,
                dataSetId: this.idOf(task.dataSet),
                modelId: this.idOf(task.model),
                basisId: this.idOf(task.basis),
                basisSetId: this.idOf(task.basisSet),
                tableSetId: this.idOf(task.tableSet),
                outputDataConnectionId: this.idOf(task.outputDataConnection),
                isEnabled: task.isEnabled,
                isOverwriteResults: task.isOverwriteResults,
                renamingStrategy: task.renamingStrategy,
                customNamingStrategy: task.customNamingStrategy,
                nthRecord: task.recordSampleSettings?.nthRecord,
                startingRange: task.recordSampleSettings.startingRange,
                endingRange: task.recordSampleSettings.endingRange,
                targetCount: task.recordSampleSettings.targetCount,
                recordSamplingId: this.idOf(task.recordSampleSettings.recordSample),
                recordSample: task.recordSampleSettings.recordSample,
                runControlTable: task.runControlTable,
                runControlTableId: this.idOf(task.runControlTable),
                storedProcedureParams: task.storedProcedureParams,
                schema: task.schema,
                storedProcedureName: task.storedProcedureName,
                dataConnectionId: task.dataConnectionId,
            };
        }
        /* old dialog service, to be replaced by omcDialog service [TS 08/07/20] */
        hideModalDialog() {
            this.modalDialogService.hide();
        }

        refreshModel() {
            this.jobsService.getJobs(true).then((jobs) => {
                this.updateJobsGrid(jobs, true);
            });
            this.modalDialogService.hide();
        }

        filterOnTaskType(taskType) {
            return (element) => {
                return element.tasks.filter((t) => t.taskTypeId === taskType.value).length > 0;
            };
        }

        filterOnTask(task) {
            return (element) => {
                return element.tasks.filter((t) => t.modelId === task.id).length > 0;
            };
        }

        filterOnDate(filterType, dates) {
            return (element) => {
                if (dates.length === 0 || dates.length == null) return true; // filtering not required

                if (filterType === 'Effective Date') {
                    return (
                        this.checkDateRange(dates, element.modelEffectiveDate) ||
                        this.checkDateRange(dates, element.tableSetEffectiveDate) ||
                        this.checkDateRange(dates, element.basisSetEffectiveDate) ||
                        this.checkDateRange(dates, element.dataSetEffectiveDate)
                    );
                } else {
                    return this.checkDateRange(dates, element.dateCreated);
                }
            };
        }

        checkDateRange(dates, dt) {
            for (var d = 0; d < dates.length; d++) {
                if (dt >= dates[d].sDate && dt <= dates[d].eDate) {
                    return true;
                }
            }
            return false;
        }

        applyFilters(jobs) {
            if (this.filterValues != null) {
                //jobs = filterService.filterApply(jobs, this.filterValues);

                //Filter on collections
                if (this.filterValues.selectedCollections.length > 0)
                    jobs = jobs.filter(this.filterService.filterOnCollection(this.filterValues.selectedCollections));

                //Filter on dates
                jobs = jobs.filter(
                    this.filterOnDate(this.filterValues.selectedFilterType, this.filterValues.treeDates)
                );

                //Filter on Task Type
                if (this.filterValues.selectedTaskType.key) {
                    jobs = jobs.filter(this.filterOnTaskType(this.filterValues.selectedTaskType));
                }

                //Filter on Task Name
                if (this.filterValues.selectedTask.id) {
                    jobs = jobs.filter(this.filterOnTask(this.filterValues.selectedTask));
                }

                //Filter on Created By
                if (this.filterValues.createdBy && this.filterValues.createdByUser)
                    jobs = jobs.filter((x) => x.createdById === this.filterValues.createdByUser.id);
            }
            return jobs;
        }

        showExecutionHistoryDialog(job) {
            let url = 'getLastExecutionHistory?jobId=' + job.id;
            Promise.all([this.dataService.getData(url, url, true), this.getHistoricalJobTasks(job.id)]).then(
                ([jobHistoryData, historyTasks]) => {
                    let startTime = jobHistoryData.startTime
                        ? moment.utc(jobHistoryData.startTime).local().format('DD/MM/YYYY HH:mm:ss')
                        : '';
                    let endTime = jobHistoryData.endTime
                        ? moment.utc(jobHistoryData.endTime).local().format('DD/MM/YYYY HH:mm:ss')
                        : '';
                    this.runJobDialogData = {
                        display: 'logs',
                        canDownload: false,
                        isEdit: false,
                        maxRolePriority: this.maxRolePriority,
                        anySelectedJobsRunning: false,
                        lastJobExeId: jobHistoryData.id,
                    };
                    job.tasks = historyTasks.tasks;
                    this.selectedJobs = [job];
                    // cherry pick the required attributes from jobHistoryData
                    this.runJobExecution = OmcFunc.diluteObject(jobHistoryData, [
                        'emServiceJobId',
                        'scheduledStartDate',
                        'logLevel',
                        'writeLimit',
                        'abortLimit',
                        'deferExecution',
                        'honourJobDependency',
                        'maxExecutionTimeMins',
                        'noOfParallelThreads',
                        'noOfSplits',
                        'noOfWorkers',
                        'failOnWarning',
                        'verifyDatasetChecksum',
                        'jobPriority',
                        'isExpireJobAfter',
                        'expireJobAfterDays',
                        'expireJobAfterHours',
                        'expireJobAfterMins',
                        'expireJobAfterSecs',
                        'failJobOnTaskFail',
                        'runJobUntilCancelExpired',
                        'holdJobUntilDate',
                        'alertOnJobFailure',
                        'alertOnJobCommencement',
                        'alertOnJobCompletion',
                        'jobRunSubscribers',
                        'distributionStrategyId',
                        'aggregateWorkerOutput',
                        'workerConfigurationName',
                    ]);
                    Object.assign(this.runJobExecution, {
                        jobExStartTime: startTime,
                        jobExEndTime: endTime,
                        jobExState: jobHistoryData.state,
                        jobExStartedBy: jobHistoryData.userId,
                        jobExTaskRecords: JobHistoryTaskRecords.makeFromJobTaskHistory(historyTasks),
                    });
                    let title = 'Job Run';
                    this.omcDialogService.open('run-job', { title: title });
                }
            );
        }
        /**
         * @param {string} id
         * @returns {JobTaskHistory}
         */
        getHistoricalJobTasks(jobId) {
            let endPoint = 'getLastTasksExecutionHistory?jobId=' + jobId;
            return this.dataService
                .getData(endPoint, endPoint, true)
                .then((data) => {
                    if (!!data) {
                        let historyData = new JobTaskHistory(data);
                        console.log(historyData);
                        return historyData;
                    } else {
                        return {};
                    }
                })
                .catch((err) => {
                    throw err;
                });
        }

        deleteArtefact(selectedArtefacts) {
            var data = { artefacts: selectedArtefacts.map((a) => a.id) };
            this.jobsService.deleteJobs(data).then(() => {
                this.refreshModel();
            });
        }

        restoreArtefacts(selectedArtefacts) {
            var data = { artefacts: selectedArtefacts.map((a) => a.id) };
            this.jobsService.restoreJobs(data).then(() => {
                this.refreshModel();
            });
        }

        importJobs() {
            this.importedJobFile = null;
            this.importJobDialogData = new ArtefactDialogData();
            this.omcDialogService.open('import-job-from-xml', { title: 'Import Jobs from XML' });
        }

        onImportJob() {
            this.fileUploadService.uploadFile(this.importedJobFile, 'importJobsFromXml').then(
                (job) => {
                    this.omcDialogService.close('import-job-from-xml');
                    var deserializeJob = this.serialisationService.retrocycle(job);
                    this.jobDialog().add(deserializeJob);
                },
                (result) => {
                    this.importError = [result?.data?.message];
                }
            );
        }
    },
};

angular.module('omc').component(jobsComponent.selector, jobsComponent);
