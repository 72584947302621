import { Inject, Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import * as angular from 'angular';
import { SignalR } from '../../../../lib/signalR/jquery.signalR-2.2.1.min.js';
import { RootScope } from '../../ajs-upgraded-providers';
import { ActivityMonitorService } from './activity-monitor.service';
import { CurrentUserService } from './current-user.service';
import { MessagingService } from './messaging.service';
import { SerialisationService } from './serialisation.service';

declare global {
    interface JQueryStatic {
        connection: SignalR;
    }
}
/* TODO: Signal R needs to updated on the backend and frontend client to eliminate jQuerry dependency[TM 01/11/22] */
@Injectable()
export class ServerEventsService {
    normalTimeout = 100;
    slowerTime = 60 * 5 * 1000; // 5 mins
    numberOfTimesToRetry = 5;

    lastUpdates = {};
    retryAttemptCount = 0;

    constructor(
        @Inject(RootScope) private rootScope,
        private currentUserService: CurrentUserService,
        private messagingService: MessagingService,
        private serialisationService: SerialisationService,
        private activityMonitorService: ActivityMonitorService
    ) {}

    initialise() {
        this.currentUserService.getCurrentUser(false).then((user) => {
            if (!user) {
                return;
            } else {
                console.log('serverEvents.initialise();');
                this.initConnection();
            }
        });
    }

    initConnection() {
        (<any>window).serverEventHub = $.connection.serverEventHub;
        (<any>window).serverEventHub.client.serverEvent = this.serverEvent.bind(this);

        (<any>window).serviceMonitorHub = $.connection.serviceMonitorHub;
        (<any>window).serviceMonitorHub.client.serviceMonitorEvent = this.serviceMonitorEvent.bind(this);
        $.connection.hub.start();

        $.connection.hub.disconnected(() => {
            console.log('hub.disconnected', $.connection.hub.lastError && $.connection.hub.lastError.message);
            this.retryAttemptCount++;
            if (this.retryAttemptCount <= this.numberOfTimesToRetry) {
                setTimeout(() => {
                    console.log('hub.restarting');
                    $.connection.hub.start();
                }, this.normalTimeout);
            } else {
                this.messagingService.displayMessage(
                    'Failed to connect to updates from server.',
                    'Connection Error',
                    this.messagingService.messageLevels.error
                );
                setTimeout(() => {
                    console.log('hub.restarting');
                    $.connection.hub.start();
                }, this.slowerTime);
            }
        });
        $.connection.hub.connectionSlow(() => {
            console.log('hub.connectionSlow');
        });
        $.connection.hub.reconnecting(() => {
            console.log('hub.reconnecting');
        });
    }

    serviceMonitorEvent(payloadRaw) {
        let payload = this.serialisationService.retrocycle(payloadRaw);
        this.retryAttemptCount = 0;
        if (!payload || !payload.key) {
            throw 'serviceMonitorEvent called, but no payload';
        }
        this.activityMonitorService.addData(payload.key, payload.data);
    }

    serverEvent(payloadRaw) {
        console.info('serverEvent', payloadRaw);
        let payload = this.serialisationService.retrocycle(payloadRaw);
        this.retryAttemptCount = 0;
        if (this.currentUserService.user) {
            if (!payload || !payload.key) {
                throw 'serverEvents called, but no payload';
            }

            if (this.lastUpdates[payload.key] && this.lastUpdates[payload.key] !== payload.lastUpdateId) {
                // client has missed an update, so reload data.
                payload.forceRefresh = true;
                console.log('serverEvent, setting payload.forceRefresh = true;', payloadRaw);
            }
            this.lastUpdates[payload.key] = payload.currentUpdateId;

            if (payloadRaw.key === 'ServiceMonitorRelayHubRestart') {
                $.connection.hub.start();
                return;
            }

            this.rootScope.$emit('serverEvent:' + payload.key, payload);
        }
    }
}
angular.module('omc').factory('serverEventsService', downgradeInjectable(ServerEventsService));
