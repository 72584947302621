import * as angular from 'angular'
import { OmcComponent } from 'shared/models/omc.types'

let jobRequestApprovalComponent: OmcComponent = {
    selector: `jobRequestApprovalComponent`,
    template: require("./job-request-approval.component.html"),
    bindings: {
        formData: "="
    },
    controller: class JobRequestApprovalController implements angular.IController {
        availableUsersSelected = []
        recipientsSelected = []
        loaded: boolean
        formData: any
        recipients: any
        removeFromAvailableUsers: any[]
        availableUsers: any
        recipientsCount: any

        constructor(
            private usersService, 
            private currentUserService
        ){}
        
        $onInit(){
            this.loaded = !!this.formData
            if(this.loaded) {
                this.recipients = this.formData.recipients;
                this.initiate()
                this.validate();
            };
        }

        initiate(){
            this.usersService.getUsers(true).then((users) => {
                let allUsers = angular.copy(users.filter(u => u.isActive && u.id !== this.currentUserService.user.id));
                let forRemove = [];
                for (var i = 0; i < allUsers.length; i++) {
                    if (allUsers[i].roles &&
                        allUsers[i].roles.find(x => x.application === "OMC") === undefined) {
                        forRemove.push(allUsers[i]);
                    }
                }

                for (var j = 0; j < forRemove.length; j++) {
                    var user = allUsers.find(x => x.userId === forRemove[j].userId);
                    if (user !== undefined) {
                        var index = allUsers.indexOf(user);
                        allUsers.splice(index, 1);
                    }
                }
                this.removeFromAvailableUsers = [];
                this.availableUsers = angular.copy(allUsers);
                if (this.formData.approvers) {
                    this.availableUsers.forEach((user) => {
                        var approver = this.formData.approvers.find(x => x.userId === user.id);
                        if (approver) {
            
                            if (approver.isApproved==null) {
                                // Waiting
                                user.jobRequestApprovalStatus = 'Waiting';
                                this.recipients.push(user);
                                this.removeFromAvailableUsers.push(user);
                            }
                            else if (approver.isApproved===true) {
                                // Approved
                                user.jobRequestApprovalStatus = 'Approved'; 
                                this.recipients.push(user);
                                this.removeFromAvailableUsers.push(user);
                            }
                            else if (approver.isApproved===false) {
                                // Denied
                                user.jobRequestApprovalStatus = 'Denied';
                                this.recipients.push(user);
                                this.removeFromAvailableUsers.push(user);
                            }
                        }
                    });
                }

                this.removeFromAvailableUsers.forEach((userToRemove) => {
                    var index = this.availableUsers.indexOf(userToRemove);
                    if (index > -1) {
                        this.availableUsers.splice(index, 1);
                    }
                });

                if (this.recipients) {
                    this.recipients.forEach((user) => {
                        user.nonRemoveableUser = true;
                    });
                    this.recipientsCount = this.recipients.length;
                }

                this.availableUsersSelected = [];
                this.recipientsSelected = [];
            })
        }

        toggleAvailableSelected(user){
            this.toggleInArray(user, this.availableUsersSelected);
            this.validate();
        }
        toggleRecipientsSelected(user){
            this.toggleInArray(user, this.recipientsSelected);
            this.validate();
        }

        validate(){
            this.formData.isValid = this.recipients && this.formData.message && this.recipients.length > 0 && this.formData.message !== "" && this.recipientsCount !== this.recipients.length;
        };

        canRemoveUser(user){
            if (user && user.nonRemoveableUser) {
                return true;
            }
            return false;
        };
        recipientsAddSelected(user) {
            if (user && !this.recipientsSelected.includes(user)) {
                this.availableUsersSelected.push(user);
            }
    
            this.availableUsersSelected.forEach(user => this.moveUserArray(user, this.availableUsers, this.recipients));
            this.recipientsSelected = [];
            this.availableUsersSelected = [];
            this.validate();
        }
    
        recipientsRemoveSelected(user) {
            if (this.canRemoveUser(user)) {
                return;
            }
            if (user && !this.recipientsSelected.includes(user)) {
                this.recipientsSelected.push(user);
            }
            this.recipientsSelected.forEach(user => this.moveUserArray(user, this.recipients, this.availableUsers));
            this.recipientsSelected = [];
            this.availableUsersSelected = [];
            this.validate();
        }
    
        toggleInArray(user, array) {
            var idx = array.findIndex(x => x === user);
            if (idx === -1) {
                if (this.canRemoveUser(user)) {
                    return;
                }
                array.push(user);
            } else {
                array.splice(idx, 1);
            }
        }
    
        moveUserArray(user, arrayFrom, arrayTo) {
            var idx = arrayFrom.findIndex(x => x === user);
            if (idx > -1) {
                arrayFrom.splice(idx, 1);
                arrayTo.push(user);
            }
        }

    }
}

angular
    .module('omc')
    .component(jobRequestApprovalComponent.selector, jobRequestApprovalComponent)