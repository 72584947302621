import * as angular from 'angular'
import { OmcComponent } from 'shared/models/omc.types'
import { EditReportsDialogData } from '../edit-report/edit-report.component';

let systemReportsComponent: OmcComponent = {
    selector: `systemReportsComponent`,
    template: require("./system-reports.component.html"),
    bindings: {

    },
    controller: class SystemReportsController implements angular.IController {
        systemReports: any;
        gridHelper: any;

        selectedReport: any;
        reportDialogData: EditReportsDialogData;
        dialogService: any;
        lang: any;
        dialogId = 'edit-user-report';

        constructor(
            private gridSettingsService,
            private dataService,
            private $state,
            private gridHelperServiceFactory,
            language,
            omcDialogService,
        ) {
            this.dialogService = omcDialogService;
            this.lang = language;
        }

        $onInit() {
            (<any>window).omc.currentVm = this;
            (<any>window).omc.currentVm.refresh = () => {
                this.$state.go(this.$state.current, {}, { reload: true });
                this.getSystemReports(true);
            }

            this.initialiseGrid();
            this.getSystemReports(true);
        }

        gridRowSelected(item) {
            return this.selectedValues().find(x => x === item);
        };

        isCurrentReportValid() {
            if ((this.selectedValues().length > 0) && (this.selectedValues()[0].url !== null)) {
                return true;
            }
            return false;
        };

        previewReport() {
            let selected = this.selectedValues()[0];
            if (selected.isOpenNewWindow) {
                window.open(selected.url, "_blank");
            } else {
                window.open(selected.url, "_self");
            }
        }

        saveReport() {
            this.dataService.postData(this.selectedReport, "Report", "Report", false).then((data) => {
                this.dialogService.close(this.dialogId)
                this.getSystemReports(true);
            }, (error) => {
                console.log(error);
            });
        }

        initialiseGrid() {
            let columnDefs = [{
                name: 'id',
                displayName: '',
                cellTemplate: '<div class="grid-row-vertical-bar" ng-class="{\'grid-row-selected\': grid.appScope.gridRowSelected(row.entity) }" data-id="{{row.entity.id}}" role="button" tabindex="0"></div>',
                width: this.gridSettingsService.columnWidths.toggleSelect,
                pinnedLeft: true,
                cellClass: "omc-select-column"
            }, {
                name: "name",
                displayName: "Report Name",
                cellTemplate: `<div class="ui-grid-cell-contents"><a href="#" ng-click="grid.appScope.reportDialog(row.entity).view(); $event.preventDefault();">{{row.entity.name}}</a></div>`,
                width: this.gridSettingsService.columnWidths.medium
            }, {
                name: "url",
                displayName: "URL",
                width: 800
            }];
            this.gridHelper = this.gridHelperServiceFactory.gridHelper('systemReports', '#system-reports-grid', this, columnDefs)
            this.gridHelper.gridData.gridId = 'system-reports-grid';
            this.gridSettingsService.initialiseGridSettings({
                id: this.gridHelper.gridData.gridId,
                name: 'system-reports-grid',
                columnsInfo: []
            });
        }

        getSystemReports(force) {

            this.dataService.getData("Report?typeId=0", "Report?typeId=0", force, false).then((systemReports) => {
                this.systemReports = systemReports;
                this.gridHelper.dataReloaded(this.systemReports)
            });
        }

        selectedValues() {
            return this?.gridHelper?.selectedValues() || [];
        }

        reportDialog(report?) {
            report = report || this.gridHelper.selectedValues()[0];
            let vm = this;
            let dialogData = new EditReportsDialogData({
                isSystemReport: true
            })
            return {
            //    add: add,
                edit: edit,
                view: view,
            }
            // Can add ability to add reports here [TS 07/01/21]
            // function add() {
            //     report = { collections: [] }
            //     dialogData.assign({
            //         usedNames: vm.systemReports.map(x => x.name),
            //         isAdd: true,
            //         isEdit: true,
            //         selectDefaultCollection: false,
            //     })
            //     open('New Report')
            // }
            function edit() {
                dialogData.assign({
                    usedNames: vm.systemReports.filter(x => x.id !== report.id).map(x => x.name),
                    isEdit: true,
                })
                open('Edit Report')
            }
            function view() {
                open('Report')
            }
            function open(title: string = '') {
                vm.selectedReport = angular.copy(report);
                vm.reportDialogData = dialogData
                vm.dialogService.open(vm.dialogId, { title: title })
            }
        }
    }

}

angular
    .module('omc')
    .component(systemReportsComponent.selector, systemReportsComponent)