import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import * as angular from 'angular';
import { CurrentUserService } from './current-user.service';
import { DataService } from './data.service';

@Injectable({
    providedIn: 'root'
  })
export class JobSettingsService {
    constructor(private dataService: DataService, private currentUserService: CurrentUserService) {}

    getMaxPriorityForRole(force) {
        return new Promise((resolve) => {
            this.dataService.getData('jobPriorities', 'jobPriorities/GetRolePriority', force).then((data) => {
                resolve(data);
            });
        });
    }

    getSettings(force) {
        return new Promise((resolve) => {
            this.dataService.getData('jobPriorities', 'jobPriorities', force).then((data: { signInMethod: any }) => {
                data.signInMethod = data.signInMethod || 'email';
                resolve(data);
            });
        });
    }

    getSettingsForCurrentUser() {
        return Promise.all([this.getSettings(true), this.currentUserService.getCurrentUser(false)]).then(
            ([settings, user]: Array<any>) => {
                let currentUserRole = user.roles[0].roleCode;
                let roleSettings = settings.find((x) => x.roleCode === currentUserRole);
                return roleSettings;
            }
        );
    }

    saveSettings(settings) {
        return this.dataService.putData(settings, 'jobPriorities', '', false);
    }
}
angular.module('omc').factory('jobSettingsService', downgradeInjectable(JobSettingsService));
