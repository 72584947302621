import * as angular from 'angular';
import { ArtefactDialogData } from "../../shared/models/dialog-data.model";
import { OmcComponent } from "../../shared/models/omc.types";
import { MatDialogService } from "../../shared/services/mat-dialog.service";

let dataConnectionComponent: OmcComponent = {
    selector: 'dataconnectionComponent',
    template: require('./data-connection.component.html'),
    controller: class dataConnectionController implements angular.IController {
        dialogService: any;
        lang: any;
        gridHelper: any;
        defaultTypes: { id: number; name: string; }[];
        databases: any[];
        dataConnections: any[];
        filteredDataConnections: any[];
        selectedDataConnection: any;
        dataConnectionDialogData: ArtefactDialogData;
        model: any;
        isEdit: boolean;
        currentDataConnection: { dataConnection: any; cancel: any; save: any; setDefault: any; setInputDefault: any; setResultDefault: any; };

        constructor(
            private dataService,
            private matDialogService: MatDialogService,
            private modalDialogService,
            private gridSettingsService,
            private $rootScope,
            private generalDialogService,
            private uiActionsService,
            private gridHelperServiceFactory,
            private $state,
            omcDialogService,
            language,
        ) {
            this.dialogService = omcDialogService;
            this.lang = language;
        }

        $onInit() {
            this.defaultTypes = [{ id: 0, name: "SQL Server" }, { id: 2, name: "UNC Path" }];
            this.databases = [];
            this.dataConnections = [];
            this.filteredDataConnections = [];
            this.selectedDataConnection = null;
            this.dataConnectionDialogData = new ArtefactDialogData();
            this.isEdit = false;

            (<any>window).omc.currentVm = this;
            (<any>window).omc.currentVm.refresh = () => this.getDataConnections(true, true);

            this.uiActionsService
                .getPermissionsForName('dataConnections-edit')
                .then((perm) => {
                    this.isEdit = perm && !perm.disabled;
                });

            this.getDataConnections(true);
            this.initGrid();
        }

        initGrid() {
            this.gridHelper = this.gridHelperServiceFactory.gridHelper(
                'dataConnectionController',
                '#dataconnection-grid',
                this,
                this.columnDefs()
            );
            this.gridHelper.gridData.gridId = 'data-connections-grid';
            this.gridSettingsService.initialiseGridSettings({
                id: this.gridHelper.gridData.gridId,
                name: 'DataConnections-Grid',
                columnsInfo: []
            });
            this.gridHelper.registerRowsRenderingComplete(() => {
                if (this.model && this.model.name && this.model.name) {
                    let filterRows = this.dataConnections.filter(x => x.name === this.model.name);
                    this.gridHelper.setSelected(filterRows[0]);
                    this.model = null;
                }
            });
        }

        initServerEvents() {
            this.$rootScope.$on("serverEvent:DataConnectionServerEvent", (event, payload) => {
                if (payload.forceRefresh) {
                    this.getDataConnections(true, true);
                }
                else {
                    let dataConnection = payload.data;
                    let modifiedDataConnection = dataConnection;

                    modifiedDataConnection.connectionType = this.defaultTypes.filter(e => e.id === dataConnection.connectionType).map(e => e.name)[0];

                    const idx = this.dataConnections.findIndex((x) => { return x.id === dataConnection.id });
                    if (idx > -1) {
                        this.dataConnections[idx] = modifiedDataConnection;
                    }
                    else {
                        this.dataConnections.push(modifiedDataConnection);
                    }

                    this.gridHelper.dataReloaded(this.dataConnections);
                }
            })
        }

        getDataConnections(force = false, refresh = false) {
            if (refresh) {
                this.$state.go(this.$state.current, {}, { reload: true });
                force = true;
            }
            this.dataService.getData('dataConnection', 'dataConnection', force).then((dataConnections) => {
                dataConnections.forEach(x => {
                    x.userName = x.userId;
                    x.connectionTypeDescription = this.defaultTypes.find(e => e.id === x.connectionType).name;
                    x.password = angular.copy(x.encryptedPassword);
                });
                this.dataConnections = dataConnections;
                this.gridHelper.dataReloaded(this.dataConnections);
            });
        }

        editsDisabled() {
            return this.gridHelper.selectedValues().length !== 1;
        }

        showUserProfile(id: string | number) {
            this.generalDialogService.userDetails(id)
        }

        deleteDisabled() {
            return this.gridHelper.selectedValues().length === 0;
        }

        gridRowSelected(item) {
            return this.gridHelper.selectedValues().find(x => x === item);
        }

        testConnection(dataConnection, dialogData) {
            if (dataConnection !== null && dataConnection !== undefined) {
                let saveInitialCatalog = dataConnection.initialCatalog;
                dataConnection.initialCatalog = "";
                this.dataService.postData(dataConnection, 'testDataConnection', 'testDataConnection', false).then((response) => {
                    // TODO: should probably review the response.result to make it more generic
                    if (response.result === "Valid Data Connection") {
                        dialogData.databases = response.databases;
                        this.databases = response.databases;
                    }
                });
                dataConnection.initialCatalog = saveInitialCatalog;
            }
        }

        setDefault() {
            this.currentDataConnection = {
                dataConnection: this.gridHelper.selectedValues()[0],
                cancel: ()=>this.modalDialogService.hide(),
                save: ()=>this.saveDefaults(),
                setDefault: this.gridHelper.selectedValues()[0].isAppDefault,
                setInputDefault: this.gridHelper.selectedValues()[0].isDataConnInputDefault,
                setResultDefault: this.gridHelper.selectedValues()[0].isDataConnResultsDefault
            };
            this.matDialogService.openEditDefaultDataConnectionDialog(this.currentDataConnection)
                .subscribe((currentDataConnection) => {
                    this.currentDataConnection = currentDataConnection;
                    if (currentDataConnection) {
                        this.saveDefaults();
                    }
                });
        }

        saveDefaults() {
            if (this.currentDataConnection) {
                if (this.currentDataConnection.setInputDefault) {
                    this.gridHelper.selectedValues().length &&
                        this.gridHelper.selectedValues()[0].setOtherDefault('dataConnectionInputs');
                } else {
                    this.gridHelper.selectedValues().length &&
                        this.gridHelper.selectedValues()[0].removeOtherDefault('dataConnectionInputs');
                }
                if (this.currentDataConnection.setResultDefault) {
                    this.gridHelper.selectedValues().length &&
                        this.gridHelper.selectedValues()[0].setOtherDefault('dataConnectionResults');
                } else {
                    this.gridHelper.selectedValues().length && this.gridHelper.selectedValues()[0].removeOtherDefault('dataConnectionResults');
                }
                this.modalDialogService.hide()
                this.getDataConnections(true);
            }
        }

        dataConnectionDialog(dataConnection?) {
            let vm = this;
            if (dataConnection) {
                dataConnection = angular.copy(dataConnection)
            } else if (vm.gridHelper.selectedValues()[0]) {
                let selected = vm.gridHelper.selectedValues()[0]
                dataConnection = angular.copy(selected)
            }

            let dialogData = new ArtefactDialogData()
            dialogData.assign({
                usedNames: vm.dataConnections.map(x => x.name),
                types: vm.defaultTypes,
                databases: vm.databases
            })

            return {
                view: view,
                edit: edit,
                add: add
            }

            function view() {
                if (!dataConnection) return;
                vm.testConnection(dataConnection, dialogData);
                openDialog('Data Connection')
            }

            function edit() {
                if (!dataConnection) return;
                dataConnection.password = angular.copy(dataConnection.encryptedPassword);
                dialogData.assign({
                    isEdit: true,
                    usedNames: vm.dataConnections
                        .filter(x => x.id !== dataConnection.id)
                        .map(x => x.name),
                })
                vm.testConnection(dataConnection, dialogData);
                openDialog('Edit Data Connection')
            }

            function add(type) {
                dialogData.assign({
                    isEdit: true,
                    isAdd: true,
                    dataBases: [],
                })
                dataConnection = {
                    connection: 0,
                    security: 0,
                    connectionType: type == "Oracle" ? 1 : (type == "UNC Path" ? 2 : 0),
                    port: type == "Oracle" ? "" : (type == "UNC Path" ? "" : 1433),
                    isActive: false,
                    password: undefined,
                    status: true,
                }
                openDialog('Add Connection')
            }
            function openDialog(title = '') {
                vm.selectedDataConnection = dataConnection
                vm.dataConnectionDialogData = dialogData
                vm.dialogService.open('edit-data-connection', { title: title })
            }
        }

        saveDataConnection() {
            var dc = this.selectedDataConnection;
            dc.userId = dc.userName;
            dc.encryptedPassword = dc.password;

            if (dc.connectionType == 2) {
                dc.UNCFilePath = dc.uncFilePath;
                //Set All other parameters to default values.
                dc.security = 0;
                dc.connection = 0;
                dc.dataSource = "";
                dc.userid = "";
                //Default port is OK...
                dc.port = 1433;
                dc.encryptedPassword = "";
                dc.initialCatalog = "";
                dc.serviceName = "";
            }
            this.dataService.putData(dc, 'dataConnection').then(() => {
                this.dialogService.close('edit-data-connection')
                this.getDataConnections(true);
            });
        }

        columnDefs(): any[] {
            return [
                {
                    name: 'id',
                    displayName: '',
                    cellTemplate: '<div class="grid-row-vertical-bar" ng-click="grid.appScope.gridHelper.toggleSelected(row.entity, $event)" ng-class="{\'grid-row-selected\': grid.appScope.gridRowSelected(row.entity) }" role="button" tabindex="0"></div>',
                    width: this.gridSettingsService.columnWidths.toggleSelect,
                    pinnedLeft: true,
                    cellClass: "omc-select-column"
                },
                {
                    name: 'name',
                    displayName: 'Name',
                    cellTemplate: `
                            <div class="ui-grid-cell-contents" ng-class="{'deleted-row-text': !row.entity.status, 'from-model':row.entity.createdAutomatically } ">
                                <a href="#" ng-click="grid.appScope.dataConnectionDialog(row.entity).view(); $event.preventDefault();">{{row.entity.name}}</a>
                            </div>
                        `,
                    width: this.gridSettingsService.columnWidths.default
                },
                {
                    name: 'isDataConnInputDefault',
                    displayName: 'Default for Inputs',
                    cellTemplate: `<div class="ui-grid-cell-contents" ng-class="{'tick-icon': row.entity.isDataConnInputDefault} "><a href="#" ng-click="grid.appScope.dataConnectionDialog(row.entity).view(); $event.preventDefault();" style="visibility: collapse;">{{row.entity.name}}</a> </div>`,
                    width: 150,
                },
                {
                    name: 'isDataConnResultsDefault',
                    displayName: 'Default for Results',
                    cellTemplate: `<div class="ui-grid-cell-contents" ng-class="{'tick-icon': row.entity.isDataConnResultsDefault} "><a href="#" ng-click="grid.appScope.dataConnectionDialog(row.entity).view(); $event.preventDefault();" style="visibility: collapse;">{{row.entity.name}}</a> </div>`,
                    width: 150,
                },
                {
                    name: 'connectionTypeDescription',
                    displayName: 'Connection Type',
                    width: this.gridSettingsService.columnWidths.default
                },
                {
                    name: 'isActive',
                    displayName: 'Status',
                    cellTemplate: '<div>{{row.entity.status? "Active": "Suspended"}}</div>',
                    width: this.gridSettingsService.columnWidths.default
                },
                {
                    name: 'notes',
                    displayName: 'Notes',
                    width: this.gridSettingsService.columnWidths.notes
                },
                {
                    name: "dateCreated",
                    displayName: 'Date Created',
                    headerCellClass: 'DateCreated',
                    cellFilter: 'omcDate', width: this.gridSettingsService.columnWidths.dateTime
                },
                {
                    name: "createdById", displayName: 'Created By', headerCellClass: 'Created-By-Name', width: this.gridSettingsService.columnWidths.userName,
                    cellTemplate: '<div class="ui-grid-cell-contents"><a href="#" ng-click="grid.appScope.showUserProfile(row.entity.createdById); $event.preventDefault();">{{row.entity.createdById}}</a></div>'
                }
            ];
        }
    }
}
angular
    .module('omc')
    .component(dataConnectionComponent.selector, dataConnectionComponent)