import './omc.module';
import './omc.run';
import './omc.config';
import './user';
import './tasks';
import './tableSets';
import './shared';
import './reports';
import './home';
import './jobs';
import './data-sets';
import './admin';
import './bases';
import './activity-monitor';

import './polyfills';