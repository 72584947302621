import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import * as angular from 'angular';
import { Endpoint } from './../models/endpoint.model';

@Injectable({
    providedIn: 'root'
  })
export class OmcLoadingService {
    log = [];

    constructor() {}

    registerCall(url, promise) {
        if (!url || !promise) return;
        let endpoint = url.includes('?') ? url.split('?')[0] : url;
        let ep = this.getEndpoint(endpoint);
        if (ep) {
            ep.loading = true;
            ep.promises.push(promise);
            ep.setFinalPromise();
        } else {
            this.log.push(new Endpoint(endpoint, promise));
        }
    }

    getEndpoint(endpoint) {
        return this.log.find((ep) => ep.url === endpoint);
    }
    isLoading(endpoint) {
        let endpointInfo = this.getEndpoint(endpoint);
        return !!endpointInfo && endpointInfo.loading;
    }
}
angular.module('omc').factory('omcLoadingService', downgradeInjectable(OmcLoadingService));
