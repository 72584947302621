import { Injectable } from '@angular/core';
import * as angular from 'angular';
import { CurrentUserService } from './current-user.service';
import { OmcGlobalsService } from './omc-globals.service';
import { DataService } from './data.service';
import { downgradeInjectable } from '@angular/upgrade/static';

@Injectable({
    providedIn: 'root'
  })
export class JobsService {
    jobTypeStatusSuspended = { id: 0, name: 'Suspended' };
    jobTypeStatusActive = { id: 1, name: 'Active' };

    jobTypeStatusesMap = {
        suspended: 0,
        active: 1,
    }; // if jobTypeStatuses changes, jobTypeStatusesMap needs to be changed to match.

    jobTypeStatuses = [this.jobTypeStatusActive, this.jobTypeStatusSuspended];

    constructor(
        private dataService: DataService,
        private omcGlobalsService: OmcGlobalsService,
        private currentUserService: CurrentUserService
    ) {}

    getJobs(force) {
        return this.dataService.getData('jobs', 'jobs', force, false);
    }

    getAllJobs(force) {
        return this.dataService.getData('jobs?showAllObjects=true', 'jobs?showAllObjects=true', force);
    }

    cancelJobs(jobs) {
        jobs.forEach((x) => {
            if (this.isRunningOnly(x)) {
                this.cancelJobEx(x)
            }
        });
    }

    cancelJobEx(job) {
        return this.dataService.putData(job, 'cancelJob', 'cancelJob', false);
    }

    getJobTypes(force) {
        return this.dataService.getData('jobTypes', 'jobTypes', force);
    }

    getActiveJobTypes(force) {
        return new Promise((resolve) => {
            this.dataService.getData('jobTypes', 'jobTypes', force).then((jobs: Array<any>) => {
                resolve(jobs.filter((x) => x.status === this.jobTypeStatusesMap.active));
            });
        });
    }

    put(data) {
        data.modelId = data.model && data.model.id;
        return this.dataService.putData(data, 'jobs', 'jobs', false);
    }

    /* called in activity monitor - mutates original object [TM 06/07/22] */
    updateJobFromEmJob(job, emJob) {
        if (emJob) {
            for (let prop in emJob) {
                if (prop !== 'id' && prop !== 'status' && emJob.hasOwnProperty(prop)) {
                    job[prop] = emJob[prop];
                    job.lastRunEnd = emJob.endTime;
                }
            }

            let lastJobEx = job.lastJobExecuted;
            for (let prop in emJob) {
                if (emJob.hasOwnProperty(prop)) {
                    lastJobEx[prop] = emJob[prop];
                }
            }
        }
        job.progress = { totalItemsToProcess: job.totalItemsToProcess, itemsProcessed: job.itemsProcessed };
    }

    // This is to check if the job is of any the states defined in jobRunningStatesValues
    isRunning(job) {
        if (job.hasJustBeenStarted) {
            return true;
        }
        let jobEx = job && job.lastJobExecuted;
        if (!jobEx) {
            return false;
        }

        let jobRunningStatesValues: number[] = this.omcGlobalsService.values('jobRunningStatesValues');
        let jobExState = this.omcGlobalsService.valueForKey('jobStates', jobEx.state);

        return jobRunningStatesValues.indexOf(jobExState) >= 0;
    }

    // This is to check if the job is of the state: "Running" and excludes all other states
    isRunningOnly(job) {
        const jobState = Number(this.getJobState(job));
        let runningState = this.omcGlobalsService.valueForKey('jobStates', 'Running')
        
        return jobState === runningState;
    }

    getJobState(job) {
        if (!job || !job.lastJobExecuted) {
            return null;
        }
        const theJobState =  this.omcGlobalsService.valueForKey('jobStates', job.lastJobExecuted.state);
        
        return theJobState;
    }

    saveJobViewModel(jobType) {
        return this.dataService.postData(jobType, 'saveJobType', 'saveJobType', false);
    }

    deleteJobs(data) {
        return this.dataService.putData(data, 'deleteJobs', 'deleteJobs', false);
    }

    restoreJobs(data) {
        return this.dataService.putData(data, 'restoreJobs', 'restoreJobs', false);
    }

    getJobById(id, force, showSpinner = true) {
        return this.dataService.getData('getJobById?jobId=' + id, 'getJobById?jobId=' + id, force, showSpinner);
    }

    getJobsByIds(ids, force, showSpinner = true) {
        return this.dataService.getData('getJobsByIds?jobIds=' + ids, 'getJobsByIds?jobIds=' + ids, force, showSpinner);
    }

    getJobDetailsById(id, force, showSpinner = true) {
        return this.dataService.getData(
            'getJobDetailsById?jobId=' + id,
            'getJobDetailsById?jobId=' + id,
            force,
            showSpinner
        );
    }

    getJobDetailsByIds(ids, force, showSpinner = true) {
        return this.dataService.getData(
            'getJobDetailsByIds?jobIds=' + ids,
            'getJobDetailsByIds?jobIds=' + ids,
            force,
            showSpinner
        );
    }

    refreshJobStatus() {
        return this.dataService.getData(
            'refreshJobStatus?userId=' + this.currentUserService.user.id,
            'refreshJobStatus?userId=' + this.currentUserService.user.id,
            false,
            false
        );
    }

    /* Removed the following unused functions: getJobPrioritiesForRole, updateJobsExecutionStatus, updateJobFromEmJobs [TM 11/07/22] */
}

angular.module('omc').factory('jobsService', downgradeInjectable(JobsService));
