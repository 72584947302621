import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import * as angular from 'angular';
import { ApiService } from './api.service';
import { DefaultItemService } from './default-item.service';
import { SerialisationService } from './serialisation.service';

@Injectable({
    providedIn: 'root'
  })
export class DataService {
    data: any;

    constructor(
        private apiService: ApiService,
        private serialisationService: SerialisationService,
        private defaultItemService: DefaultItemService
    ) {
        (<any>window).omc = {data: {}};
        this.data = this.serialisationService.retrocycle((<any>window).omc.data);
    }

    /* Doesn't appear to be used [CM 15/11/22] */
    setData(key, value) {
        this.data[key] = value;
    }

    resetCache(dataSetName?) {
        if (dataSetName) {
            this.data[dataSetName] = undefined;
        } else {
            this.data = {};
        }
    }

    putData(value, key, url, reload, globalSpinner = true) {
        url = url || key;
        let valStr = this.serialisationService.serialiseJsonGraph(value);

        return new Promise<void>((resolve, reject) => {
            this.apiService
                .post(url, valStr, null, globalSpinner)
                .then(() => {
                    if (reload !== false) {
                        // refresh cache after update
                        this.getData(key, url, true).then(resolve);
                    } else {
                        resolve();
                    }
                })
                .catch((err) => reject(err));
        });
    }

    getData(key, url, force, showSpinner = true) {
        url = url || key;
        return new Promise((resolve, reject) => {
            if (this.data[key] && !force) {
                resolve(this.data[key]);
            } else {
                this.apiService
                    .get(url, null, showSpinner)
                    .then((result) => {
                        this.data[key] = this.serialisationService.retrocycle(result);
                        this.defaultItemService.refreshDefaults(false, key).then(() => {
                            this.defaultItemService.setDefaults(key, result);
                            resolve(this.data[key]);
                        });
                    })
                    .catch((err) => {
                        reject(err);
                    });
            }
        });
    }

    postData(value, key, url, reload, globalSpinner = true) {
        url = url || key;
        let valStr = this.serialisationService.serialiseJsonGraph(value);

        return new Promise((resolve, reject) => {
            this.apiService
                .post(url, valStr, null, globalSpinner)
                .then((result) => {
                    if (reload !== false) {
                        // refresh cache after update
                        this.getData(key, url, true, globalSpinner).then(resolve);
                    } else {
                        resolve(result);
                    }
                })
                .catch((err) => reject(err));
        });
    }
}
angular.module('omc').factory('dataService', downgradeInjectable(DataService));
