import * as angular from 'angular'
import { OmcComponent } from 'shared/models/omc.types'
import { OmcFunc } from "../../../shared/functions/omc.functions";

let editJobTemplateComponent: OmcComponent = {
    selector: `editJobTemplateComponent`,
    template: require("./edit-job-template.component.html"),
    bindings: {
        jobTemplate: "=",
        formData: "=",
    },
    controller: class editJobTemplateController implements angular.IController {
        lang: any;
        maximumNoOfWorkers: number;
        maxNoOfSplits: number;
        loaded: boolean;
        formData: any;
        jobTemplate: any;
        noOfWorkersOptions: any;
        noOfSplitsOptions: any;
        workerConfigurations: any;
        status: boolean;

        constructor(
            private platformSettingsService, 
            private dataService,
            private language,
            private formHelperService,
            private jobSettingsService,
        ){
            this.lang = this.language
        }
        
        $onInit(){
            this.maximumNoOfWorkers = 0;
            this.maxNoOfSplits = 0;

            this.loaded = !!this.formData && !!this.jobTemplate
            if(!this.loaded) return;
            this.populateOptions();
            this.status = this.jobTemplate.jobTemplateStatusId === 0;
        }

        isInvalid(key){
            var errors = this.validate();
            this.formData.isValid = errors.isValid();
            if (key) {
                return errors[key];
            }
            return !errors.isValid();
        }

        errorMessageFor(){
            return this.validate();
        }

        onChangeStatus(){
            this.jobTemplate.jobTemplateStatusId = this.status ? 0 : 1;
        }

        onLogLevelChange(){
            var logLevel = this.formData.logLevels.filter(x => x.id === this.jobTemplate.logLevelId)[0];
            this.jobTemplate.logLevel = logLevel;
        }
    
        populateOptions() {
            Promise.all([
                this.platformSettingsService.getSettings(true),
                this.jobSettingsService.getSettingsForCurrentUser()
            ])
            .then(([platSettings, roleSettings])=> {
                this.maximumNoOfWorkers = roleSettings.maximumNoOfWorkers;
                this.maxNoOfSplits = platSettings.maximumNoOfSplits
    
                if(!this.noOfWorkersOptions){
                    this.noOfWorkersOptions = this.formHelperService.numericalFormOptions(1, this.maximumNoOfWorkers)
                }
                if(!this.jobTemplate.noOfWorkers){
                    this.jobTemplate.noOfWorkers =  roleSettings.defaultNoOfWorkers
                } else if (this.jobTemplate.noOfWorkers > this.maximumNoOfWorkers){
                    // create a disabled, invalid option and push to noOfWorker options
                    let invalidOption = this.formHelperService.numericalFormOptions(this.jobTemplate.noOfWorkers)[0]
                    invalidOption.isDisabled = true
                    invalidOption.value += " [Invalid]"
                    this.noOfWorkersOptions.push(invalidOption)
                    // force angular to refresh form
                    // this.jobTemplate.noOfWorkers = this.jobTemplate.noOfWorkers
                }
                if(!this.noOfSplitsOptions){
                    this.noOfSplitsOptions = this.formHelperService.numericalFormOptions(1, this.maxNoOfSplits) 
                    
                }
                if(!this.jobTemplate.noOfSplits){
                    if( this.maxNoOfSplits >= roleSettings.defaultNoOfWorkers){
                        this.jobTemplate.noOfSplits = roleSettings.defaultNoOfWorkers; // Why? see OMC-963
                    } else {
                        this.jobTemplate.noOfSplits = this.maxNoOfSplits;
                    }
                }
                if (!this.workerConfigurations) {
                    this.getWorkersConfigOptions(platSettings).then(configOptions=>{
                        this.workerConfigurations = configOptions
                        if(this.jobTemplate.workerConfigurationName
                            && !this.workerConfigurations.find(x => x.Name === this.jobTemplate.workerConfigurationName)){
                                let flaggedName = this.jobTemplate.workerConfigurationName+" [Removed]";
                                this.workerConfigurations.push({
                                    Name: flaggedName,
                                    disabled: true
                                })
                                this.jobTemplate.workerConfigurationName = flaggedName
                        }
                    })
                }
            })
        }
        getWorkersConfigOptions(platformSettings){
            const url = "getWorkersConfigs?workerConfigurationLocation=" + platformSettings.workerConfigLocation
            return this.dataService.getData(url, url, true).then((workerConfigData)=> {
                return workerConfigData.map(data=> data.Configuration)
            });
        }
    
        validate() {
            var hasErrors = false;
            var rtn: any = {
                isValid: () => !hasErrors
            };
            if (this.formData.isEdit && this.jobTemplate) {
                if (!this.jobTemplate.name) {
                    rtn.name = "Please specify a name for job template";
                    hasErrors = true;
                }
                else  if (this.formData.usedNames.includes(this.jobTemplate.name)) {
                    rtn.name = "Name is already in use, please use a different name";
                    hasErrors = true;
                }
                if(!(this.jobTemplate.logLevelId >= 0)){
                    rtn.loggingLevel = "Please set a logging level";
                    hasErrors = true;
                }
                if(!this.jobTemplate.writeLimit || !(this.jobTemplate.writeLimit >= 0)){
                    rtn.writeLimit = "Please set maximum number of logs";
                    hasErrors = true;
                }
                if(!this.jobTemplate.abortLimit || !(this.jobTemplate.abortLimit >= 0)){
                    rtn.abortLimit = "Please set maximum number of errors";
                    hasErrors = true;
                }
                if(!(this.jobTemplate.distributionStrategyId >= 0)){
                    rtn.distributionStrategy = "Please set a distribution strategy";
                    hasErrors = true;
                }
                if(this.jobTemplate.distributionStrategyId === 1 || this.jobTemplate.distributionStrategyId === 2){
                    if(!this.jobTemplate.noOfSplits || !(this.jobTemplate.noOfSplits >= 0)){
                        rtn.noOfSplits = "Please set number of splits";
                        hasErrors = true;
                    }
                    if(this.jobTemplate.distributionStrategyId===2){
                        if(!this.jobTemplate.noOfWorkers || !(this.jobTemplate.noOfWorkers >= 0)){
                            rtn.noOfWorkers = "Please set number of workers";
                            hasErrors = true;
                        } else if (this.jobTemplate.noOfWorkers > this.maximumNoOfWorkers){
                            rtn.noOfWorkers = "No of workers exceeds max allowed";
                            hasErrors = true;
                        } 
                        if(!this.jobTemplate.workerConfigurationName){
                            rtn.workerConfiguration = "Please set worker configuration";
                            hasErrors = true;
                        } else if (OmcFunc.isSet(this.workerConfigurations,true)){
                            let selectConfig = this.workerConfigurations.find(x => x.Name === this.jobTemplate.workerConfigurationName)
                            if(selectConfig && selectConfig.disabled){
                                rtn.workerConfiguration = "Please select a different worker configuration";
                                hasErrors = true;
                            }
                        }
                    }
                }
                
            }
            return rtn;
        }
    }
}

angular
    .module('omc')
    .component(editJobTemplateComponent.selector, editJobTemplateComponent)