import { Component, Input, OnInit } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import * as angular from 'angular';
import { pathToApp } from '../../core/constants/global-constants';
import { OmcFunc } from '../../shared/functions/omc.functions';

@Component({
    selector: `changePasswordComponent`,
    templateUrl: pathToApp + 'user/change-password/change-password.component.html',
})
export class ChangePasswordComponent implements OnInit {
    isLoaded: boolean;
    @Input() user;
    @Input() formData;

    constructor() {}

    ngOnInit() {
        this.isLoaded = !!this.formData && !!this.user;
        if (!this.isLoaded) return;
        this.onChange();
    }

    onChange() {
        this.isInvalid();
    }

    isInvalid(key?: string) {
        var errors = this.validate();
        this.formData.isValid = errors.isValid();
        if (key) {
            return errors[key];
        }
        return !errors.isValid();
    }

    errorMessageFor() {
        return this.validate();
    }

    validate(this) {
        var hasErrors = false;
        var rtn: any = {
            isValid: () => !hasErrors,
        };
        if (this.formData) {
            if (!OmcFunc.isSet(this.formData.oldPassword, true)) {
                rtn.oldPassword = 'Please specify old password';
                hasErrors = true;
            }
            if (!OmcFunc.isSet(this.formData.newPassword, true)) {
                rtn.newPassword = 'Please specify new password';
                hasErrors = true;
            }
            if (!OmcFunc.isSet(this.formData.confirmPassword, true)) {
                rtn.confirmPassword = 'Please confirm the new password';
                hasErrors = true;
            } else if (this.formData.confirmPassword !== this.formData.newPassword) {
                rtn.confirmPassword = 'Password and confirm password do not match';
                hasErrors = true;
            }
        }
        return rtn;
    }
}

angular.module('omc').directive(
    'changePasswordComponent',
    downgradeComponent({
        component: ChangePasswordComponent,
        inputs: ['user', 'formData'],
    })
);
