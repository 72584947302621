import { AlertNotificationTypes, ValueOptionsWithHashKey } from "../interfaces/constant-interfaces";

// Admin -> Platform Settings
export const omcWallpapers: ValueOptionsWithHashKey[] = [
    {
        value: 0,
        description: 'None',
        key: 'None',
        $$hashKey: 'object:249',
    },
    {
        value: 1,
        description: 'Balloons',
        key: 'Balloons',
        $$hashKey: 'object:250',
    },
    {
        value: 2,
        description: 'Chess',
        key: 'Chess',
        $$hashKey: 'object:251',
    },
    {
        value: 3,
        description: 'Clouds',
        key: 'Clouds',
        $$hashKey: 'object:252',
    },
    {
        value: 4,
        description: 'Maths',
        key: 'Maths',
        $$hashKey: 'object:253',
    },
    {
        value: 5,
        description: 'Speed',
        key: 'Speed',
        $$hashKey: 'object:254',
    },
];

// Alerts & Notifications options in User Settings
// Only first 3 currently used
export const alertNotificationTypes: AlertNotificationTypes[] = [
    {
        value: 0,
        description: 'Both',
        key: 'Both',
        longDescription: 'Both',
        $$hashKey: 'object:73',
    },
    {
        value: 1,
        description: 'Notifications',
        key: 'JobRunNotification',
        longDescription: 'Job Run Notification',
        $$hashKey: 'object:74',
    },
    {
        value: 2,
        description: 'Email',
        key: 'JobRunEmailAlert',
        longDescription: 'Job Run Email Alert',
        $$hashKey: 'object:75',
    },
    {
        value: 3,
        description: 'Notifications',
        key: 'PersonalMessageNotification',
        longDescription: 'Personal Message / Notification',
        $$hashKey: '',

    },
    {
        value: 4,
        description: 'Job Approval Request',
        key: 'JobApprovalRequestNotification',
        longDescription: 'Job Approval Request',
        $$hashKey: '',

    },
    {
        value: 5,
        description: 'Job Approval Status',
        key: 'JobApprovalStatusNotification',
        longDescription: 'Job Approval Status',
        $$hashKey: '',

    },
];

// User Settings List Sort Order
export const gridSortOrders: ValueOptionsWithHashKey[] = [
    {
        value: 0,
        description: 'Date Created (Ascending)',
        key: 'DateCreatedAscending',
        $$hashKey: 'object:79',
    },
    {
        value: 1,
        description: 'Date Created (Descending)',
        key: 'DateCreatedDescending',
        $$hashKey: 'object:80',
    },
    {
        value: 2,
        description: 'Name (Ascending)',
        key: 'NameAscending',
        $$hashKey: 'object:81',
    },
    {
        value: 3,
        description: 'Name (Descending)',
        key: 'NameDescending',
        $$hashKey: 'object:82',
    }
];

// User Settings Default Logging Level options
export const logLevels: ValueOptionsWithHashKey[] = [
    {
        value: 0,
        description: '(0) Off - No logging',
        key: 'Off',
        $$hashKey: 'object:83',
    },
    {
        value: 1,
        description: '(1) Fatal - Critical issues are raised. Application will stop.',
        key: 'Fatal',
        $$hashKey: 'object:84',
    },
    {
        value: 2,
        description: '(2) Error - Application exceptions are raised. Application may stop.',
        key: 'Error',
        $$hashKey: 'object:85',
    },
    {
        value: 3,
        description: '(3) Warning -Potential issue encountered.  Application will continue',
        key: 'Warning',
        $$hashKey: 'object:86',
    },
    {
        value: 4,
        description: '(4) Information - No issue but might be useful for user',
        key: 'Information',
        $$hashKey: 'object:87',
    },
    {
        value: 5,
        description: '(5) Debug - High level information for developers',
        key: 'Debug',
        $$hashKey: 'object:88',
    },
    {
        value: 6,
        description: '(6) Trace - Low level, verbose logging. Will generate very large logs.',
        key: 'Trace',
        $$hashKey: 'object:89',
    },
];
