import { ValueOptions } from "../interfaces/constant-interfaces";

export const jobStates: ValueOptions[] = [
    {
        value: 0,
        description: 'Failed',
        key: 'Failed',
    },
    {
        value: 1,
        description: 'Submitted',
        key: 'Submitted',
    },
    {
        value: 2,
        description: 'Configuring',
        key: 'Configuring',
    },
    {
        value: 3,
        description: 'Queued',
        key: 'Queued',
    },
    {
        value: 4,
        description: 'Validating',
        key: 'Validating',
    },
    {
        value: 5,
        description: 'Running',
        key: 'Running',
    },
    {
        value: 6,
        description: 'Finishing',
        key: 'Finishing',
    },
    {
        value: 7,
        description: 'Finished',
        key: 'Finished',
    },
    {
        value: 8,
        description: 'Finished With Errors',
        key: 'FinishedWithErrors',
    },
    {
        value: 9,
        description: 'Cancelling',
        key: 'Cancelling',
    },
    {
        value: 10,
        description: 'Cancelled',
        key: 'Cancelled',
    },
    {
        value: 11,
        description: 'Crashed',
        key: 'Crashed',
    },
    {
        value: 12,
        description: 'New',
        key: 'New',
    },
];

export const jobLinkingTypes: ValueOptions[] = [
    {
        value: 1,
        description: 'Standalone',
        key: 'Standalone',
    },
    {
        value: 2,
        description: 'Linked (Must Succeed)',
        key: 'LinkedDependent',
    },
    {
        value: 3,
        description: 'Linked (Can Fail)',
        key: 'LinkedInDependent',
    },
];

// Dropdown list in Run Job Settings
export const distributionStrategies: ValueOptions[] = [
    {
        value: 0,
        description: 'Use Parallel Settings from Original Task',
        key: 'UseSettingsFromOriginalTask',
    },
    {
        value: 1,
        description: 'Use Multiple Threads',
        key: 'UseMultipleThreads',
    },
    {
        value: 2,
        description: 'Use Worker Nodes',
        key: 'UseWorkerNodes',
    },
    {
        value: 3,
        description: 'None',
        key: 'None',
    },
];

// Admin -> Job Settings, items in Job Approval Rule dropdown
export const jobApprovalRules: ValueOptions[] = [
    {
        value: 0,
        description: 'None',
        key: 'None',
    },
    {
        value: 1,
        description: '4',
        key: 'Four',
    },
    {
        value: 2,
        description: '6',
        key: 'Six',
    },
];
