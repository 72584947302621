import * as angular from 'angular';
import { OmcFunc } from "../../functions/omc.functions";

angular.module('omc').directive("omcSpinner", function (omcLoadingService, $compile, $timeout) {
  return {
    restrict: 'A',
    scope: {
      loading: "=?",             // boolean - overrides spinForEndpoint
      spinForEndpoint: "@?",     // can be a string representing and endpoint, or an array of strings ['string','anotherString']
      disableWhileLoading: "=?", // boolean - defaults to true
      wrap: "=?",                // boolean - defaults to false
      disabled: '=?ngDisabled',    // boolean - inherits ng-disabled state
    },
    link: function ($scope: any, $el) {
      $scope.spin = true;
      $scope.wrap = $scope.wrap ?? false;
      let spinner = $compile('<omc-logo-spinner-component [spin]="spin" data-test="{{spin}}"></omc-logo-spinner-component>')($scope)
      let container;

      let disableWhileLoading = $scope.disableWhileLoading ?? true;

      // check if the element is an inline element like button or input
      let wrap = $scope.wrap ?? $($el).css('display').includes('inline')
      if (wrap) {
        // wrap the element and copy over size properties
        wrapElement($el)
        container = $($el).parent('omc-logo-spinner-component-host')
      } else {
        $($el).addClass('omc-spinner-host')
        container = $($el)
        // wrap text nodes in span so their opacity can be manipulated
        let textNodes = getTextNodesIn(container[0]);
        $(textNodes).wrap('<span></span>')
      }


      // check 'loading' attribute first to check if defined
      let loadingStatus = $scope.loading
      if (loadingStatus !== undefined && loadingStatus !== null) {
        updateLoadingStatus(loadingStatus, container, spinner)
        $scope.$watch('loading', function (e) {
          updateLoadingStatus(e, container, spinner)
        })
      } else {
        // check 'spinForEndpoint' attribute second if 'loading' wasn't provided
        let endpointInput = $scope.spinForEndpoint;
        try {
          let endpoints = OmcFunc.parseArray(endpointInput);
          $scope.$watch(function () {
            return endpoints.some(endpoint => omcLoadingService.isLoading(endpoint))
          }, function (isLoading) {
            updateLoadingStatus(isLoading, container, spinner)
          })
        } catch (err) {
          console.error(`Could not parse endpoints for spinner: ${endpointInput}\n`, err);
        }
      }

      let spinnerDebouncer = 0;
      function updateLoadingStatus(loading, container, spinner) {
        $timeout.cancel(spinnerDebouncer)
        if (loading) {
          spinnerDebouncer = $timeout(() => {
            appendSpinner(container, spinner)
          }, 300)
        } else {
          removeSpinner(container)
        }
      }

      function appendSpinner(container, spinner) {
        $scope.spin = true;
        $(container).addClass('show-spinner')
        if ($(container).children('omc-logo-spinner-component').length === 0) {
          $(container).append(spinner);
        }
        $(container).children('omc-logo-spinner-component').fadeIn(null, function () {
          let height = $(this).outerHeight()
          let width = $(this).outerWidth()
          $(this).css({
            maxHeight: width,
            maxWidth: height
          })
        })
        if (disableWhileLoading && ($scope.disabled ?? true)) {
          $($el).prop('disabled', 'disabled');
          $($el).addClass('disabled');
          $($el).attr('disabled', 'disabled');
        };
      }

      function removeSpinner(container) {
        $scope.spin = false;
        $(container).removeClass('show-spinner')
        if (disableWhileLoading && !$scope.disabled) {
          $($el).removeProp('disabled');
          $($el).removeClass('disabled');
          $($el).removeAttr('disabled');
        };
        $timeout(() => {
          if (!$scope.spin) {
            $(container).children('omc-logo-spinner-component').hide()
          }
        }, 1200)
      }

      function wrapElement(el) {
        let height = $el[0].offsetHeight;
        let width = $el[0].offsetWidth;
        let marginTop = $el.css('marginTop');
        let marginBottom = $el.css('marginBottom');
        let marginLeft = $el.css('marginLeft');
        let marginRight = $el.css('marginRight');
        let wrapper = $(`<div class="omc-spinner-inline-wrapper omc-spinner-host"></div>`);
        wrapper.css({
          height: height,
          width: width,
          marginTop: marginTop,
          marginBottom: marginBottom,
          marginLeft: marginLeft,
          marginRight: marginRight,
        })
        $(el).css({
          height: '100%',
          width: '100%',
          marginTop: 0,
          marginBottom: 0,
          marginLeft: 0,
          marginRight: 0,
        })
        $(el).wrap(wrapper)
      }

      //Adapted from: https://stackoverflow.com/questions/298750/how-do-i-select-text-nodes-with-jquery
      function getTextNodesIn(node, includeWhitespaceNodes = false) {
        var textNodes = [],
          nonWhitespaceMatcher = /\S/;
        function getTextNodes(node) {
          if (node.nodeType == 3 && (includeWhitespaceNodes || nonWhitespaceMatcher.test(node.nodeValue))) {
            textNodes.push(node);
          }
        }
        for (var i = 0, len = node.childNodes.length; i < len; ++i) {
          getTextNodes(node.childNodes[i]);
        }
        return textNodes;
      }
    }
  }
})
