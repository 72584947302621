import * as angular from 'angular';
import { OmcComponent } from 'shared/models/omc.types';
import { OmcFunc } from '../shared/functions/omc.functions';
import { JobTaskHistory, JobHistoryTaskRecords } from '../shared/models/job-task-execution-history.model';
import { MatDialogService } from '../shared/services/mat-dialog.service';
import * as moment from 'moment';

export class ActivityMonitorController implements angular.IController {
    //public enum EventNames {
    //    CpuLoad,
    //    AvailableMemory,
    //    DiskLoad,
    //    TotalMemory
    //}

    jobCheck: any = undefined;
    jobStatusUpdatePeriodSeconds: any = 10;
    enableAutoRefresh: any = true;
    isRefreshingJobStatus: any = false;

    maxWaitNetworkError: any = 30000; // default wait, will read from refresh interval on initialization

    lang: any;
    jobs = [];
    currentUserRole = {};
    snapshots = {};
    selectedJobs = {}; // for the run-status dialog
    runJobExecution = {}; // execution data for the run-status dialog
    runJobDialogData = {}; // dialog data for the run-status dialog
    dialogService: any;

    serviceHost = {};
    serviceHosts = [];
    gridHelper: any;
    isAdmin: boolean;
    loaded: boolean = false;

    //For the unused methods
    chartSettingsDisk: any;
    chartSettingsMemory: any;
    chartSettingsTotalMemory: any;
    chartSettingsCpu: any;
    chartSettingsNetwork: any;
    //

    hasError: boolean;
    lastReceived: any;
    statusMessage: string;
    editJobModel: {
        job: any;
        maxRolePriority: any;
        display: string;
        isEdit: any;
        isAdd: boolean;
        isCopy: boolean;
        cancel: any;
        allJobs: any;
        title: string;
        saveBtnText: string;
    };
    maxRolePriority: any;

    constructor(
        private $rootScope,
        private platformSettingsService,
        private gridSettingsService,
        private jobsService,
        private gridHelperServiceFactory,
        private modalDialogService,
        private $timeout,
        private activityMonitorService,
        private $interval,
        private serverEventsService,
        private usersService,
        private currentUserService,
        private $scope,
        private omcDialogService,
        private language,
        private dataService,
        private matDialogService: MatDialogService
    ) {
        this.lang = language;
        this.dialogService = omcDialogService;
    }

    $onInit() {
        this.initialiseGrid();
        this.initialise();
        this.initialiseCharts();
        this.initialiseConnectivityCheck();

        this.$scope.stopJobCheck = () => {
            if (angular.isDefined(this.jobCheck)) {
                console.log('jobsController: cancelling interval...');
                this.$interval.cancel(this.jobCheck);
                this.jobCheck = undefined;
                this.isRefreshingJobStatus = false;
            }
        };

        (<any>window).omc.currentVm = this;
        (<any>window).omc.currentVm.refresh = () => this.getJobs(true);

        this.getJobs(true);
        this.usersService.getUsers(true).then((users) => {
            var user = users.find((f) => f.id === this.currentUserService.user.id);
            var omcAdmin = user.roles.filter((f) => f.application === 'OMC' && f.roleName === 'Administrator');
            this.isAdmin = omcAdmin.length > 0;
        });
    }

    $onDestroy() {
        this.stopJobCheck();
    }

    initialise() {
        this.serverEventsService.initialise();
        $(window).on('resize', this.activityPageHeight.bind(this));
        this.$timeout(this.activityPageHeight.bind(this));

        this.platformSettingsService.getSettings().then((settings) => {
            this.serviceHosts = [
                { name: settings.jobServiceUrl, description: settings.jobServiceUrl + ' [Primary]' },
                {
                    name: settings.jobServiceUrlSecondary,
                    description: settings.jobServiceUrlSecondary + ' [Secondary]',
                },
            ];
            this.serviceHost = this.serviceHosts[0];
            this.jobStatusUpdatePeriodSeconds = settings.jobStatusUpdatePeriodSeconds;
            this.activityPageHeight();
        });
        this.getUserSettings();

        this.$rootScope.$on('serverEvent:JobsServerEvent', (event, id) => this.jobsServerEvent(event, id));
        //$rootScope.$on("activityMonitorServiceUpdate:CpuLoad", updateGridCpu);
        //$rootScope.$on("activityMonitorServiceUpdate:AvailableMemory", updateAvailableMemory);
        //$rootScope.$on("activityMonitorServiceUpdate:DiskLoad", updateDiskLoad);
        //$rootScope.$on("activityMonitorServiceUpdate:TotalMemory", updateGridTotalMemory);
        //$rootScope.$on("activityMonitorServiceUpdate:NetworkThroughput", updateNetworkThroughput);
        this.$rootScope.$on('userSettingsUpdated', (event, id) => {
            this.getUserSettings();
        });
    }

    initialiseGrid() {
        this.gridHelper = this.gridHelperServiceFactory.gridHelper(
            'activityMonitorController',
            'activity-grid',
            this,
            this.columnDefs()
        );
        this.gridHelper.gridData.gridId = 'activity-monitor-grid';
        this.gridSettingsService.initialiseGridSettings({
            id: this.gridHelper.gridData.gridId,
            name: 'Activity-Grid',
            columnsInfo: [],
        });

        this.gridSettingsService.setParentHeight($('#content-activity-monitor'), $('#activity-grid'));
        this.gridRowSelected = this.gridHelper.gridRowSelected;
    }

    gridRowSelected(item) {
        return this.gridHelper.selectedValues().find((x) => x === item);
    }

    cancelAllJobs() {
        this.confirmCancelJobs(this.jobs, 'Are you sure you want to cancel all running jobs?');
    }

    stopJobCheck() {
        if (angular.isDefined(this.jobCheck)) {
            console.log('jobsController: cancelling interval...');
            this.$interval.cancel(this.jobCheck);
            this.jobCheck = undefined;
            this.isRefreshingJobStatus = false;
        }
    }

    getUserSettings() {
        this.currentUserService.getCurrentUser().then((user) => {
            if (user && user.userSettings) {
                this.enableAutoRefresh = user.userSettings.enableAutoRefresh;
            }
        });
    }

    isRefreshDisabled() {
        return !this.enableAutoRefresh && this.loaded;
    }

    /* Bellow are not used [TM 05/05/22] */

    updateAvailableMemory(event, data) {
        if (this.isRefreshDisabled() || !this.isActivityMonitorVisible()) return;

        if (this.chartSettingsMemory && this.chartSettingsMemory.data) {
            this.chartSettingsMemory.data.labels = data.labels;
            this.chartSettingsMemory.data.datasets[0].data = data.values;
            this.snapshots['Memory Use'] = data.values[data.values.length - 1] + '%';
            this.$rootScope.$emit('chartDataUpdated', 'chartSettingsMemory');
            this.signalPulse(data);
        }
    }

    updateDiskLoad(event, data) {
        if (this.isRefreshDisabled() || !this.isActivityMonitorVisible()) return;

        if (this.chartSettingsDisk && this.chartSettingsDisk.data) {
            this.chartSettingsDisk.data.labels = data.labels;
            this.chartSettingsDisk.data.datasets[0].data = data.values;

            this.snapshots['Disk Use'] = data.values[data.values.length - 1] + '%';
            this.$rootScope.$emit('chartDataUpdated', 'chartSettingsDisk');
            this.signalPulse(data);
        }
    }

    updateGridTotalMemory(event, data) {
        if (this.isRefreshDisabled() || !this.isActivityMonitorVisible()) return;

        if (this.chartSettingsTotalMemory && this.chartSettingsTotalMemory.data) {
            this.chartSettingsTotalMemory.data.labels = data.labels;
            this.chartSettingsTotalMemory.data.datasets[0].data = data.values;

            this.snapshots['Total Memory'] = data.values[data.values.length - 1] + 'MB';

            this.$rootScope.$emit('chartDataUpdated', 'chartSettingsTotalMemory');
            this.signalPulse(data);
        }
    }

    updateGridCpu(event, data) {
        if (this.isRefreshDisabled() || !this.isActivityMonitorVisible()) return;

        if (this.chartSettingsCpu && this.chartSettingsCpu.data) {
            this.chartSettingsCpu.data.labels = data.labels;
            this.chartSettingsCpu.data.datasets[0].data = data.values;

            this.snapshots['Cpu Utilisation'] = data.values[data.values.length - 1] + '%';

            this.$rootScope.$emit('chartDataUpdated', 'chartSettingsCpu');
            this.signalPulse(data);
        }
    }

    updateNetworkThroughput(event, data) {
        if (this.isRefreshDisabled() || !this.isActivityMonitorVisible()) return;

        if (this.chartSettingsNetwork && this.chartSettingsNetwork.data) {
            this.chartSettingsNetwork.data.labels = data.labels;
            this.chartSettingsNetwork.data.datasets[0].data = data.values;

            this.snapshots['Network Throughput'] = data.values[data.values.length - 1] + 'Kbps';

            this.$rootScope.$emit('chartDataUpdated', 'chartSettingsNetwork');
            this.signalPulse(data);
        }
    }

    /* END OF NOT USED FUNCTIONS, SEE PREVIOUS COMMENT FOR START [TM 05/05/22] */

    refreshActivityMonitor() {
        this.activityMonitorService.refreshActivityMonitor();
        this.getJobs(true);
    }

    signalPulse(data) {
        if (data) {
            if (!this.loaded) {
                this.$timeout(() => this.activityPageHeight.bind(this));
            }
            this.loaded = true;
            this.hasError = false;
            this.lastReceived = new Date().getDate();
        } else {
            this.checkForNetworkError();
        }
    }

    serviceHostChanged(serviceHost) {
        console.log('serviceHostChanged', serviceHost);
    }

    getJobs(force) {
        /* TODO: not sure why this is called here - to investigate [TM 04/07/22] */
        // this.taskTypeService.defaultTaskTypes();

        this.jobsService.getAllJobs(force).then((jobs) => {
            this.$scope.stopJobCheck();
            jobs.map((x) => x.hasBeenRun && this.jobsService.updateJobFromEmJob(x));
            this.jobs = jobs.filter((x) => this.jobsService.isRunning(x));
            // Note: this is necessary to update the completion end times, which aren't retrieved in getAllJobs
            this.jobs.forEach((job) => {
                this.updateJob(job);
            });
            this.gridHelper.dataReloaded(this.jobs);
            this.jobCheck = this.$interval(this.refreshJobStatus.bind(this), this.jobStatusUpdatePeriodSeconds * 1000);
            this.activityPageHeight();
        });
    }

    refreshJobStatus() {
        if (this.enableAutoRefresh && this.isActivityMonitorVisible() && !this.isRefreshingJobStatus) {
            this.isRefreshingJobStatus = true;
            this.jobsService.refreshJobStatus().then(() => {
                this.isRefreshingJobStatus = false;
            });
        }
    }

    isActivityMonitorVisible() {
        return $('#content-activity-monitor').is(':visible');
    }

    jobsServerEvent(event, payload) {
        if (this.isRefreshDisabled() || !this.isActivityMonitorVisible()) return;
        if (payload.forceRefresh) {
            this.getJobs(true);
        } else {
            var jobUpdateDetails = payload.data;
            console.log('jobsServerEvent: payload.data = ', payload.data);
            if (jobUpdateDetails === null || jobUpdateDetails === undefined) return;

            if (Array.isArray(jobUpdateDetails)) {
                var jobIds = jobUpdateDetails.map((x) => x.id.toString()).join(', ');
                console.log('jobsServerEvent: jobIds = ', jobIds);
                this.jobsService.getJobDetailsByIds(jobIds, true, false).then((jobs) => {
                    for (var i = 0; i < jobs.length; i++) {
                        this.updateJob(jobs[i]);
                    }
                    this.jobs = this.jobs.filter((x) => this.jobsService.isRunning(x));
                    this.gridHelper.dataReloaded(this.jobs);
                });
            } else {
                console.log('jobsServerEvent: jobUpdateDetails.id = ', jobUpdateDetails.id);
                this.jobsService.getJobDetailsById(jobUpdateDetails.id, true, false).then((job) => {
                    this.updateJob(job);
                    this.jobs = this.jobs.filter((x) => this.jobsService.isRunning(x));
                    this.gridHelper.dataReloaded(this.jobs);
                });
            }
        }
    }

    updateJob(job) {
        var idx = this.jobs.findIndex((x) => x.id === job.id);
        if (idx > -1) {
            this.jobs[idx] = job;
        } else {
            this.jobs.push(job);
        }
        job = this.predictedFinishTime(job);
    }

    predictedFinishTime(job) {
        var jobExecution = job.lastJobExecuted;
        if (jobExecution) {
            var donePercent =
                ((jobExecution.currentTaskNo - 1) / jobExecution.totalTasks) * 100 +
                (100 * job.itemsProcessed) / job.totalItemsToProcess / jobExecution.totalTasks;
            donePercent = Math.min(donePercent, 100);
            job.jobDonePercent = donePercent;
            if (donePercent > 0 && donePercent < 100) {
                var milliseconds: any = Math.abs(
                    new Date(new Date().toUTCString()).getTime() - new Date(job.lastRunStart + 'Z').getTime()
                );
                /* Original legacy for date [TM 12/05/22] */
                // var milliseconds: any = Math.abs(new Date(new Date().toUTCString()) - new Date(job.lastRunStart+'Z'));
                var recordPerMillisecond = job.itemsProcessed / milliseconds;
                var remainingRecords = job.totalItemsToProcess - job.itemsProcessed;
                var estimatedMillisecondsToAdd = remainingRecords / recordPerMillisecond;
                var remainingTasksEstTime =
                    (jobExecution.totalTasks - jobExecution.currentTaskNo) *
                    (job.totalItemsToProcess / recordPerMillisecond);
                estimatedMillisecondsToAdd = estimatedMillisecondsToAdd + remainingTasksEstTime;
                var dateNow = new Date();
                dateNow.setMilliseconds(dateNow.getMilliseconds() + estimatedMillisecondsToAdd);
                job.lastRunEnd = dateNow;
                console.log('job.lastRunEnd = ' + job.lastRunEnd);
            }
        }
        return job;
    }

    initialiseCharts() {
        var cpuData = this.activityMonitorService.getValues('CpuLoad');
        this.chartSettingsCpu = this.yaxisPercentageChartSettings();
        this.chartSettingsMemory = this.yaxisPercentageChartSettings();
        this.chartSettingsDisk = this.yaxisPercentageChartSettings();
        this.chartSettingsNetwork = this.defaultChartSettings();
    }

    initialiseConnectivityCheck() {
        this.loaded = false;
        this.statusMessage = 'Waiting for information...';
        this.lastReceived = new Date().getDate();
        this.$timeout(this.activityPageHeight.bind(this));
        //platformSettingsService.getSettings(true).then(function (setting) {
        //    var interval = setting.runJobsIntervalSeconds || 5;
        //    maxWaitNetworkError = (interval + interval * 0.5)*1000;
        //}).finally(function() {
        //    $interval(checkForNetworkError, maxWaitNetworkError);
        //});

        this.$interval(this.checkForNetworkError.bind(this), this.maxWaitNetworkError); // workaround as service is always returning 5
    }

    checkForNetworkError() {
        if (this.isRefreshDisabled() || !this.isActivityMonitorVisible()) return;
        var currentDate = new Date().getDate();
        if (Math.abs(currentDate - this.lastReceived) > this.maxWaitNetworkError) {
            this.hasError = true;
            var msg = 'Unable to retrieve activity information. Please contact help and support.';
            this.statusMessage = msg;
            console.log(msg);
        } else {
            this.hasError = true;
        }
    }

    activityPageHeight() {
        var $window = $('.logged-in');
        var height = $window.height();
        var chartHeight = $('chart-component').height();
        if (chartHeight === 0) {
            chartHeight = height * 0.3; // layout fix in case if charts are not initialized yet.
        }
        $('.activity-monitor-grid').height(
            height - ($('.activity-monitor-grid').offset().top + chartHeight + $('.horizontal').height() + 10)
        );
        this.gridHelper?.onRerender();
    }

    editSelected(forEdit, job, isHistory) {
        if (forEdit) {
            return;
        }
        job = job || angular.copy(this.gridHelper.selectedValues()[0]);
        // job.jobNotificationSubscribers = job.jobNotificationSubscribers || [];
        job.taskDependencies = job.taskDependencies || [];

        var displayTab = isHistory && !forEdit ? 'audits' : '';

        let _title = forEdit ? 'Edit Job' : 'Job';

        this.editJobModel = {
            job: job,
            maxRolePriority: this.maxRolePriority,
            display: displayTab,
            isEdit: forEdit,
            isAdd: false,
            isCopy: false,
            cancel: this.cancel,
            allJobs: this.jobs,
            title: _title,
            saveBtnText: 'Save',
        };

        this.modalDialogService.show('edit-job', this.editJobModel, { title: _title });
    }

    cancel() {
        this.modalDialogService.hide();
    }

    columnDefs() {
        return [
            {
                name: 'id',
                displayName: '',
                cellTemplate:
                    '<div class="grid-row-vertical-bar" ng-click="grid.appScope.toggleSelected(row.entity, $event); " ng-class="{\'grid-row-selected\': grid.appScope.gridRowSelected(row.entity) }" data-id="{{row.entity.id}}" role="button" tabindex="0"></div>',
                width: this.gridSettingsService.columnWidths.toggleSelect,
                pinnedLeft: true,
                cellClass: 'omc-select-column',
            },
            {
                name: 'description',
                displayName: 'Name',
                headerCellClass: 'description',
                cellTemplate:
                    '<div class="ui-grid-cell-contents"><a href="#" ng-click="grid.appScope.editSelected(false, row.entity); $event.preventDefault();">{{row.entity.description}}</a></div>',
                width: 350,
            },
            {
                name: 'jobTasks',
                displayName: 'Tasks',
                width: this.gridSettingsService.columnWidths.default,
            },
            {
                name: 'lastRunStart',
                displayName: 'Last Run Started',
                headerCellClass: 'lastRunStart',
                cellFilter: 'omcDate',
                width: this.gridSettingsService.columnWidths.dateTime,
            },
            {
                name: 'lastRunEnd',
                displayName: 'Last Run Ended',
                headerCellClass: 'lastRunEnd',
                cellTemplate: `<span class="ui-grid-cell-contents" ng-class="{'text-italic': row.entity.jobDonePercent > 0 && row.entity.jobDonePercent < 100 && row.entity.state === 'Running'}">{{row.entity.lastRunEnd | omcDate }}</span>`,
                cellFilter: 'omcDate',
                width: this.gridSettingsService.columnWidths.dateTime,
            },
            {
                name: 'lastRunByName',
                displayName: 'Last Run By',
                headerCellClass: 'Created-By-Name',
                cellTemplate:
                    '<div class="ui-grid-cell-contents"><a href="#" ng-click="grid.appScope.showUserProfile(row.entity.lastRunById); $event.preventDefault();">{{row.entity.lastRunByName}}</a></div>',
                width: this.gridSettingsService.columnWidths.userName,
            },
            {
                name: 'state',
                displayName: 'State',
                headerCellClass: 'status',
                width: 165,
                cellTemplate:
                    '<div class="ui-grid-cell-contents"><a href="#" ng-click="grid.appScope.showExecutionHistoryDialog(row.entity); $event.preventDefault();">{{row.entity.state}}</a></div>',
            },
            {
                name: 'downloadResults',
                displayName: 'Results',
                width: 165,
                cellTemplate:
                    '<div class="ui-grid-cell-contents"><a check-permissions="result-export" href="#" ng-click="grid.appScope.downloadResults(row.entity); $event.preventDefault();" ng-show="grid.appScope.canDownloadResults(row.entity)">Download</a></div>',
            },
            {
                name: 'progress',
                displayName: 'Progress',
                headerCellClass: 'job-progress',
                cellTemplate: '<job-progress-component [job]="row.entity" ></job-progress-component>',
                width: 200,
            },
            {
                name: 'collectionsName',
                displayName: 'Collections',
                headerCellClass: 'CompiledTask',
                width: this.gridSettingsService.columnWidths.default,
            },
            {
                name: 'notes',
                displayName: 'Notes',
                width: this.gridSettingsService.columnWidths.notes,
            },
            {
                name: 'dateCreated',
                displayName: 'Date Created',
                headerCellClass: 'DateCreated',
                cellFilter: 'omcDate',
                width: this.gridSettingsService.columnWidths.dateTime,
            },
            {
                name: 'createdByName',
                displayName: 'Created By',
                headerCellClass: 'Created-By-Name',
                width: this.gridSettingsService.columnWidths.userName,
                cellTemplate:
                    '<div class="ui-grid-cell-contents"><a href="#" ng-click="grid.appScope.showUserProfile(row.entity.createdById); $event.preventDefault();">{{row.entity.createdByName}}</a></div>',
            },
        ];
    }

    defaultChartSettings() {
        return {
            type: 'line',
            data: {
                //labels: cpuData && cpuData.labels,
                datasets: [
                    {
                        label: '',
                        //data: cpuData && cpuData.values,
                        backgroundColor: [
                            'rgba(17, 149, 198, 0.2)',
                            'rgba(17, 149, 198, 0.2)',
                            'rgba(17, 149, 198, 0.2)',
                            'rgba(17, 149, 198, 0.2)',
                            'rgba(17, 149, 198, 0.2)',
                            'rgba(17, 149, 198, 0.2)',
                        ],
                        borderColor: [
                            'rgba(17, 149, 198, 1)',
                            'rgba(17, 149, 198, 1)',
                            'rgba(17, 149, 198, 1)',
                            'rgba(17, 149, 198, 1)',
                            'rgba(17, 149, 198, 1)',
                            'rgba(17, 149, 198, 1)',
                        ],
                        borderWidth: 1,
                        pointBorderColor: 'transparent',
                        pointHoverBorderColor: 'transparent',
                        pointBackgroundColor: 'transparent',
                        pointHoverBackgroundColor: 'transparent',
                    },
                ],
            },
            options: {
                layout: {
                    padding: {
                        top: 20,
                    },
                },
                legend: {
                    display: false,
                },
                maintainAspectRatio: false,
                elements: {
                    line: {
                        tension: 0, // disables bezier curves
                    },
                },
                scales: {
                    yAxes: [
                        {
                            ticks: {
                                beginAtZero: true,
                            },
                            gridLines: {
                                color: 'rgba(17, 149, 198, 0.2)',
                            },
                        },
                    ],
                    xAxes: [
                        {
                            gridLines: {
                                color: 'rgba(17, 149, 198, 0.2)',
                            },
                            ticks: {
                                autoSkip: false,
                                maxRotation: 0,
                                minRotation: 0,
                            },
                        },
                    ],
                },
                tooltips: {
                    enabled: false,
                },
                animation: false,
            },
        };
    }

    yaxisPercentageChartSettings() {
        return {
            type: 'line',
            data: {
                //labels: cpuData && cpuData.labels,
                datasets: [
                    {
                        label: '',
                        //data: cpuData && cpuData.values,
                        backgroundColor: [
                            'rgba(17, 149, 198, 0.2)',
                            'rgba(17, 149, 198, 0.2)',
                            'rgba(17, 149, 198, 0.2)',
                            'rgba(17, 149, 198, 0.2)',
                            'rgba(17, 149, 198, 0.2)',
                            'rgba(17, 149, 198, 0.2)',
                        ],
                        borderColor: [
                            'rgba(17, 149, 198, 1)',
                            'rgba(17, 149, 198, 1)',
                            'rgba(17, 149, 198, 1)',
                            'rgba(17, 149, 198, 1)',
                            'rgba(17, 149, 198, 1)',
                            'rgba(17, 149, 198, 1)',
                        ],
                        borderWidth: 1,
                        pointBorderColor: 'transparent',
                        pointHoverBorderColor: 'transparent',
                        pointBackgroundColor: 'transparent',
                        pointHoverBackgroundColor: 'transparent',
                    },
                ],
            },
            options: {
                layout: {
                    padding: {
                        top: 20,
                    },
                },
                legend: {
                    display: false,
                },
                maintainAspectRatio: false,
                elements: {
                    line: {
                        tension: 0, // disables bezier curves
                    },
                },
                scales: {
                    yAxes: [
                        {
                            ticks: {
                                callback: (label, index, labels) => {
                                    return label + '%';
                                },
                                beginAtZero: true,
                                max: 100,
                                stepSize: 20,
                            },
                            gridLines: {
                                color: 'rgba(17, 149, 198, 0.2)',
                            },
                        },
                    ],
                    xAxes: [
                        {
                            gridLines: {
                                color: 'rgba(17, 149, 198, 0.2)',
                            },
                            ticks: {
                                autoSkip: false,
                                maxRotation: 0,
                                minRotation: 0,
                            },
                        },
                    ],
                },
                tooltips: {
                    enabled: false,
                },
                animation: false,
            },
        };
    }

    canCancelJobs() {
        return (
            this.gridHelper.selectedValues().length &&
            this.gridHelper.selectedValues().every((x) => this.jobsService.isRunningOnly(x))
        );
    }

    cancelSelectedJobs() {
        this.confirmCancelJobs(
            this.gridHelper.selectedValues(),
            'Are you sure you want to cancel the selected job(s)?'
        );
    }

    confirmCancelJobs(jobs, prompt) {
        this.matDialogService.openConfirmDialog(prompt).subscribe((confirmed: boolean) => {
            if (confirmed) {
                this.jobsService.cancelJobs(jobs);
                this.getJobs(false);
            }
        });
    }

    showExecutionHistoryDialog(job) {
        let url = 'getLastExecutionHistory?jobId=' + job.id;
        Promise.all([this.dataService.getData(url, url, true), this.getHistoricalJobTasks(job.id)]).then(
            ([jobHistoryData, historyTasks]) => {
                let startTime = jobHistoryData.startTime
                    ? moment.utc(jobHistoryData.startTime).local().format('DD/MM/YYYY HH:mm:ss')
                    : '';
                let endTime = jobHistoryData.endTime
                    ? moment.utc(jobHistoryData.endTime).local().format('DD/MM/YYYY HH:mm:ss')
                    : '';
                this.runJobDialogData = {
                    display: 'logs',
                    canDownload: false,
                    isEdit: false,
                    maxRolePriority: this.maxRolePriority,
                    anySelectedJobsRunning: false,
                    lastJobExeId: jobHistoryData.id,
                };
                job.tasks = historyTasks.tasks;
                this.selectedJobs = [job];
                // cherry pick the required attributes from jobHistoryData
                this.runJobExecution = OmcFunc.diluteObject(jobHistoryData, [
                    'emServiceJobId',
                    'scheduledStartDate',
                    'logLevel',
                    'writeLimit',
                    'abortLimit',
                    'deferExecution',
                    'honourJobDependency',
                    'maxExecutionTimeMins',
                    'noOfParallelThreads',
                    'noOfSplits',
                    'noOfWorkers',
                    'failOnWarning',
                    'verifyDatasetChecksum',
                    'jobPriority',
                    'isExpireJobAfter',
                    'expireJobAfterDays',
                    'expireJobAfterHours',
                    'expireJobAfterMins',
                    'expireJobAfterSecs',
                    'failJobOnTaskFail',
                    'runJobUntilCancelExpired',
                    'holdJobUntilDate',
                    'alertOnJobFailure',
                    'alertOnJobCommencement',
                    'alertOnJobCompletion',
                    'jobRunSubscribers',
                    'distributionStrategyId',
                    'aggregateWorkerOutput',
                    'workerConfigurationName',
                ]);
                Object.assign(this.runJobExecution, {
                    jobExStartTime: startTime,
                    jobExEndTime: endTime,
                    jobExState: jobHistoryData.state,
                    jobExStartedBy: jobHistoryData.userId,
                    jobExTaskRecords: JobHistoryTaskRecords.makeFromJobTaskHistory(historyTasks),
                });
                let title = 'Job Run';
                this.omcDialogService.open('run-status', { title: title });
            }
        );
    }

    /**
     * @param {string} id
     * @returns {JobTaskHistory}
     */
    getHistoricalJobTasks(jobId) {
        let endPoint = 'getLastTasksExecutionHistory?jobId=' + jobId;
        return this.dataService
            .getData(endPoint, endPoint, true)
            .then((data) => {
                if (!!data) {
                    let historyData = new JobTaskHistory(data);
                    console.log(historyData);
                    return historyData;
                } else {
                    return {};
                }
            })
            .catch((err) => {
                throw err;
            });
    }
}

const ActivityMonitorComponent: OmcComponent = {
    selector: `activityMonitorComponent`,
    template: require('./activity-monitor.component.html'),
    bindings: {},
    controller: ActivityMonitorController,
};

angular.module('omc').component(ActivityMonitorComponent.selector, ActivityMonitorComponent);
