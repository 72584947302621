import * as angular from 'angular'
import { OmcComponent } from 'shared/models/omc.types'
import { TaskView } from '../../../shared/models/task-view.model';

let viewJobTaskComponent: OmcComponent = {
    selector: `viewJobTaskComponent`,
    template: require("./view-job-task.component.html"),
    bindings: {
        task: "=",
        editFilterColumn: "=",
    },
    controller: class viewJobTaskComponent implements angular.IController {
        lang: any;
        fileLang: any;
        task: TaskView;
        editFilterColumn: any;

        constructor(
            private language
        ){
            this.lang = language.taskForm;
            this.fileLang = language.fileRenamingForm
        }
        
        $onInit(){
        }
        showFilter(){
            return this.task?.hasRunControlTable && !!this.editFilterColumn
        }
        mapToRunControlTableName(task) {
            if(task && task.hasRunControlTable && task.runControlTable){
                if(task.runControlTable.isRunControlTable || task.runControlTable.id !== 0){
                    return task.runControlTable.name
                } else if (task.runControlTable.id === 0) {
                    return this.language.taskForm.options.runControlTable.definedFromTask.name
                }
            } 
            return this.language.general.NA
        }
        namingStrategyMapToLabel(value) {
            let option:any = Object.values(this.language.fileRenamingForm.options.renamingStrategy).find((x:any)=>x.value === value)
            return option ? option.name : value;
        }
        recordSamplingString(recordSampleSettings) {
            if(recordSampleSettings){
                let settings = recordSampleSettings
                let strings = this.language.taskForm.labels.dataSamplingStrings
                switch (settings.recordSample.id) {
                    case 0:
                        return this.language.taskForm.options.dataSamples.none.name
                    case 1:
                        return strings.nthRecord(this.language.general.ordinal_suffix(settings.nthRecord))
                    case 2:
                        return strings.recordsXToY(settings.startingRange, settings.endingRange)
                    case 3:
                        return strings.recordsUntil(settings.targetCount)
                    default:
                        console.error('Record sampling settings were set incorrectly', recordSampleSettings)
                        return ''
                }
            } else {
                return '';
            }
        }
    }
}

angular
    .module('omc')
    .component(viewJobTaskComponent.selector, viewJobTaskComponent)