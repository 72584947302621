import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { GridSettingsService } from './grid-helper/grid-settings.service';
import * as angular from 'angular'

@Injectable({
     providedIn: 'root'
   })
export class AuditService {

    nameLookupMap = new Map([
        ['dateTime', 'Time Stamp'],
        ['userFullName', 'User'],
        ['auditEntryTypeDescription', 'Action'],
        ['auditEntryFieldDescription', 'Property'],
        ['originalValue', 'Original Value'],
        ['newValue', 'New Value'],
        ['artefact', 'Artefact']
    ]);

    constructor(
        private gridSettingsService: GridSettingsService
    ) {}

    auditLogColumnDefs() { 
        const columns = [
           {
                name: "dateTime", displayName: this.getDesc("dateTime"), cellFilter: 'omcDate', width: this.gridSettingsService.columnWidths.dateTime
           },
           {
                name: "userFullName", displayName: this.getDesc("userFullName"), width: this.gridSettingsService.columnWidths.userName
           },
           //{
           //    name: "auditEntryTypeDescription", displayName: getDesc("auditEntryTypeDescription"), width: gridSettingsService.columnWidths.default
           //},
           {
                name: "auditEntryTypeLDescription",
                displayName: this.getDesc("auditEntryTypeDescription"),
                cellTemplate: ` <div class="ui-grid-cell-contents">
                                    <a href="#" ng-click="grid.appScope.viewAuditDetails(row.entity); $event.preventDefault();" ng-show="{{grid.appScope.isLinkedAction(row.entity)}}">{{row.entity.auditEntryTypeDescription}}</a>
                                    <span ng-show="{{!grid.appScope.isLinkedAction(row.entity)}}">{{row.entity.auditEntryTypeDescription}}</span>
                                </div>`,
                width: this.gridSettingsService.columnWidths.default
           },
           {
                name: "artefactTypeDescription", displayName: 'Type', width: this.gridSettingsService.columnWidths.default
           },
           {
                name: "artefact", displayName: this.getDesc("artefact"), width: this.gridSettingsService.columnWidths.default
           },
           {
                name: "auditEntryFieldDescription", displayName: this.getDesc("auditEntryFieldDescription"), width: this.gridSettingsService.columnWidths.default
           },
           {
                name: "originalValue", displayName: this.getDesc("originalValue"), width: this.gridSettingsService.columnWidths.default
           },
           {
                name: "newValue", displayName: this.getDesc("newValue"), width: this.gridSettingsService.columnWidths.default
           }
        ];
        return columns;
    }

    getDesc(field: string) {
        return this.nameLookupMap.get(field) || field; 
    }
}

angular
    .module('omc')
    .factory('auditService', downgradeInjectable(AuditService))
