import * as angular from 'angular'
import { OmcComponent } from 'shared/models/omc.types'

let parallelSettingsComponent: OmcComponent = {
    selector: `parallelSettingsComponent`,
    template: require("./parallel-settings.component.html"),
    bindings: {
        settings: '=',
    },
    controller: class ParallelSettingsController implements angular.IController {
        settings: any;
        workerStartup: string;
        parallelSettingsSelected: string;
        
        constructor(){}
        
        $postLink(){
            if(!this.settings) return;
            var isMasterWorker = false;
            if (this.settings.isSequentialStartUp) {
                this.workerStartup = "Sequential"; isMasterWorker = true;
            } else if (this.settings.isParallel) {
                this.workerStartup = "Parallel"; isMasterWorker = true;
            }
            if (this.settings.isMultithreaded) {
                this.parallelSettingsSelected = "Multithreaded";
            } else if (this.settings.isHpcRun) {
                this.parallelSettingsSelected = "HPCRun";
            } else if(isMasterWorker){
                this.parallelSettingsSelected = "MasterWorker";
            }
            else if (!this.settings.isMultithreaded) {
                this.parallelSettingsSelected = "SingleThreaded";
            }
        }
        
    }
}

angular
    .module('omc')
    .component(parallelSettingsComponent.selector, parallelSettingsComponent)