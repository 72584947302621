import * as angular from 'angular';
import { MatDialogService } from '../../shared/services/mat-dialog.service';
import { OmcComponent } from '../../shared/models/omc.types';
import { BatchConfirmDialogData, ButtonConfig } from '../../shared/models/dialog-data.model';
import { buttons } from '../../constants/app.constants';

let fileManagementComponent: OmcComponent = {
    selector: 'fileManagementComponent',
    template: require('./file-management.component.html'),
    controller: class FileManagementController implements angular.IController {
        allExtensions: string[];
        selectedNodes: any[];
        tree: any;

        constructor(
            private $scope,
            private language,
            private fileBrowserService,
            private apiService,
            private messagingService,
            private matDialogService: MatDialogService
        ) { }

        $onInit() {
            this.allExtensions = this.getExtensionArray();
            this.initTree();
            // set file manager to window size
            this.resizeTreeView();
            // listen to window resize
            $(window).on('resize', this.resizeTreeView);
        }
        $onDestroy(){
            // cancel resize listener
            $(window).off('resize');
        }
        getExtensionArray() {
            return Object.values(this.language.dataSets.options.fileTypes)
                .filter((x: any) => !x.isDataConnection)
                .map((x: any) => x.extensions)
                .reduce((all, current) => [...all, ...current], [])
        }

        initTree() {
            // selected files - must be defined before this.tree
            this.selectedNodes = [];
            this.tree = this.fileBrowserService.createTree({
                extensions: this.allExtensions,
                treeOptions: {
                    allowDeselect: true,
                    multiSelection: true,
                    isSelectable: this.isSelectable
                },
                checkUsed: true
            });
            this.tree.load()
        }

        expandNode(node, expanded, $path) {
            node.expanded = expanded
            if (expanded) {
                this.tree.getNodeChildren(node)
            }
        };

        isSelectable(node) {
            return node.entityType === 0 && !node.isUsed
        }

        resizeTreeView() {
            console.log('resizing')
            let panel = $('file-management .file-management-panel')
            let windowHeight = $('.logged-in').height();
            let actionPanelHeight = $('file-management .action-panel').outerHeight();
            let pageFooter = 30;
            var panelPos = panel.offset()
            if (panelPos) {
                var top = panelPos.top;
                var elHeight = windowHeight - (top + actionPanelHeight + 30);
                panel.height(elHeight);
            }
        }

        selectedChildren(node) {
            if (node && node.entityType === 1) {
                return node.children.reduce((total, node) => {
                    if (node.entityType === 0) {
                        return this.selectedNodes.includes(node) ? total + 1 : total;
                    } else {
                        return total + this.selectedChildren(node)
                    }
                }, 0)
            }
            return 0;
        }

        confirmDelete() {
            const selectedFiles = this.selectedNodes.map(node => ({ id: node.id, name: node.name }))
            const batchConfirmDialogData = new BatchConfirmDialogData({
                title: 'Delete Files',
                message: 'You are about to delete the following files from the server. Do you want to continue?',
                confirmButton: new ButtonConfig({ text: buttons.DELETE }),
                artefacts: selectedFiles
            });
            this.matDialogService.openBatchConfirmDialog(batchConfirmDialogData)
                .subscribe((selectedArtefacts) => {
                    if (selectedArtefacts) {
                        this.deleteSelected(selectedFiles);
                    }
                });
        }

        deleteSelected(selectedFiles) {
            let data = { files: selectedFiles.map(a => a.id) };
            let url = 'deleteFiles'
            let prevSelectedNodes = [...this.selectedNodes]
            this.selectedNodes = [];
            this.apiService.post(url, data, null, false)
                .then(() => {
                    let msg = 'Successfully deleted ' + data.files.length + ' file' + (data.files.length > 1 ? 's' : '')
                    this.messagingService.displayMessage(msg, 'Deleted Successfully', this.messagingService.messageLevels.success)
                    this.initTree();
                })
                .catch(() => {
                    let msg = 'Could not delete selected file' + (data.files.length > 1 ? 's' : '');
                    this.messagingService.displayMessage(msg, 'Delete Failed', this.messagingService.messageLevels.error)
                    this.selectedNodes = prevSelectedNodes
                })
        }
    }

}

angular
    .module('omc')
    .component(fileManagementComponent.selector, fileManagementComponent)