import { Component, Input, OnInit } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import * as angular from 'angular';
import { pathToApp } from '../../../core/constants/global-constants';

@Component({
    selector: 'progressBarComponent',
    templateUrl: pathToApp + 'shared/components/progress-bar/progress-bar.component.html',
})
export class ProgressBarComponent {
    @Input() showPercentage: boolean;
    @Input() status: string;
    @Input() progress: number;
    @Input() fullWidth: boolean;

    constructor() {}
}
angular.module('omc').directive(
    'progressBarComponent',
    downgradeComponent({
        component: ProgressBarComponent,
        inputs: ['showPercentage', 'status', 'progress', 'fullWidth'],
    })
)
