import './activity-monitor.service';
import './api.service';
import './audit.service';
import './collections.service';
import './current-user.service';
import './data.service';
import './default-item.service';
import './download.service';
import './file-browser.service';
import './file-upload.service';
import './filter.service';
import './form-helper.service';
import './grid-helper/grid-helper.service';
import './grid-helper/grid-functions.service';
import './grid-helper/grid-builder-legacy.service';
import './grid-helper/grid-settings.service';
import './job-settings.service';
import './jobs.service';
import './messaging.service';
import './models.service';
import './omc-globals.service';
import './omc-loading.service';
import './parallel-threads-helper.service';
import './platform-settings.service';
import './redirection.service';
import './serialisation.service';
import './server-events.service';
import './task-type.service';
import './type-checker.service';
import './ui-actions.service';
import './used-by.service';
import './user-cookie.service';
import './users.service';
import './icon-registry.module';