import { MatDialogService } from './shared/services/mat-dialog.service';
import { GeneralDialogService } from './shared/dialogs/general-dialogs.service';
import { OmcDialogService } from './shared/dialogs/omcDialog/omc-dialog.service';
import { ActivityMonitorService } from './shared/services/activity-monitor.service';
import { AppEnvironmentDataService } from "./shared/services/app-environment-data.service";
import { CurrentUserService } from './shared/services/current-user.service';
import { RedirectionService } from './shared/services/redirection.service';
import { ServerEventsService } from './shared/services/server-events.service';
/* because we use @Inject for upgraded AJS services it creates a circular dependency when using `providein: root` [TM 16/11/22] */
export const ANG_DOWNGRADED_PROVIDERS = [
    RedirectionService,
    CurrentUserService,
    ActivityMonitorService,
    ServerEventsService,
    OmcDialogService,
    AppEnvironmentDataService,
    GeneralDialogService,
    MatDialogService
];
