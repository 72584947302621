import { Inject, Injectable } from '@angular/core';
import { RootScope } from '../../../ajs-upgraded-providers';
import * as angular from 'angular';
import { downgradeInjectable } from '@angular/upgrade/static';

@Injectable()
export class OmcDialogService {
    dialogs = [];

    constructor( @Inject(RootScope) private rootScope) {}

    register(dialogData) {
        let i = this.dialogs.findIndex((d) => d.id === dialogData.id);
        if (i > -1) {
            // update dialog data
            Object.assign(this.dialogs[i], dialogData);
        } else {
            // add new object
            this.dialogs.push(dialogData);
        }
    }

    /**
     * options: {title}
     */
    open(dialogId, options) {
        let dialog = this.dialogs.find((d) => d.id === dialogId);
        if (dialog) {
            dialog.title = (options && options.title) || dialog.title;
            dialog.hideClose = (options && options.hideClose) || dialog.hideClose;
            dialog.show = true;
            this.rootScope.$emit('dialog.show', dialogId);
        } else {
            console.warn('Could not find and open dialog with id ' + dialogId);
        }
    }

    close(dialogId) {
        let dialog = this.dialogs.find((d) => d.id === dialogId);
        if (dialog) {
            dialog.show = false;
            if (typeof dialog.onClose === 'function') {
                dialog.onClose();
            }
            this.rootScope.$emit('dialog.hide', dialogId);
        } else {
            console.warn('Could not find and close dialog with id ' + dialogId);
        }
    }

    onCloseDialog(id, callback) {
        if (typeof id !== 'string') {
            console.error('onCloseDialog expects a string id as 1st parameter');
            return;
        }
        if (typeof callback !== 'function') {
            console.error('onCloseDialog expects a function callback as 2nd parameter');
            return;
        }
        let dialog = this.dialogs.find((d) => d.id === id);
        if (!dialog) {
            console.warn('onCloseDialog: Could find dialog with id ' + id);
        } else {
            dialog.onClose = callback;
        }
    }
}
angular.module('omc').factory('omcDialogService', downgradeInjectable(OmcDialogService));
