import { OmcFunc } from '../functions/omc.functions';

/**
 * Create a Task Dependency for view
 *
 *
 * @param {TaskView} task TaskView
 * @param {Object} properties TaskDependencyView - Will override any given task properties
 *
 */
export class TaskDependencyView {
    id: any;
    taskId: any;
    taskName: any;
    taskType: any;
    task: any;
    groupNo: any;
    sNo: any;
    isDeleted: any;
    name: any;
    constructor(task: Partial<TaskDependencyView> = {}, properties: Partial<TaskDependencyView> = {}) {
        this.id = properties.id || OmcFunc.newGuid();
        this.taskId = properties.taskId || task.id;
        this.taskName = properties.name || task.name;
        this.taskType = properties.taskType || task.taskType.name;
        this.task = properties.task || task;

        this.groupNo = properties.groupNo || 1;
        this.sNo = task.sNo || properties.sNo || 1;
        this.isDeleted = properties.isDeleted || false;
    }
}
