import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { CurrentUserService } from '../current-user.service';
import * as angular from 'angular';

@Injectable({
    providedIn: 'root'
  })
export class GridSettingsService {
    scrollBarSettings = {
        never: 0,
        always: 1,
        /* legacy comment said to useScrollBar function instead of whenNeeded [TM 23/06/22] */
        whenNeeded: 2,
    };

    columnWidths = {
        toggleSelect: 5,
        edit: 60,
        fileName: 185,
        fileSize: 100,
        dateTime: 160,
        userName: 150,
        default: 200,
        email: 300,
        notes: 500,
        roleName: 350,
        roleUiAction: 180,
        roleUiActionGroup: 180,
        number: 120,
        active: 120,
        medium: 300,
        icon: 25,
    };

    constructor(private currentUserService: CurrentUserService) {}

    defaultGridOptions() {
        return {
            minRowsToShow: 0,
            rowHeight: 25,
            enableHorizontalScrollbar: this.scrollBarSettings.whenNeeded,
            enableVerticalScrollbar: this.scrollBarSettings.never,
            enableRowSelection: true,
            enableRowHeaderSelection: false,
            modifierKeysToMultiSelect: true,
        };
    }

    setParentHeight() {
        setTimeout(() => {
            const $omcGrid = $('.omc-grid');
            $omcGrid.each(() => {
                if ($omcGrid.attr('adjust-height') === 'false') {
                    return;
                }
                if ($omcGrid.parents('.component-wrapper').length > 0) {
                    /* does not seem to be needed - left in case any use case surfaces [TM 23/06/22] */
                    // $('.omc-grid').height($('.component-wrapper').height() - 60);
                } else {
                    /* Checks for the window size (logged-in class is the top most element) then for the 
                    grid position and then adjusts the grid height to match the window size [TM 23/06/22] */
                    const windowHeight = $('.logged-in').height();
                    const pos = $omcGrid.position();
                    if (pos) {
                        const top = pos.top;
                        const elHeight = windowHeight - (top + 110);
                        $('[ui-grid]').height(elHeight);
                    }

                    /* used to check if multiple grids are used on one page and 
                    adjust each one's height - class grid-holder marks the usage [TM 23/06/22] */
                    const parent = $omcGrid.parent('.grid-holder');
                    if (parent) {
                        parent.each((e) => {
                            let parentHeight = parent[e].offsetHeight;
                            parent[e].style.height = parentHeight + 'px';
                            $omcGrid[e].style.height = parentHeight + 'px';
                        });
                    }
                }
            });
        });
    }

    setScrollBar($el, gridData) {
        setTimeout(() => {
            const canvas = $el.find('[ui-grid-viewport]');
            const canvasHeight = canvas.innerHeight();
            let heightOfAllRows = 0;
            const children = $el.find('.ui-grid-canvas').children();

            children.each((index, child) => {
                heightOfAllRows += $(child).outerHeight();
            });

            gridData &&
                (gridData.enableVerticalScrollbar =
                    heightOfAllRows > canvasHeight ? this.scrollBarSettings.always : this.scrollBarSettings.whenNeeded);

            /* was commented out in the legacy code [TM 23/06/22] */
            /*angular.element($window).unbind('resize');
            angular.element($window).bind('resize', function () {
                setParentHeight();
                $timeout(function () {
                    setScrollBar($el, gridData);
                });
            });*/

            $(document).ready(() => {
                $('.js-onchange-zoom').on('change', () => {
                    //$('.ui-grid-render-container-body .ui-grid-viewport, .omc-grid').height('auto');
                    const footerHeight = 30;
                    const loggedInHeight = $('.logged-in').height();
                    const newGridHeight1 = loggedInHeight - ($('.omc-grid').offset().top + footerHeight);
                    const newGridHeight2 =
                        loggedInHeight -
                        ($('.ui-grid-render-container-body .ui-grid-viewport').offset().top + footerHeight);
                    $('.omc-grid').height(newGridHeight1 + 'px');
                    $('.ui-grid-render-container-body .ui-grid-viewport').height(newGridHeight2 + 'px');
                });
            });
        });
    }

    initialiseGridSettings(settings) {
        if (this.currentUserService.user?.userSettings) {
            if (this.currentUserService.user.userSettings.gridSettings) {
                if (!this.currentUserService.user.userSettings.gridSettings.find((x) => x.id === settings.id)) {
                    this.currentUserService.user.userSettings.gridSettings.push(settings);
                    this.currentUserService.setUserSettings(this.currentUserService.user.userSettings);
                }
            }
        }
    }
}
angular.module('omc').factory('gridSettingsService', downgradeInjectable(GridSettingsService));
