import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { DateTimeFormat, pathToApp } from '../../../core/constants/global-constants';
import { ColDefinition, ColumnType } from '../../../core/interfaces/col-definitions.interface';

@Component({
    selector: `prime-grid-component`,
    templateUrl: pathToApp + 'shared/components/prime-grid/prime-grid.component.html',
})
export class PrimeGridComponent implements OnInit, OnChanges, OnDestroy {
    columnType = ColumnType;
    @Input() cols: ColDefinition[];
    @Input() data: any[];
    @Input() selectionMode: string;
    @Input() stateKey: string;

    /* selection change mechanism [TM 06/02/23] */
    private _selection: any;
    get selection(): any {
        return this._selection;
    }
    @Input() set selection(val: any) {
        this._selection = val;
        this.selectionChange.emit(this._selection);
    }
    @Output() selectionChange = new EventEmitter<any>();
    /* selection change mechanism [TM 06/02/23] */

    @Output() nameClicked = new EventEmitter<any>();
    @Output() createdByClicked = new EventEmitter<any>();


    /* Potential inputs that can be used to add dynamic properties [TM 13/02/23] */
    // @Input()  filter: boolean;
    // @Input()  paginator: boolean;
    // @Input()  rows: number;
    // @Input()  lazy: boolean;
    // @Input()  totalRecords: number;
    // @Input()  first: number;
    // @Input()  sortField: string;
    // @Input()  sortOrder: number;
    // @Input()  multiSortMeta: any[];
    // @Input()  globalFilter: string;
    // @Input()  style: string;
    // @Input()  styleClass: string;
    // @Input()  tableStyle: string;
    // @Input()  tableStyleClass: string;
    // @Input()  autoLayout: boolean;
    // @Input()  resizableColumns: boolean;
    // @Input()  reorderableColumns: boolean;
    // @Input()  scrollable: boolean;
    // @Input()  scrollHeight: string;
    // @Input()  scrollWidth: string;
    // @Input()  frozenWidth: string;
    // @Input()  frozenValue: any[];

    longDateFormat = DateTimeFormat.longDateFormat;

    constructor() {}

    ngOnInit(): void {
        // console.log('PrimeGridComponent.ngOnInit()', this.cols);
    }

    ngOnChanges(changes: SimpleChanges): void {
        // console.log('PrimeGridComponent.ngOnChanges()', this.data);
    }

    ngOnDestroy(): void {
        let gridSettings = JSON.parse(localStorage.getItem(this.stateKey));
        /* TODO: send grid settings data to API when created [TM 20/02/23] */
        console.log('PrimeGridComponent.ngonDestroy()', JSON.parse(localStorage.getItem(this.stateKey)) );
    }
}
