angular.module('omc').directive("checkPermissions", function (uiActionsService) {
    return {
        restrict: "A",
        link: link,
    }

    function link(scope, element, attrs) {
        var permissionName = attrs.checkPermissions;
        var hideIfDisabled = attrs.hideIfDisabled;

        uiActionsService.getPermissionsForName(permissionName).then(function (perm) {
            if (hideIfDisabled) {
                if(!perm || perm.disabled){
                    $(element).addClass('permission-hidden');
                } else {
                    $(element).removeClass('permission-hidden');
                }
            } 
            if (!perm || perm.disabled) {
                attrs.$set('disabled', 'disabled');
                attrs.$set('tabindex', '-1');
                $(element).addClass('disabled');
                $(element).unbind("click");
                element.bind("click", function (evt) {
                    evt.preventDefault();
                    return true;
                });
            }
        });
    }
});