import * as angular from 'angular'
import { OmcComponent } from 'shared/models/omc.types';

export class JobExecutionController implements angular.IController {
    model: any;
    popupGridData: any;

    constructor(
        private gridSettingsService,
        private $timeout
    ) { }

    $onInit() {
        this.model = this.model || {};
        this.initialiseGrid();
        this.$timeout(() => this.popupGridData.data = this.model.job.logs);
    }

    initialiseGrid() {
        this.popupGridData = this.gridSettingsService.defaultGridOptions();
        this.popupGridData.onRegisterApi = this.onRegisterApi.bind(this);
        this.popupGridData.appScopeProvider = this;
        this.popupGridData.columnDefs = [
            {
                name: "date",
                displayName: 'Time',
                cellFilter: 'omcDate',
                width: 160
            },
            {
                name: "message", displayName: 'Message', width: this.gridSettingsService.columnWidths.notes
            },
            {
                name: "logLevel",
                displayName: 'Log Level',
                width: 100
            }
        ];
    }

    onRegisterApi(api) {
        $(window).off('resize');
        $(window).on('resize', () => {
            this.gridSettingsService.setParentHeight($('#content-jobs'), $('#job-history-details-grid'));
            this.gridSettingsService.setScrollBar($('#job-history-details-grid'), this.popupGridData);
        });
    };

}

const JobExecutionComponent: OmcComponent = {
    selector: `jobExecutionComponent`,
    template: require('./job-execution-details.component.html'),
    bindings: {
        model: "="
    },
    controller: JobExecutionController
}

angular
    .module('omc')
    .component(JobExecutionComponent.selector, JobExecutionComponent)
