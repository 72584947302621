import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import * as angular from 'angular';
import * as dataConstants from '../../core/constants/data-constants';
import * as globalConstants from '../../core/constants/global-constants';
import * as jobConstants from '../../core/constants/job-constants';
import * as userConstants from '../../core/constants/user-constants';

@Injectable({
    providedIn: 'root'
  })
export class OmcGlobalsService {
    constructor() {}

    appConstants = {
        ...globalConstants,
        ...jobConstants,
        ...userConstants,
        ...dataConstants
    }

    values(enumNameKey) {
        return this.appConstants[enumNameKey];
    }

    descriptionForValue(enumNameKey, value) {
        const itm = this.appConstants[enumNameKey].find(x => x.value == value);
        return itm && itm.description;
    }

    keyForValue(enumNameKey, value) {
        const itm = this.appConstants[enumNameKey].find(x => x.value == value);
        return itm && itm.key;
    }

    valueForKey(enumNameKey, key) {
        const itm = this.appConstants[enumNameKey].find(x => x.key == key);
        return itm && itm.value;
    }

    descriptionForKey(enumNameKey, key) {
        const itm = this.appConstants[enumNameKey].find(x => x.key == key);
        return itm && itm.description;
    }
}
angular.module('omc').factory('omcGlobalsService', downgradeInjectable(OmcGlobalsService) );
