import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import * as angular from 'angular'

@Injectable({
  providedIn: 'root'
})
export class ModalDialogService {
  modalName = ""
  options = {
      isVisible: false,
      isChild: false
  }
  model = {}

  constructor(
  ) { }

  hide() {
      this.modalName = "";
      this.model = {};
      this.options = {
          isVisible: false,
          isChild: false
      };
  }

  show(modalName, model, options) {
    this.modalName = modalName;
    this.model = model || {};
    this.options = options || {};
    this.options.isVisible = true;
  }
 
}
angular
  .module('omc')
  .factory('modalDialogService', downgradeInjectable(ModalDialogService) )