var testApp = angular.module('omc').directive("autocomplete", function ($timeout, $interval) {
    return {
        require: 'ngModel',
        link: function (scope, element, attrs, ngModel) {

            let triggered = false;
            // listen for pertinent events to trigger input on form element
            // use timeout to ensure val is populated before triggering 'input'
            // ** 'change' event fires for Chrome
            // ** 'DOMAttrModified' fires for Firefox 22.0
            // ** 'keydown' for Safari  6.0.1
            // ** 'propertychang' for IE
            let events = 'change.autofill DOMAttrModified.autofill keydown.autofill propertychange.autofill';

            element.on(events, function (e) {
                if(!triggered){
                    $timeout(function () {
                        element.trigger('input')
                        triggered = true;
                    }, 0);
                }
            });

            element.on('$destroy', ()=>{
                element.off(events)
            })
            
        }
    }
});