import { Component, Inject } from '@angular/core';
import * as angular from 'angular';
import { downgradeComponent } from '@angular/upgrade/static';
import { MAT_DIALOG_DATA } from '@angular/material';
import { pathToApp } from '../../../core/constants/global-constants';
import { BatchConfirmDialogData } from '../../../shared/models/dialog-data.model';

@Component({
    selector: `batchConfirmDialogComponent`,
    templateUrl: pathToApp + 'shared/dialogs/batch-confirm-dialog/batch-confirm-dialog.component.html',
})
export class BatchConfirmDialogComponent {
    selected: any[];
    artefacts: any[];

    constructor(@Inject(MAT_DIALOG_DATA) public data: BatchConfirmDialogData) {
        this.artefacts = data.artefacts;
    }

    updateSelected(selected) {
        if (selected) {
            this.selected = selected.map((a) => ({ name: a.name, id: a.id }));
        }
    }
}
angular.module('omc').directive(
    'batchConfirmDialogComponent',
    downgradeComponent({
        component: BatchConfirmDialogComponent,
        outputs: ['artefacts'],
    })
)
