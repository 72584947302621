import * as angular from 'angular'
import { OmcComponent } from 'shared/models/omc.types';
import { language } from '../shared/language/language.const';
import { ArtefactDialogData, BatchConfirmDialogData, ButtonConfig } from "../shared/models/dialog-data.model";
import { OmcFunc } from "../shared/functions/omc.functions";
import * as moment from 'moment';
import { MatDialogService } from '../shared/services/mat-dialog.service';
import { buttons } from '../constants/app.constants';

class TableSetDialogData extends ArtefactDialogData {
    filterValues: any;
    saveBtnText: string;
    /**
     * @param {TableSetDialogData} props 
     */
    constructor(props: Partial<TableSetDialogData> = {}) {
        super(props);
        this.filterValues = props.filterValues || {};
        this.saveBtnText = props.saveBtnText || language.general.SAVE;
    }
}

export class TableSetController implements angular.IController {

    dialogService: any;
    lang: any;
    gridHelperService: any;
    defaultInputTypeId: any;

    tableSets = [];          // all Tablesets
    tableSetsForModel = [];  // filtered tablesets by model
    selectedArtefacts = [];  // For deleting or restoring
    isDelDisabled = false;
    isRestDisabled = false;
    isEditUsedByArtefactDisabled = false;

    selectedTableSet = null;                         // selected table set for the dataset dialogs
    tableSetDialogData = new TableSetDialogData();   // the data used for the dataset dialogs

    filterValues = {
        treeDates: [{ sDate: moment().subtract(2, 'year').format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'), eDate: moment().format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]') }],
        selectedCollections: [],
        selectedTask: { id: 0 }
    };

    model: any;
    gridData: any;
    filteredTableSets = [];
    showUserProfile: any;
    isEdit = false;

    constructor(
        private dataService,
        private generalDialogService,
        private gridSettingsService,
        private $rootScope,
        private gridHelperServiceFactory,
        private uiActionsService,
        private filterService,
        private omcGlobalsService,
        private $state,
        private omcDialogService,
        private language,
        private gridHelperFunctionService,
        private matDialogService: MatDialogService
    ) {
        /*  can be refactored to use this.omcDialogservice where dialog service is used [TM 20/04/22] */
        this.dialogService = omcDialogService;
        this.lang = language;
        this.defaultInputTypeId = this.omcGlobalsService.valueForKey("dataInputTypes", "Internal");
        this.showUserProfile = generalDialogService.userDetails;
    }

    $onInit() {
        this.initGrid();
        this.checkPermissions();
        this.initServerEventListners();
    }

    initGrid() {
        this.gridHelperService = this.gridHelperServiceFactory.gridHelper('tableSetController', '#tableset-grid');
        this.gridData = this.gridHelperService.gridData;
        this.gridHelperService.registerRowsRenderingComplete(() => {
            if (this.selectedTableSet) {
                var filterRows = this.gridData.data.filter((x) => {
                    return x.id === this.selectedTableSet.id;
                });
                this.gridHelperService.setSelected(filterRows[0]);
            }
        });

        this.gridData.gridId = 'tables-grid';

        this.gridSettingsService.initialiseGridSettings({
            id: this.gridData.gridId,
            name: 'Tables-Grid',
            columnsInfo: []
        });

        this.gridData.appScopeProvider = this;

        this.gridData.columnDefs = [
            {
                name: 'id',
                displayName: '',
                cellTemplate: '<div class="grid-row-vertical-bar" ng-click="grid.appScope.gridHelper.toggleSelected(row.entity, $event)" ng-class="{\'grid-row-selected\': grid.appScope.gridRowSelected(row.entity) }" role="button" tabindex="0"></div>',
                width: this.gridSettingsService.columnWidths.toggleSelect,
                pinnedLeft: true,
                cellClass: "omc-select-column"
            },
            this.gridHelperFunctionService.pinColumnDef(),
            {
                name: "isDeleted",
                displayName: '',
                cellTemplate: `<span class="ui-grid-cell-contents" ng-class="{'deleted-icon': row.entity.isDeleted}"></span>`,
                width: this.gridSettingsService.columnWidths.icon
            },
            {
                name: 'name',
                displayName: 'Name',
                cellTemplate: '<div class="ui-grid-cell-contents" ng-class="{\'from-model\':row.entity.createdAutomatically, \'deleted-row-text\': row.entity.isDeleted}"><a href="#" ng-click="grid.appScope.tableSetDialog(row.entity.id).view(); $event.preventDefault();">{{row.entity.name}}</a></div>',
                width: this.gridSettingsService.columnWidths.default
            },
            {
                name: "taskName",
                displayName: 'Associated Task Name',
                width: this.gridSettingsService.columnWidths.medium
            },
            //{
            //    name: "isUsedBy", displayName: 'Is Used By', headerCellClass: 'CompiledTask', width: gridSettingsService.columnWidths.default
            //},
            {
                name: 'tableCount',
                displayName: 'Table Count',
                width: this.gridSettingsService.columnWidths.number
            },
            {
                name: "collectionsName", displayName: 'Collections', headerCellClass: 'CompiledTask', width: this.gridSettingsService.columnWidths.default
            },
            {
                name: 'notes',
                displayName: 'Notes',
                width: this.gridSettingsService.columnWidths.notes
            },
            {
                name: "dateCreated",
                displayName: 'Date Created',
                headerCellClass: 'DateCreated',
                cellFilter: 'omcDate', width: this.gridSettingsService.columnWidths.dateTime
            },
            {
                name: "modifiedDateTime",
                displayName: 'Date Modified',
                headerCellClass: 'DateCreated',
                cellFilter: 'omcDate', width: this.gridSettingsService.columnWidths.dateTime
            },
            {
                name: "createdById", displayName: 'Created By', headerCellClass: 'Created-By-Name', width: this.gridSettingsService.columnWidths.userName,
                cellTemplate: '<div class="ui-grid-cell-contents"><a href="#" ng-click="grid.appScope.showUserProfile(row.entity.createdById); $event.preventDefault();">{{row.entity.createdById}}</a></div>'
            },
            {
                name: "effectiveDate",
                displayName: 'Effective Date',
                headerCellClass: 'DateCreated',
                cellFilter: 'omcDate', width: this.gridSettingsService.columnWidths.dateTime
            }
        ];

        (<any>window).window.omc.currentVm = this;
        (<any>window).window.omc.currentVm.refresh = () => this.getTableSet(true);
    }

    checkPermissions() {
        this.uiActionsService.getPermissionsForName('artefact-delete', true).then((perm) => {
            this.isDelDisabled = perm && perm.disabled;
        });

        this.uiActionsService.getPermissionsForName('artefact-restore', true).then((perm) => {
            this.isRestDisabled = perm && perm.disabled;
        });

        this.uiActionsService.getPermissionsForName('edit-used-artefacts', true).then((perm) => {
            if (perm)
                this.isEditUsedByArtefactDisabled = perm.disabled;
            else
                this.isEditUsedByArtefactDisabled = true;
        });

        this.uiActionsService.getPermissionsForName('tables-edit').then((perm) => {
            this.isEdit = perm && !perm.disabled;
        });

    }

    initServerEventListners() {
        this.$rootScope.$on("serverEvent:TableSetServerEvent", (event, payload) => {
            if (payload.forceRefresh) {
                this.getTableSets(true);
            }
            else {

                var tableSet = payload.data;
                if (Array.isArray(tableSet)) {
                    tableSet.map(x => this.updateTableSet(x));
                } else {
                    this.updateTableSet(tableSet);
                }

                this.filteredTableSets = this.filterService.filterApplyWithModel(this.tableSetsForModel, this.filterValues);
                this.gridHelperService.dataReloaded(this.filteredTableSets)
            }
        });
    }

    applyFilterChanges(data) {
        this.filterValues = data;
        this.model = data.selectedTask;
        this.getTableSetsByModelId(true);
    }

    /* comented out in legacy OMC [TM 26/04/22] */
    // copySelected () {
    //     this.editSelected(true);
    // };

    gridRowSelected(item) {
        return this.gridHelperService.selectedValues().find((x) => x.id === item.id);
    };

    editsDisabled() {
        var result = !this.isEdit
            || this.gridHelperService.selectedValues().length !== 1
            || this.gridHelperService.selectedValues()[0].createdAutomatically
            || this.gridHelperService.selectedValues()[0].isDeleted;

        var result2 = this.editUsedByArtefactDisabled();
        return result || result2;
    };

    editUsedByArtefactDisabled() {
        if (this.gridHelperService.selectedValues().length === 0) //Disable the edit button if no row is selected
            return true;

        if (this.isEditUsedByArtefactDisabled === false)
            return false;

        if (this.gridHelperService.selectedValues().length > 0 && this.gridHelperService.selectedValues()[0].isAnyJobRun)
            return this.isEditUsedByArtefactDisabled;

        return false;
    }

    copyDisabled() {
        return !this.isEdit
            || this.gridHelperService.selectedValues().length !== 1
            || this.gridHelperService.selectedValues()[0].isDeleted;
    }

    deleteDisabled() {
        return !this.isEdit
            || !this.gridHelperService.selectedValues().length
            || this.gridHelperService.selectedValues().find(z => z.createdAutomatically);
    };

    canDeleteArtefact() {

        if (!this.isDelDisabled) {
            var anyDeleted = this.gridHelperService.selectedValues().filter((x) => {
                return x.isDeleted === true;
            }).length;
            return this.gridHelperService.selectedValues().length !== 0 && anyDeleted === 0;
        }

        return this.isDelDisabled;
    }

    canRestoreArtefact() {
        if (!this.isRestDisabled) {
            var anyNotDeleted = this.gridHelperService.selectedValues().filter((x) => {
                return x.isDeleted === false;
            }).length;
            return this.gridHelperService.selectedValues().length !== 0 && anyNotDeleted === 0;
        }

        return this.isRestDisabled;
    }

    deleteArtefact() {
        this.selectedArtefacts = this.gridHelperService.selectedValues();
        const batchConfirmDialogData = new BatchConfirmDialogData({
            title: 'Delete TableSet(s)',
            message: 'You are about to delete the following tablesets. Do you want to continue?',
            confirmButton: new ButtonConfig({ classes: 'btn-danger', text: buttons.DELETE }),
            artefacts: this.selectedArtefacts,
        });
        this.matDialogService.openBatchConfirmDialog(batchConfirmDialogData).subscribe((selectedArtefacts) => {
            if (selectedArtefacts) {
                this.deleteArtefacts(selectedArtefacts);
            }
        });
    }

    confirmRestoreArtefact() {
        this.selectedArtefacts = this.gridHelperService.selectedValues();
        const batchConfirmDialogData = new BatchConfirmDialogData({
            title: 'Restore TableSet(s)',
            message: 'You are about to restore the following tablesets. Do you want to continue?',
            confirmButton: new ButtonConfig({ text: buttons.RESTORE }),
            artefacts: this.selectedArtefacts,
        });
        this.matDialogService.openBatchConfirmDialog(batchConfirmDialogData).subscribe((selectedArtefacts) => {
            if (selectedArtefacts) {
                this.restoreArtefact(selectedArtefacts);
            }
        });
    }

    updateTableSet(tableSet) {

        /* Commented out in legacy OMC [TM 27/04/22] */
        //tableSet.collectionsName = tableSet.collections.map(function(c) {
        //    return c.name;
        //}).join(", ");

        //if (tableSet.model !== null) {
        //    tableSet.taskName = tableSet.model.name;
        //}

        var idx = this.tableSetsForModel.findIndex((x) => x.id === tableSet.id);
        if (idx > -1) {
            this.tableSetsForModel[idx] = tableSet;
        }
        else {
            this.tableSetsForModel.push(tableSet);
        }
    }

    modelChanged(newModel) {
        this.model = newModel || this.model;
        this.getTableSetsByModelId(true);
    }

    getTableSet(force) {
        localStorage.setItem("selectedItem", null);
        localStorage.setItem("selectedItem", JSON.stringify(this.model));
        this.$state.go(this.$state.current, {}, { reload: true });
        this.getTableSets(true);
    }

    getTableSets(force) {
        this.dataService.getData('tableSets', 'tableSets', force).then((tableSets) => {
            console.log(tableSets);
            this.tableSets = tableSets;
            var selectedModel = JSON.parse(localStorage.getItem("selectedItem"));
            if (selectedModel) {
                this.model = selectedModel;
            }
            this.model && (this.filteredTableSets = this.filterTableSets(this.tableSets, this.model));
            this.gridHelperService.dataReloaded(this.filteredTableSets)
            console.log("Data fetched and assigned to grid.");
        });
    }

    getTableSetsByModelId(force) {
        var modelId = this.model && this.model.id;
        this.selectedTableSet = this.gridHelperService.selectedValues()[0]
        if (modelId > 0) {
            this.dataService
                .getData('getTableSetsByModelId?modelId=' + modelId, 'getTableSetsByModelId?modelId=' + modelId, force)
                .then((tableSetsForModel) => {
                    tableSetsForModel = this.filterService.filterApply(tableSetsForModel, this.filterValues)
                    this.tableSetsForModel = angular.copy(tableSetsForModel);
                    this.gridHelperService.dataReloaded(tableSetsForModel)
                });
        } else {
            this.dataService
                .getData('tableSets', 'tableSets', force)
                .then((tableSets) => {
                    this.tableSets = angular.copy(tableSets);
                    tableSets = this.filterService.filterApply(tableSets, this.filterValues);
                    this.tableSetsForModel = angular.copy(tableSets);
                    this.gridHelperService.dataReloaded(this.tableSetsForModel)
                });
        }
    }

    refreshModel() {
        this.getTableSetsByModelId(true);
    }


    saveTableSet() {
        let URL = this.tableSetDialogData.isCopy ? 'copyTableSet' : 'tableSets';
        this.dataService.putData(this.selectedTableSet, 'tableSets', URL, false).then((tableSets) => {
            this.model.tableSets = this.tableSets = tableSets;
            this.refreshModel();
            this.omcDialogService.close('edit-table-set')
        });
    }

    filterTableSets(tableSets, model) {
        return model && tableSets.filter(x => x.modelId === model.id);
    }

    deleteArtefacts(selectedArtefacts) {
        var data = { artefacts: selectedArtefacts.map(a => a.id) };
        this.dataService.putData(data, 'deleteTableSet', 'deleteTableSet', false).then(() => {
            this.refreshModel();
        });
    }

    restoreArtefact(selectedArtefacts) {
        var data = { artefacts: selectedArtefacts.map(a => a.id) };
        this.dataService.putData(data, 'restoreTableset', 'restoreTableset', false).then(() => {
            this.refreshModel();
        });
    }


    tableSetDialog(id = null) {
        let vm = this;
        let selectedTableSet;
        if (!id) {
            let selected = this.gridHelperService.selectedValues()
            id = (OmcFunc.isSet(selected, true) ? selected[0] : {}).id;
        }
        let dialogData = new TableSetDialogData({
            filterValues: this.filterValues,
            usedNames: this.tableSets.map(t => {
                return {
                    name: t.name,
                    modelId: t.modelId,
                    id: t.id,
                }
            }),
            saveBtnText: language.general.SAVE,
        })

        return {
            view: view,
            add: add,
            edit: edit,
            copy: copy,
        }
        function view() {
            vm.getTableSetById(id).then(tableSet => {
                selectedTableSet = tableSet;
                openDialog('Tableset')
            })
        }
        function edit() {
            if (vm.editsDisabled()) return;
            dialogData.assign({
                isEdit: true,
            })
            vm.getTableSetById(id).then(tableSet => {
                selectedTableSet = tableSet;
                openDialog('Edit Tableset')
            })
        }
        function add() {
            var tableFiles = [];
            if (vm.model && vm.model.id > 0) {
                tableFiles = vm.model && angular.copy(vm.model.tables);
                tableFiles.map(function (tbl) {
                    tbl.table = { name: tbl.name, id: tbl.id };
                    tbl.tableId = tbl.id;
                    tbl.id = 0;
                    tbl.dataInputTypeId = vm.defaultInputTypeId;
                    delete tbl.name;
                });
            }
            dialogData.assign({
                isEdit: true,
                isAdd: true,
                selectDefaultCollection: true,
            })
            selectedTableSet = {
                modelId: 0,
                tableFiles: tableFiles,
                model: vm.model,
                collections: []
            }
            openDialog('Add Tableset')
        }
        function copy() {
            if (vm.copyDisabled()) return;
            dialogData.assign({
                isEdit: true,
                isCopy: true,
                saveBtnText: language.general.OK,
            })
            vm.getTableSetById(id).then(tableSet => {
                selectedTableSet = tableSet
                selectedTableSet.name = OmcFunc.renameCopy(selectedTableSet.name, dialogData.usedNames.map(x => x.name))
                selectedTableSet.copyFromId = selectedTableSet.id
                selectedTableSet.id = undefined;
                openDialog('Copy Tableset')
            })
        }
        function openDialog(title) {
            vm.tableSetDialogData = dialogData;
            vm.selectedTableSet = selectedTableSet;
            vm.omcDialogService.open('edit-table-set', { title: title })
        }
    }

    getTableSetById(id) {
        let url = 'getTableSetById?id=' + id
        return this.dataService.getData(url, url, true)
    }
}

const TableSetComponent: OmcComponent = {
    selector: `tableSetComponent`,
    template: require('./table-set.component.html'),
    bindings: {

    },
    controller: TableSetController
}

angular
    .module('omc')
    .component(TableSetComponent.selector, TableSetComponent)
