export enum ColumnType {
    name = 'Name',
    date = 'Date',
    createdBy = 'CreatedBy',
    icon = 'Icon',
    pinned = 'Pinned',
}

export interface ColDefinition {
    field: string;
    header: string;
    width?: string;
    type?: ColumnType;
}
