angular.module('omc').directive("dropdownMenu", function ($document) {
    return {
        restrict: "A",
        scope: {
            dropdownMenu: "=",  // {active: boolean, speed: number}.
            onClickOutside: "&",
        },
        link: link,
    }
    function link($scope, $element) {
        let active = $scope.dropdownMenu?.active ?? false;
        let speed = $scope.dropdownMenu?.speed ?? 500;

        $scope.$watch('dropdownMenu.active', (value) => {
            if (active !== value) {
                toggle()
            }
        })
        function toggle() {
            active = !active
            speed = $scope.dropdownMenu?.speed ?? 500;
            toggleElement()
            toggleClickListener()
        }
        function toggleElement() {
            let startOpacity = active ? 0 : 1;
            let endOpacity = active ? 1 : 0;
            $($element)
                .css('opacity', startOpacity)
                .slideToggle(speed)
                .animate(
                    { opacity: endOpacity },
                    { queue: false, duration: speed }
                );
        }
        function toggleClickListener() {
            if (active) {
                $document.bind('click', listenToExternalClicks)
            } else {
                $document.off('click', listenToExternalClicks)
            }
        }

        function listenToExternalClicks(event) {
            if (!active) {
                return;
            }
            var isClickedElementChildOfPopup = $element.find(event.target).length > 0;
            if (isClickedElementChildOfPopup) return;
            toggle()
            $scope.onClickOutside();
            $scope.$apply();

        }
    }


})