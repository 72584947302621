angular.module('omc').filter('descriptionForValue', function (omcGlobalsService) {
    return function (value, enumNameKey) {
        return omcGlobalsService.descriptionForValue(enumNameKey, value);
    }
});

angular.module('omc').filter('descriptionForKey', function (omcGlobalsService) {
    return function (key, enumNameKey) {
        return omcGlobalsService.descriptionForKey(enumNameKey, key);
    }
})