import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { ColDefinition, ColumnType } from '../../../core/interfaces/col-definitions.interface';
import { DateTimeFormat, pathToApp } from '../../../core/constants/global-constants';
import { DefaultDialogConfig } from '../../../shared/models/dialog-data.model';
import { DownloadService } from '../../../shared/services/download.service';
import { UsersService } from '../../../shared/services/users.service';

@Component({
    selector: `signinHistoryComponent`,
    templateUrl: pathToApp + 'user/user-status/signin-history/signin-history.component.html',
})

export class SigninHistoryComponent implements OnInit {
        longDateFormat = DateTimeFormat.longDateFormat;
        signInHistory: any;
        showSpinner: boolean;
        selectedEntry: any;
        cols: ColDefinition[] = [
            { field: 'activityDate', header: 'Date/Time', width: '160px', type: ColumnType.date },
            { field: 'duration', header: 'Duration', width: '160px' },
        ]

        constructor(
            @Inject(MAT_DIALOG_DATA) public data: DefaultDialogConfig,
            private usersService: UsersService,
            private downloadService: DownloadService,
        ) {
        };

        ngOnInit(){
            this.getSigninHistory();
        }

        getSigninHistory(){
            this.showSpinner = true;
            this.usersService.getSinginHistory(true).then( data => {
               this.signInHistory = data;
                this.showSpinner = false;
            });
        }

        copyToClipboard() {
            let signInHistoryText = this.createSigninHistory(this.signInHistory);
            this.addToClipboard(signInHistoryText);
        }

        downloadData() {
            let signInHistoryText = this.createSigninHistory(this.signInHistory);
            this.downloadService.downloadFile(signInHistoryText, "Signin_History" + "_" + new Date().getTime(), "text/plain");
        }

        createSigninHistory(data){
            return "Date/Time|Duration" + "\n" + data.map(item => item.activityDate + "|" + item.duration + "\n" ).join('');
        }     

        addToClipboard(text){
            let textArea = document.createElement("textarea");
            textArea.value = text;
            document.body.appendChild(textArea);
            textArea.disabled = false;
            textArea.focus();
            textArea.select();
            document.execCommand('copy');           
            document.body.removeChild(textArea);
        }
}
