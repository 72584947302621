import * as angular from 'angular'
import { OmcComponent } from 'shared/models/omc.types'

let editRoleTemplateComponent: OmcComponent = {
    selector: `editRoleTemplateComponent`,
    template: require("./edit-role-template.component.html"),
    bindings: {
        roleTemplate: "=",
            formData: "="
    },
    controller: class EditRoleTemplateController implements angular.IController {
        availableJobTemplatesSelected = [];
        jobTemplatesSelected = [];
        isLoaded: boolean;
        roleTemplate: any;
        formData: any;
        loadedJobTemplates: any;
        removeFromAvailableJobTemplates: any[];
        availableJobTemplates: any;
        jobTemplatesCount: any;

        constructor(
            private formHelperService, 
            private dataService
        ){}
        
        $onInit(){
            this.isLoaded = !!(this.roleTemplate && this.formData)
            if(this.isLoaded){
                this.roleTemplate.jobTemplates = this.roleTemplate.jobTemplates || [];
                this.loadedJobTemplates = angular.copy(this.roleTemplate.jobTemplates) // for change detection
                this.getTemplates();
            }
        }

        getTemplates(){
            this.dataService.getData("JobTemplates", "JobTemplates?skipCurrentUserFilter=true", true, false).then((data)=> {
                this.removeFromAvailableJobTemplates = [];
                this.availableJobTemplates = angular.copy(data).filter(template => {
                    return !this.roleTemplate.jobTemplates.find(t => template.id === t.id)
                });

                if (this.roleTemplate.jobTemplates) {
                    this.jobTemplatesCount = this.roleTemplate.jobTemplates.length;
                }
                this.validate();
            });
        }
        toggleAvailableSelected(template){
            this.toggleInArray(template, this.availableJobTemplatesSelected);
           this.validate();
        }
        
        toggleJobTemplatesSelected(template){
            this.toggleInArray(template, this.jobTemplatesSelected);
           this.validate();
        }
        
       validate(){
           this.formData.hasChanged = this.formHelperService.haveTheseArraysChanged(this.loadedJobTemplates, this.roleTemplate.jobTemplates, ['id'])
           this.formData.isValid = this.roleTemplate.jobTemplates && this.roleTemplate.jobTemplates.length > 0 && this.jobTemplatesCount !== this.roleTemplate.jobTemplates.length;
        }


        canAddTemplate(){
            return this.availableJobTemplatesSelected && this.availableJobTemplatesSelected.length > 0;
        }

        canRemoveTemplate(){
            return this.jobTemplatesSelected && this.jobTemplatesSelected.length > 0;
        }

        jobTemplatesAddSelected(template) {
            if (template && !this.jobTemplatesSelected.includes(template)) {
                this.availableJobTemplatesSelected.push(template);
            }
    
            this.availableJobTemplatesSelected.forEach(template => this.moveArray(template, this.availableJobTemplates, this.roleTemplate.jobTemplates));
            this.jobTemplatesSelected = [];
            this.availableJobTemplatesSelected = [];
           this.validate();
        }
    
        jobTemplatesRemoveSelected(template) {
            if (template && !this.jobTemplatesSelected.includes(template)) {
                this.jobTemplatesSelected.push(template);
            }
            this.jobTemplatesSelected.forEach(template => this.moveArray(template, this.roleTemplate.jobTemplates, this.availableJobTemplates));
            this.jobTemplatesSelected = [];
            this.availableJobTemplatesSelected = [];
           this.validate();
        }
    
        toggleInArray(template, array) {
            var idx = array.findIndex(x => x === template);
            if (idx === -1) {
                array.push(template);
            } else {
                array.splice(idx, 1);
            }
        }
    
        moveArray(item, arrayFrom, arrayTo) {
            var idx = arrayFrom.findIndex(x => x === item);
            if (idx > -1) {
                arrayFrom.splice(idx, 1);
                arrayTo.push(item);
            }
        }
    }
}

angular
    .module('omc')
    .component(editRoleTemplateComponent.selector, editRoleTemplateComponent)