import * as angular from 'angular'
import { OmcComponent } from 'shared/models/omc.types';

export class EditTableSetController implements angular.IController {

    tableSet: any;
    formData: any;

    gridHelper: any;
    datGridHelper: any;
    gridHelperService: any;
    gridData: any;

    gridRowSelected: any;

    userSettings: any;
    usedByGridApi: any;
    auditGridApi: any;

    usedByJobGridData: any;

    auditPermission: boolean = true;
    auditGridData: any;

    tasks: any[] = [];
    schemas: any[] = [];

    loaded: boolean;
    display: any;

    multiSelectSettings = {
        checkBoxes: true,
        dynamicTitle: true,
        showUncheckAll: false,
        showCheckAll: false
    };

    gridDatFileData: any;
    datGridRowSelected: any;
    datFileExist: boolean = false;

    dataSourceIds: any;

    noData: boolean = false;
    isRunControl: boolean = false;
    showRunControlButton: boolean = true;
    tableColumns: any[] = [];

    excelId: any;
    csvId: any;
    txtId: any;
    internalId: any;
    oracleId: any;
    sqlServerId: any;
    odbcId: any;

    tableDataFile: any;

    filteredDataConnections = [];
    dataConnections: any;
    tablesData: any;
    filteredTasks: any[];
    selectedTaskType: any;
    selectedTask: any;
    tableFile: any;
    taskTypes: any;
    tableReferences: any;
    excelReferences: any;

    constructor(
        private modelsService,
        private taskTypeService,
        private $timeout,
        private fileUploadService,
        private omcGlobalsService,
        private gridSettingsService,
        private gridHelperServiceFactory,
        private dataService,
        private auditService,
        private uiActionsService,
        private $rootScope,
        private usedByService,
        private currentUserService,
        private filterService,
        private $scope
    ) {
        this.gridHelperService = this.gridHelperServiceFactory.gridHelper('basisUsedBy');
    }

    $onInit() {
        this.uiActionsService.getPermissionsForName('admin-view-audit', true).then((perm) => {
            this.auditPermission = perm && !perm.disabled;
        });

        this.currentUserService.getUserSettings(true).then(settings => {
            this.userSettings = settings;
        });
        this.$rootScope.$on("dataSetTypeChange", (event, value) => this.dataSetTypeChange(value))
        this.startUpTasks();
    }

    startUpTasks() {
        /* prevent component to be loaded if inputs are not available 
        - to review when moving to angular dialog system [TM 07/07/22] */
        let inputsLoaded = !!(this.tableSet && this.formData)
        if (!inputsLoaded) return
        this.getDataConnections();
        this.getTableSets();
        this.initUsedByGrid();
        this.initiGlobalValues();
        this.initdataSourceIds()
        this.initAuditGrid()
        this.initDatGrid()
        this.initGrid();
        this.initWatchers();
    }

    initWatchers() {
        /* when modernising components[TM 06/04/22] https://www.codelord.net/2016/12/13/replacing-%24scope-dot-%24watch-with-lifecycle-hooks/ */
        this.$scope.$watch("$ctrl.tableFile", (current, prev) => {
            if (current && current !== prev) {
                console.log("uploading file");
                this.uploadTableFile();
            }
        });

        this.$scope.$watch("$ctrl.tableDataFile", (current, prev) => {
            if (current && current !== prev) {
                console.log("uploading dat file");
                this.uploadModelFile();
            }
        });

        this.$scope.$watch("$ctrl.selectedTable.dataConnectionId", (current, prev) => {
            if (current && current != prev) {
                if (this.isDataConnection()) {
                    this.dataConnectionChanged();
                }
            }
        });

        this.$scope.$watch("$ctrl.selectedTable.runIdColumn", (current, prev) => {
            if (current && current != prev) {
                this.updateDatTableColumns();
            }
        });

        this.$scope.$watch("$ctrl.selectedTable.table.isRunControl", (current, prev) => {
            this.updateDatTableColumns();
        });
    }

    getDataConnections() {
        this.dataService.getData('dataConnection?skipCurrentUserFilter=true', 'dataConnection?skipCurrentUserFilter=true', true).then((dataConnections) => {
            this.dataConnections = dataConnections;
            if (this.selectedTable()) {
                this.filterConnections(this.dataConnections, this.selectedTable().dataInputTypeId);
            }
            this.updateTableSetTableFiles()
            this.$timeout(() => { /* Allows the grid to update its values before using vm.selectedTable() [TS 19/01/21] */
                this.dataConnectionChanged();
            })
        })
    }

    initdataSourceIds() {
        this.dataSourceIds = [this.oracleId, this.sqlServerId, this.odbcId];
        this.dataSourceIds = this.omcGlobalsService.values("dataInputTypes").filter(x => this.dataSourceIds.includes(x.value));
    }

    initGrid() {
        this.gridHelper = this.gridHelperServiceFactory.gridHelper('editTableSet', '#edit-tableset-grid');
        this.gridHelper.gridData.modifierKeysToMultiSelect = false;
        this.gridData = this.gridHelper.gridData;
        this.gridData.appScopeProvider = this;
        this.gridRowSelected = this.gridHelper.gridRowSelected;
        this.gridData.columnDefs = this.columnDefs();

        this.gridHelper.registerRowClickCallBack((row) => {
            console.log("Row Click Callback called.");
            this.getExcelReferences(row);
            if (row.table?.name) {
                this.getTableColumns(row.table.name);
            }
        });
    }

    initUsedByGrid() {
        this.usedByJobGridData = this.gridSettingsService.defaultGridOptions();
        this.usedByJobGridData.onRegisterApi = (api) => {
            this.usedByGridApi = api;
        };
        this.usedByJobGridData.appScopeProvider = this;
        this.usedByJobGridData.columnDefs = this.usedByService.usedByColumnDefs();
    }

    initDatGrid() {
        //vm.gridDatFileData.columnDefs = undefined;  commented out in legacy OMC

        this.gridDatFileData = [];
        this.datGridHelper = this.datGridHelper = this.gridHelperServiceFactory.gridHelper('editdatfile', '#edit-datfile-grid');
        this.datGridHelper.gridData.modifierKeysToMultiSelect = false;
        this.datGridHelper.gridData.multiSelect = false;
        this.gridDatFileData = this.datGridHelper.gridData;
        this.gridDatFileData.appScopeProvider = this;
        this.datGridRowSelected = this.datGridHelper.gridRowSelected;
    }

    initAuditGrid() {
        this.auditGridData = this.gridSettingsService.defaultGridOptions();
        this.auditGridData.onRegisterApi = (api) => {
            this.auditGridApi = api;
        };
        this.auditGridData.appScopeProvider = this;
        this.auditGridData.columnDefs = this.auditService.auditLogColumnDefs();
    }

    initiGlobalValues() {
        this.excelId = this.omcGlobalsService.valueForKey("dataInputTypes", "Excel");
        this.csvId = this.omcGlobalsService.valueForKey("dataInputTypes", "CommaDelimitedText");
        this.txtId = this.omcGlobalsService.valueForKey("dataInputTypes", "TabDelimitedText");
        this.internalId = this.omcGlobalsService.valueForKey("dataInputTypes", "Internal");
        this.oracleId = this.omcGlobalsService.valueForKey("dataInputTypes", "Oracle");
        this.sqlServerId = this.omcGlobalsService.valueForKey("dataInputTypes", "SqlServer");
        this.odbcId = this.omcGlobalsService.valueForKey("dataInputTypes", "OdbcSource");
    }

    changeDatFile() {
        this.updateDatTableColumns();
    }

    selectedDatTable() {
        return this.datGridHelper.selectedValues()[0];
    }

    selectedTable() {
        return this.gridHelper?.selectedValues()[0]
    }

    showDatabaseTableFields() {
        return this.selectedTable() &&
            (this.selectedTable().dataInputTypeId === this.oracleId ||
                this.selectedTable().dataInputTypeId === this.sqlServerId ||
                this.selectedTable().dataInputTypeId === this.odbcId);
    }

    getTableSets() {
        this.loaded = !!this.tableSet && !!this.formData
        if (!this.loaded) return;

        //Load Tasks and Init
        this.modelsService.getModelsForPicker(true).then((models) => {
            this.tasks = models;
            //Task Types related changes
            this.taskTypeService.getTaskTypes(true).then((data) => {
                this.taskTypes = data;
                this.selectedTaskType = this.taskTypes[0];
                this.selectedTask = { id: 0 };

                this.filteredTasks = [];

                if (this.tableSet && this.tableSet.taskTypeId) {
                    this.selectedTaskType = this.taskTypes.find(x => x.key === this.tableSet.taskTypeId);
                }

                this.tableSet.taskTypeId = this.selectedTaskType.key;

                if (this.tableSet && this.tableSet.modelId) {
                    this.selectedTask = models.find(x => x.id === this.tableSet.modelId);
                } else if (models.find(x => x.isDefault)) {
                    this.selectedTask = models.find(x => x.isDefault);
                    this.tableSet.modelId = this.selectedTask.id;
                }

                this.filteredTasks = this.tasks.filter(e => e.taskTypeId === this.selectedTaskType.key);
                if (this.userSettings && this.userSettings.applyTaskFilter && (this.selectedTaskType.key === this.formData.filterValues.selectedTaskType.key || this.formData.filterValues.selectedTaskType.key === 0) && this.formData.isAdd) {
                    this.filteredTasks = this.filterService.filterApplyOnTasks(this.filteredTasks, this.formData.filterValues);
                }
            });
        });

        if (this.tableSet.tableFiles) {
            this.getExcelReferences(this.tableSet.tableFiles[0]);
            if (this.tableSet.tableFiles[0]) {
                this.getTableColumns(this.tableSet.tableFiles[0].table.name);
            }
        }

        if (!this.tableSet.effectiveDate)
            this.tableSet.effectiveDate = new Date();

        this.$rootScope.$on("serverEvent:TaskTypeServerEvent", (event, payload) => {

            var load: { data: any } = payload;
            if (load && load.data && this.taskTypes) {
                if (!Array.isArray(load)) {
                    for (var i = 0; i < this.taskTypes.length; i++) {
                        if (load.data.description === this.taskTypes[i].value) {
                            this.taskTypes[i].isDisabled = load.data.isDisabled;
                            return;
                        }
                    }
                } else {
                    for (var j = 0; j < load.data.length; j++) {
                        for (var i = 0; i < this.taskTypes.length; i++) {
                            if (load.data.description === this.taskTypes[i].value) {
                                this.taskTypes[i].isDisabled = load.data.isDisabled;
                            }
                        }
                    }
                }

            }
        })
    }


    showTab(display) {
        var basisId;
        this.display = display;
        if (display === "tables") {
            //usedByGridApi.core.refresh();
            this.gridHelper.refresh();
        } else if (display === "datfile") {
            //usedByGridApi.core.refresh();
            this.updateDatTableColumns();
        } else if (display === "usedBy") {
            if (!this.formData.isAdd && this.tableSet.id) {
                basisId = this.tableSet.id;
                this.dataService.getData('auditInfo?id=' + basisId, 'auditInfo?id=' + basisId, true).then((data) => {
                    data.usedBy.map(x => x.taskType = this.taskTypeService.getTaskTypeValueById(x.taskType));
                    this.usedByJobGridData.data = data.usedBy;
                    this.usedByGridApi.core.refresh();
                });
            }
        } else if (display === "audits") {
            if (!this.formData.isAdd && this.tableSet.id) {
                basisId = this.tableSet.id;
                this.dataService.getData('auditInfo?id=' + basisId, 'auditInfo?id=' + basisId, true).then((data) => {
                    this.auditGridData.data = data.auditEntries;
                    this.auditGridApi.core.refresh();
                });
            }
        }
    }

    isDisabled() {
        return this.formData.isAdd || this.formData.isCopy;
    }

    canEditFile() {
        // can edit file if in edit mode and if it is either in Copy mode or it was not linked natively (created automatically),
        return this.formData.isEdit && (this.formData.isCopy || !this.tableSet.createdAutomatically);
    };

    showFileTableFields() {
        return this.selectedTable() &&
            (this.selectedTable().dataInputTypeId === this.excelId ||
                this.selectedTable().dataInputTypeId === this.csvId ||
                this.selectedTable().dataInputTypeId === this.txtId);
    }

    showExcelTableFields() {
        return this.selectedTable() && this.selectedTable().dataInputTypeId === this.excelId;
    }

    showStandardTableFields() {
        return (this.selectedTable() && this.selectedTable().dataInputTypeId === this.internalId) || this.showFileTableFields();
    }

    uploadModelFile() {
        this.modelsService.uploadTableDataFile(this.tableDataFile)
            .then((dataTableFile) => {
                this.tableSet.tableSetPackage = angular.extend(this.tableDataFile, dataTableFile.tableDataFile);
                this.tableSet.tableSetPackageId = this.tableSet.tableSetPackage.tablePackageFileId;
                this.tableSet.tableFiles = [];
                dataTableFile.tables.forEach((e) => {
                    this.tableSet.tableFiles.push({
                        modelPackageId: null,
                        id: 0,
                        table: { name: e, id: 0 },
                        tableId: 0,
                        dataInputTypeId: this.internalId,
                        isRunControl: false,
                        runIdColumn: '',
                    });
                });
                this.datFileExist = true;
                this.loadDatFileTables();
                this.gridHelper.dataReloaded(this.tableSet.tableFiles);
            })
            .catch ((err) => {
                console.log(err.error.message)
            })
    }

    clickFile() {
        $('#datFile').trigger('click');
    }

    isInvalid(key) {
        var errors = this.validate();
        this.formData.isValid = errors.isValid()
        if (key) {
            return errors[key];
        }
        return !errors.isValid();
    };

    errorMessageFor() {
        return this.validate();
    };

    uploadTable(table) {
        $('#file').trigger('click');
    };

    removeTable(table) {
        //might need to create a table property
        table.tablePackageFileId = "";
        table.fileName = "";
        table.isRunControl = false;
        table.runIdColumn = "";
        table.hasChanges = false;
    };

    hasReference() {
        return this.$scope.row.entity.dataInputTypeId === this.excelId;
    }


    isDataConnection() {
        try {
            return (this.selectedTable() && this.dataSourceIds && this.dataSourceIds.find(d => d.value === this.selectedTable().dataInputTypeId)) !== undefined;
        } catch (e) {
            return false;
        }

    };

    dataSetTypeChange(dsType) {
        let selectedTable = this.selectedTable();
        if (selectedTable) {
            if (dsType.value !== this.excelId) {
                selectedTable.fileName = "";
                selectedTable.reference = "";
                selectedTable.table.isRunControl = false;
                selectedTable.table.runIdColumn = "";
            } else if (this.isDataConnection()) {
                selectedTable.reference = "";
                selectedTable.fileName = "";
                selectedTable.table.isRunControl = false;
                selectedTable.table.runIdColumn = "";
            }

            this.updateTableList(selectedTable.table.name, dsType.value);
            this.filterConnections(this.dataConnections, dsType.value);
        }
    };

    updateTableList(name, value) {
        if (!this.tablesData?.tables) {
            // console.warn('Could not update table list');
            return
        };
        for (let i = 0; i < this.tablesData.tables.length; i++) {
            var table = this.tablesData.tables[i];
            if (table.name === name) {
                table.isDisabled = value !== this.internalId;
                break;
            }
        }
    }


    fileUploadEnabled() {
        return this.canEditFile() && this.selectedTable() && this.selectedTable().dataInputTypeId !== this.internalId;
    }

    referenceEditable() {
        if (!this.formData.isEdit) {
            return false;
        }
        return this.selectedTable && this.selectedTable() && this.selectedTable().dataInputTypeId === this.excelId;
    }

    selectRunControlEditable() {
        if (!this.formData.isEdit) {
            return false;
        }
        return this.selectedTable && this.selectedTable();
    }

    runIdColumnEditable() {
        if (!this.formData.isEdit) {
            return false;
        }
        return this.selectedTable && this.selectedTable() && this.selectedTable().table?.isRunControl;
    }

    modelChanged() {
        this.tableSet.model = this.tasks.find(x => x.id === this.tableSet.modelId);
        console.log("modelChanged: model = " + this.tableSet.model.name);
        //vm.tableSet.modelId = vm.tableSet.model.id;
    }

    dataConnectionChanged() {
        //Create a call to service to get all references...
        //Pass the connectionName... and show spinner on screen.
        let selectedTable = this.selectedTable();
        if (selectedTable.dataConnectionId) {
            if (this.filteredDataConnections.length === 0) {
                return;
                //    vm.filteredDataConnections = dataConnections &&
                //dataConnections.length > 0 &&
                //dataConnections.filter(dc => dc.connectionType === platformTypeId);
            }
            this.selectedTable().fileName = this.filteredDataConnections
                .find(d => d.id === this.selectedTable().dataConnectionId)
                .name;
            this.getSchemaReferences()
            this.getTableReferences();

        }
        //on successful connection show tables, else show error message...
    };

    onTaskTypeChanged() {
        this.filteredTasks = this.tasks.filter((e) => e.taskTypeId === this.selectedTaskType.key);
        if (
            this.userSettings &&
            this.userSettings.applyTaskFilter &&
            (this.selectedTaskType.key === this.formData.filterValues.selectedTaskType.key ||
                this.formData.filterValues.selectedTaskType.key === 0) &&
            this.formData.isAdd
        ) {
            this.filteredTasks = this.filterService.filterApplyOnTasks(this.filteredTasks, this.formData.filterValues);
        }
        if (this.filteredTasks.length > 0) {
            this.selectedTask = this.filteredTasks[0];
            this.onTaskChanged();
        }
    }

    onTaskChanged() {
        if (this.filteredTasks.length > 0) {
            this.tableSet.modelId = this.selectedTask.id;
            this.tableSet.taskTypeId = this.selectedTaskType.key;
        }
    }

    //might need to be called oninit
    updateTableSetTableFiles() {
        this.tableSet.tableFiles.map(x => {
            var platformTypeId = -1;
            if (x.dataInputTypeId === this.oracleId) {
                platformTypeId = 1;
            }
            if (x.dataInputTypeId === this.sqlServerId) {
                platformTypeId = 0;
            }

            if (platformTypeId !== -1) {
                this.filteredDataConnections = this.dataConnections &&
                    this.dataConnections.length > 0 &&
                    this.dataConnections.filter(dc => dc.connectionType === platformTypeId);
            }
            if (x.dataConnectionId) {
                x.dataConnectionId = parseInt(x.dataConnectionId);
                x.fileName = this.filteredDataConnections.find(d => d.id === x.dataConnectionId).name;
            }
        });
        this.gridHelper.dataReloaded(this.tableSet.tableFiles);
        if (this.tableSet.tableSetPackage != null) {
            this.datFileExist = true;
            this.loadDatFileTables();
        } else {
            this.datFileExist = false;
        }
    }

    getSchemaReferences() {
        let table = this.selectedTable()
        if (table.dataConnectionId ?? false) {
            const url = 'getSchemaReferences?connectionId=' + table.dataConnectionId;
            this.dataService.getData(url, url, true, false).then((schemas) => {
                this.schemas = schemas;
            });
        }
    }

    getTableReferences() {
        let table = this.selectedTable()
        if (table?.dataConnectionId ?? false) {
            let schema = table.schema ?? '';
            let url = 'getTableReferences?connectionId=' + table.dataConnectionId + '&schemaName=' + schema;
            this.dataService.getData(url, url, false).then((tableReferences) => {
                this.tableReferences = tableReferences;
            });
        }
    };

    filterConnections(dataConnections, dsType) {
        var platformTypeId = -1;
        if (dsType === 4) {
            platformTypeId = 1;
        }
        if (dsType === 5) {
            platformTypeId = 0;
        }

        this.filteredDataConnections = dataConnections &&
            dataConnections.length > 0 &&
            dataConnections.filter(dc => dc.connectionType === platformTypeId);
    }

    loadDatFileTables() {
        var fileId = this.tableSet.tableSetPackage.tablePackageFileId;
        let url = "parseDATFile?fileId=" + fileId;
        this.dataService.getData(url, url, true).then((data) => {
            if (!data.tables || !data.filesData) return;
            this.tablesData = this.tablesData || {};
            this.tablesData.tables = data.tables;
            // Note: we must iterate the tables this way (instead of using foreach) since data.tables
            //       is a 'dynamic' (and not an array) returned from the data service - rfs 15.10.19
            for (let i = 0; i < this.tablesData.tables.length; i++) {
                var table = this.tablesData.tables[i];
                const item = this.tableSet.tableFiles.filter(tf => tf.table.name === table.name)[0];
                table.isDisabled = item && item.dataInputTypeId !== this.internalId;
            }
            this.tablesData.selectedTable = data.tables[0];
            this.tablesData.files = data.filesData;
            this.datGridHelper.dataReloaded(data.filesData.filter(x => x.key === this.tablesData.selectedTable.name)[0].value);
            this.getTableColumns(this.tablesData.selectedTable.name);
        });
    }

    uploadTableFile() {
        this.fileUploadService.uploadFile(this.tableFile, 'uploadTableFile').then((data) => {
            this.selectedTable().tablePackageFileId = data.id;
            this.selectedTable().fileName = data.fileName;
            //vm.selectedTable().table.isRunControl = data.isRunControl;
            //vm.selectedTable().table.runIdColumn = data.runIdColumn;
            this.selectedTable().hasChanges = true;
            this.excelReferences = data.references;
            this.getTableColumns(this.selectedTable().table.name);
        });
    }

    validate() {
        var hasErrors = false;
        var rtn = {
            isValid: () => !hasErrors,
            modelForCopy: null,
            name: null,
            taskname: null,
            tasktype: null,
            collection: null,
            tableDataFileName: null,
            table: null,
            dataConnectionId: null,
            filename: null,
            reference: null,
            runIdColumn: null
        };

        if (this.formData.isEdit) {
            if (!this.tableSet || (this.tableSet.model && !this.tableSet.model.name)) {
                rtn.modelForCopy = "Please select a package";
                hasErrors = true;
            }
            if (!this.tableSet || !this.tableSet.name) {
                rtn.name = "Please specify a value for the Tableset Name";
                hasErrors = true;
            }
            if (!this.selectedTask || !this.selectedTask.id) {
                rtn.taskname = "Please select the Task Name";
                hasErrors = true;
            }
            if (!this.selectedTaskType || !this.selectedTaskType.key) {
                rtn.tasktype = "Please select the Task Type";
                hasErrors = true;
            }
            if (!this.tableSet.collections || this.tableSet.collections.length === 0) {
                rtn.collection = "Please select at least one collection for this Tableset";
                hasErrors = true;
            }
            if (this.tableSet && this.tableSet.name) {
                if (this.formData.usedNames.find(t =>
                    t.name === this.tableSet.name
                    && t.modelId === this.tableSet.modelId
                    && t.id !== this.tableSet.id)
                ) {
                    rtn.name = "Tableset Name is already in use";
                    hasErrors = true;
                }
            }
            if (!this.tableSet ||
                !this.tableSet.tableSetPackage ||
                !this.tableSet.tableSetPackage.fileName) {
                rtn.tableDataFileName = "Please specify a value for the Table Data File";
                hasErrors = true;
            }
            if (this.isDataConnection()) {
                if (!this.selectedTable() || !this.selectedTable().reference) {
                    rtn.table = "Select Table";
                    hasErrors = true;
                }
                if (!this.selectedTable() || !this.selectedTable().dataConnectionId) {
                    rtn.dataConnectionId = "Data Connection";
                    hasErrors = true;
                }
            }
            else {
                if (this.selectedTable()) {
                    if (this.showFileTableFields() && !this.selectedTable().fileName) {
                        rtn.filename = "Select File";
                        hasErrors = true;
                    }
                    if (this.showExcelTableFields() && !this.selectedTable().reference) {
                        rtn.reference = "Select Reference";
                        hasErrors = true;
                    }
                    if (this.selectRunControlEditable() && this.selectedTable().table?.isRunControl && !this.selectedTable().table.runIdColumn) {
                        rtn.runIdColumn = "Select Filter Column";
                        hasErrors = true;
                    }
                }
            }
        }
        return rtn;
    }

    camelCase(input) {
        input = input || '';
        return input.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
    }

    columnDefs() {
        /* Removed  this.hasReference($scope) [TM 06/04/22] */
        return [
            {
                name: 'id',
                displayName: '',
                cellTemplate: '<div class="grid-row-vertical-bar" ng-click="grid.appScope.gridHelper.toggleSelected(row.entity, $event);" ng-class="{\'grid-row-selected\': grid.appScope.gridRowSelected(row.entity) }" role="button" tabindex="0"></div>',
                width: this.gridSettingsService.columnWidths.toggleSelect,
                pinnedLeft: true,
                cellClass: "omc-select-column"
            },
            {
                name: 'table.name', displayName: 'Name', enableCellEdit: false
            },
            {
                name: 'fileName', displayName: 'File Name', enableCellEdit: false
            },
            {
                name: 'reference', displayName: 'Reference', cellEditableCondition: ($scope) => { return this.hasReference(); }
            },
            {
                name: 'dataInputTypeId', displayName: 'Type', cellFilter: 'descriptionForValue:"dataInputTypes"', cellEditableCondition: ($scope) => { return this.hasReference(); }
            }
        ];
    }

    getExcelReferences(row) {
        if (row) {
            var extension = row.fileName ? row.fileName.substring((row.fileName.lastIndexOf('.') + 1)) : '';
            if (extension === "xlsx" || extension === "xls" || extension === "xlsm") {
                this.dataService.getData("GetExcelReferences?fileId=" + row.tablePackageFileId, "GetExcelReferences?fileId=" + row.tablePackageFileId, true).then((data) => {
                    this.excelReferences = data;
                    if (data.includes(this.selectedTable().reference)) {
                        this.selectedTable().reference = this.selectedTable().reference;
                    } else {
                        this.selectedTable().reference = "";
                    }
                });
                // TODO: this is a placeholder for when we want to populate the preview grid with actual
                //       data from the associated Excel spreadsheet
                //dataService.getData("GetExcelTable?fileId=" + row.tablePackageFileId + "&reference=" + vm.selectedTable().reference,
                //                    "GetExcelTable?fileId=" + row.tablePackageFileId + "&reference=" + vm.selectedTable().reference, true).then(function (table) {
                //    console.log('table = ' + table);
                //});
            }
        }
    }


    getTableColumns(name) {
        if (this.tablesData === undefined) return;
        var selectedData = this.tablesData.files.filter(x => x.key === name)[0].value;
        var hasHeadings = this.tablesData.tables.filter(x => x.name === name)[0].hasHeadings;
        var columnsArray = [];
        if (selectedData !== null && selectedData !== undefined && selectedData.length > 0) {
            var columnsIn = selectedData[0];
            //var tableVm = vm.gridHelper.gridData.data.find(x => x.table.name === name);
            for (var key in columnsIn) {
                if (key !== "$$hashKey") {
                    var columnHeading = "";
                    if (hasHeadings) {
                        columnHeading = this.camelCase(key);
                        //if (vm.isRunControl && key.toLowerCase() === tableVm.table.runIdColumn.toLowerCase()) {
                        //    columnHeading += "(*)";
                        //}
                    }
                    columnsArray.push({ key: key.toLowerCase(), displayName: columnHeading });
                }
            }
        }
        this.tableColumns = columnsArray; //.map(x => x.name);
    }

    updateDatTableColumns() {
        if (!this.tablesData) return;

        var selectedTableName = this.tablesData.selectedTable.name;
        var selectedData = this.tablesData.files.filter(x => x.key === selectedTableName)[0].value;
        var hasHeadings = this.tablesData.tables.filter(x => x.name === selectedTableName)[0].hasHeadings;

        var columnCount = 0;
        var columnsArray = [];

        this.isRunControl = false;
        this.showRunControlButton = false;
        if (selectedData !== null && selectedData !== undefined && selectedData.length > 0) {
            var tableVm = this.gridHelper.gridData.data.find(x => x.table.name === selectedTableName);
            this.isRunControl = tableVm && tableVm.table.isRunControl;
            this.showRunControlButton = true;
            var columnsIn = selectedData[0];
            for (var key in columnsIn) {
                if (key !== "$$hashKey") {
                    var columnHeading = "";
                    if (hasHeadings) {
                        columnHeading = this.camelCase(key);
                        if (this.isRunControl && tableVm.table.runIdColumn && key.toLowerCase() === tableVm.table.runIdColumn.toLowerCase()) {
                            columnHeading += "(*)";
                        }
                    }
                    columnsArray.push({ field: key, displayName: columnHeading });
                    columnCount++;
                }
            }
            //return columnsArray;
        } else {
            console.log("No columns");
            columnsArray = [];
            this.noData = true;
            //return null;
        }
        if (columnCount === 0) {
            this.noData = true;
        } else {
            this.noData = false;
        }

        //var columnsDef = generateColumns();
        this.gridDatFileData.columnDefs = columnsArray;
        this.datGridHelper.dataReloaded(selectedData);
    }
}

const EditTableSetComponent: OmcComponent = {
    selector: `editTableSetComponent`,
    template: require('./edit-tableset.component.html'),
    bindings: {
        tableSet: '=',
        formData: "=",
    },
    controller: EditTableSetController
}

angular
    .module('omc')
    .component(EditTableSetComponent.selector, EditTableSetComponent)



