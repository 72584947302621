export const columnWidths = {
    toggleSelect: '5px',
    edit: '60px',
    fileName: '185px',
    fileSize: '100px',
    dateTime: '160px',
    userName: '150px',
    default: '200px',
    email: '300px',
    notes: '500px',
    roleName: '350px',
    roleUiAction: '180px',
    roleUiActionGroup: '180px',
    number: '120px',
    active: '120px',
    medium: '300px',
    icon: '25px',
};
