import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { UiActionsService } from '../../shared/services/ui-actions.service';

@Directive({
    selector: '[hasPermission]',
})
export class HasPermissionDirective implements OnInit {
    @Input() hasPermission;
    @Input() hideIfDisabled;

    constructor(private uiActionsService: UiActionsService, private el: ElementRef, private renderer: Renderer2) {}

    ngOnInit() {
        this.updateView();
    }

    private updateView() {
        let element = this.el.nativeElement;
        this.uiActionsService.getPermissionsForName(this.hasPermission, false).then((perm: { disabled: boolean }) => {
            if (this.hideIfDisabled) {
                if (!perm || perm.disabled) {
                    this.renderer.addClass(element, 'permission-hidden');
                } else {
                    this.renderer.removeClass(element, 'permission-hidden');
                }
            }
            if (!perm || perm.disabled) {
                this.renderer.setAttribute(element, 'disabled', 'disabled');
                this.renderer.setAttribute(element, 'tabindex', '-1');
                this.renderer.addClass(element, 'disabled');
                this.renderer.listen(element, 'click', (event) => {
                    event.preventDefault();
                    return true;
                });
            }
        });
    }
}
