import { Component, Inject, Input, OnInit } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import * as angular from 'angular';
import { StateService } from '../../../ajs-upgraded-providers';
import { pathToApp } from '../../../core/constants/global-constants';
import { UiActionsService } from '../../services/ui-actions.service';

@Component({
    selector: 'navLinkComponent',
    templateUrl: pathToApp + 'shared/components/nav-link/nav-link.component.html',
})
export class NavLinkComponent implements OnInit {
    visible=true;
    disabled;
    @Input() sref: string;
    @Input() permission: string;

    constructor(@Inject(StateService) private state, private uiActionsService: UiActionsService) {}

    ngOnInit() {
        setTimeout(() => {
            this.uiActionsService.getPermissionsForName(this.permission, false).then((perm: { disabled: boolean }) => {
                // if the permission is disabled and hidden it would come back as undefined
                if (perm) {
                    this.visible = true;
                    this.disabled = perm.disabled;
                } else {
                    this.visible = false;
                }
            });
        });
    }

    isActive() {
        return this.sref === this.state.current.name;
    }

    navigateToRoute() {
        this.state.go(this.sref);
    }
}

angular.module('omc').directive(
    'navLinkComponent',
    downgradeComponent({
        component: NavLinkComponent,
        inputs: ['sref', 'permission'],
    })
);
