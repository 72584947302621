import * as angular from 'angular';

export class GridHelperService {
    gridHelpers = {};

    constructor(private gridHelperBuilderServiceLegacy) {}

    gridHelper(key, gridName, appScopeProvider, columnDefs) {
        /* legacy grid builderService in use untill switch to Angular multi service injector [TM 17/08/22] */
        this.gridHelpers[key] = this.gridHelpers[key] || new this.gridHelperBuilderServiceLegacy.GridHelper();
        this.gridHelpers[key].initialise(gridName, appScopeProvider, columnDefs);
        return this.gridHelpers[key];
    }
}
angular.module('omc').service('gridHelperServiceFactory', GridHelperService);
