import { TaskView } from './task-view.model';

export class JobTaskHistory {
    executionHistoryId: any;
    tasks: any;
    constructor(historyTask: Partial<JobTaskHistory> = {}) {
        this.executionHistoryId = historyTask.executionHistoryId;
        console.log(historyTask.tasks);
        this.tasks = historyTask.tasks ? historyTask.tasks.map((task, i) => new TaskView(task, i)) : [];
    }
}
export class JobHistoryTaskRecords {
    jobId: any;
    recordsProcessed: any;
    taskId: any;
    name: any;
    isStoredProcedure: any;
    tasks: any;
    executionHistoryId: any;
    constructor(historyTask: Partial<JobHistoryTaskRecords> = {}) {
        this.jobId = historyTask.jobId;
        this.recordsProcessed = historyTask.recordsProcessed;
        this.taskId = historyTask.taskId;
        this.name = historyTask.name;
        this.isStoredProcedure = historyTask.isStoredProcedure;
    }
    /** Returns JobHistoryTaskRecords[] */
    static makeFromJobTaskHistory(jobHistory: Partial<JobHistoryTaskRecords> = {}) {
        return (jobHistory.tasks || []).map((task) => {
            return new JobHistoryTaskRecords({
                jobId: jobHistory.executionHistoryId,
                recordsProcessed: task.recordsProcessed,
                taskId: task.jobRunTaskId,
                name: task.name,
                isStoredProcedure: task.isStoredProcedure?.() ?? false,
            });
        });
    }
}
