import * as angular from 'angular';

export class GridHelperFunctionService {
    constructor(private $timeout, private uiGridConstants, private currentUserService, private typeCheckerService) {}

    getGridSettingsById(gridId) {
        return this.currentUserService.user.userSettings.gridSettings.find((x) => x.id === gridId);
    }

    updateGridSetting(settings) {
        let i = this.currentUserService.user.userSettings.gridSettings.findIndex((x) => x.id === settings.id);
        if (i > -1) {
            this.currentUserService.user.userSettings.gridSettings[i] = settings;
        } else {
            this.currentUserService.user.userSettings.gridSettings.push(settings);
        }
        this.currentUserService.setUserSettings(this.currentUserService.user.userSettings);
    }

    sortChanged(grid, sortColumns) {
        if (
            sortColumns &&
            grid &&
            grid.options &&
            grid.options.gridId &&
            this.currentUserService.user.userSettings.gridSettings
        ) {
            var gridSetting = this.getGridSettingsById(grid.options.gridId);
            if (gridSetting) {
                gridSetting.columnsInfo = gridSetting.columnsInfo || [];
                sortColumns.forEach((col) => {
                    let columnInfo = {
                        name: col.name,
                        sortDirection: col.sort.direction,
                        priority: col.sort.priority,
                    };
                    var savedColumn = gridSetting.columnsInfo.find((x) => x.name === col.name);
                    if (!savedColumn) {
                        gridSetting.columnsInfo.push(columnInfo);
                    } else {
                        Object.assign(savedColumn, columnInfo);
                    }
                });
                // remove sorting from columns that are not in event
                gridSetting.columnsInfo.forEach((col) => {
                    if (!sortColumns.find((x) => x.name === col.name)) {
                        col.sortDirection = null;
                        col.priority = null;
                    }
                });
                this.updateGridSetting(gridSetting);
            }
        }
    }

    columnPinned(col, $event, gridId) {
        if (col && gridId) {
            let gridSetting = this.getGridSettingsById(gridId);
            if (gridSetting) {
                let pinnedDirection = $event;
                let savedColumn = gridSetting.columnsInfo.find((x) => x.name === col.name);
                if (!savedColumn) {
                    gridSetting.columnsInfo.push({
                        name: col.name,
                        pinned: pinnedDirection,
                    });
                } else {
                    savedColumn.pinned = pinnedDirection;
                }
                this.updateGridSetting(gridSetting);
            }
        }
    }

    setSortOrder(gridData) {
        var gridSetting = this.getGridSettingsById(gridData.gridId);
        if (gridSetting && gridSetting.columnsInfo) {
            // if grid settings has column info saved, set in grid
            if (gridSetting.columnsInfo.length !== 0) {
                gridSetting.columnsInfo.forEach((column) => {
                    var sortCol = gridData.columnDefs.find((x) => x.name === column.name);
                    if (sortCol) {
                        sortCol.sort = {
                            direction: column.sortDirection,
                            priority: column.priority,
                        };
                        if (column.pinned && column.pinned === 'left') {
                            sortCol.pinnedLeft = true;
                        } else if (column.pinned && column.pinned === 'right') {
                            sortCol.pinnedRight = true;
                        }
                    }
                });
            } else {
                let userSettings = this.currentUserService.user.userSettings;
                if (userSettings) {
                    // get user's default
                    var sortColName = userSettings.defaultSortColumn;
                    if (sortColName === 'name' && !gridData.columnDefs.find((x) => x.name === sortColName)) {
                        sortColName = 'description';
                    }
                    // try user's defaults
                    var sortCol = gridData.columnDefs.find((x) => x.name === sortColName);
                    if (!sortCol) {
                        // fallback:  sort priority set in column defs
                        sortCol = gridData.columnDefs.find((x) => x.sort && x.sort.priority === 1);
                        if (!sortCol) {
                            // fallback:  the first column
                            sortCol = gridData.columnDefs[0];
                        }
                    }
                    sortColName = sortCol.name;
                    sortCol.sort = {
                        direction: userSettings.defaultSortDirection,
                        priority: 1,
                    };
                    gridSetting.columnsInfo = [];
                    gridSetting.columnsInfo.push({
                        name: sortColName,
                        sortDirection: userSettings.defaultSortDirection,
                        priority: 1,
                    });
                }
            }
            this.updateGridSetting(gridSetting);
        }
    }

    setPinnedRows(gridData) {
        var gridSetting = this.getGridSettingsById(gridData.gridId);
        let data = gridData.data;
        if (gridSetting && this.typeCheckerService.isObjectArrayWithProps(gridSetting.pinnedRows, ['id'])) {
            let pinned = gridSetting.pinnedRows.map((x) => x.id);
            pinned.forEach((id) => {
                /* the pinnedRows in the userSettings schema will always send and expect string ids */
                let item = data.find((item) => item.id.toString() === id.toString());
                if (item) {
                    item.isPinned = true;
                }
            });
        }
    }

    /* the pinnedRows in the api will always be string ids
    this function must be called and assigned to grid.toggleRowPinned */
    toggleRowPinnedFn(gridId) {
        return (row) => {
            if (row && row.entity) {
                let isPinned = !row.entity.isPinned;
                if (isPinned) {
                    row.entity.isPinned = true;
                } else {
                    /*  for sorting purposes the pinned column should only expect true or undefined
                        because only pinned rows will receive a value on init while the rest will be skipped [TS 29/07/20] */
                    row.entity.isPinned = undefined;
                }
                /* the pinnedRows in the userSettings schema will always send and expect string ids */
                let entityId = row.entity.id.toString();
                let settings = this.getGridSettingsById(gridId);
                if (this.typeCheckerService.isObjectArrayWithProps(settings.pinnedRows, ['id'])) {
                    let i = settings.pinnedRows.findIndex((x) => x.id === entityId);
                    if (isPinned && i === -1) {
                        settings.pinnedRows.push({ id: entityId });
                    } else if (!isPinned && i > -1) {
                        settings.pinnedRows.splice(i, 1);
                    }
                } else {
                    settings.pinnedRows = [{ id: entityId }];
                }
                this.updateGridSetting(settings);
                row.grid.refresh();
                row.grid.api.core.notifyDataChange(this.uiGridConstants.dataChange.EDIT);
                this.$timeout(() => {
                    row.grid.api.selection.clearSelectedRows();
                    row.grid.api.selection.selectRow(row.entity, { fromRowChanged: true });
                    console.log('selecting row', row.entity);
                }, 0);
            }
        };
    }

    // grid must have a method named toggleRowPinned for pinning to work
    pinColumnDef() {
        return {
            field: 'isPinned',
            displayName: '',
            visible: true,
            sort: { direction: this.uiGridConstants.ASC, priority: 0 },
            suppressRemoveSort: true,
            sortDirectionCycle: [this.uiGridConstants.ASC],
            cellTemplate: `
                <div class="ui-grid-cell-contents">
                    <a 
                        class="pin-icon" 
                        title="{{ row.entity.isPinned ? 'Unpin' : 'Pin to top'}}"
                        ng-class="{'pinned':row.entity.isPinned}" 
                        ng-click="grid.toggleRowPinned(row)">
                    </a>
                </div>`,
            width: 31,
        };
    }
}
angular.module('omc').service('gridHelperFunctionService', GridHelperFunctionService);
