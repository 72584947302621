import * as angular from 'angular'
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { downgradeComponent } from '@angular/upgrade/static';
import { pathToApp } from '../../../core/constants/global-constants';
import { buttons } from '../../../constants/app.constants';

@Component({
    selector: `user-details-dialog-component`,
    templateUrl: pathToApp + 'shared/dialogs/user-details-dialog/user-details-dialog.component.html'
})
export class UserDetailsDialogComponent {
    buttons = buttons;
    constructor(@Inject(MAT_DIALOG_DATA) private data) {}
}
angular.module('omc').directive(
    'user-details-dialog-component',
    downgradeComponent({
        component: UserDetailsDialogComponent
    })
);