import { OmcFunc } from '../../shared/functions/omc.functions';

export class Collection {
    id: any;
    name: any;
    isDefault: any;
    status: any;
    constructor(collection) {
        this.id = collection.id;
        this.name = collection.name;
        this.isDefault = collection.isDefault;
        this.status = collection.status;
    }
}

/**
 * @param {Collection|CollectionView} collectionView Collection | CollectionView
 */
export class CollectionView {
    id: any;
    label: any;
    isDefault: any;
    disabled: any;
    constructor(collectionView) {
        this.id = collectionView.id;
        this.label = collectionView.label || collectionView.name || '';
        this.isDefault = collectionView.isDefault || false;
        this.disabled = OmcFunc.isSet(collectionView.disabled) ? collectionView.disabled : collectionView.status === 0;
    }
}
