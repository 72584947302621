import * as angular from 'angular'
import { OmcComponent } from 'shared/models/omc.types'

let importJobFromXmlComponent: OmcComponent = {
    selector: `importJobFromXmlComponent`,
    template: require("./import-job-from-xml.component.html"),
    bindings: {
        importedJobFile:"=",
            formData: "=",
    },
    controller: class ImportJobFromXmlController implements angular.IController {
        errors: any;
        loaded: boolean;
        formData: any;
        importedJobFile: any;

        constructor(
        
        ){}
        
        $onInit(){
            this.loaded = !!this.formData
        }

        clickFile(){
            $('#file').trigger('click');
        };

        isInvalid(keys){
            this.validate();
            let hasErrors = !angular.equals(this.errors, {})
            this.formData.isValid = !hasErrors;
            if (!keys) {
                return hasErrors;
            }
            var keysLocal = Array.isArray(keys) ? keys : [keys];
            return keysLocal.find((x) => {
                return this.errors[x];
            });
        };
        errorMessageFor(){
            this.validate();
            return this.errors;
        };
        validate() {
            this.errors = {};
            var rtn = true;
            if (!this.importedJobFile || !this.importedJobFile.name) {
                this.errors.name = "Please select a valid XML job import file";
                rtn = false;
            }
            return rtn;
        }
    }
}

angular
    .module('omc')
    .component(importJobFromXmlComponent.selector, importJobFromXmlComponent)