import * as angular from 'angular';
import { OmcComponent } from 'shared/models/omc.types';
import * as moment from 'moment';
import { ArtefactDialogData, BatchConfirmDialogData, ButtonConfig } from '../shared/models/dialog-data.model';
import { MatDialogService } from '../shared/services/mat-dialog.service';
import { buttons } from '../constants/app.constants';

/* TODO: move to edit-task dialog [TS 22/01/21] */
class ModelsDialogData extends ArtefactDialogData {
    uncPaths: string[];
    constructor(props: Partial<ModelsDialogData> = {}) {
        super(props);
        this.uncPaths = props.uncPaths || [];
    }
}

let tasksComponent: OmcComponent = {
    selector: `tasksComponent`,
    template: require('./tasks.component.html'),
    controller: class TasksController implements angular.IController {
        selectedTaskModels: any[] = [];
        isDeleteDisabled = false;
        isResetDisabled = false;
        isEditUsedByArtefactDisabled = false;
        models = [];
        editTaskDialogData = null; // data used for the task model dialog
        selectedTaskModel = null; // the selected task model for the task model dialog
        isEdit = false;
        lang: any;
        filterValues: any;
        isRestDisabled: any;
        dataConnections: any;
        defaultWorkingDirectoryId: any;
        gridHelper: any;
        gridData: any;

        constructor(
            private modelsService,
            private modalDialogService,
            private gridSettingsService,
            private currentUserService,
            private $rootScope,
            private dataService,
            private uiActionsService,
            private downloadService,
            private filterService,
            private gridHelperServiceFactory,
            private $state,
            private omcDialogService,
            private language,
            private generalDialogService,
            private gridHelperFunctionService,
            private matDialogService: MatDialogService
        ) {
            this.lang = language;
        }

        $onInit() {
            this.filterValues = {
                treeDates: [
                    {
                        sDate: moment().subtract(2, 'year').format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'),
                        eDate: moment().format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'),
                    },
                ],
                selectedCollections: [],
                selectedTask: { id: 0 },
            };
            this.getPermissions();
            this.initialiseGrid();

            this.$rootScope.$on('serverEvent:ModelServerEvent', this.onModelServerEvent.bind(this));

            (<any>window).omc.currentVm = this;
            (<any>window).omc.currentVm.refresh = () => this.refresh(true);

            this.getUNCPaths(true);
        }

        getPermissions() {
            this.uiActionsService.getPermissionsForName('edit-used-artefacts', true).then((perm) => {
                if (perm) {
                    this.isEditUsedByArtefactDisabled = perm.disabled;
                } else {
                    this.isEditUsedByArtefactDisabled = true;
                }
            });

            this.uiActionsService.getPermissionsForName('artefact-delete', true).then((perm) => {
                this.isDeleteDisabled = perm && perm.disabled;
            });

            this.uiActionsService.getPermissionsForName('artefact-restore', true).then((perm) => {
                this.isRestDisabled = perm && perm.disabled;
            });
            this.uiActionsService.getPermissionsForName('models-edit').then((perm) => {
                this.isEdit = perm && !perm.disabled;
            });
        }

        showUserProfile(id: string | number) {
            this.generalDialogService.userDetails(id);
        }

        applyFilterChanges(data) {
            this.filterValues = data;
            this.getDisplayModels(true);
        }

        canEditNew() {
            return true;
        }

        editsDisabled() {
            var result =
                !this.isEdit ||
                !this.models ||
                this.gridHelper.selectedValues().length !== 1 ||
                this.gridHelper.selectedValues()[0].isDeleted;
            return result || this.editUsedByArtefactDisabled();
        }

        editUsedByArtefactDisabled() {
            if (this.gridHelper.selectedValues().length === 0)
                // Disable the edit button if no row is selected
                return true;

            if (this.isEditUsedByArtefactDisabled === false) return false;

            if (this.gridHelper.selectedValues().length > 0 && this.gridHelper.selectedValues()[0].isAnyJobRun)
                return this.isEditUsedByArtefactDisabled;

            return false;
        }

        canDownloadModel() {
            let selected = this.gridHelper.selectedValues();
            return this.isEdit && this.models && selected.length === 1 && selected[0].hasSourceCode;
        }

        canDeleteArtefact() {
            if (!this.isDeleteDisabled) {
                var anyDeleted = this.gridHelper.selectedValues().filter((x) => {
                    return x.isDeleted === true;
                }).length;
                return this.gridHelper.selectedValues().length !== 0 && anyDeleted === 0;
            }

            return this.isDeleteDisabled;
        }

        canRestoreArtefact() {
            if (!this.isResetDisabled) {
                var anyNotDeleted = this.gridHelper.selectedValues().filter((x) => {
                    return x.isDeleted === false;
                }).length;
                return this.gridHelper.selectedValues().length !== 0 && anyNotDeleted === 0;
            }

            return this.isResetDisabled;
        }

        confirmDeleteArtefact() {
            this.selectedTaskModels = this.gridHelper.selectedValues();
            const batchConfirmDialogData = new BatchConfirmDialogData({
                title: 'Delete Task(s)',
                message: 'You are about to delete the following tasks. Do you want to continue?',
                confirmButton: new ButtonConfig({ text: buttons.DELETE, classes: 'btn-danger' }),
                artefacts: this.selectedTaskModels
            })
            this.matDialogService.openBatchConfirmDialog(batchConfirmDialogData)
                .subscribe((selectedTaskModels) => {
                    if (selectedTaskModels) {
                        this.deleteArtefacts(selectedTaskModels)
                    };
                })
        }

        confirmRestoreArtefact() {
            this.selectedTaskModels = this.gridHelper.selectedValues();
            const batchConfirmDialogData = new BatchConfirmDialogData({
                title: 'Restore Task(s)',
                message: 'You are about to restore the following tasks. Do you want to continue?',
                confirmButton: new ButtonConfig({ text: buttons.RESTORE }),
                artefacts: this.selectedTaskModels
            })
            this.matDialogService.openBatchConfirmDialog(batchConfirmDialogData)
                .subscribe((selectedArtefacts) => {
                    if (selectedArtefacts) {
                        this.restoreArtefact(selectedArtefacts)
                    };
                })
        }

        downloadModel() {
            var model = this.gridHelper.selectedValues()[0];
            if (model && model.modelPackageId) {
                this.downloadService.downloadModel(model.modelPackageId, model.fileName, 'zip');
            }
        }

        refresh(force) {
            this.$state.go(this.$state.current, {}, { reload: true });
            this.getDisplayModels(force);
        }

        getDisplayModels(force) {
            this.getModels(force).then((data: Array<any>) => {
                var models = data;

                if (this.filterValues.selectedTask.id) {
                    models = models.filter((x) => {
                        return x.id === this.filterValues.selectedTask.id;
                    });
                }
                models = this.filterService.filterApply(models, this.filterValues);

                this.models = models;
                this.models.forEach((e) => {
                    e.taskTypeName = e.taskType.description;
                });

                this.gridHelper.dataReloaded(this.models);
                this.$rootScope.$broadcast('refresh:filterDates');
            });
        }

        getModels(force) {
            return new Promise((resolve, reject) => {
                this.modelsService.getModels(force).then(
                    (models) => {
                        resolve(models);
                    },
                    (error) => {
                        reject(error);
                    }
                );
            });
        }

        getUNCPaths(force) {
            this.dataService
                .getData('dataConnection?skipCurrentUserFilter=true', 'dataConnection?skipCurrentUserFilter=true', true)
                .then((dataConnections) => {
                    this.dataConnections = dataConnections.filter((e) => e.connectionType === 2);
                    if (
                        this.dataConnections.length > 0 &&
                        this.dataConnections.find((x) => {
                            return x.isDefault;
                        })
                    ) {
                        this.defaultWorkingDirectoryId = this.dataConnections.find((x) => {
                            return x.isDefault;
                        }).id;
                    }
                });
        }

        setDefault() {
            this.gridHelper.selectedValues().length && this.gridHelper.selectedValues()[0].setDefault();
            this.getDisplayModels(true);
        }

        saveTaskModel() {
            this.selectedTaskModel.ownerId = this.selectedTaskModel.owner?.id || this.selectedTaskModel.ownerId;
            this.modelsService.put(this.selectedTaskModel, false).then(() => {
                this.omcDialogService.close('edit-task-model');
                this.refreshModel();
            });
        }

        /* old dialog service, to be replaced by omcDialog service [TS 08/07/20] */
        hideModalDialog() {
            this.modalDialogService.hide();
        }

        refreshModel() {
            this.getDisplayModels(true);
        }

        onModelServerEvent(event, payload) {
            if (payload.forceRefresh) {
                this.getModels(true);
            } else {
                var model = payload.data;
                if (Array.isArray(model)) {
                    model.map((x) => {
                        this.updateModel(x);
                    });
                } else {
                    this.updateModel(model);
                }

                this.models = this.filterService.filterApply(this.models, this.filterValues);
                this.gridHelper.dataReloaded(this.models);
            }
        }

        updateModel(model) {
            var idx = this.models.findIndex((x) => {
                return x.id === model.id;
            });

            if (model.taskType) model.taskTypeName = model.taskType.description;

            if (idx > -1) {
                this.models[idx] = model;
            } else {
                this.models.push(model);
            }

            // if (selectedRows.indexOf(model) < 0)
            //     selectedRows.push(model);
        }

        initialiseGrid() {
            this.gridHelper = this.gridHelperServiceFactory.gridHelper('modelsController', '#tasks-grid', this);
            this.gridData = this.gridHelper.gridData;
            this.gridData.appScopeProvider = this;

            this.gridHelper.registerRowsRenderingComplete(() => {
                if (this.selectedTaskModel) {
                    this.gridHelper.clearSelectedValues();
                    let selected = this.gridHelper.gridData.data.find((task) => task.id === this.selectedTaskModel.id);
                    this.gridHelper.setSelected(selected);
                }
            });

            this.gridData.gridId = 'tasks-grid';
            this.gridSettingsService.initialiseGridSettings({
                id: this.gridData.gridId,
                name: 'Models-Grid',
                columnsInfo: [],
            });

            this.gridData.columnDefs = [
                {
                    name: 'id',
                    displayName: '',
                    cellTemplate:
                        '<div class="grid-row-vertical-bar" ng-click="grid.appScope.gridHelper.toggleSelected(row.entity, $event)" ng-class="{\'grid-row-selected\': grid.appScope.gridRowSelected(row.entity) }" role="button" tabindex="0"></div>',
                    width: this.gridSettingsService.columnWidths.toggleSelect,
                    pinnedLeft: true,
                    cellClass: 'omc-select-column',
                },
                this.gridHelperFunctionService.pinColumnDef(),
                {
                    name: 'isDeleted',
                    displayName: '',
                    cellTemplate: `<span class="ui-grid-cell-contents" ng-class="{'deleted-icon': row.entity.isDeleted}"></span>`,
                    width: this.gridSettingsService.columnWidths.icon,
                },
                {
                    name: 'name',
                    displayName: 'Name',
                    cellTemplate: `<span class="ui-grid-cell-contents" ng-class="{'default-icon': row.entity.isDefault, 'deleted-row-text': row.entity.isDeleted}"><a href="#" ng-click="grid.appScope.taskDialog(row.entity.id).view(); $event.preventDefault();">{{row.entity.name}}</a></span>`,
                    width: this.gridSettingsService.columnWidths.default,
                },
                {
                    name: 'taskTypeName',
                    displayName: 'Type',
                    width: this.gridSettingsService.columnWidths.medium,
                },
                //{
                //    name: "isUsedBy", displayName: 'Is Used By', headerCellClass: 'CompiledTask', width: gridSettingsService.columnWidths.default
                //},
                {
                    name: 'ownerName',
                    displayName: 'Owner',
                    width: this.gridSettingsService.columnWidths.default,
                },
                {
                    name: 'collectionsName',
                    displayName: 'Collections',
                    headerCellClass: 'CompiledTask',
                    width: this.gridSettingsService.columnWidths.default,
                },
                {
                    name: 'notes',
                    displayName: 'Notes',
                    headerCellClass: 'Notes',
                    width: this.gridSettingsService.columnWidths.notes,
                },
                {
                    name: 'dateCreated',
                    displayName: 'Date Created',
                    headerCellClass: 'DateCreated',
                    cellFilter: 'omcDate',
                    width: this.gridSettingsService.columnWidths.dateTime,
                },
                {
                    name: 'modifiedDateTime',
                    displayName: 'Date Modified',
                    headerCellClass: 'DateCreated',
                    cellFilter: 'omcDate',
                    width: this.gridSettingsService.columnWidths.dateTime,
                },
                {
                    name: 'createdByName',
                    displayName: 'Created By',
                    headerCellClass: 'Created-By-Name',
                    width: this.gridSettingsService.columnWidths.userName,
                    cellTemplate:
                        '<div class="ui-grid-cell-contents"><a href="#" ng-click="grid.appScope.showUserProfile(row.entity.createdById); $event.preventDefault();">{{row.entity.createdByName}}</a></div>',
                },
                {
                    name: 'effectiveDate',
                    displayName: 'Effective Date',
                    headerCellClass: 'DateCreated',
                    cellFilter: 'omcDate',
                    width: this.gridSettingsService.columnWidths.dateTime,
                },
                //{
                //    name: "compiledTask", displayName: 'Compiled Task', headerCellClass: 'CompiledTask', width: gridSettingsService.columnWidths.default
                //},
            ];
        }

        deleteArtefacts(selectedArtefacts) {
            /*  removed code that checks if some artefacts were set as default, 
                may need to put back if setting default is still something that we need for tasks [TS 21/08/20] */
            var data = { artefacts: selectedArtefacts.map((a) => a.id) };
            this.modelsService.deleteModel(data).then(() => {
                this.refreshModel();
            });
        }

        restoreArtefact(selectedArtefacts) {
            var data = { artefacts: selectedArtefacts.map((a) => a.id) };
            this.modelsService.restoreModel(data).then(() => {
                this.refreshModel();
            });
        }

        taskDialog(id = null) {
            let vm = this;
            let selected = id ? vm.models.find((task) => task.id === id) : vm.gridHelper.selectedValues()[0];
            let taskId = id || (selected ? selected.id : null);
            let taskModel = {};
            let dialogData = new ModelsDialogData({
                uncPaths: vm.dataConnections,
                usedNames: vm.models.map((task) => task.name),
                dialogId: 'edit-task-model',
            });

            return {
                edit: edit,
                add: add,
                //copy: copy, // currently not an option [TS 19/06/20]
                view: view,
            };

            function edit() {
                if (vm.editsDisabled() || !selected) return;
                dialogData.assign({
                    isEdit: true,
                    isHelpEnabled: true,
                    usedNames: vm.models.filter((task) => task.id !== taskId).map((task) => task.name),
                });
                vm.modelsService.getModelById(taskId, true).then((model) => {
                    taskModel = angular.copy(model);
                    openDialog('Edit Task');
                });
            }
            function add() {
                if (!vm.canEditNew()) return;
                taskModel = {
                    owner: vm.currentUserService.user,
                    collections: [],
                    workingDirectoryId: vm.defaultWorkingDirectoryId,
                };
                dialogData.assign({
                    isEdit: true,
                    isAdd: true,
                    selectDefaultCollection: true,
                });
                openDialog('New Task');
            }
            // Currently not an option [TS 19/06/20]
            // copy() {
            //     openDialog('Copy Task')
            // }
            function view() {
                dialogData.assign({
                    isHelpEnabled: true,
                    usedNames: [],
                });
                vm.modelsService.getModelById(taskId, true).then((model) => {
                    taskModel = angular.copy(model);
                    openDialog('Task');
                });
            }

            function openDialog(title) {
                vm.selectedTaskModel = taskModel;
                vm.editTaskDialogData = dialogData;
                vm.omcDialogService.open(dialogData.dialogId, { title: title });
            }
        }

        closeDialog(id) {
            this.omcDialogService.close(id);
        }
    },
};

angular.module('omc').component(tasksComponent.selector, tasksComponent);
