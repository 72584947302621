import * as angular from 'angular'
import { OmcComponent } from 'shared/models/omc.types';
import { OmcFunc } from "../shared/functions/omc.functions";
import { ArtefactDialogData, BatchConfirmDialogData, ButtonConfig } from "../shared/models/dialog-data.model";
import * as moment from 'moment';
import { MatDialogService } from '../shared/services/mat-dialog.service';
import { buttons } from '../constants/app.constants';

class BasisSetDialogData extends ArtefactDialogData {
    filterValues: any;

    constructor(props: Partial<BasisSetDialogData> = {}) {

        super(props);
        this.filterValues = props.filterValues || {};

    }
}

export class BasesController implements angular.IController {

    gridHelper: any;
    selectedArtefacts = [];
    isDelDisabled = false;
    isRestDisabled = false;
    isEditUsedByArtefactDisabled = false;

    filterValues = {
        treeDates: [{ sDate: moment().subtract(2, 'year').format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'), eDate: moment().format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]') }],
        selectedCollections: [],
        selectedTask: { id: 0 }
    };

    model = this.filterValues.selectedTask;

    allBases = [];
    selectedBasisSet = null;             // used for the basisSet dialogs
    basisSetDialogData = null;           // meta data used for the basisSet dialogs

    isAdd = false;
    isEdit = false;
    showUserProfile: any;
    gridData: any;
    selectedRows: any = [];
    selectedValues: any;
    lang: any;
    dialogService: any;

    constructor(
        private dataService,
        private gridSettingsService,
        private gridHelperServiceFactory,
        private $rootScope,
        private generalDialogService,
        private uiActionsService,
        private modelsService,
        private filterService,
        private $state,
        private omcDialogService,
        private language,
        private gridHelperFunctionService,
        private matDialogService: MatDialogService
    ) {
        this.lang = this.language;
        this.dialogService = omcDialogService;
        this.showUserProfile = this.generalDialogService.userDetails;
    }

    $onInit() {
        this.initEventListeners();
        this.initGrid();
        this.getPermissions();
        (<any>window).omc.currentVm = this;
        (<any>window).omc.currentVm.refresh = () => this.getBase(true);
    }

    initGrid() {
        this.gridHelper = this.gridHelper = this.gridHelperServiceFactory.gridHelper('basesController', '#bases-grid');
        this.gridData = this.gridHelper.gridData;
        this.gridData.gridId = 'basis-grid';
        this.gridSettingsService.initialiseGridSettings({
            id: this.gridData.gridId,
            name: 'Basis-Grid',
            columnsInfo: []
        });

        this.gridData.appScopeProvider = this;
        // this.gridRowSelected = this.gridHelper.gridRowSelected;

        this.gridHelper.registerRowsRenderingComplete(() => {
            if (this.selectedRows.length > 0) {
                this.gridHelper.clearSelectedValues();
                this.selectedRows.forEach((entity) => {
                    if (this.gridData.data.indexOf(entity) >= 0) {
                        this.gridHelper.setSelected(entity);
                    }
                });
            }
        });
        this.gridHelper.registerRowClickCallBack((row) => {
            this.selectedRows = [];   // clear the selection
        });

        this.gridData.columnDefs = [
            {
                name: 'id',
                displayName: '',
                cellTemplate: '<div class="grid-row-vertical-bar" ng-click="grid.appScope.gridHelper.toggleSelected(row.entity, $event)" ng-class="{\'grid-row-selected\': grid.appScope.gridRowSelected(row.entity) }" role="button" tabindex="0"></div>',
                width: this.gridSettingsService.columnWidths.toggleSelect,
                pinnedLeft: true,
                cellClass: "omc-select-column"
            },
            this.gridHelperFunctionService.pinColumnDef(),
            {
                name: "isDeleted",
                displayName: '',
                cellTemplate: `<span class="ui-grid-cell-contents" ng-class="{'deleted-icon': row.entity.isDeleted}"></span>`,
                width: this.gridSettingsService.columnWidths.icon
            },
            {
                name: 'name',
                displayName: 'Name',
                cellTemplate: '<div class="ui-grid-cell-contents"  ng-class="{\'from-model\':row.entity.createdAutomatically, \'deleted-row-text\': row.entity.isDeleted}"><a href="#" ng-click="grid.appScope.basisSetDialog(row.entity.id).view(); $event.preventDefault();">{{row.entity.name}}</a></div>',
                width: this.gridSettingsService.columnWidths.default
            },
            {
                name: "taskName",
                displayName: 'Associated Task Name',
                width: this.gridSettingsService.columnWidths.medium
            },
            //{
            //    name: "isUsedBy", displayName: 'Is Used By', headerCellClass: 'CompiledTask', width: gridSettingsService.columnWidths.default
            //},
            {
                name: 'basisCount',
                displayName: 'Basis Count',
                width: this.gridSettingsService.columnWidths.number
            },
            {
                name: "collectionsName", displayName: 'Collections', headerCellClass: 'CompiledTask', width: this.gridSettingsService.columnWidths.default
            },
            {
                name: 'notes',
                displayName: 'Notes',
                width: this.gridSettingsService.columnWidths.notes
            },
            {
                name: "dateCreated",
                displayName: 'Date Created',
                headerCellClass: 'DateCreated',
                cellFilter: 'omcDate',
                width: this.gridSettingsService.columnWidths.dateTime
            },
            {
                name: "modifiedDateTime",
                displayName: 'Date Modified',
                headerCellClass: 'DateCreated',
                cellFilter: 'omcDate', width: this.gridSettingsService.columnWidths.dateTime
            },
            {
                name: "createdById", displayName: 'Created By', headerCellClass: 'Created-By-Name', width: this.gridSettingsService.columnWidths.userName,
                cellTemplate: '<div class="ui-grid-cell-contents"><a href="#" ng-click="grid.appScope.showUserProfile(row.entity.createdById); $event.preventDefault();">{{row.entity.createdById}}</a></div>'
            },
            {
                name: "effectiveDate",
                displayName: 'Effective Date',
                headerCellClass: 'DateCreated',
                cellFilter: 'omcDate', width: this.gridSettingsService.columnWidths.dateTime
            }
        ];
    }

    initEventListeners() {
        this.$rootScope.$on("serverEvent:BasisServerEvent", (event, payload) => {
            if (payload.forceRefresh) {
                this.getBaseByModelId(true);
            }
            else {
                var basisSet = payload.data;
                if (Array.isArray(basisSet)) {
                    basisSet.map(x => this.updateBasisSet(x));
                } else {
                    this.updateBasisSet(basisSet);
                }

                this.allBases = this.filterService.filterApplyWithModel(this.allBases, this.filterValues);
                this.gridHelper.dataReloaded(this.allBases);
            }
        });
    }


    getPermissions() {
        this.uiActionsService.getPermissionsForName('basis-edit').then((perm) => {
            this.isEdit = perm && !perm.disabled;
        });

        this.uiActionsService.getPermissionsForName('edit-used-artefacts', true).then((perm) => {
            if (perm)
                this.isEditUsedByArtefactDisabled = perm.disabled;
            else
                this.isEditUsedByArtefactDisabled = true;
        });

        this.uiActionsService.getPermissionsForName('artefact-delete', true).then((perm) => {
            this.isDelDisabled = perm && perm.disabled;
        });

        this.uiActionsService.getPermissionsForName('artefact-restore', true).then((perm) => {
            this.isRestDisabled = perm && perm.disabled;
        });
    }

    applyFilterChanges(data) {
        this.filterValues = data;
        this.model = data.selectedTask;
        this.getBaseByModelId(true);
    };

    editsDisabled() {
        var result = !this.isEdit
            || this.gridHelper.selectedValues().length !== 1
            || this.gridHelper.selectedValues()[0].createdAutomatically
            || this.gridHelper.selectedValues()[0].isDeleted;
        var result2 = this.editUsedByArtefactDisabled();
        return result || result2;
    };

    editUsedByArtefactDisabled() {

        if (this.gridHelper.selectedValues().length === 0) //Disable the edit button if no row is selected
            return true;

        if (!this.isEditUsedByArtefactDisabled)
            return false;

        if (this.gridHelper.selectedValues().length > 0) {
            var basisSetId = this.gridHelper.selectedValues()[0].id;
            this.dataService.getData('getBasisSetById?id=' + basisSetId, 'getBasisSetById?id=' + basisSetId, true)
                .then((data) => {
                    if (data.usedBy.filter(x => x.isJobRun === true).length > 0)
                        return this.isEditUsedByArtefactDisabled;
                    return false;
                });
        }

        return false;
    };

    copyDisabled() {
        return !this.isEdit
            || this.gridHelper.selectedValues().length !== 1
            || this.gridHelper.selectedValues()[0].isDeleted;
    }

    deleteDisabled() {
        return !this.isEdit
            || !this.gridHelper.selectedValues().length
            || this.gridHelper.selectedValues().find(z => z.createdAutomatically);
    };

    setEditMode(val) {
        this.isEdit = val;
    };

    gridRowSelected(item) {
        return this.gridHelper.selectedValues().find(x => x === item);
    };

    canDeleteArtefact() {
        if (!this.isDelDisabled) {
            var anyDeleted = this.gridHelper.selectedValues().filter(x => x.isDeleted === true).length;
            return this.gridHelper.selectedValues().length !== 0 && anyDeleted === 0;
        }
        return this.isDelDisabled;
    }

    canRestoreArtefact() {
        if (!this.isRestDisabled) {
            var anyNotDeleted = this.gridHelper.selectedValues().filter(x => x.isDeleted === false).length;
            return this.gridHelper.selectedValues().length !== 0 && anyNotDeleted === 0;
        }
        return this.isRestDisabled;
    }

    confirmDeleteArtefact() {
        this.selectedArtefacts = this.gridHelper.selectedValues();
        const batchConfirmDialogData = new BatchConfirmDialogData({
            title: 'Delete Bases',
            message: 'You are about to delete the following bases. Do you want to continue?',
            confirmButton: new ButtonConfig({  classes: 'btn-danger', text: buttons.DELETE }),
            artefacts: this.selectedArtefacts
        });
        this.matDialogService.openBatchConfirmDialog(batchConfirmDialogData)
            .subscribe((selectedArtefacts) => {
                if (selectedArtefacts) {
                    this.deleteArtefacts(selectedArtefacts);
                }
            });
    }

    confirmRestoreArtefact() {
        this.selectedArtefacts = this.gridHelper.selectedValues();
        const batchConfirmDialogData = new BatchConfirmDialogData({
            title: 'Restore Bases',
            message: 'You are about to restore the following bases. Do you want to continue?',
            confirmButton: new ButtonConfig({ text: buttons.RESTORE }),
            artefacts: this.selectedArtefacts
        });
        this.matDialogService.openBatchConfirmDialog(batchConfirmDialogData)
            .subscribe((selectedArtefacts) => {
                if (selectedArtefacts) {
                    this.restoreArtefacts(selectedArtefacts);
                }
            });
    }

    updateBasisSet(basisSet) {

        var idx = this.allBases.findIndex((x) => { return x.id === basisSet.id });
        if (idx > -1) {
            this.allBases[idx] = basisSet;
        }
        else {
            this.allBases.push(basisSet);
        }
        if (this.selectedRows.indexOf(basisSet) < 0)
            this.selectedRows.push(basisSet);
    }

    modelChanged(newModel) {
        this.model = newModel;
        this.getBaseByModelId(true);
    }

    getBase(force) {
        localStorage.setItem("selectedItem", JSON.stringify(this.model));
        this.$state.go(this.$state.current, {}, { reload: true });
        this.getBaseByModelId(true);
    }

    getBaseByModelId(force) {
        var modelId = this.model && this.model.id;
        let url = modelId > 0 ? 'getBasisByModelId?modelId=' + modelId : 'bases';
        this.dataService.getData(url, url, force).then((bases) => {
            this.updateAllBases(bases);
        });
    }

    updateAllBases(bases) {
        bases = this.filterService.filterApply(bases, this.filterValues);
        this.allBases = bases;
        var selectedModel = JSON.parse(localStorage.getItem("selectedItem"));
        if (selectedModel) {
            this.model = selectedModel;
        }
        this.selectedRows = [];   // clear the selection
        if (this.selectedBasisSet) {
            if (this.selectedRows.findIndex(row => row.id === this.selectedBasisSet.id) < 0)
                this.selectedRows.push(this.allBases.find(bs => bs.id === this.selectedValues?.id));
        }
        this.gridHelper.dataReloaded(this.allBases);
    }

    refreshModel() {
        this.model = this.filterValues.selectedTask;
        this.getBaseByModelId(true);
        this.modelsService.getModels(true);
    }

    saveBasisSet() {
        this.model = this.filterValues.selectedTask;
        if (this.basisSetDialogData.isAutomaticCopy) {
            this.selectedBasisSet.createdAutomatically = false;
        }
        this.dataService.putData(
            this.selectedBasisSet
            , 'bases', 'bases', false
        ).then(() => {
            this.omcDialogService.close('edit-basis-set')
            this.refreshModel()
        });
    }

    deleteArtefacts(selectedArtefacts) {
        var data = { artefacts: selectedArtefacts.map(a => a.id) };
        this.dataService.putData(data, 'deleteBasisset', 'deleteBasisset', false).then(() => {
            this.refreshModel();
        });
    }

    restoreArtefacts(selectedArtefacts) {
        var data = { artefacts: selectedArtefacts.map(a => a.id) };
        this.dataService.putData(data, 'restoreBasisset', 'restoreBasisset', false).then(() => {
            this.refreshModel();
        });
    }


    basisSetDialog(id = null) {
        let vm = this;
        let selectedBasisSet;
        if (!id) {
            let selected = this.gridHelper.selectedValues();
            id = selected && selected[0] ? selected[0].id : null;
        }
        let dialogData = new BasisSetDialogData({
            filterValues: this.filterValues,
            usedNames: this.allBases.map(bs => bs.name),
        })

        return {
            view: view,
            edit: edit,
            copy: copy,
            // 'add' is currently not an available function [TS 29/06/20]
        }

        function view() {
            if (!id) return;
            vm.getBasisSetById(id).then(basisSet => {
                selectedBasisSet = basisSet;
                openDialog('Basis Set');
            })
        }
        function edit() {
            if (!id) return;
            vm.getBasisSetById(id).then(basisSet => {
                selectedBasisSet = basisSet;
                dialogData.assign({
                    isEdit: true,
                    usedNames: vm.allBases
                        .filter(bs => bs.id !== id)
                        .map(bs => bs.name),
                })
                openDialog('Edit Basis');
            })
        }
        function copy() {
            if (!id) return;
            vm.getBasisSetById(id).then(basisSet => {
                basisSet.name = OmcFunc.renameCopy(basisSet.name, dialogData.usedNames)
                basisSet.copyFromId = basisSet.id;
                basisSet.id = undefined;
                basisSet.readOnly = false;
                selectedBasisSet = basisSet;
                dialogData.assign({
                    isEdit: true,
                    isCopy: true,
                })
                openDialog('Copy Basis');
            })
        }

        function openDialog(title) {
            vm.selectedBasisSet = selectedBasisSet;
            vm.basisSetDialogData = dialogData;
            vm.omcDialogService.open('edit-basis-set', { title: title });
        }
    }

    getBasisSetById(id) {
        let url = 'getBasisSetById?id=' + id;
        return this.dataService.getData(url, url, true);
    }

    /* Comented out in legacy OMC [TM 03/05/22] */

    /* old dialog used by delete\restore, to be replaced by omcDialog service [TS 08/07/20] */
    // hideModalDialog() {
    //     this.modalDialogService.hide();
    // }

    // Currently not in use, but may be useful in the future for reference [TS 30/06/20]
    /* function editNew() {
        if (vm.isAdd) {
            vm.model.basisSet = {
                modelId: 0,
                name: '',
                bases: [], //createBasesFromBasisDefinitions(vm.model.basisDefinitions),
                collections: []
            };
            vm.currentBasisSet = {
                basisSets: vm.allBases,
                model: vm.model,
                display: "",
                isEdit: true,
                isAdd: true,
                isCopy: false,
                cancel: cancel,
                save: save,
                saveBtnText: "Save",
                filterValues: vm.filterValues
            }
            modalDialogService.show("edit-basis", vm.currentBasisSet, { title: "Add Basis" });
        }
     }*/
}

const BasesComponent: OmcComponent = {
    selector: `basesComponent`,
    template: require('./bases.component.html'),
    bindings: {

    },
    controller: BasesController
}

angular
    .module('omc')
    .component(BasesComponent.selector, BasesComponent)
