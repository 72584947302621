import * as angular from 'angular'
import { OmcFunc } from "../../functions/omc.functions";

angular.module('omc').directive("disableForEndpoint", function (omcLoadingService) {
    return {
      restrict: 'A',
      scope: {
        disableForEndpoint: "@",
        disabled: '=?ngDisabled'
      },
      link: function ($scope: any, $el, $att) {
        let endpoint = $scope.disableForEndpoint
        let endpointsArray = OmcFunc.parseArray(endpoint);
        if (!!endpoint && endpoint !== '') {
          $scope.$watch(function () {
            return endpointsArray.some(endpoint => omcLoadingService.isLoading(endpoint))
          }, updateElDisabled.bind($scope))
        }
        function updateElDisabled(loading) {
          if (loading && (this.disabled ?? true)) {
            $($el).prop('disabled', 'disabled');
            $($el).addClass('disabled');
            $($el).attr('disabled', 'disabled');
          } else if(!this.disabled){
            $($el).removeProp('disabled');
            $($el).removeClass('disabled');
            $($el).removeAttr('disabled');
          }
        }
      }
    }
  })