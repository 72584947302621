import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core"
import { downgradeInjectable } from "@angular/upgrade/static";
import * as angular from 'angular';
import { first } from "rxjs/operators";

@Injectable()
export class AppEnvironmentDataService {
    constructor(private http: HttpClient) {}

    cacheMap = new Map();
    private readonly API_ADDRESS: string = "/api/GetEnvItemInfo?item="
    
    getEnvironmentData(query: string) {
        if (!this.cacheMap.get(query)) {
            this.cacheMap.set(query, this.http
                .get<string>(`${this.API_ADDRESS}${query}`)
                .pipe(first()));
        }
        return this.cacheMap.get(query);
    }
    
    getBuildDescription() {
        return this.getEnvironmentData('buildDescription')
    }
    
    getApplicationVersion() {
        return this.getEnvironmentData('applicationVersion');
    }
    
    getApplicationVersionWithoutBuild() {
        return this.getEnvironmentData('applicationVersionWithoutBuild');
    }
    
    getExecutionServiceVersion() {
        return this.getEnvironmentData('executionServiceVersion');
    }
    
    getExecutionServiceDate() {
        return this.getEnvironmentData('executionServiceDate');
    }
    
    getUtilityServiceVersion() {
        return this.getEnvironmentData('utilityServiceVersion');
    }
        
    getUtilityServiceDate() {
        return this.getEnvironmentData('utilityServiceDate');
    }

    getIdentityServiceVersion() {
        return this.getEnvironmentData('identityServiceVersion');
    }
        
    getIdentityServiceDate() {
        return this.getEnvironmentData('identityServiceDate');
    }    
    getAppBuildVersion() {
        return this.getEnvironmentData('appBuildVersion');
    }
    
    getLoginUsingEmailId() {
        return this.getEnvironmentData('loginUsingEmailId');
    }    

    getOmcCurrentWallpaper() {
        return this.getEnvironmentData('omcCurrentWallpaper');
    }
}
angular.module('omc').factory('appEnvironmentDataService', downgradeInjectable(AppEnvironmentDataService));
