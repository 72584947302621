import { OmcFunc } from '../../shared/functions/omc.functions';
import { TaskView } from '../../shared/models/task-view.model';
import { TaskDependencyView } from '../../shared/models/task-dependency-view.model';
import { JobTaskHistory, JobHistoryTaskRecords } from '../../shared/models/job-task-execution-history.model';
import * as angular from 'angular';
import { OmcComponent } from 'shared/models/omc.types';
import * as moment from 'moment';

let editJobComponent: OmcComponent = {
    selector: `editJobComponent`,
    template: require('./edit-job.component.html'),
    bindings: {
        job: '=',
        formData: '=',
    },
    controller: class EditJobController implements angular.IController {
        loadedJob: any = {}; // The job as it was loaded initially - for change checks
        taskDependencies: any = {};

        selectedTask: any = null; // reference to selected task for the view-job-task component
        editingTask: any = null; // reference to temporary task for editTask dialogs
        editTaskDialogData: any = {}; // meta data used for editTask dialogs
        auditJob: any = [{}]; // job to be loaded by the run-job audit dialog
        auditJobDialogData: any = {}; // data for run-job audit dialog
        auditJobExecution: any = {}; // execution data history for run-job audit dialog

        touched = {
            jobDetails: false,
            tasks: false,
            dependencies: false,
            reporting: false,
            collections: false,
        };
        errors: any = {};

        multiSelectSettings = {
            checkBoxes: true,
            dynamicTitle: true,
            showUncheckAll: false,
            showCheckAll: false,
        };
        advanced: any = {};
        lang: any;
        dialogs: any;
        formData: any;
        job: any;
        auditPermission: boolean;
        display: any;
        auditEntries: any[];
        jobTypes: any[];
        jobTemplates: { key: number; value: string; isDefault?: boolean; id?: string | number }[];
        reports: any;
        selectedGrid: string;
        jobPriorities: any[];
        gridHelperTasksData: any;
        model: this;
        userSettings: any;
        taskGridApi: any;

        // Dependencies Grids
        dependenciesOneGridApi: any;
        dependenciesTwoGridApi: any;
        dependenciesThreeGridApi: any;
        dependenciesSelectRows: any[] = [];
        dependenciesGridApi: any;
        gridHelperDependenciesOneData: any;
        gridHelperDependenciesTwoData: any;
        gridHelperDependenciesThreeData: any;
        gridHelperDependenciesData: any;
        canRemoveGroup: boolean;
        canAddGroup: boolean;
        isUpEnabled: boolean;
        isDownEnabled: boolean;

        constructor(
            private dataService,
            private jobsService,
            private formHelperService,
            private gridSettingsService,
            private omcGlobalsService,
            private omcDialogService,
            private uiActionsService,
            private currentUserService,
            private defaultItemService,
            private language
        ) {
            this.lang = language;
            this.dialogs = omcDialogService;
        }

        $onInit() {
            this.initialise();
            this.initGrids();
            this.initDependenciesMethods();

            this.currentUserService.getUserSettings(true).then((settings) => {
                this.userSettings = settings;
            });

            if (this.formData && this.job) {
                this.auditPermission = true;
                this.storeLoadedJob(this.job);
                this.display = OmcFunc.isSet(this.formData.display, true) ? this.formData.display : 'jobDetails';
                this.uiActionsService.getPermissionsForName('admin-view-audit', true).then((perm) => {
                    this.auditPermission = perm && !perm.disabled;
                });
                this.auditEntries = [];
                if (!this.formData.isAdd && !this.formData.isCopy) {
                    //Load used by jobs/tasks
                    let url = 'jobInfo?id=' + this.job.id;
                    this.dataService.getData(url, url, true, false).then((data) => {
                        try {
                            console.log(`Audit entries:`, data.auditEntries);
                            this.auditEntries = data.auditEntries;
                        } catch (e) {
                            console.log(e);
                        }
                    });
                }
                //refreshDependenciesGridData();
                this.showTab(this.display);
            }
        }

        initialise() {
            this.jobTypes = [];
            /* prevent component to be loaded if inputs are not available 
            - to review when moving to angular dialog system [TM 07/07/22] */
            let inputsLoaded = !!(this.job && this.formData);
            if (!inputsLoaded) return;

            this.jobsService.getActiveJobTypes(true).then((jobTypes) => {
                this.jobTypes = jobTypes;
                if (
                    angular.isUndefined(this.job.jobTypeId) &&
                    jobTypes.length > 0 &&
                    this.jobTypes.find((x) => x.isDefault)
                ) {
                    this.job.jobTypeId = this.jobTypes.find((x) => x.isDefault).id;
                } else if (angular.isUndefined(this.job.jobTypeId) && jobTypes.length > 0) {
                    this.job.jobTypeId = this.jobTypes[0].id;
                }
            });

            this.jobTemplates = [{ key: 1, value: 'Default' }];
            this.defaultItemService.refreshDefaults(false, 'JobTemplates').then(() => {
                this.dataService.getData('JobTemplates', 'JobTemplates?skipCurrentUserFilter=false', true).then(
                    (data) => {
                        this.jobTemplates = data;
                        if (
                            this.formData.isAdd &&
                            this.job.jobTemplateId === undefined &&
                            this.jobTemplates &&
                            this.jobTemplates.length > 0 &&
                            this.jobTemplates.find((x) => x.isDefault)
                        ) {
                            this.job.jobTemplateId = this.jobTemplates.find((x) => x.isDefault).id;
                        }
                    },
                    (error) => {
                        console.log(error);
                    }
                );
            });

            this.advanced.logLevels = this.omcGlobalsService.values('logLevels');
            this.defaultItemService.refreshDefaults(false, 'report').then(() => {
                this.dataService.getData('Report', 'Report', true).then((reports) => {
                    this.reports = reports;
                    this.reports.splice(0, 0, { id: null, name: 'None' });
                    if (
                        this.formData.isAdd &&
                        !this.job.reportId &&
                        this.reports &&
                        this.reports.length > 0 &&
                        this.reports.find((x) => x.isDefault)
                    ) {
                        this.job.reportId = reports.find((x) => x.isDefault).id;
                    }
                });
            });
        }

        initGrids() {
            this.gridSettingsService.setParentHeight();
            this.initTasksGrid();
            this.initDependenciesGrid();
        }

        isHistoryDisabled() {
            return !this.auditEntries || this.auditEntries.length === 0;
        }

        copySelectedTask() {
            this.editingTask = angular.copy(this.selectedTask);
            this.editingTask.id = OmcFunc.newGuid();
            let formData = {
                isCopy: true,
                hasChanged: true,
            };
            this.openEditTaskDialog('Copy Task', formData);
        }

        addTask() {
            this.editingTask = new TaskView();
            let formData = {
                isAdd: true,
                hasChanged: true,
            };
            this.openEditTaskDialog('New Task', formData);
        }

        editSelectedTask() {
            this.editingTask = angular.copy(this.selectedTask);
            let formData = {
                isValid: true,
                hasChanged: true, // TODO: Change to false if change detection is enabled in editTask [TS 18/05/20]
            };
            this.openEditTaskDialog('Edit Task', formData);
        }

        openEditTaskDialog(title, formData) {
            let defaultDialogData = {
                taskIndex: 0,
                isFirstTask: true,
                isAdd: false,
                isCopy: false,
                isValid: true,
                hasChanged: false,
            };
            Object.assign(defaultDialogData, formData);
            defaultDialogData.taskIndex =
                this.editTaskDialogData.isAdd || this.editTaskDialogData.isCopy
                    ? this.job.tasks.length
                    : this.job.tasks.findIndex((x) => x.id === this.selectedTask.id);
            defaultDialogData.isFirstTask = this.editTaskDialogData.taskIndex > 0;

            this.editTaskDialogData = defaultDialogData;
            this.omcDialogService.open('edit-job-task', { title: title });
        }

        saveSelectedTask() {
            let editedTask = this.editingTask,
                data = this.editTaskDialogData,
                tasks = this.job.tasks,
                taskDeps = this.job.taskDependencies;

            // update Tasks
            let taskIndex = tasks.findIndex((task) => task.id === editedTask.id);
            if (taskIndex > -1) {
                tasks[taskIndex] = editedTask;
            } else {
                editedTask.sNo = tasks.length + 1;
                tasks.push(editedTask);
            }

            // Update TaskDependencies
            let taskDepIndex = taskDeps.findIndex((dep) => dep.taskId === editedTask.id);
            let newTaskDependency = new TaskDependencyView(editedTask);
            if (taskDepIndex > -1) {
                taskDeps[taskDepIndex] = newTaskDependency;
            } else {
                taskDeps.push(newTaskDependency);
            }

            this.updateTasks(tasks);
            this.selectTaskById(editedTask.id);
            this.formData.hasChanged = true;

            this.omcDialogService.close('edit-job-task');
        }

        removeSelectedTask() {
            if (!this.selectedTask) return;
            let taskIndex = this.job.tasks.findIndex((task) => task.id === this.selectedTask.id);
            if (taskIndex > -1) {
                this.job.tasks.splice(taskIndex, 1);
            }
            let dependencyTaskIndex = this.job.taskDependencies.findIndex((dep) => dep.taskId === this.selectedTask.id);
            if (taskIndex > -1) {
                this.job.taskDependencies.splice(dependencyTaskIndex, 1);
            }

            if (this.job.tasks.length > 0) {
                let newSelectedIndex = taskIndex > 0 ? taskIndex - 1 : 0;
                this.updateTasks(this.job.tasks);
                this.selectTaskById(this.job.tasks[newSelectedIndex].id);
            } else {
                this.updateTasks(this.job.tasks);
                this.selectedTask = null;
            }
        }

        isLinkedAction(audit) {
            if (audit.auditEntryType.name === 'JobExecution')
                return (
                    audit.auditEntryType.name === 'JobExecution' && audit.auditEntryFieldDescription !== 'Job Started'
                );
        }

        viewAuditDetails(jobInfo) {
            console.log('Audit of Id ' + jobInfo.artefactId);

            let url = 'getExecutionHistoryById?id=' + jobInfo.artefactId;
            Promise.all([
                this.dataService.getData(url, url, true),
                this.getHistoricalJobTasks(jobInfo.artefactId),
            ]).then(([jobHistoryData, historyTasks]) => {
                let startTime = jobHistoryData.startTime
                    ? moment.utc(jobHistoryData.startTime).local().format('DD/MM/YYYY HH:mm:ss')
                    : '';
                let endTime = jobHistoryData.endTime
                    ? moment.utc(jobHistoryData.endTime).local().format('DD/MM/YYYY HH:mm:ss')
                    : '';

                this.auditJobDialogData = {
                    display: '',
                    canDownload: false,
                    isEdit: false,
                    maxRolePriority: this.formData.maxRolePriority,
                    audit: jobInfo,
                    anySelectedJobsRunning: false,
                    lastJobExeId: jobInfo.artefactId,
                };
                jobInfo.tasks = historyTasks;
                this.auditJob = [this.job];

                this.auditJobExecution = OmcFunc.diluteObject(jobHistoryData, [
                    'emServiceJobId',
                    'scheduledStartDate',
                    'logLevel',
                    'writeLimit',
                    'abortLimit',
                    'deferExecution',
                    'honourJobDependency',
                    'maxExecutionTimeMins',
                    'noOfParallelThreads',
                    'noOfSplits',
                    'noOfWorkers',
                    'failOnWarning',
                    'verifyDatasetChecksum',
                    'jobPriority',
                    'isExpireJobAfter',
                    'expireJobAfterDays',
                    'expireJobAfterHours',
                    'expireJobAfterMins',
                    'expireJobAfterSecs',
                    'failJobOnTaskFail',
                    'runJobUntilCancelExpired',
                    'holdJobUntilDate',
                    'alertOnJobFailure',
                    'alertOnJobCommencement',
                    'alertOnJobCompletion',
                    'jobRunSubscribers',
                    'distributionStrategyId',
                    'aggregateWorkerOutput',
                    'workerConfigurationName',
                    'runControlTable',
                    'runControlFilter',
                ]);

                Object.assign(this.auditJobExecution, {
                    jobExStartTime: startTime,
                    jobExEndTime: endTime,
                    jobExState: jobHistoryData.state,
                    jobExStartedBy: jobHistoryData.userId,
                    jobExTaskRecords: JobHistoryTaskRecords.makeFromJobTaskHistory(historyTasks),
                });

                let title = 'Job Run';
                this.omcDialogService.open('audit-run-job', { title: title });
            });
        }

        getHistoricalJobTasks(id) {
            let endPoint = 'getTasksExecutionHistoryById?id=' + id;
            return this.dataService
                .getData(endPoint, endPoint, true)
                .then((data) => {
                    if (!!data) {
                        let historyData = new JobTaskHistory(data);
                        console.log(historyData);
                        return historyData;
                    } else {
                        return {};
                    }
                })
                .catch((err) => {
                    throw err;
                });
        }

        /* TODO: Move this logic into a "Dependencies" component[TS 18/12/20] */
        initDependenciesMethods() {
            this.taskDependencies.changeTaskGroup = (isMoveUp) => {
                if (isMoveUp) {
                    this.taskDependencies.selectedTaskInDependencies.groupNo--;
                } else {
                    this.taskDependencies.selectedTaskInDependencies.groupNo++;
                }
                this.refreshDependenciesGridData();

                this.selectedGrid = 'grid-' + this.taskDependencies.selectedTaskInDependencies.groupNo;
                this.enableDisableUpDownButtons();
            };

            this.taskDependencies.removeSelectedTaskGroup = () => {
                var selectedTaskGroupNo = this.getSelectedGridId(); //this.taskDependencies.selectedTaskInDependencies.groupNo;
                if (this.job.taskDependencies.length > 0) {
                    var maxNumber = Math.max(...this.job.taskDependencies.map((e) => e.groupNo));
                    if (maxNumber === selectedTaskGroupNo) {
                        this.job.taskDependencies
                            .filter((e) => e.groupNo === selectedTaskGroupNo)
                            .forEach((task) => {
                                task.groupNo--;
                            });
                    } else {
                        this.job.taskDependencies
                            .filter((e) => e.groupNo > selectedTaskGroupNo)
                            .forEach((task) => {
                                task.groupNo--;
                            });
                    }
                }

                //filter all task where group is greater than this group, and increment it's group number;

                this.refreshDependenciesGridData();
                this.job.visibleTaskDependenciesGrids--;
                this.selectedGrid = 'grid-' + this.job.visibleTaskDependenciesGrids;
                this.enableDisableUpDownButtons();
            };

            this.taskDependencies.addTaskGroup = () => {
                if (this.job.visibleTaskDependenciesGrids < 3) {
                    this.job.visibleTaskDependenciesGrids++;
                }
                this.enableDisableUpDownButtons();
            };
        }

        gridClicked() {
            if (
                this.getSelectedGridId() !==
                (this.taskDependencies.selectedTaskInDependencies &&
                    this.taskDependencies.selectedTaskInDependencies.groupNo)
            ) {
                this.dependenciesOneGridApi.selection.clearSelectedRows();
                this.dependenciesTwoGridApi.selection.clearSelectedRows();
                this.dependenciesThreeGridApi.selection.clearSelectedRows();
                this.dependenciesSelectRows.splice(0, this.dependenciesSelectRows.length);

                this.taskDependencies.selectedTaskInDependencies = null;
            }

            this.enableDisableUpDownButtons();
        }

        showTab(display) {
            this.display = display;
            this.touched[display] = true;
            switch (display) {
                case 'tasks':
                    break;
                case 'dependencies':
                    this.refreshDependenciesGridData();
                    break;
                case 'audits':
                    break;
                default:
                    break;
            }
        }

        isInvalid(key) {
            if (!this.formData.isEdit) return false;
            this.updateJobChangedStatus();
            var valid = this.validate();
            this.formData.isValid = valid;
            if (key) {
                return this.errors[key];
            }
            return !valid;
        }
        linkTypeChange(linkType) {
            if (linkType.value === 1) {
                this.job.linkedJobId = undefined;
            }
        }
        disableLinkedJobSelection() {
            return !this.job.linkType || this.job.linkType < 2; // not selected or standalone
        }
        errorMessageFor() {
            this.validate();
            return this.errors;
        }

        isErrorCompleteDisabled() {
            return !this.formData.isEdit || !this.job.reportId || this.job.reportId <= 0;
        }

        reportChanged() {
            if (!this.job.reportId || this.job.reportId <= 0) {
                this.job.runReportOnSuccessOnly = false;
            }
        }

        // Note: none of this job priorities stuff is used, but I'm leaving it here in case
        //       it's needed one day - rfs 29.01.20
        initialiseJobPriorities() {
            this.jobPriorities = [];
            const jobPriorityL = { key: 0, value: 'Lowest' };
            const jobPriorityBN = { key: 1000, value: 'Below Normal' };
            const jobPriorityN = { key: 2000, value: 'Normal' };
            const jobPriorityAN = { key: 3000, value: 'Above Normal' };
            const jobPriorityH = { key: 4000, value: 'Highest' };
            const jobPriorityMap = {
                lowest: 0,
                belowNormal: 1000,
                normal: 2000,
                aboveNormal: 3000,
                highest: 4000,
            };

            if (this.formData.isEdit) {
                this.jobPriorities = [jobPriorityL, jobPriorityBN, jobPriorityN, jobPriorityAN, jobPriorityH].filter(
                    (e) => e.key <= this.formData.maxRolePriority
                );
                if (this.formData.maxRolePriority < this.job.priority || this.job.priority === undefined) {
                    this.job.priority = this.formData.maxRolePriority;
                }
            } else {
                this.jobPriorities = [jobPriorityL, jobPriorityBN, jobPriorityN, jobPriorityAN, jobPriorityH];
            }
        }

        validate() {
            this.errors = {};
            var rtn = true;

            if (!this.job.collections || this.job.collections.length === 0) {
                this.errors.collections = 'Please select at least one collection for this job.';
                rtn = false;
            }
            if (!this.job || !this.job.description) {
                this.errors.description = 'Please enter a job name';
                this.errors.jobDetails = 'Fields are missing';
                rtn = false;
            }

            if (this.job && this.formData.usedNames.includes(this.job.description)) {
                this.errors.description = 'Name is already in use, please use a different name';
                this.errors.jobDetails = 'Fields are missing';
                rtn = false;
            }

            if (!this.job || !OmcFunc.isSet(this.job.jobTemplateId)) {
                this.errors.jobTemplate = 'Please specify a job template';
                this.errors.jobDetails = 'Fields are missing';
                rtn = false;
            }
            if (!this.job.tasks || this.job.tasks.length === 0) {
                this.errors.tasks = 'Please add at least one task';
                rtn = false;
            }
            return rtn;
        }

        /** resets the change detection and stored job for comparison */
        storeLoadedJob(job = null) {
            let hasChanged = this.formData.hasChanged;
            job = job || this.job;
            this.loadedJob = angular.copy(job);

            this.updateTasks(this.loadedJob.tasks);
            if (OmcFunc.isSet(this.loadedJob.tasks, true)) {
                this.selectTaskById(this.loadedJob.tasks[0].id);
            }
            this.formData.hasChanged = hasChanged;
        }

        updateTasks(tasks) {
            var taskNo = 1;
            this.job.tasks = tasks;
            this.job.tasks.forEach((element) => {
                element.sNo = taskNo++; // if this number reflects the execution order it should be stored, not generated [TS 18/05/20]
            });
            this.gridHelperTasksData.data = this.job.tasks;
        }

        updateJobChangedStatus() {
            // Only check if job hasn't changed already
            if (this.loadedJob && !this.formData.hasChanged) {
                var updatedJob = this.job;
                var primitives = [
                    'description',
                    'jobTemplateId',
                    'jobTypeId',
                    'notes',
                    'reportId',
                    'runReportOnSuccessOnly',
                ];
                var arrays = {
                    taskDependencies: ['id', 'groupNo', 'taskId'], // compare Object.taskDependencies by 'id','groupNo', and 'taskId'
                    tasks: ['id'],
                    collections: ['id'],
                };

                if (this.formHelperService.haveTheseObjectsChanged(this.loadedJob, updatedJob, primitives, arrays)) {
                    this.formData.hasChanged = true;
                }
            }
        }

        taskRowClick(row, $event) {
            if ($event && ($event.fromRowChanged || $event.ctrlKey)) {
                return;
            }
            this.selectTaskById(row.entity.id);
        }

        selectTaskById(id) {
            let task = this.job.tasks.find((task) => task.id === id);
            if (task) {
                this.selectedTask = task;
                if (this.taskGridApi) {
                    let rowEntityIndex = this.gridHelperTasksData.data.findIndex((task) => task.id === id);
                    this.taskGridApi.selection.selectRow(this.gridHelperTasksData.data[rowEntityIndex]);
                }
            }
            console.log('Selected task: ', this.selectedTask);
        }

        /* TODO: Move this logic into a "Dependencies" component[TS 18/12/20] */

        /* TODO: Move this logic into a "Dependencies" component[TS 18/12/20] */
        refreshDependenciesGridData() {
            this.gridHelperDependenciesOneData.data = this.job.taskDependencies.filter((e) => e.groupNo === 1);
            this.gridHelperDependenciesTwoData.data = this.job.taskDependencies.filter((e) => e.groupNo === 2);
            this.gridHelperDependenciesThreeData.data = this.job.taskDependencies.filter((e) => e.groupNo === 3);
            this.dependenciesOneGridApi.core.refresh();
            this.dependenciesTwoGridApi.core.refresh();
            this.dependenciesThreeGridApi.core.refresh();
            this.enableDisableUpDownButtons();
        }

        getSelectedGridId() {
            if (this.selectedGrid) {
                var selectedGrid = this.selectedGrid;
                var splitString = selectedGrid.split('-');
                return parseInt(splitString[1], 10);
            }
        }

        refreshTaskGrid() {
            if (this && this.model && this.formData.isAdd) {
                this.gridHelperTasksData.columnDefs.filter((x) => x.name === 'id')[0].sort = {
                    sortPriority: 1,
                    direction: 'asc',
                };
            }
        }

        initTasksGrid() {
            this.gridHelperTasksData = this.gridSettingsService.defaultGridOptions();
            this.gridHelperTasksData.enableSorting = false;
            this.gridHelperTasksData.enableColumnMenus = false;
            this.gridHelperTasksData.onRegisterApi = (api) => {
                this.taskGridApi = api;
                this.taskGridApi.selection.setMultiSelect(false);
                this.taskGridApi.selection.on.rowSelectionChanged(null, this.taskRowClick.bind(this));
                this.taskGridApi.core.on.rowsRendered(null, () => {
                    if (this.selectedTask) {
                        this.selectTaskById(this.selectedTask.id);
                    }
                    //gridSettingsService.setScrollBar($('#tasks-grid'), this.gridHelperTasksData);
                });
            };
            this.gridHelperTasksData.appScopeProvider = this;
            this.gridHelperTasksData.columnDefs = [
                {
                    name: 'id',
                    displayName: '',
                    visible: false,
                },
                {
                    name: 'sNo',
                    displayName: '#',
                    width: this.gridSettingsService.columnWidths.edit,
                    //sort: { sortPriority: 1, direction: 'asc' }
                },
                {
                    name: 'name',
                    displayName: 'Task Name',
                    width: this.gridSettingsService.columnWidths.default,
                },
                {
                    name: 'taskType.name',
                    displayName: 'Task Type',
                    width: this.gridSettingsService.columnWidths.default,
                },
            ];
        }

        initDependenciesGrid() {
            var enableSorting = false;
            var enableColumnsMenus = false;
            var taskDependencyColumns = [
                {
                    name: 'sNo',
                    displayName: '#',
                    width: this.gridSettingsService.columnWidths.edit,
                    //sort: { sortPriority: 1, direction: 'asc' }
                },
                {
                    name: 'taskName',
                    displayName: 'Task Name',
                    width: this.gridSettingsService.columnWidths.default,
                },
                {
                    name: 'taskType',
                    displayName: 'Task Type',
                    width: this.gridSettingsService.columnWidths.default,
                },
            ];

            this.gridHelperDependenciesOneData = this.gridSettingsService.defaultGridOptions();
            this.gridHelperDependenciesOneData.enableSorting = enableSorting;
            this.gridHelperDependenciesOneData.enableColumnMenus = enableColumnsMenus;
            this.gridHelperDependenciesOneData.onRegisterApi = (api) => {
                $(window).off('resize');
                this.dependenciesOneGridApi = api;
                this.dependenciesOneGridApi.selection.on.rowSelectionChanged(null, (row, $event) => {
                    if ($event && ($event.fromRowChanged || $event.ctrlKey)) {
                        return;
                    }
                    this.dependenciesOneGridApi.selection.clearSelectedRows();
                    this.dependenciesTwoGridApi.selection.clearSelectedRows();
                    this.dependenciesThreeGridApi.selection.clearSelectedRows();
                    this.dependenciesSelectRows.splice(0, this.dependenciesSelectRows.length);
                    this.dependenciesSelectRows.push(row.entity);
                    this.dependenciesOneGridApi.selection.selectRow(row.entity, { fromRowChanged: true });
                    this.taskDependencies.selectedTaskInDependencies = this.job.taskDependencies.filter(
                        (e) => e.id === row.entity.id
                    )[0];
                    this.selectedGrid = 'one';

                    this.enableDisableUpDownButtons();
                });
            };
            this.gridHelperDependenciesOneData.appScopeProvider = this;
            this.gridHelperDependenciesOneData.columnDefs = taskDependencyColumns;

            this.gridHelperDependenciesTwoData = this.gridSettingsService.defaultGridOptions();
            this.gridHelperDependenciesTwoData.enableSorting = enableSorting;
            this.gridHelperDependenciesTwoData.enableColumnMenus = enableColumnsMenus;
            this.gridHelperDependenciesTwoData.onRegisterApi = (api) => {
                $(window).off('resize');
                this.dependenciesTwoGridApi = api;
                this.dependenciesTwoGridApi.selection.on.rowSelectionChanged(null, (row, $event) => {
                    if ($event && ($event.fromRowChanged || $event.ctrlKey)) {
                        return;
                    }

                    this.dependenciesOneGridApi.selection.clearSelectedRows();
                    this.dependenciesTwoGridApi.selection.clearSelectedRows();
                    this.dependenciesThreeGridApi.selection.clearSelectedRows();
                    this.dependenciesSelectRows.splice(0, this.dependenciesSelectRows.length);
                    this.dependenciesSelectRows.push(row.entity);
                    this.dependenciesTwoGridApi.selection.selectRow(row.entity, { fromRowChanged: true });
                    this.taskDependencies.selectedTaskInDependencies = this.job.taskDependencies.filter(
                        (e) => e.id === row.entity.id
                    )[0];
                    this.selectedGrid = 'two';

                    this.enableDisableUpDownButtons();
                });
            };
            this.gridHelperDependenciesTwoData.appScopeProvider = this;
            this.gridHelperDependenciesTwoData.columnDefs = taskDependencyColumns;

            this.gridHelperDependenciesThreeData = this.gridSettingsService.defaultGridOptions();
            this.gridHelperDependenciesThreeData.enableSorting = enableSorting;
            this.gridHelperDependenciesThreeData.enableColumnMenus = enableColumnsMenus;
            this.gridHelperDependenciesThreeData.onRegisterApi = (api) => {
                $(window).off('resize');
                this.dependenciesThreeGridApi = api;
                this.dependenciesThreeGridApi.selection.on.rowSelectionChanged(null, (row, $event) => {
                    if ($event && ($event.fromRowChanged || $event.ctrlKey)) {
                        return;
                    }
                    this.dependenciesOneGridApi.selection.clearSelectedRows();
                    this.dependenciesTwoGridApi.selection.clearSelectedRows();
                    this.dependenciesThreeGridApi.selection.clearSelectedRows();
                    this.dependenciesSelectRows.splice(0, this.dependenciesSelectRows.length);
                    this.dependenciesSelectRows.push(row.entity);
                    this.dependenciesThreeGridApi.selection.selectRow(row.entity, { fromRowChanged: true });
                    this.taskDependencies.selectedTaskInDependencies = this.job.taskDependencies.filter(
                        (e) => e.id === row.entity.id
                    )[0];
                    this.selectedGrid = 'three';
                    this.enableDisableUpDownButtons();
                });
            };
            this.gridHelperDependenciesThreeData.appScopeProvider = this;
            this.gridHelperDependenciesThreeData.columnDefs = taskDependencyColumns;
        }

        /* TODO: Move this logic into a "Dependencies" component[TS 18/12/20] */
        enableDisableUpDownButtons() {
            if (!this.formData.isEdit) {
                this.isUpEnabled = false;
                this.isDownEnabled = false;
                this.canRemoveGroup = false;
                this.canAddGroup = false;
                return;
            }
            if (this.taskDependencies && this.taskDependencies.selectedTaskInDependencies) {
                var selectedGroupNo = this.taskDependencies.selectedTaskInDependencies.groupNo;
                var maxNumber = this.job.visibleTaskDependenciesGrids; //Math.max(...this.job.taskDependencies.map(e => e.groupNo));
                var minNumber = 1; //Math.min(...this.job.taskDependencies.map(e => e.groupNo));

                if (selectedGroupNo === minNumber) {
                    this.isUpEnabled = false;
                } else {
                    this.isUpEnabled = true;
                }
                if (selectedGroupNo === maxNumber) {
                    this.isDownEnabled = false;
                } else {
                    this.isDownEnabled = true;
                }
                //this.job.taskDependencies.filter(e => e.groupNo === this.taskDependencies.selectedTaskInDependencies.groupNo).length
                if (this.job.visibleTaskDependenciesGrids === 1) {
                    this.isUpEnabled = false;
                    this.isDownEnabled = false;
                }
            } else {
                this.isUpEnabled = false;
                this.isDownEnabled = false;
            }

            var groupNo = this.getSelectedGridId();

            if (groupNo && this.job.taskDependencies.filter((e) => e.groupNo === groupNo).length <= 1 && groupNo > 1) {
                this.canRemoveGroup = true;
            } else {
                this.canRemoveGroup = false;
            }

            if (this.job.visibleTaskDependenciesGrids < 3) {
                this.canAddGroup = true;
            } else {
                this.canAddGroup = false;
            }
        }

        taskSelectedAndEnabled() {
            return this.selectedTask && this.selectedTask.isEnabled;
        }
        canEditSelectedTask() {
            return this.formData.isEdit && this.taskSelectedAndEnabled();
        }
    },
};

angular.module('omc').component(editJobComponent.selector, editJobComponent);
