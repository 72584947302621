import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import * as angular from 'angular';
import { DataService } from './data.service';

@Injectable({
    providedIn: 'root'
  })
export class TaskTypeService {
    
    taskTypes: Array<any>;

    constructor(
        private dataService: DataService) {}

    taskTypeApiCall(force, globalSpinner = true) {
        return this.dataService.getData('taskTypes', 'taskTypes', force, globalSpinner);
    }

    getTaskTypes(force, globalSpinner = true) {
        return this.taskTypeApiCall(force, globalSpinner).then((data: string) => {
            return JSON.parse(data).filter((x) => x.key > 0);
        });
    }

    defaultTaskTypes(force) {
        return this.taskTypeApiCall(force).then((data: string) => {
            this.taskTypes = JSON.parse(data)
            return this.taskTypes;
        });
    }

    getTaskTypeValueById(id) {
        return this.taskTypes.filter((e) => e.key === id).map((m) => m.value)[0];
    }
}

angular
    .module('omc')
    .factory('taskTypeService', downgradeInjectable(TaskTypeService));
