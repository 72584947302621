const salIcons = require('sal-icons')
const allIcons = salIcons.completeIconSet

angular
    .module('omc')
    .run(function (serverEventsService, $rootScope, currentUserService, redirectionService, modalDialogService, pageFiltersService, IconRegistryService) {

        IconRegistryService.registerIcons(allIcons)

        console.log('module Run')
        currentUserService.getCurrentUser().then(function (user) {
            /* TODO: when gettingUser handle 401 (Unauthorized) error - user has never logged in [TM 13/07/22] */
            if (user) {
                serverEventsService.initialise();
                $rootScope.$on('$stateChangeStart', function (event, toState, toParams, fromState, fromParams) {
                    if (toState.requiresPermission) {
                        currentUserService.ensurePermission(toState.permissionName || toState.name, toState.requiresPermission);
                    }
                });
                pageFiltersService.setForceDataRefreshRequired(true);
            } else {
                redirectionService.redirectToLogin('default');
            }
        });

        $rootScope.$on('$locationChangeSuccess', () => modalDialogService.hide());

        $rootScope.$on('gridSettingsChanged', function (settings) {
            (<any>window).omc && (<any>window).omc.currentVm && (<any>window).omc.currentVm.refresh();
        });
    });