import * as angular from 'angular'
import { OmcComponent } from 'shared/models/omc.types'

let advancedPageFiltersComponent: OmcComponent = {
    selector: `advancedPageFiltersComponent`,
    template: require("./advanced-page-filters.component.html"),
    bindings: {
        filters: "="
    },
    controller: class AdvancedPageFiltersController implements angular.IController {
        filters: any;
        readonly userListEndpoint = 'auditUsers';

        constructor(
            private currentUserService,
        ) {}

        $onInit() {}

        setDefaultUser() {
            this.filters.owner = this.filters.createdBy ? this.currentUserService.user : null;
        }
    }
}

angular
    .module('omc')
    .component(advancedPageFiltersComponent.selector, advancedPageFiltersComponent)