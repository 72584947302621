export class OmcFunc {
    constructor() {}
    static diluteObject(obj, arr = []) {
        let returnObj = {};
        let err = '';
        if (obj && arr && Array.isArray(arr) && arr.every((x) => typeof x === 'string')) {
            arr.forEach((prop) => {
                if (obj.hasOwnProperty(prop)) {
                    returnObj[prop] = obj[prop];
                } else {
                    console.error(`Object does not have property named '${prop}'`);
                }
            });
            return returnObj;
        } else {
            err = '';
            err += 'Could not dilute object';
            if (typeof obj !== 'object') {
                err += '\nFirst parameter must be a valid object';
            } else {
                err += '\nSecond parameter must be a valid array of strings';
            }
        }
        console.log(err);
        return obj;
    }

    /**
     * Return a deep value in an object or return first undefined/null value
     * @param {Object} obj The base object
     * @param {Array<string>} props array of properties in order of lookup
     */
    static deepVal(obj, props = []) {
        if (!obj || !props || !Array.isArray(props) || props.length === 0) return obj;
        let firstProp = props.splice(0, 1);
        return this.deepVal(obj[firstProp[0]], props);
    }

    static limitDecimals(n, dec = 2) {
        return Math.floor(n * Math.pow(10, dec)) / Math.pow(10, dec);
    }

    static isSet(obj, notEmpty = false) {
        if (obj === undefined || obj === null) {
            return false;
        }
        if (notEmpty) {
            switch (typeof obj) {
                case 'number':
                    return !Number.isNaN(obj);
                case 'object':
                    if (Array.isArray(obj)) {
                        return obj.length > 0;
                    }
                    return Object.values(obj).length > 0;
                case 'string':
                    return obj.trim() !== '';
                default:
                    return true;
            }
        }
        return true;
    }

    static formatBytes(bytes, decimals = 2) {
        if (bytes === 0) return '0 Bytes';
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return this.limitDecimals(bytes / Math.pow(k, i), dm) + ' ' + sizes[i];
    }

    static renameCopy(name, allNames = []) {
        let newName = name + ' - copy';
        let i = 1,
            unique = false;
        while (!unique && i < 100) {
            if (allNames.includes(newName)) {
                newName = name + ` - copy(${i})`;
            } else {
                break;
            }
            i++;
        }
        return newName;
    }

    static newGuid() {
        return ([1e7].toString() + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
            (Number(c) ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (Number(c) / 4)))).toString(16)
        );
    }

    static sortByName(a, b) {
        let nameA = a.name.toUpperCase(); // ignore upper and lowercase
        let nameB = b.name.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0;
    }

    static parseArray(string) {
        let arrayRegex = /^\[.*\]$/;
        let apostrophesRegex = /\'/g;
        let rtnArray = [];
        if (arrayRegex.test(string)) {
            let stringArray = string.replace(apostrophesRegex, '"');
            rtnArray = JSON.parse(stringArray);
        } else {
            rtnArray = [string];
        }
        return rtnArray;
    }
}
