import { Inject, Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { RootScope, StateService, UiGridConstants } from '../../ajs-upgraded-providers';
import * as angular from 'angular';
import { ApiService } from './api.service';
import { DataService } from './data.service';
import { DefaultItemService } from './default-item.service';
import { RedirectionService } from './redirection.service';
import { UserCookieService } from './user-cookie.service';
import { MessagingService } from './messaging.service';
import { UiActionsService } from './ui-actions.service';
import { OmcGlobalsService } from './omc-globals.service';

@Injectable()
export class CurrentUserService {
    reloadOnLogin = true;
    user = this.getuser();

    constructor(
        private apiService: ApiService,
        private dataService: DataService,
        private redirectionService: RedirectionService,
        private defaultItemService: DefaultItemService,
        private userCookieService: UserCookieService,
        private messagingService: MessagingService,
        private uiActionsService: UiActionsService,
        private omcGlobalsService: OmcGlobalsService,
        @Inject(UiGridConstants) private uiGridConstants,
        @Inject(RootScope) private rootScope,
        @Inject(StateService) private state
    ) {
        this.initServerEvents();
    }

    initServerEvents() {
        this.rootScope.$on('serverEvent:UserServerEvent', (event, payload) => {
            if (this.user && payload.data.id === this.user.id) {
                this.messagingService.displayMessage('Your user account has been modified. Please log back in.');
                this.logOut();
            }
        });

        this.rootScope.$on('serverEvent:UserPermissionsServerEvent', (event, payload) => {
            if (this.user && payload.data.id === this.user.id) {
                this.uiActionsService.uiActions(true).then(() => {
                    this.messagingService.displayMessage('Your permissions have been updated.');
                    this.getCurrentUser(true).then(() => {
                        this.defaultItemService.refreshDefaults(true, 'permissions').then(() => {
                            this.rootScope.$emit('userSettingsUpdated', 'activeCompanyId');
                            this.state.go(this.state.current, {}, { reload: true });
                        });
                    });
                });
            }
        });
    }

    getuser() {
        return (<any>window).omc.user;
    }

    getUserEmail() {
        return this.userCookieService.getUserEmail();
    }

    getCurrentUser(force, globalSpinner = true) {
        return new Promise((resolve) => {
            if (force || !this.user) {
                this.dataService.getData('currentUser', 'currentUser', force, globalSpinner).then(
                    (user) => {
                        if (!user) {
                            this.clientLogOut();
                        }
                        this.user = user;
                        this.user.profilePicture = 'data:image/png;base64,' + this.user.ProfilePictureBase64;
                        this.setDefaultSortOrder(user);
                        resolve(user);
                    },
                    (err) => {
                        if (err.status === 401) {
                            console.log('No user is logged in');
                        } else {
                            console.warn(err);
                        }
                    }
                );
            } else {
                this.setDefaultSortOrder(this.user);
                resolve(this.user);
            }
        });
    }

    setUserSettings(settings, globalSpinner = false) {
        return this.dataService.putData(settings, 'setUserSettings', 'setUserSettings', false, globalSpinner);
    }

    getUserSettings(force, globalSpinner = true) {
        return new Promise((resolve) => {
            this.getCurrentUser(force, globalSpinner).then((user: { userSettings: any }) => {
                resolve(user.userSettings);
            });
        });
    }

    //sets the sort order for the user
    setDefaultSortOrder(user) {
        if (user.userSettings) {
            let defaultSortOrder = this.omcGlobalsService.keyForValue(
                'gridSortOrders',
                user.userSettings.defaultGridSortOrder
            );
            switch (defaultSortOrder) {
                case 'DateCreatedAscending':
                    user.userSettings.defaultSortColumn = 'dateCreated';
                    user.userSettings.defaultSortDirection = this.uiGridConstants.ASC;

                    break;
                case 'DateCreatedDescending':
                    user.userSettings.defaultSortColumn = 'dateCreated';
                    user.userSettings.defaultSortDirection = this.uiGridConstants.DESC;
                    break;
                case 'NameAscending':
                    user.userSettings.defaultSortColumn = 'name';
                    user.userSettings.defaultSortDirection = this.uiGridConstants.ASC;

                    break;
                case 'NameDescending':
                    user.userSettings.defaultSortColumn = 'name';
                    user.userSettings.defaultSortDirection = this.uiGridConstants.DESC;
                    break;
                default:
                    throw 'Unexpected Value for user.userSettings.defaultGridSortOrder: ' + defaultSortOrder;
            }
        }
    }

    logIn(user) {
        let rememberMe = user.rememberMe;
        return this.apiService.post('logIn', user, this.failedLogin.bind(this), true).then((response) => {
            if (response === 'ResetPassword') {
                this.state.go('resetPassword', { t: user.userName });
                this.redirectionService.redirectToLogin('home');
                return;
            }
            return this.logUserIn(rememberMe, response);
        });
    }

    failedLogin(response) {
        const message = (response.data && response.data.message) || 'Failed to log in. ';
        this.messagingService.displayMessage(message, '', this.messagingService.messageLevels.error);
    }

    logOut() {
        this.apiService.post('logOut', null, this.clientLogOut.bind(this)).then(this.clientLogOut.bind(this));
    }

    clientLogOut() {
        this.user = {};
        window.onpopstate = () => {
            let state = this.state.current.name;

            if (state !== 'login' && !this.userCookieService.userCookieExists()) {
                this.state.go('login', { next: state });
                $('body').removeClass('theme-logged-background-color');
                $('body').addClass('theme-login-background-color');
            }
        };

        this.userCookieService.deleteUserCookie();
        this.dataService.resetCache();
        this.redirectionService.redirectToLogin('home');
    }

    verifyPasswordToken(token) {
        return new Promise((resolve, reject) => {
            this.apiService.get('verifyPasswordToken?token=' + token).then(
                (response) => {
                    resolve(response);
                },
                (reason) => {
                    reject(reason);
                }
            );
        });
    }

    forgetPassword(user) {
        return new Promise((resolve, reject) => {
            let webDomain = window.location.origin;
            console.log(webDomain);
            user.url = webDomain;
            this.apiService.post('forgetPassword', user).then(
                (response) => {
                    resolve(response);
                },
                (reason) => {
                    reject(reason);
                }
            );
        });
    }

    resetPassword(token) {
        return new Promise((resolve, reject) => {
            this.apiService.post('resetPassword', token).then(
                (response) => {
                    if (response === 'ResetSuccessful') {
                        return this.logUserIn(true, response);
                    }
                    resolve(response);
                },
                (reason) => {
                    reject(reason);
                }
            );
        });
    }

    logUserIn(rememberMe, response) {
        this.dataService.resetCache();
        return this.getCurrentUser(true).then((user: { userName: any }) => {
            this.user = user;
            let next = this.state.params.next || 'default';
            if (next === 'login') {
                next = 'default';
            }
            if (rememberMe) {
                this.userCookieService.rememberUser(user.userName);
            } else {
                this.userCookieService.forgetUser();
            }
            window.onpopstate = () => {};
            if (this.reloadOnLogin) {
                if (next === 'default') {
                    // we've got rid of default route - now have home instead.
                    next = 'home';
                }
                let paramObj = null;
                if (this.state.params.id) {
                    paramObj = { id: +this.state.params.id };
                }

                let url = this.state.href(next, paramObj, { absolute: true });
                window.location = url;
            } else {
                this.state.go(next);
            }
            return response;
        });
    }

    ensurePermission(routeName, failIfDisabled, forceReloadPerms) {
        return new Promise((resolve, reject) => {
            this.uiActionsService
                .getPermissionsForRoute(routeName, forceReloadPerms)
                .then((perm: { disabled?: any }) => {
                    if (!perm || (failIfDisabled && perm.disabled)) {
                        this.failedPermissionCheck();
                    }
                    resolve(perm);
                });
        });
    }

    failedPermissionCheck() {
        this.messagingService.displayMessage(
            'You do not have permission to perform the requested action',
            '',
            this.messagingService.messageLevels.warning
        );
        this.redirectionService.redirectToDefault();
    }

    updateApplicationTheme() {
        this.getUserSettings(true).then((userSettings: { applicationTheme: any }) => {
            let theme = 'light';
            if (
                userSettings.applicationTheme !== undefined &&
                userSettings.applicationTheme !== null &&
                userSettings.applicationTheme !== ''
            ) {
                theme = userSettings.applicationTheme;
            }
            let themeClass = 'sal-' + theme + '-theme';
            $('body').attr('class', 'active ' + themeClass);
        });
    }
}
angular.module('omc').factory('currentUserService', downgradeInjectable(CurrentUserService));
