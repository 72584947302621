import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import * as angular from 'angular';
import { CollectionsService } from '../../services/collections.service';
import { pathToApp } from '../../../core/constants/global-constants';
import { CollectionView } from '../../models/collection.model';

@Component({
    selector: `collectionSelectorComponent`,
    templateUrl: pathToApp + 'shared/components/collection-selector/collection-selector.component.html',
})
export class CollectionSelectorComponent implements OnInit {
    collections: any[] = [];
    @Input() selected: any[]; // array of object with ids {id: string}[]
    @Output() selectedChange = new EventEmitter<any[]>();
    @Input() disabledCollections: boolean; // Array of numbers (ids to disable) or boolean (to disable all)
    @Input() invalid: boolean; // to mark the border around selector in red
    @Input() setDefault: boolean; //to set default collection automatically

    constructor(private collectionsService: CollectionsService) {}

    ngOnInit() {
        this.getCollections();
    }
    
    getCollections() {
        this.collectionsService.getCollections(true, false).then((collections: Array<any>) => {
            this.collections = collections.map((col) => new CollectionView(col));
            console.log(`Got collections`, this.collections);
            if (this.setDefault === true) {
                let defaults = this.collections.filter((x) => x.isDefault === true);
                this.selected = defaults;
                // emit the collections array to the parent after default has been set so that it's validated correctly
                this.selectedChange.emit(this.selected);
            }
        });
    }

    isSelected(col) {
        let selected = this.indexOfCol(col) > -1;
        return selected;
    }
    
    toggleCollection(col) {
        if (this.isSelected(col)) {
            let i = this.indexOfCol(col);
            this.selected.splice(i, 1);
        } else {
            this.selected.push(col);
        }
        this.selectedChange.emit(this.selected);
    }
    isDisabled(col) {
        return (
            col.disabled ||
            this.disabledCollections === true ||
            (Array.isArray(this.disabledCollections) && this.disabledCollections.includes(col.id))
        );
    }

    indexOfCol(col) {
        return (this.selected || []).findIndex((c) => c.id === col.id);
    }
}

angular.module('omc').directive(
    'collectionSelectorComponent',
    downgradeComponent({
        component: CollectionSelectorComponent,
        inputs: ['selected', 'disabled', 'invalid', 'setDefault'],
        outputs: ['selectedChange']
    })
);
