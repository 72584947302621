import { ArtefactDialogData } from "../../shared/models/dialog-data.model";
import * as angular from 'angular';
import { OmcComponent } from "../../shared/models/omc.types";

let taskTypesComponent: OmcComponent = {
    selector: 'taskTypesComponent',
    template: require('./task-types.component.html'),
    controller: class taskTypesController implements angular.IController {
        public dialogService;
        public lang;
        public gridHelper;
        public taskTypes;
        public taskTypeDialogData;
        public selectedTaskType;
        public dataConnections;

        constructor(
            private gridHelperServiceFactory,
            private gridSettingsService,
            private dataService,
            private $rootScope,
            omcDialogService,
            language,
        ) {
            this.dialogService = omcDialogService;
            this.lang = language
        }

        $onInit() {
            this.initGrid()
            this.getTaskTypes(true);
            this.$rootScope.$on("serverEvent:TaskTypeServerEvent", (event, payload) =>{
                if (payload.forceRefresh) {
                    this.getTaskTypes(true);
                }
                else {
                    let taskType = payload.data;
                    let modifiedTaskType = taskType;

                    const idx = this.taskTypes.findIndex((x) => { return x.id === taskType.id; });
                    if (idx > -1) {
                        this.taskTypes[idx] = modifiedTaskType;
                    }
                    else {
                        this.dataConnections.push(modifiedTaskType);
                    }
                    this.gridHelper.dataReloaded(this.taskTypes);
                }
            });
        }

        initGrid() {
            let vm = this;
            this.gridHelper = this.gridHelperServiceFactory.gridHelper('taskTypesController', '#taskTypes-grid', vm, this.columnDefs());
            this.gridHelper.gridData.gridId = 'task-types-grid';
            this.gridSettingsService.initialiseGridSettings({
                id: vm.gridHelper.gridData.gridId,
                name: 'TaskTypes-Grid',
                columnsInfo: []
            });
        }
        editsDisabled() {
            return !this.gridHelper.selectedValues() || this.gridHelper.selectedValues().length <= 0;
        };

        editSelected(mode, taskType) {
            var selectedTaskType = taskType || this.gridHelper.selectedValues()[0];
            this.selectedTaskType = angular.copy(selectedTaskType)
            this.taskTypeDialogData = new ArtefactDialogData({
                isEdit: true
            })
            this.dialogService.open('edit-task-type', { title: 'Edit Task Type' })
        };

        viewSelected(taskType) {
            var selectedTaskType = taskType || this.gridHelper.selectedValues()[0];
            this.selectedTaskType = angular.copy(selectedTaskType)
            this.taskTypeDialogData = new ArtefactDialogData()
            this.dialogService.open('edit-task-type', { title: 'Edit Task Type' })
        };

        setDefault() {
            this.gridHelper.selectedValues().length && this.gridHelper.selectedValues()[0].setAppDefault();
            this.getTaskTypes(true);
        };

        getTaskTypes(force) {
            this.dataService.getData('taskType', 'taskType', force, false).then((taskTypes) => {
                this.taskTypes = taskTypes;
                this.gridHelper.dataReloaded(this.taskTypes);
            });
        }

        saveTaskType() {
            let url = 'taskType'
            this.dataService.postData(this.selectedTaskType, url, url, true, false).then((taskTypes) => {
                this.taskTypes = taskTypes;
                this.gridHelper.dataReloaded(this.taskTypes);
                this.dialogService.close('edit-task-type')
            });
        }

        columnDefs() {
            return [
                {
                    name: 'id',
                    displayName: '',
                    cellTemplate: '<div class="grid-row-vertical-bar" ng-click="grid.appScope.gridHelper.toggleSelected(row.entity, $event)" ng-class="{\'grid-row-selected\': grid.appScope.gridRowSelected(row.entity) }" role="button" tabindex="0"></div>',
                    width: this.gridSettingsService.columnWidths.toggleSelect,
                    pinnedLeft: true,
                    cellClass: "omc-select-column",
                    //sort: { sortPriority: 1, direction: 'asc' }
                },
                {
                    name: 'name',
                    displayName: 'Name',
                    cellTemplate: `
                                <div class="ui-grid-cell-contents" ng-class="{'default-icon': row.entity.isAppDefault} ">
                                    <a href="#" ng-click="grid.appScope.viewSelected(row.entity); $event.preventDefault();">{{row.entity.name}}</a>
                                </div>
                            `,
                    width: this.gridSettingsService.columnWidths.default
                },
                {
                    name: 'description',
                    displayName: 'Description',
                    width: this.gridSettingsService.columnWidths.notes
                },
                {
                    name: "isDisabled",
                    displayName: "Status",
                    cellTemplate: `
                                <div class="ui-grid-cell-contents">
                                    {{row.entity.isDisabled? "Suspended": "Active"}}
                                </div>
                            `,
                    width: this.gridSettingsService.columnWidths.default
                }
            ];
        }
    }
}

angular
    .module('omc')
    .component(taskTypesComponent.selector, taskTypesComponent)
