import * as angular from 'angular'
import { OmcComponent } from 'shared/models/omc.types'


let editTaskComponent: OmcComponent = {
    selector: `editTaskComponent`,
    template: require("./edit-task.component.html"),
    bindings: {
        taskModel: '=',
        formData: '=',
    },
    controller: class EditTaskController implements angular.IController {
        lang: any;
        usedByGridHelper: any;
        auditPermission: boolean = true;
        usedByGridData: any;
        gridRowSelected: any;
        auditGridData: any;
        taskTypes: any[] = [];
        isCommandLine: boolean = false;
        selectedTaskType: 'command-line' | 'stored-procedure' | 'other';
        taskModel: any;
        loaded: boolean;
        formData: any;
        display: any;
        modelFile: any;
        parallelSettingsExist: any;
        isMultiThreaded: any;
        usedByGridApi: any;
        auditGridApi: any;
        errors: any;
        readonly userListEndpoint = 'taskUsers';


        constructor(
            private modelsService,
            private taskTypeService,
            private gridHelperServiceFactory,
            private gridSettingsService,
            private auditService,
            private uiActionsService,
            private dataService,
            private usedByService,
            private omcDialogService,
            language,
        ) {
            this.lang = language;
        }


        $onInit() {
            this.initUsedByGrid()
            this.initAuditGrid();

            this.uiActionsService.getPermissionsForName('admin-view-audit', true).then((perm) => {
                this.auditPermission = perm && !perm.disabled;
            });
        }
        
        $postLink(){
            this.loaded = !!this.formData && !!this.taskModel
            if (!this.loaded) return;

            this.getTaskTypes();

            if (this.taskModel.mergeTableData === "true") {
                this.taskModel.MergeTable = true;
            } else {
                this.taskModel.MergeTable = false;
            };

            if ((!this.taskModel.projectNotes || this.taskModel.projectNotes === "") && this.taskModel.notes !== "") {
                this.taskModel.projectNotes = this.taskModel.notes;
            }
            this.display = this.formData.display || '';

            // this.omcDialogService.onCloseDialog(this.formData.dialogId, () => {
                // used to rely on this for cancelling file upload. Now delegated to this.$onDestroy()
                // leaving in case of error
            // })

            this.usedByGridData.data = this.taskModel.usedBy;
            this.parallelSettingsExist = this.taskModel?.taskParallelSettings?.isMultithreaded || this.taskModel?.taskParallelSettings?.isParallel;
            this.isMultiThreaded = this.parallelSettingsExist || this.taskModel?.taskParallelSettings?.isSequentialStartUp

            if (!this.taskModel.effectiveDate){
                this.taskModel.effectiveDate = new Date();
            }
        }

        $onDestroy(){
            if (this.modelFile) {
                this.cancelFileUpload()   
            }
        }
        initUsedByGrid(){
            this.usedByGridHelper = this.gridHelperServiceFactory.gridHelper('tasksUsedBy');
            this.usedByGridData = this.gridSettingsService.defaultGridOptions();
            this.usedByGridData.onRegisterApi = (api) => {
                this.usedByGridApi = api;
            }
            this.usedByGridData.appScopeProvider = this;
            this.gridRowSelected = this.usedByGridHelper.gridRowSelected;
            this.usedByGridData.columnDefs = this.usedByService.usedByColumnDefs();
        }

        initAuditGrid(){
            this.auditGridData = this.gridSettingsService.defaultGridOptions();
            this.auditGridData.onRegisterApi = (api) => {
                this.auditGridApi = api;
            };
            this.auditGridData.appScopeProvider = this;
            this.auditGridData.columnDefs = this.auditService.auditLogColumnDefs();
        }

        getTaskTypes(){
            this.taskTypeService.getTaskTypes(true).then((data) => {
                this.taskTypes = data;
                if (!this.taskModel.taskTypeId || this.taskModel.taskTypeId === 0) {
                    this.taskModel.taskTypeId = 1;
                }
                this.TaskTypeChanged();
            });
        }
        showTab(display) {
            this.display = display;
            console.log('taskModel: ',this.taskModel)
            switch(this.display){
                case 'audits':
                    if (!this.formData.isAdd) {
                        this.getAuditData().then((data) => {
                            this.auditGridData.data = data.auditEntries;
                            this.auditGridApi.core.refresh();
                        });
                    }
                    break;
                case 'properties':

                    break;
                case 'usedBy': 
                    this.usedByGridApi.core.refresh();
                    break;
                default:
                    break;

            }
        }

        TaskTypeChanged() {
            switch(this.taskModel.taskTypeId){
                case 4:
                    this.selectedTaskType = 'command-line'
                    this.isCommandLine = true;
                    break;
                case 6: 
                    this.selectedTaskType = 'stored-procedure'
                    this.isCommandLine = false;
                    break
                default:
                    this.selectedTaskType = "other"
                    this.isCommandLine = false;
            }
        };

        mergeTableDataBool() {
            return this.taskModel && this.fromBool(this.taskModel.mergeTableData);
        };


        cancelFileUpload() {
            this.modelFile.upload.abort()
        }

        clickFile() {
            $('#file').click();
            this.formData.isHelpEnabled = true;
        };

        helpEnabled() {
            if (this.formData.isAdd && this.formData.isEdit) {
                return this.formData.isHelpEnabled;
            }
            if (!this.formData.isAdd && this.formData.isEdit) {
                return this.formData.isHelpEnabled;
            }
        };

        uploadModelFile() {
            this.modelsService.uploadModel(this.modelFile, false)
                .then((modelPackage) => {
                    this.taskModel = angular.extend(this.taskModel, modelPackage);
                    if ((this.taskModel.projectNotes === undefined || this.taskModel.projectNotes === "") && this.taskModel.notes !== "") {
                        this.taskModel.projectNotes = this.taskModel.notes;
                    }

                    if (this.taskModel.mergeTableData === "true") {
                        this.taskModel.MergeTable = true;
                    } else {
                        this.taskModel.MergeTable = false;
                    }
                    let parallelSettings = this?.taskModel?.taskParallelSettings
                    this.parallelSettingsExist = parallelSettings?.isMultithreaded || parallelSettings?.isParallel
                    this.isMultiThreaded = parallelSettings?.isMultithreaded || parallelSettings?.isSequentialStartUp || parallelSettings?.isParallel;
                    this.taskModel.taskTypeId = this.taskModel.compiledTaskTypeId;
                })
                .finally(() => {
                    this.modelFile = null;
                });
        };

        fromBool(val) {
            return val && val.toLowerCase() === "true";
        }

        isInvalid(keys) {
            let isValid = this.validate();
            this.formData.isValid = isValid;
            if (!keys) {
                return !isValid
            }

            var keysLocal = Array.isArray(keys) ? keys : [keys];

            return keysLocal.find((x) => {
                return this.errors[x];
            });
        };
        errorMessageFor() {
            this.validate();
            return this.errors;
        }

        validate() {
            this.errors = {};
            var valid = true;
            if (!this.taskModel || !this.taskModel.name) {
                this.errors.modelName = "Please specify a value for task name";
                valid = false;
            }
            if (!this.taskModel.collections || this.taskModel.collections.length === 0) {
                this.errors.collection = "Please select at least one collection for this task.";
                valid = false;
            }
            if (this.selectedTaskType === 'other') {
                if (!this.taskModel || !this.taskModel.fileName) {
                    this.errors.fileName = "Please select a package file";
                    valid = false;
                }
                //TODO:Add TaskType message
                if (!this.taskModel || !this.taskModel.taskTypeId) {
                    this.errors.taskTypeId = "Please select a task type";
                    valid = false;
                }
                if (this.taskModel && this.taskModel.name && this.formData.usedNames.includes(this.taskModel.name)) {
                    this.errors.modelName = "Package Name is already in use, please use a different name";
                    valid = false;
                }
            }
            if (this.selectedTaskType === 'stored-procedure') {
                if(!this.taskModel?.dataConnectionId){
                    valid = false;
                }
                if(!this.taskModel?.storedProcedureName){
                    valid = false;
                }
                if(this.taskModel.schema === undefined){
                    valid = false;
                }
            }
            if (this.selectedTaskType === 'command-line') {
                //if (!this.taskModel || !this.taskModel.commandLine || this.taskModel.commandLine === null || this.taskModel.commandLine === "") {
                //    errors.commandLine = "Please enter command.";
                //    rtn = true;
                //}
                if (!this.taskModel || !this.taskModel.workingDirectoryId) {
                    this.errors.workingDirectory = "Please select working directory.";
                    valid = false;
                }
                if (!this.taskModel || !this.taskModel.standardInputId) {
                    this.errors.standardInput = "Please select standard input directory.";
                    valid = false;
                }
                if (!this.taskModel || !this.taskModel.standardOutputId) {
                    this.errors.standardOutput = "Please select standard output directory.";
                    valid = false;
                }
                if (!this.taskModel || !this.taskModel.standardErrorId) {
                    this.errors.standardError = "Please select standard error directory.";
                    valid = false;
                }
            }
            return valid;
        }

        openParallelSettings() {
            if (this.parallelSettingsExist) {
                this.omcDialogService.open('task-parallel-settings', { title: "Task Parallel Settings" });
            }
        };

        getAuditData(){
            let modelAuditInfo = 'modelAuditInfo?id=' + this.taskModel.id
            return this.dataService.getData(modelAuditInfo, modelAuditInfo, true)
        }
    }
}

angular
    .module('omc')
    .component(editTaskComponent.selector, editTaskComponent)