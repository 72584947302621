import * as angular from 'angular'
import { OmcComponent } from 'shared/models/omc.types';

export class EditBasisController implements angular.IController {

    basisSet: any;
    formData: any;

    gridHelper: any;
    userSettings: any;
    gridHelperService: any;
    auditPermission = true;
    usedByJobGridData: any;

    auditGridData: any;
    gridData: any;

    multiSelectSettings = {
        checkBoxes: true,
        dynamicTitle: true,
        showUncheckAll: false,
        showCheckAll: false
    };

    isLoaded: boolean;

    taskTypes: any;
    selectedTaskType: any;
    selectedTask: any;
    tasks: any;
    filteredTasks: any;

    selectedBasis: any;
    gridRowSelected: any;
    selectedRow: any;

    constructor(
        private modelsService,
        private taskTypeService,
        private $timeout,
        private gridSettingsService,
        private gridHelperServiceFactory,
        private auditService,
        private dataService,
        private uiActionsService,
        private usedByService,
        private $rootScope,
        private currentUserService,
        private filterService
    ) {
        this.gridHelperService = gridHelperServiceFactory.gridHelper('basisUsedBy');

        this.currentUserService.getUserSettings(true).then(settings => {
            this.userSettings = settings;
        });

        this.$rootScope.$on("serverEvent:TaskTypeServerEvent", this.onTaskTypeStatusChangeServerEvent);
    }

    $onInit() {
        this.getPermissions();
        this.auditGrid();
        this.initGridData();
        this.initUsedByGrid();
        this.startUpTasks();
    }

    startUpTasks() {
        this.isLoaded = !!this.basisSet && !!this.formData
        if (!this.isLoaded) return;

        if (!this.formData.isAdd && !this.formData.isCopy) {
            //Load used by jobs/tasks
            var id = this.basisSet.id;
            this.dataService.getData('basisInfo?id=' + id, 'basisInfo?id=' + id, true).then((data) => {
                data.usedBy.map(x => x.taskType = this.taskTypeService.getTaskTypeValueById(x.taskType));
                this.usedByJobGridData.data = data.usedBy;
                this.auditGridData.data = data.auditEntries;
            });
        }

        if (this.basisSet.createdAutomatically) {
            this.formData.isAutomaticCopy = true;
        } else {
            this.formData.isAutomaticCopy = false;
        }

        if (!this.basisSet.effectiveDate)
            this.basisSet.effectiveDate = new Date();

        //Load Tasks and Init
        this.modelsService.getModelsForPicker(true).then((models) => {

            //Task Types related changes
            this.taskTypeService.getTaskTypes(true).then((data) => {
                this.taskTypes = data;
                this.selectedTaskType = this.taskTypes[0];
                this.selectedTask = { id: 0 };
                this.tasks = [];
                this.filteredTasks = [];

                if (this.basisSet && this.basisSet.taskTypeId) {
                    this.selectedTaskType = this.taskTypes.find(x => x.key === this.basisSet.taskTypeId);
                }

                this.basisSet.taskTypeId = this.selectedTaskType.key;

                if (this.basisSet && this.basisSet.modelId) {
                    this.selectedTask = models.find(x => x.id === this.basisSet.modelId);
                } else if (models.find(x => x.isDefault)) {
                    this.selectedTask = models.find(x => x.isDefault);
                    this.basisSet.modelId = this.selectedTask.id;
                }

                this.tasks = models;
                this.filteredTasks = this.tasks.filter(e => e.taskTypeId === this.selectedTaskType.key);

                if (this.userSettings && this.userSettings.applyTaskFilter && (this.formData.filterValues.selectedTaskType.key === 0 || this.selectedTaskType.key === this.formData.filterValues.selectedTaskType.key) && this.formData.isAdd) {
                    this.filteredTasks = this.filterService.filterApplyOnTasks(this.filteredTasks, this.formData.filterValues);
                }
            });
        });

        this.selectedBasis = this.basisSet.bases[0];
        if (this.selectedBasis?.parameterValues)
            this.gridHelper.dataReloaded(this.selectedBasis?.parameterValues);
    }

    auditGrid() {
        this.auditGridData = this.gridSettingsService.defaultGridOptions();
        this.auditGridData.appScopeProvider = this;
        this.auditGridData.columnDefs = this.auditService.auditLogColumnDefs();
    }

    initGridData() {
        this.gridHelper = this.gridHelper = this.gridHelperServiceFactory.gridHelper('editTableSet', '#edit-tableset-grid');
        this.gridHelper.gridData.modifierKeysToMultiSelect = false;
        this.gridHelper.gridData.multiSelect = false;
        this.selectedRow = () => this.gridHelper.selectedValues()[0];
        this.gridData = this.gridHelper.gridData;
        this.gridData.appScopeProvider = this;
        this.gridRowSelected = this.gridHelper.gridRowSelected;

        this.gridData.columnDefs = this.columnDefs();
    }

    initUsedByGrid() {
        this.usedByJobGridData = this.gridSettingsService.defaultGridOptions();
        this.usedByJobGridData.appScopeProvider = this;
        this.gridRowSelected = this.gridHelperService.gridRowSelected;

        this.usedByJobGridData.columnDefs = this.usedByService.usedByColumnDefs();
    }

    canEditNewValue() {
        if (this.basisSet.createdAutomatically && this.formData.isCopy) {
            return false;
        } else {
            return !this.formData.isEdit;
        }
    };

    getPermissions() {
        this.uiActionsService.getPermissionsForName('admin-view-audit', true).then((perm) => {
            this.auditPermission = perm && !perm.disabled;
        });
    }

    isDisabled() {
        return this.formData.isAdd || this.formData.isCopy;
    }

    isInvalid(key) {
        var errors = this.validate();
        this.formData.isValid = errors.isValid();
        if (key) {
            return errors[key];
        }
        return !errors.isValid();
    };

    errorMessageFor() {
        return this.validate();
    }

    onTaskTypeChanged() {
        this.filteredTasks = this.tasks.filter(e => e.taskTypeId === this.selectedTaskType.key);

        if (this.userSettings && this.userSettings.applyTaskFilter && (this.formData.filterValues.selectedTaskType.key === 0 || this.selectedTaskType.key === this.formData.filterValues.selectedTaskType.key) && this.formData.isAdd) {
            this.filteredTasks = this.filterService.filterApplyOnTasks(this.filteredTasks, this.formData.filterValues);
        }

        if (this.filteredTasks.length > 0) {
            this.selectedTask = this.filteredTasks[0];
            var bases = this.createBasesFromBasisDefinitions(this.selectedTask.basisDefinitions);
            if (bases && bases.length !== 0) {
                this.basisSet = bases[0];
            }
            this.onTaskChanged();
        }
    }

    onBasisChanged() {
        console.log("onBasisChanged: selectedBasis = ", this.selectedBasis);
        if (this.selectedBasis?.parameterValues) {
            this.gridHelper.dataReloaded(this.selectedBasis.parameterValues);
        }
    }


    onTaskChanged() {
        if (this.filteredTasks.length > 0) {
            this.basisSet.modelId = this.selectedTask.id;
            this.basisSet.taskTypeId = this.selectedTaskType.key;
        }
    }

    validate() {
        var hasErrors = false;
        let rtn;
        rtn = { isValid: () => !hasErrors };
        if (this.formData.isEdit) {
            if (!this.basisSet || !this.basisSet.name) {
                rtn.name = "Please specify a value for name";
                hasErrors = true;
            }
            if (!this.basisSet.collections || this.basisSet.collections.length === 0) {
                rtn.collection = "Please select at least one collection for this basis.";
                hasErrors = true;
            }
            if (!this.selectedTask || !this.selectedTask.id) {
                rtn.taskname = "Please select the Task Name";
                hasErrors = true;
            }
            if (this.basisSet && this.basisSet.name && this.formData.usedNames.includes(this.basisSet.name)) {
                rtn.name = "Name is already in use, please use a different name";
                hasErrors = true;
            }
        }
        return rtn;
    }

    columnDefs() {
        return [
            {
                name: 'id',
                displayName: '',
                cellTemplate: '<div class="grid-row-vertical-bar" ng-click="grid.appScope.gridHelper.toggleSelected(row.entity, $event)" ng-class="{\'grid-row-selected\': grid.appScope.gridRowSelected(row.entity) }" role="button" tabindex="0"></div>',
                width: this.gridSettingsService.columnWidths.toggleSelect,
                pinnedLeft: true,
                cellClass: "omc-select-column"
            },
            {
                name: 'parameterDefinition.modelPath', displayName: 'Model Path'
            },
            {
                name: 'parameterDefinition.name', displayName: 'Name'
            },
            {
                name: 'parameterDefinition.dataType', displayName: 'Data Type'
            },
            {
                name: 'parameterDefinition.value', displayName: 'Value (Default)'
            },
            {
                name: 'value', field: 'value', displayName: 'Value'
            }
        ];
    }

    onTaskTypeStatusChangeServerEvent(event, payload: { data?: any }) {
        let load = payload;
        if (load && load.data && this.taskTypes) {
            if (!Array.isArray(load)) {
                for (var i = 0; i < this.taskTypes.length; i++) {
                    if (load.data.description === this.taskTypes[i].value) {
                        this.taskTypes[i].isDisabled = load.data.isDisabled;
                        return;
                    }
                }
            } else {
                for (var j = 0; j < load.data.length; j++) {
                    for (var i = 0; i < this.taskTypes.length; i++) {
                        if (load.data.description === this.taskTypes[i].value) {
                            this.taskTypes[i].isDisabled = load.data.isDisabled;
                        }
                    }
                }
            }

        }
    }


    createBasesFromBasisDefinitions(basisDefinitions) {
        let rtn = [];
        if (basisDefinitions != null) {
            for (let basisDef of basisDefinitions) {
                rtn.push({
                    basisDefinition: basisDef,
                    basisDefinitionId: basisDef.id,
                    parameterValues: this.createParameterValues(basisDef.parameterDefinitions),
                    notes: ""
                });
            }
        }
        return rtn;
    }


    createParameterValues(paramDefs) {
        let rtn = [];
        for (let paramDef of paramDefs) {
            rtn.push({
                parameterDefinition: paramDef,
                parameterDefinitionId: paramDef.id,
                value: paramDef.value,
                originalValue: paramDef.value,
                modelPath: paramDef.modelPath
            });
        }
        return rtn;
    }
}

const EditBasisComponent: OmcComponent = {
    selector: `editBasisComponent`,
    template: require('./edit-basis.component.html'),
    bindings: {
        basisSet: '=',
        formData: "=",
    },
    controller: EditBasisController
}

angular
    .module('omc')
    .component(EditBasisComponent.selector, EditBasisComponent);
