export const toastrConfig = {
    closeButton: true,
    tapToDismiss: true,
    newestOnTop: false,
    progressBar: false,
    positionClass: 'toast-bottom-right',
    preventDuplicates: false,
    timeOut: 5000,
    extendedTimeOut: 5000,
    easeTime: 300
};

export const toastrMessageLevels = {
  success: 'success',
  info: 'info',
  warning: 'warning',
  error: 'error',
};
