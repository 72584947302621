import * as angular from 'angular'
import { OmcComponent } from "shared/models/omc.types";

let editJobPrioritiesComponent: OmcComponent = {
    selector: `editJobPrioritiesComponent`,
    template: require("./edit-job-priorities.component.html"),
    bindings: {
        rolePriority: "=",
        formData: "=",
    },
    controller: class EditJobPrioritiesController implements angular.IController {
        rolePriority: any;
        formData: any;
        
        options = {
            noOfThreads: [],
            noOfWorkers: [],
        }
        
        constructor(
            private platformSettingsService, 
            private formHelperService
        ){}

        $onInit(){
            if (this.rolePriority) {
                this.platformSettingsService.getSettings(true).then((settings) => {
                    this.options.noOfThreads = this.formHelperService.numericalFormOptions(1, settings.maximumNoOfThreads)
                    this.options.noOfWorkers = this.formHelperService.numericalFormOptions(1, settings.maximumNoOfWorkers)
                    this.filterDefaultThreads()
                    this.filterDefaultWorkers()
                })
            }
        }

        isInvalid(key: string) {
            var errors = this.validate();
            if (this.formData) {
                this.formData.isValid = errors.isValid
            }
            if (key) {
                return errors[key];
            }
            return !errors.isValid;
        };
        errorMessageFor() {
            return this.validate();
        };

        filterDefaultThreads() {
            let val = this.rolePriority.maximumNoOfThreads
            this.options.noOfThreads.forEach(n => {
                n.isDisabled = n.value > val
            })
            if (this.rolePriority.defaultNoOfThreads > val) {
                this.rolePriority.defaultNoOfThreads = val
            }
        }
        filterDefaultWorkers() {
            let val = this.rolePriority.maximumNoOfWorkers
            this.options.noOfWorkers.forEach(n => {
                n.isDisabled = n.value > val
            })
            if (this.rolePriority.defaultNoOfWorkers > val) {
                this.rolePriority.defaultNoOfWorkers = val
            }
        }

        validate() {
            var rtn:any = {
                isValid: true
            };
            if (this.formData && this.formData.isEdit) {
                if (!this.rolePriority.maximumNoOfThreads) {
                    rtn.maxThreads = "Please specify the maximum number of threads value";
                    rtn.isValid = false;
                }
                if (!this.rolePriority.defaultNoOfThreads) {
                    rtn.defaultThreads = "Please specify the default number of threads value";
                    rtn.isValid = false;
                }
                if (this.rolePriority.defaultNoOfThreads > this.rolePriority.maximumNoOfThreads) {
                    rtn.defaultThreads = "Please specify a default value less than and equal to the maximum number of threads";
                    rtn.isValid = false;
                }
                if (!this.rolePriority.maximumNoOfWorkers) {
                    rtn.maxWorkers = "Please specify the maximum number of workers value";
                    rtn.isValid = false;
                }
                if (!this.rolePriority.defaultNoOfWorkers) {
                    rtn.defaultWorkers = "Please specify the default number of workers value";
                    rtn.isValid = false;
                }
                if (this.rolePriority.defaultNoOfWorkers > this.rolePriority.maximumNoOfWorkers) {
                    rtn.defaultWorkers = "Please specify a default value less than and equal to the maximum number of workers";
                    rtn.isValid = false;
                }
            }
            return rtn;
        }
    }
}

angular
    .module('omc')
    .component(editJobPrioritiesComponent.selector, editJobPrioritiesComponent)
