import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import * as angular from 'angular';
import { pathToApp } from '../../../core/constants/global-constants';
import { DataService } from "../../services/data.service";

@Component({
    selector: `userSelectComponent`,
    templateUrl: pathToApp + 'shared/components/user-select/user-select.component.html',
})
export class UserSelectComponent implements OnInit {
    @Input() disabled: boolean;
    @Input() userId: any;
    @Input() options: any;
    @Input() nullOption: string;
    @Input() selected: any;
    @Input() userListEndpoint: string;

    @Output() selectedChange = new EventEmitter();
    onChange: (...args: any[]) => any;
    allUsers: any;
    user: any;

    constructor(private dataService: DataService) {}

    ngOnInit() {
        this.dataService.getData(this.userListEndpoint, this.userListEndpoint, false).then((users) => {
            this.allUsers = angular.copy(users);
            if (this.nullOption) {
                this.allUsers.splice(0, 0, { fullName: this.nullOption });
                if (Object.keys(this.selected).length == 0) {
                    this.selected = this.allUsers[0];
                }
            }

            setTimeout(() => {
                let userId = this.userId || this.user?.id;
                if (this.userId) {
                    this.selected = this.allUsers.find((x) => {
                        return x.id === userId;
                    });
                } else if (this.selected) {
                    this.selected = this.allUsers.find((x) => {
                        return x.id === this.selected.id;
                    });
                }
            });
        });
    }

    identify(index, item) {
        return item.userName;
    }

    changed() {
        this.selectedChange.emit(this.selected);
        if (this.onChange) {
            setTimeout(this.onChange);
        }
    }
}

angular.module('omc').directive(
    'userSelectComponent',
    downgradeComponent({
        component: UserSelectComponent,
        inputs: ['disabled', 'selected', 'userId', 'options', 'nullOption', 'onChange', 'userListEndpoint'],
        outputs: ['selectedChange'],
    })
);
