import 'reflect-metadata';
import './index';

import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { UpgradeModule } from '@angular/upgrade/static';
import { AppModule } from './app.module';

// Bootstrap using the UpgradeModule
/* window.onload used to signal when the bootstrapping should happen - to be removed after decoupling from cshtml [TM 25/08/22] */
platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .then((platformRef) => {
        console.log('Bootstrapping in Hybrid mode with Angular & AngularJS');
        const upgrade = platformRef.injector.get(UpgradeModule) as UpgradeModule;
        window.onload = () => upgrade.bootstrap(document.body, ['omc']);
    });
