import { Inject, Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { RootScope } from '../../ajs-upgraded-providers';
import * as angular from 'angular';
import { ConfirmDialogOptions, BatchConfirmDialogOptions } from '../models/dialog-data.model';

@Injectable()
export class GeneralDialogService {
    constructor(@Inject(RootScope) private rootScope) {}

    /**
     * @param {string} userId
     */
    userDetails = (userId) => {
        this.rootScope.$emit('userDetailsDialog.open', userId);
    };
    /**
     * @param {ConfirmDialogOptions} options
     */
    confirm = (options = new ConfirmDialogOptions()) => {
        this.rootScope.$emit('omcConfirm.open', options);
    };
    /**
     * @param {BatchConfirmDialogOptions} options
     */
    batchConfirm = (options = new BatchConfirmDialogOptions()) => {
        this.rootScope.$emit('omcBatchConfirm.open', options);
    };
}
angular.module('omc').factory('generalDialogService', downgradeInjectable(GeneralDialogService) );
