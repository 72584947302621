import { ApiService } from "./api.service";
import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import * as angular from 'angular';

@Injectable({
    providedIn: 'root'
  })
export class DownloadService {
    
    constructor(private apiService: ApiService) {}

    downloadModel(id, fileName, fileType) {
        return this.downloadBlobFromServer('DownloadModel', id, fileName, fileType);
    }

    downloadResults(id, fileName, fileType) {
        return this.downloadBlobFromServer('DownloadResults', id, fileName, fileType);
    }

    downloadResultsHistoryFile(id, fileName, filePath) {
        return this.downloadFileFromServer('DownloadResultHistoryFile', { id: id, fileName: filePath }, fileName);
    }

    downloadTaskExecutionLog(id, fileName) {
        return this.downloadFileFromServer('DownloadTaskExecutionLog', { id: id }, fileName);
    }

    downloadTaskRunReport(id, fileName) {
        return this.downloadFileFromServer('DownloadTaskRunReport', { id: id }, fileName);
    }

    downloadFile(data, fileName, fileType) {
        let file = new Blob([data], {
            type: this.type(fileType),
        });
        //trick to download store a file having its URL
        let fileUrl = URL.createObjectURL(file);
        this.showFileSaveDialog(fileUrl, fileName);
    }

    downloadBlobFromServer(apiEndpoint, id, fileName, fileType) {
        fileType = fileType || 'zip';
        let fileUrl =
            this.apiService.apiUrl(apiEndpoint) +
            '?id=' +
            id +
            '&fileName=' +
            encodeURIComponent(fileName) +
            '&mediaTypeHeader=' +
            encodeURIComponent(this.type(fileType));
        this.showFileSaveDialog(fileUrl, fileName);
    }

    downloadFileFromServer(apiEndpoint, params = {}, fileName) {
        let fileUrl = this.apiService.apiUrl(apiEndpoint) + this.apiService.stringifyParams(params);
        this.showFileSaveDialog(fileUrl, fileName);
    }

    type(fileType) {
        switch (fileType) {
            case 'csv':
                return 'application/csv';
            case 'exe':
                return 'application/vnd.microsoft.portable-executable';
            case 'zip':
                return 'application/zip';
            case 'xml':
                return 'application/xml';
            default:
                return fileType;
        }
    }

    showFileSaveDialog(fileUrl, fileName) {
        let a = document.createElement('a');
        a.href = fileUrl;
        a.target = '_blank';
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
    }
}

angular.module('omc').factory('downloadService', downgradeInjectable(DownloadService));

