import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import * as angular from 'angular';
import { RootScope } from '../../../ajs-upgraded-providers';
import { pathToApp } from '../../../core/constants/global-constants';
import { OmcGlobalsService } from '../../../shared/services/omc-globals.service';

@Component({
    selector: `enumSelectorComponent`,
    templateUrl: pathToApp + 'shared/components/enum-selector/enum-selector.component.html',
})
export class EnumSelectorComponent implements OnInit {
    options: any;
    @Input() enumName: string;
    @Input() id: string;
    @Input() disabled: any;
    @Input() onChange: any;
    @Input() model: any;
    @Output() modelChange = new EventEmitter<unknown>();

    constructor(@Inject(RootScope) private rootScope, private omcGlobalsService: OmcGlobalsService) {}

    ngOnInit() {
        this.options = this.omcGlobalsService.values(this.enumName);
    }

    localOnChange() {
        this.modelChange.emit(this.model);
        var val = this.options.find((x) => x.value == this.model);
        this.onChange && this.rootScope.$emit('dataSetTypeChange', val);
    }
}

angular.module('omc').directive(
    'enumSelectorComponent',
    downgradeComponent({
        component: EnumSelectorComponent,
        inputs: ['enumChange', 'model', 'id', 'disabled', 'onChange'],
        outputs: ['modelChange'],
    })
);
