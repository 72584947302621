import * as angular from 'angular'
import { OmcComponent } from 'shared/models/omc.types';

let editServicesComponent: OmcComponent = {
    selector: `editServicesComponent`,
    template: require("./edit-services.component.html"),
    bindings: {
        disabled: "=",
        onChange: '='
    },
    controller: class EditServicesController implements angular.IController {
        model: any;
        constructor(
            private modalDialogService, 
        ){}
        
        $onInit(){
            this.model = this.modalDialogService.model || {};
            this.model.service.canEdit = this.model.isEdit;

            if (this.model.service.name !== "Mo.net Activity Monitor") {
                this.model.IsHidden = true;
            }
            if (this.model.IsHidden) {
                if (this.model.service.name === "Mo.net Identity Service") {
                    this.model.service.canEdit = false;
                    this.model.isEdit = false;
                }
            }
        }
        isInvalid(key){
            var errors = this.validate();
            if (key) {
                return errors[key];
            }
            return !errors.isValid();
        };
        errorMessageFor(){
            return this.validate();
        };

        validate() {
            var hasErrors = false;
            var rtn: any = {
                isValid: () => !hasErrors
            };
            if (this.model.isEdit) {
                if (!this.model.IsHidden)
                {
                    if (!this.model.service.username || !this.model.service.password || !this.model.service.endpoint) {
                        hasErrors = true;
                    }
                }
                else
                {
                    if (!this.model.service.endpoint) {
                        hasErrors = true;
                    }  
                }
            }
            return rtn;
        }
    }
}

angular
    .module('omc')
    .component(editServicesComponent.selector, editServicesComponent)