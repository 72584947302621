import { InjectionToken } from '@angular/core';

//create injection token for $rootScope service

export const RootScope = new InjectionToken('RootScope');

export function rootScopeServiceFactory(i: any) {
    return i.get('$rootScope');
}
export const rootScopeServiceProvider = {
    provide: RootScope,
    useFactory: rootScopeServiceFactory,
    deps: ['$injector'],
};

//create injection token for $state service

export const StateService = new InjectionToken('StateService');

export function stateServiceFactory(i: any) {
    return i.get('$state');
}
export const stateServiceProvider = {
    provide: StateService,
    useFactory: stateServiceFactory,
    deps: ['$injector'],
};

//create injection token for $uiGridConstants service

export const UiGridConstants = new InjectionToken('UiGridConstants');

export function uiGridConstantsFactory(i: any) {
    return i.get('uiGridConstants');
}
export const uiGridConstantsProvider = {
    provide: UiGridConstants,
    useFactory: uiGridConstantsFactory,
    deps: ['$injector'],
};

export const AJS_UPGRADED_PROVIDERS = [stateServiceProvider, rootScopeServiceProvider, uiGridConstantsProvider];
