import {
    ComponentFactory,
    ComponentFactoryResolver,
    ComponentRef,
    Directive,
    DoCheck,
    ElementRef,
    HostBinding,
    Input,
    OnDestroy,
    OnInit,
    Renderer2,
    ViewContainerRef,
} from '@angular/core';
import { OmcLoadingService } from '../../../shared/services/omc-loading.service';
import { OmcFunc } from '../../functions/omc.functions';
import { OmcLogoSpinnerComponent } from './omc-logo-spinner.component';

/* TODO: When it needs to wrap[TM 25/01/23] */

@Directive({
    selector: '[omcSpinnerAngular]',
})
export class OmcSpinnerDirective implements OnInit, OnDestroy, DoCheck {
    @Input() spinForEndpoint: string;
    @Input() disableWhileLoading: boolean = true;
    @HostBinding('attr.disabled') disabled: boolean;
    @Input() loading: boolean = null; // show spinner untill loading is false

    /* to implement bellow two inputs [TM 25/01/23] */
    @Input() wrap: boolean = false; // to implement wrapping logic

    endpoints: Array<string>;
    host: HTMLElement;

    private _cmpRef: ComponentRef<OmcLogoSpinnerComponent>;
    private _factory: ComponentFactory<OmcLogoSpinnerComponent>;

    constructor(
        private el: ElementRef,
        private renderer: Renderer2,
        private viewContainer: ViewContainerRef,
        private resolver: ComponentFactoryResolver,
        private omcLoadingService: OmcLoadingService
    ) {}

    ngOnInit() {
        this.host = this.el.nativeElement;
        this.endpoints = OmcFunc.parseArray(this.spinForEndpoint);
    }

    ngDoCheck() {
        this.updateSpinnerStatus();
    }

    updateSpinnerStatus() {
        if (this.endpointLoading() && !this._cmpRef) {
            this.createSpinner();
        } else if (!this.endpointLoading() && this._cmpRef) {
            this.removeSpinner();
        }
    }

    endpointLoading() {
        return (this.loading!=null) ? this.loading : this.endpoints.some((endpoint) => this.omcLoadingService.isLoading(endpoint));
    }

    createSpinner() {
        this._factory = this.resolver.resolveComponentFactory(OmcLogoSpinnerComponent);
        this.addSpinner();
    }

    addSpinner() {
        this.host.classList.add('omc-spinner-host', 'show-spinner');
        this._cmpRef = this.viewContainer.createComponent(this._factory);
        this._cmpRef.instance.spin = true;
        this.host.insertBefore(this._cmpRef.location.nativeElement, this.host.firstChild);
        /* TODO: to test - compare to original [TM 25/01/23] */
        if (this.disableWhileLoading && (this.disabled ?? true)) {
            this.disabled = true;
            this.host.classList.add('disabled');
        }
    }

    removeSpinner() {
        this.host.classList.remove('omc-spinner-host', 'show-spinner');
        /* TODO: to test - compare to original [TM 25/01/23] */
        if (this.disableWhileLoading && this.disabled) {
            this.disabled = false;
            this.host.classList.remove('disabled');
        }
        this._cmpRef.destroy();
    }

    ngOnDestroy() {
        if (this._cmpRef)
            this._cmpRef.destroy();
    }
}
