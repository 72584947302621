import { OmcFunc } from "../functions/omc.functions";

export class HasIdAndName {
    id: any;
    name: any;
    constructor(hasIdAndName: Partial<HasIdAndName> = {}) {
        this.id = hasIdAndName && hasIdAndName.id;
        this.name = hasIdAndName && hasIdAndName.name
    }
}

export class TaskTypeView {
    id: any;
    name: any;
    key: any;
    isDisabled: any;
    value: any;
    constructor(taskType: Partial<TaskTypeView> = {}) {
        this.id = taskType.id;
        this.name = taskType.name || taskType.value;
        this.key = taskType.key;
        this.isDisabled = taskType.isDisabled || false;
    }
}

export class BasisView {
    id: any;
    name: any;
    basisDefinitionName:any;
    constructor(basis: Partial<BasisView> = {}) {
        this.id = basis.id;
        this.name = basis.name || basis.basisDefinitionName
    }
}
export class RecordSampleView {
    id: any;
    name: any;
    definition
    constructor(recordSample: Partial<RecordSampleView> = {}) {
        recordSample = recordSample || {};
        this.id = recordSample.id || 0;
        this.name = recordSample.name || recordSample.definition;
    }
}
export class RecordSampleSettingsView {
    recordSample: Partial<RecordSampleView>;
    nthRecord: any;
    startingRange: any;
    endingRange: any;
    targetCount: any;
    constructor(settings: Partial<RecordSampleSettingsView> = {}) {
        this.recordSample = new RecordSampleView(settings.recordSample);
        this.nthRecord = settings.nthRecord;
        this.startingRange = settings.startingRange;
        this.endingRange = settings.endingRange;
        this.targetCount = settings.targetCount;
    }
}

/* review type [TM 15/08/22] */
export class TaskModelView extends HasIdAndName {
    isDeleted: any;
    workingDirectory: HasIdAndName;
    standardInput: HasIdAndName;
    standardOutput: HasIdAndName;
    standardError: HasIdAndName;
    constructor(model: Partial<TaskModelView> = {}) {
        super(model)
        this.id = model.id
        this.isDeleted = model.isDeleted || false;
        this.workingDirectory = new HasIdAndName(model.workingDirectory);
        this.standardInput = new HasIdAndName(model.standardInput);
        this.standardOutput = new HasIdAndName(model.standardOutput);
        this.standardError = new HasIdAndName(model.standardError);
    }
}
export class RunControlTableView extends HasIdAndName {
    runIdColumn: any;
    constructor(table: Partial<RunControlTableView> = {}) {
        super(table)
        this.runIdColumn = table && table.runIdColumn;
    }
}
export class TableSetOptions extends HasIdAndName {
    runControlTables;
    constructor(tableSet: Partial<TableSetOptions> = {}) {
        super(tableSet);
        if(tableSet && tableSet.runControlTables && tableSet.runControlTables.length > 0){
            this.runControlTables = tableSet.runControlTables.map(t => new RunControlTableView(t))
        } else {
            this.runControlTables = []
        }
    }
}
export class DataConnectionOptions extends HasIdAndName {
    connectionType: any;
    isDefault: any;
    isDataConnResultsDefault
    constructor(dc: Partial<DataConnectionOptions> = {}) {
        super(dc);
        this.connectionType = dc.connectionType
        this.isDefault = dc.isDataConnResultsDefault
    }
}
export class BasisSetOptions extends HasIdAndName {
    bases: any;
    constructor(basisSet: Partial<BasisSetOptions>) {
        super(basisSet);
        this.bases = basisSet.bases ? basisSet.bases.map(b=>new BasisView(b)) : [];
    }
} 
export class TaskView {
    basis: BasisView;
    basisSet: HasIdAndName;
    customNamingStrategy: any;
    dataSet: HasIdAndName;
    hasRunControlTable: boolean;
    id: any;
    isEnabled: any;
    jobRunTaskId: any;
    isOverwriteResults: any;
    model: TaskModelView;
    name: any;
    outputDataConnection: DataConnectionOptions;
    recordSampleSettings: RecordSampleSettingsView;
    recordsProcessed: any;
    renamingStrategy: any;
    runControlFilter: any;
    sNo: any;
    runControlTable: RunControlTableView;
    schema: any;
    storedProcedureDatabase: any;
    storedProcedureDataConnection: any;
    storedProcedureName: any;
    storedProcedureParams: any;
    tableSet: HasIdAndName;
    taskType: TaskTypeView;
    isCommandLine: () => boolean;
    isStoredProcedure: () => boolean;
    isGroupProjectionTask: () => boolean;
    hasOutputDatabaseConnection: () => boolean;
    constructor(task: Partial<TaskView> = {}, index?) {
        this.id = task.id || OmcFunc.newGuid();
        this.name = task.name;
        this.isEnabled = task.isEnabled || true;
        this.taskType = new TaskTypeView(task.taskType);
        this.tableSet = new HasIdAndName(task.tableSet);
        this.basisSet = new HasIdAndName(task.basisSet);
        this.basis = new BasisView(task.basis)
        this.dataSet = new HasIdAndName(task.dataSet);
        this.storedProcedureName = task.storedProcedureName ?? null;
        this.storedProcedureDatabase = task.storedProcedureDatabase ?? null;
        this.storedProcedureParams = task.storedProcedureParams ?? null;
        this.storedProcedureDataConnection = task.storedProcedureDataConnection ?? null;
        this.recordSampleSettings = new RecordSampleSettingsView(task.recordSampleSettings)
        this.outputDataConnection = new DataConnectionOptions(task.outputDataConnection);
        this.schema = task.schema ?? null;
        this.isOverwriteResults = task.isOverwriteResults;
        this.renamingStrategy = task.renamingStrategy;
        this.customNamingStrategy = task.customNamingStrategy;
        this.hasRunControlTable = !!task.hasRunControlTable || false;
        this.runControlTable = task.runControlTable && new RunControlTableView(task.runControlTable);
        this.model = new TaskModelView(task.model);
        this.runControlFilter = task.runControlFilter || null;
        this.recordsProcessed = task.recordsProcessed || null;
        this.jobRunTaskId = task.jobRunTaskId || null;
        this.sNo = index + 1 || task.sNo || null;
        this.isCommandLine = () => this.taskType?.key === 4;
        this.isStoredProcedure = () => this.taskType?.key === 6;
        this.isGroupProjectionTask = () => this.taskType?.key === 2;
        this.hasOutputDatabaseConnection = () => this.outputDataConnection?.connectionType === 0
    }
}