import * as angular from 'angular'
import * as moment from 'moment';
import { OmcFunc } from "../../shared/functions/omc.functions";
import { OmcComponent } from 'shared/models/omc.types'

let loginComponent: OmcComponent = {
    selector: `loginComponent`,
    template: require("./login.component.html"),
    controller: class LoginController implements angular.IController {

        sections = {
            LOGIN: 'LOGIN',
            FORGOT_PASSWORD: 'FORGOT_PASSWORD',
            TERMS: 'TERMS',
            PRIVACY: 'PRIVACY',
        };

        wallpaperMapping = {
            1: 'bg-balloons',
            2: 'bg-chess',
            3: 'bg-clouds',
            4: 'bg-maths',
            5: 'bg-speed'
        }

        showSection: string;
        forgetMessage: any = "";
        loginUsingEmailId: any;
        user: any = {};
        emailPlaceHolder: string;
        showGlobalSpinner: ()=>boolean = ()=>true; // Spin by default
        
        currentYear: any;
        buildDescription: any;
        applicationVersion: any;
        executionServiceVersion: any;
        executionServiceDate: any;
        utilityServiceVersion: any;
        utilityServiceDate: any;
        identityServiceVersion: any;
        identityServiceDate: any;
        appBuildVersion: any;
        omcWallpaper: number;
        resetPasswordSent: boolean;

        constructor(
            private currentUserService,
            private serverEventsService,
            private appEnvironmentDataService,
            globalSpinnerService,
        ) {
            this.showGlobalSpinner = globalSpinnerService.showSpinner
        }

        
        $onInit() {
            /* Logs out the user if they visit the login page while logged in [TS 30/12/20] */
            if (this.currentUserService.user) {
                this.currentUserService.logOut();
            }

            this.showSection = this.sections.LOGIN
            this.user.userName = this.currentUserService.getUserEmail();

            if (this.user.userName) {
                this.user.rememberMe = true;
            }

            this.currentYear = moment().format('YYYY');
            this.appEnvironmentDataService.getApplicationVersion().subscribe(x => {this.applicationVersion = x})
            this.appEnvironmentDataService.getBuildDescription().subscribe(x => {this.buildDescription = x})
            this.appEnvironmentDataService.getExecutionServiceVersion().subscribe(x => {this.executionServiceVersion = x})
            this.appEnvironmentDataService.getExecutionServiceDate().subscribe(x => {this.executionServiceDate = x})
            this.appEnvironmentDataService.getUtilityServiceVersion().subscribe(x => {this.utilityServiceVersion = x})
            this.appEnvironmentDataService.getUtilityServiceDate().subscribe(x => {this.utilityServiceDate = x})
            this.appEnvironmentDataService.getIdentityServiceVersion().subscribe(x => {this.identityServiceVersion = x})
            this.appEnvironmentDataService.getIdentityServiceDate().subscribe(x => {this.identityServiceDate = x})
            this.appEnvironmentDataService.getAppBuildVersion().subscribe(x => {this.appBuildVersion = x})
            this.appEnvironmentDataService.getOmcCurrentWallpaper().subscribe(x => {this.omcWallpaper = x})
            this.appEnvironmentDataService.getLoginUsingEmailId().subscribe(x => {
            this.loginUsingEmailId = x.toLowerCase() == 'true' ? true : false;
                this.emailPlaceHolder = x ? "Email Address" : "Username";
            })
        }

        onShowSection(sectionName: string, e?) {
            e?.preventDefault();
            this.showSection = sectionName;
            switch (this.showSection) {
                case this.sections.FORGOT_PASSWORD:
                    this.forgetMessage = "";
                    this.resetPasswordSent = false;
                    break;
                case this.sections.PRIVACY:
                    break;
                case this.sections.TERMS:
                    break;
                case this.sections.LOGIN:
                default:
                    break;
            }
        }
        login() {
            this.currentUserService.logIn(this.user).then((response) => {
                this.currentUserService.updateApplicationTheme();
                this.serverEventsService.initialise();
            }, (reason) => {
                console.log(reason);
            });
        }

        forgetPassword() {
            this.forgetMessage = null;
            this.currentUserService.forgetPassword(this.user)
                .then((response) => {
                    this.forgetMessage = "Please check your email for instructions on how to reset your password.";
                    this.resetPasswordSent = true;
                }, (reason) => {
                    this.forgetMessage = reason.data.message.replace(/^"|"$/g, '');
                    this.resetPasswordSent = false;

                });
        }


        canLogin() {
            return OmcFunc.isSet(this.user, true) && OmcFunc.isSet(this.user.password, true) && OmcFunc.isSet(this.user.userName, true)
        }

        canResetPassword() {
            return OmcFunc.isSet(this.user, true) && OmcFunc.isSet(this.user.userName, true)
        }

        smallLogo(){
            return this.showSection === this.sections.PRIVACY || this.showSection === this.sections.TERMS
        }
    }
}

angular
    .module('omc')
    .component(loginComponent.selector, loginComponent)