import { AfterContentInit, Component, DoCheck, Input, TemplateRef, ViewChild } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import * as angular from 'angular';
import { pathToApp } from '../../../core/constants/global-constants';

@Component({
    selector: `omcLogoSpinnerComponent`,
    templateUrl: pathToApp + 'shared/components/spinner/omc-logo-spinner.component.html',
})
export class OmcLogoSpinnerComponent implements DoCheck, AfterContentInit {
    @Input() spin: boolean;
    @ViewChild('spinner', { static: true }) spinnerTmpl: TemplateRef<any>;
    isSpinning: boolean;
    svgs: {
        inner: { rotation: number; timeout: number };
        mid: { rotation: number; timeout: number };
        outer: { rotation: number; timeout: number };
    };
    initial: any;


    constructor() {}

    ngAfterContentInit() {
        this.svgs = {
            inner: {
                rotation: 0,
                timeout: 0,
            },
            mid: {
                rotation: 0,
                timeout: 0,
            },
            outer: {
                rotation: 0,
                timeout: 0,
            },
        };
        this.startSpins();
    }

    ngDoCheck() {
        if (this.svgs && this.isSpinning !== this.spin) {
            if (this.spin === false) {
                this.stopSpins();
            } else {
                this.startSpins();
            }
        }
    }

    getRandom(min, max) {
        return Math.random() * (max - min) + min;
    }

    startTimeout(svg) {
        clearTimeout(svg.timeout);
        if (this.spin) {
            svg.timeout = setTimeout(
                () => {
                    this.initial = false;
                    let randomSpin = this.getRandom(-360, 360);
                    svg.rotation = randomSpin;
                    this.startTimeout(svg);
                },
                this.initial ? 0 : this.getRandom(600, 2000)
            );
        }
    }

    startSpins() {
        this.initial = true;
        this.isSpinning = true;
        Object.values(this.svgs).forEach((svg) => {
            this.startTimeout(svg);
        });
    }

    stopSpins() {
        this.isSpinning = false;
        Object.values(this.svgs).forEach((svg) => {
            clearTimeout(svg.timeout);
            svg.rotation = 0;
        });
    }
}

angular.module('omc').directive(
    'omcLogoSpinnerComponent',
    downgradeComponent({
        component: OmcLogoSpinnerComponent,
        inputs: ['spin'],
    })
);
