import * as angular from "angular";
import { OmcComponent } from "../../shared/models/omc.types";

let auditEntryComponent: OmcComponent = {
    selector: 'auditEntryComponent',
    template: require('./audit-entry.component.html'),
    controller: class AuditEntryController implements angular.IController {
        defaultAction: { description: string; id: any; };
        defaultType: { longDescription: string; id: any; };
        defaultArtefactType: { description: string; id: any; };
        filter: {
            user: any;
            fromTime: Date;
            toTime: Date;
            dateFrom?: Date,
            dateTo?: Date,
            auditEntryFields?: any,
            auditEntryTypes?: any,
            auditEntryArtefactTypes?: any,
        };
        gridApi: any;
        auditEntries: any[];
        showTimeSpan: boolean;
        searchText: string;
        toDate: any;
        fromDate: any;
        disableCustom: boolean;
        timeSpanOptions: { id: number; name: string; }[];
        selectedTimeSpan: string;
        prevSelectedTimeSpan: any;
        allFields: any;
        allActions: any;
        allTypes: any;
        gridData: any;

        // The endpoint that retrieves the users list displayed in user-select-component dropdown
        readonly userListEndpoint = 'auditUsers'
        serviceName = 'entities/Entities'; // breeze Web API controller
        manager;

        /* When migrating breeze to angular
         https://github.com/Breeze/breeze.bridge2.angular
         How to use - note Adapter registering in ctor: 
        https://github.com/Breeze/temphire.angular/blob/master/TempHire/app/core/services/entity-manager-provider.ts
         [TM 13/10/22] */
        constructor(
            private $state,
            private gridSettingsService,
            private uiGridConstants,
            private $timeout,
            private csvFormattingService,
            private downloadService,
            private generalDialogService,
            private globalSpinnerService,
            private breeze,
            private gridHelperFunctionService,
        ) {

        }
        $onInit() {
            this.initBreeze();
            this.initialise()
            this.initGrid()
            this.getAuditEntries()
        }

        /* Part of the former entity-manager service - only used here [TM 13/10/22] */
        initBreeze() {
            this.breeze.NamingConvention.camelCase.setAsDefault(); // Convert properties between server-side PascalCase and client-side camelCase
            this.manager = new this.breeze.EntityManager(this.serviceName);
            this.manager.enableSaveQueuing(true);
        }

        executeQuery(query) {
            return this.manager.executeQuery(query).catch(this.queryFailed);
        }

        queryFailed(error) {
            console.error(error.message, 'Query failed');
            return Promise.reject(error)// so downstream promise users know it failed
        }

        /* end service [TM 13/10/22] */

        initialise() {
            this.defaultAction = { description: "[All Actions]", id: null };
            this.defaultType = { longDescription: "[All Actions]", id: null };
            this.defaultArtefactType = { description: "[All Types]", id: null };
            this.filter = {
                user: {},
                fromTime: new Date(),
                toTime: new Date(),
            };

            this.auditEntries = [];
            this.showTimeSpan = false;
            this.searchText = "";
            this.toDate = null;
            this.fromDate = null;
            this.disableCustom = true;
            this.timeSpanOptions = [
                { id: 1, name: 'Last 1 hour' },
                { id: 2, name: 'Last 6 hours' },
                { id: 3, name: 'Last 24 hours' },
                { id: 4, name: 'Last week' },
                { id: 5, name: 'Last 2 weeks' },
                { id: 6, name: 'Last month' },
                { id: 6, name: 'Custom' },
            ];

            this.selectedTimeSpan = this.timeSpanOptions[2].name;
            this.prevSelectedTimeSpan = this.selectedTimeSpan;
            this.setFromToDate();

            this.executeQuery("AuditEntryFields").then((data) => {
                this.allFields = data.results;
                this.allFields.unshift(this.defaultAction);
                this.filter.auditEntryFields = [this.defaultAction];
            });

            this.executeQuery("AuditEntryTypes").then((data) => {
                this.allActions = data.results;
                this.allActions.unshift(this.defaultType);
                this.filter.auditEntryTypes = this.defaultType;
            });

            this.executeQuery("AuditEntryArtefactTypes").then((data) => {
                this.allTypes = data.results;
                this.allTypes.unshift(this.defaultArtefactType);
                this.filter.auditEntryArtefactTypes = this.defaultArtefactType;
            });

            (<any>window).omc.currentVm = this;
            (<any>window).omc.currentVm.refresh = () => {
                this.$state.go(this.$state.current, {}, { reload: true });
                this.getAuditEntries();
            }
        }
        initGrid() {
            this.gridApi = {};
            this.gridData = this.gridSettingsService.defaultGridOptions();
            this.gridData.gridId = 'audits-grid';
            this.gridSettingsService.initialiseGridSettings({
                id: this.gridData.gridId,
                name: 'Audits-Grid',
                columnsInfo: []
            });
            this.gridData.onRegisterApi = (api) => {
                this.gridApi = api;

                $(window).off('resize');
                $(window).on('resize', () => {
                    this.gridSettingsService.setParentHeight($('#admin-view'), $('#user-grid'));
                    this.gridSettingsService.setScrollBar($('#user-grid'), this.gridData);
                });
                this.gridApi.core.on.sortChanged(null, this.gridHelperFunctionService.sortChanged);
                this.gridApi.pinning.on.columnPinned(null, (col, e) => this.gridHelperFunctionService.columnPinned(col, e, this.gridData.gridId));
            }
            this.gridData.data = this.auditEntries;
            this.gridData.appScopeProvider = this;

            this.gridData.columnDefs = [
                {
                    name: "dateTime", displayName: this.getDesc("dateTime"), cellFilter: 'omcDate', width: this.gridSettingsService.columnWidths.dateTime
                },
                {
                    name: "userFullName", displayName: this.getDesc("userFullName"), width: this.gridSettingsService.columnWidths.userName,
                    cellTemplate: '<div class="ui-grid-cell-contents"><a href="#" ng-click="grid.appScope.showUserProfile(row.entity.userId); $event.preventDefault();">{{row.entity.userFullName}}</a></div>'
                },
                {
                    name: "auditEntryTypeDescription", displayName: this.getDesc("auditEntryTypeDescription"), width: this.gridSettingsService.columnWidths.default
                },
                {
                    name: "artefactTypeDescription", displayName: 'Type', width: this.gridSettingsService.columnWidths.default
                },
                {
                    name: "artefact", displayName: this.getDesc("artefact"), width: this.gridSettingsService.columnWidths.default
                },
                {
                    name: "auditEntryFieldDescription", displayName: this.getDesc("auditEntryFieldDescription"), width: this.gridSettingsService.columnWidths.default
                },
                {
                    name: "originalValue", displayName: this.getDesc("originalValue"), width: this.gridSettingsService.columnWidths.default
                },
                {
                    name: "newValue", displayName: this.getDesc("newValue"), width: this.gridSettingsService.columnWidths.default
                }
            ];
            this.gridHelperFunctionService.setSortOrder(this.gridData);
        }

        showUserProfile(id: string | number) {
            this.generalDialogService.userDetails(id);
        }

        setFromToDate() {
            this.toDate = new Date();

            if (this.selectedTimeSpan === 'Last 1 hour') {
                this.fromDate = angular.copy(this.toDate);
                this.fromDate.setHours(this.toDate.getHours() - 1);
            } else if (this.selectedTimeSpan === 'Last 6 hours') {
                this.fromDate = angular.copy(this.toDate);
                this.fromDate.setHours(this.toDate.getHours() - 6);
            } else if (this.selectedTimeSpan === 'Last 24 hours') {
                this.fromDate = angular.copy(this.toDate);
                this.fromDate.setHours(this.toDate.getHours() - 24);
            } else if (this.selectedTimeSpan === 'Last week') {
                this.fromDate = angular.copy(this.toDate);
                this.fromDate.setDate(this.toDate.getDate() - 7);
            } else if (this.selectedTimeSpan === 'Last 2 weeks') {
                this.fromDate = angular.copy(this.toDate);
                this.fromDate.setDate(this.toDate.getDate() - 14);
            } else if (this.selectedTimeSpan === 'Last month') {
                this.fromDate = angular.copy(this.toDate);
                this.fromDate.setMonth(this.toDate.getMonth() - 1);
            } else if (this.selectedTimeSpan === 'Custom') {
                //Set From Date
                this.fromDate = this.filter.dateFrom;
                this.fromDate.setHours(this.filter.fromTime.getHours());
                this.fromDate.setMinutes(this.filter.fromTime.getMinutes());
                this.fromDate.setSeconds(this.filter.fromTime.getSeconds());

                //Set To Date
                this.toDate = this.toDate = this.filter.dateTo;
                this.toDate.setHours(this.filter.toTime.getHours())
                this.toDate.setMinutes(this.filter.toTime.getMinutes());
                this.toDate.setSeconds(this.filter.toTime.getSeconds());
            }
        }

        search() {
            var auditEntries = angular.copy(this.auditEntries);
            if (this.searchText.length > 0) {
                auditEntries = auditEntries.filter(x => (
                    (x.artefact !== null && x.artefact.search(new RegExp(this.searchText, "i")) >= 0)
                    || (x.auditEntryTypeDescription !== null && x.auditEntryTypeDescription.search(new RegExp(this.searchText, "i")) >= 0)
                    || (x.auditEntryFieldDescription !== null && x.auditEntryFieldDescription.search(new RegExp(this.searchText, "i")) >= 0)
                    || (x.originalValue !== null && x.originalValue.search(new RegExp(this.searchText, "i")) >= 0)
                    || (x.newValue !== null && x.newValue.search(new RegExp(this.searchText, "i")) >= 0)
                    || (x.artefact !== null && x.artefact.search(new RegExp(this.searchText, "i")) >= 0)));
            }
            this.gridApi.core && this.gridApi.core.handleWindowResize();
            this.gridData.data = auditEntries;
            this.gridApi.core && this.gridApi.core.notifyDataChange(this.uiGridConstants.dataChange.COLUMN);
        }

        getAuditEntries() {
            this.setFromToDate();
            var query = this.breeze.EntityQuery.from("AuditEntryVm").orderBy("DateTime desc");
            var pred = null;

            if (this.filter.auditEntryFields) {
                this.filter.auditEntryFields.forEach((auditEntryField) => {
                    if (auditEntryField.id) {
                        pred = this.orPred(pred, 'AuditEntryFieldId', '==', auditEntryField.id);
                    }
                });
            }

            var newPred = null;
            if (this.filter.auditEntryTypes) {
                if (this.filter.auditEntryTypes.id) {
                    newPred = this.orPred(newPred, 'AuditEntryTypeId', '==', this.filter.auditEntryTypes.id);
                }
                pred = pred && newPred ? (pred.and(newPred)) : (pred || newPred);
            }

            if (this.filter.auditEntryArtefactTypes) {
                if (this.filter.auditEntryArtefactTypes.id) {
                    newPred = this.orPred(newPred, 'AuditEntryArtefactTypeId', '==', this.filter.auditEntryArtefactTypes.id);
                }
                pred = pred && newPred ? (pred.and(newPred)) : (pred || newPred);
            }


            if (this.filter.user && this.filter.user.id) {
                pred = this.addPred(pred, 'UserId', '==', this.filter.user.id);
            }

            if (this.fromDate) {
                pred = this.addPred(pred, 'DateTime', '>=', this.fromDate);
            }

            if (this.toDate) {
                pred = this.addPred(pred, 'DateTime', '<=', this.toDate);
            }

            if (pred) {
                query = query.where(pred);
            }

            this.globalSpinnerService.addSpinner(query);

            this.executeQuery(query).then((data) => {
                this.gridSettingsService.setParentHeight($('#admin-view'), $('#user-grid'));
                this.gridSettingsService.setScrollBar($('#user-grid'), this.gridData);

                this.auditEntries = data.results;
                this.search();

                this.$timeout(() => { });
                this.globalSpinnerService.removeSpinner(query);
            }, (response) => {
                this.globalSpinnerService.removeSpinner(query);
            });
        }
        export() {
            this.exportSelection(this.auditEntries);
        }
        exportSelection(auditEntries) {
            var fields = ["dateTime", "userFullName", "auditEntryTypeDescription", "auditEntryFieldDescription", "originalValue", "newValue"];
            var data = this.csvFormattingService.toCsv(auditEntries, fields, this.getDesc);
            this.downloadService.downloadFile(data, "audit.csv", 'csv');
        }

        resetFilters() {
            this.searchText = "";
            this.filter.fromTime = null;
            this.filter.toTime = null;
            this.selectedTimeSpan = this.timeSpanOptions[2].name;
            this.setFromToDate();
            this.filter.auditEntryTypes = this.defaultType;
            this.filter.auditEntryArtefactTypes = this.defaultArtefactType;
            this.filter.user = {};
            this.getAuditEntries();
        }

        refreshFilters() {
            this.setFromToDate();
            this.getAuditEntries();
        }

        changedTimeSpan() {
            if (this.selectedTimeSpan === "Custom")
                this.disableCustom = false;
            else
                this.disableCustom = true;
            this.setFromToDate();
        }

        showTimeSpanControl() {
            if (this.showTimeSpan === true) {
                this.showTimeSpan = false;
            }
            else {

                this.filter.fromTime = new Date();
                this.filter.toTime = new Date();
                this.filter.fromTime.setHours(0, 0, 0, 0);
                this.filter.toTime.setHours(0, 0, 0, 0);

                //Set the dates & Time
                if (this.fromDate) {
                    this.filter.dateFrom = this.fromDate;
                    this.filter.fromTime.setHours(this.fromDate.getHours());
                    this.filter.fromTime.setMinutes(this.fromDate.getMinutes());
                    this.filter.fromTime.setSeconds(this.fromDate.getSeconds());
                } else {
                    this.filter.dateFrom = new Date();
                }

                if (this.toDate) {
                    this.filter.dateTo = this.toDate;
                    this.filter.toTime.setHours(this.toDate.getHours());
                    this.filter.toTime.setMinutes(this.toDate.getMinutes());
                    this.filter.toTime.setSeconds(this.toDate.getSeconds());
                } else {
                    this.filter.dateTo = new Date();
                }
                this.prevSelectedTimeSpan = this.selectedTimeSpan;
                this.showTimeSpan = true;
            }
        }
        hideTimeSpanControl() {
            this.selectedTimeSpan = this.prevSelectedTimeSpan;
            this.setFromToDate();
            this.showTimeSpan = false;
        }

        okTimeSpanControl() {
            this.setFromToDate();
            this.showTimeSpan = false;
        }

        addPred(pred, p1, p2, p3) {
            var predNew = this.breeze.Predicate(p1, p2, p3);
            if (!(pred)) {
                return predNew;
            }
            return pred.and(predNew);
        }

        orPred(pred, p1, p2, p3) {
            var predNew = this.breeze.Predicate(p1, p2, p3);
            if (!(pred)) {
                return predNew;
            }
            return pred.or(predNew);
        }

        getDesc(field) {
            switch (field) {
                case "dateTime":
                    return "Time Stamp";
                case "userFullName":
                    return 'User';
                case "auditEntryTypeDescription":
                    return 'Action';
                case "auditEntryFieldDescription":
                    return 'Property';
                case "originalValue":
                    return 'Original Value';
                case "newValue":
                    return 'New Value';
                case "artefact":
                    return 'Artefact';
            }
            return field;
        }
    }
}

angular
    .module('omc')
    .component(auditEntryComponent.selector, auditEntryComponent);
