import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { UpgradeModule } from '@angular/upgrade/static';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from "@angular/forms";
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { LocationUpgradeModule } from '@angular/common/upgrade';
import { DragDropModule } from '@angular/cdk/drag-drop';

// vendors
import { CookieService } from 'ngx-cookie-service';
import { ToastrModule } from 'ngx-toastr';
import { TableModule } from 'primeng/table';
import { FilterService } from 'primeng/api';

/* file imports [TM 20/09/22] */

/* constants import [TM 22/09/22] */
import { toastrConfig } from './core/constants/toastr.constants';

/* services import [TM 22/09/22] */
import { AJS_UPGRADED_PROVIDERS } from './ajs-upgraded-providers';
import { ANG_DOWNGRADED_PROVIDERS } from './ang-downgraded-providers';

/* components import [TM 29/11/22] */
import { ANG_DOWNGRADED_COMPONENTS } from './ang-downgraded-components';

/* directives import [TM 29/11/22] */
import { OmcSpinnerDirective } from './shared/components/spinner/ang-omc-spinner.directive';
import { HasPermissionDirective } from './shared/directives/hasPermission.directive';

/* Avoid using relative path to file imports [TM 25/08/22] */

@NgModule({
    declarations: [ 
        ANG_DOWNGRADED_COMPONENTS,
        OmcSpinnerDirective,
        HasPermissionDirective
    ],
    imports: [
        BrowserModule,
        UpgradeModule,
        DragDropModule,
        HttpClientModule,
        BrowserAnimationsModule,
        FormsModule,
        ToastrModule.forRoot(toastrConfig),
        MatDialogModule,
        CommonModule,
        TableModule,
        LocationUpgradeModule.config({
            useHash: true,
            hashPrefix: '!'
          })
    ],
    providers: [CookieService, AJS_UPGRADED_PROVIDERS, ANG_DOWNGRADED_PROVIDERS, FilterService],
    entryComponents: [
        ANG_DOWNGRADED_COMPONENTS
    ]
})
export class AppModule {
    // Override Angular bootstrap so it doesn't do anything
    ngDoBootstrap() {}
}
