import { OmcComponent } from "shared/models/omc.types";
import {OmcFunc} from '../../shared/functions/omc.functions';
import * as angular from 'angular';

let fileUploaderComponent: OmcComponent = {
    selector: 'fileUploaderComponent',
    template: require('./file-uploader.component.html'),
    controller: class FileUploadController implements angular.IController {
        lang: any;
        errors: any = {};
        files: any[] = [];
        renameFileIfExist: boolean = true;
        renamingStrategy: any;
        customNamingStrategy: any = null;
        customNameRegex: RegExp = /^[\w,\s-]+$/;
        selectedDataConnection: any;
        isUploading: boolean;
        dataConnections: any[];

        constructor(
            private $scope,
            private fileUploadService,
            private dataService,
            language,
        ) {
            this.lang = language.fileRenamingForm;
        }

        $onInit() {
            this.renameFileIfExist = true;
            this.renamingStrategy = this.lang.options.renamingStrategy.date.value

            this.files = this.getDataFromSessionsStorage();

            this.getDataConnections().then(dataConnections => {
                this.dataConnections = dataConnections;
                this.selectedDataConnection = this.extractDefaultDataConnection(dataConnections)
            })
        }

        getDataFromSessionsStorage() {
            if (sessionStorage.length > 0) {
                var storedData = JSON.parse(sessionStorage.getItem('files'));
                return storedData || []
            } else {
                return [];
            }
        }

        getDataConnections(){
            let url = `dataConnection?skipCurrentUserFilter=true`;
            return this.dataService.getData(url, url, true)
        }

        extractDefaultDataConnection(dataConnections) {
            if (dataConnections.length === 0) return null;
            let activeConnections = dataConnections.filter(x => x.connectionType === 2 && x.status === true);
            return activeConnections?.find(x => x.isDataConnInputDefault) || null;
        }

        uploadFiles(files: any[], errFiles) {
            let pendingFiles = files
                .filter(file => {
                    return this.files.findIndex(f => f.file.name === file.name) === -1
                })
                .map(file => {
                    return {
                        file: file,
                        status: "Pending",
                        isProcessed: false,
                        donePercent: 0,
                        backgroundColor: '#1195c6',
                        fileSize: OmcFunc.formatBytes(file.size) 
                    }
                })
            this.files.push(...pendingFiles);

            this.updateSessionStorage();
        }

        updateSessionStorage() {
            sessionStorage.removeItem('files');
            let fileData = this.files.map((file) => {
                return {
                    file: {
                        name: file.file.name,
                        lastModified: file.file.lastModifiedDate,
                        webKitRelativePath: file.file.webKitRelativePath,
                        size: file.file.size,
                        type: file.file.type
                    },
                    status: file.status,
                    isProcessed: file.isProcessed,
                    donePercent: file.donePercent,
                    backgroundColor: file.backgroundColor,
                    fileSize: file.fileSize
                };
            })
            sessionStorage.setItem('files', JSON.stringify(fileData));
        }

        removeFile(fileInfo) {
            if (fileInfo.status === 'Uploading') return;
            let index = this.files.findIndex(file => file.file.name === fileInfo.file.name);

            if (index > -1) {
                this.files.splice(index, 1);
            }
        }

        clearList() {
            this.files = [];
            sessionStorage.removeItem('files');
        }

        uploadFilesToServer() {
            if (this.files.filter(f => f.isProcessed === false).length > 0) {
                let fileInfo = this.files.find(f => f.isProcessed === false);
                if (fileInfo !== undefined) {
                    fileInfo.status = "Uploading";
                    let conflictStrategy = {
                        renameFileIfExist: this.renameFileIfExist,
                        renamingStrategy: this.renamingStrategy,
                        customNamingStrategy: this.customNamingStrategy,
                    }

                    this.fileUploadService.uploadFileToPath(fileInfo, this.selectedDataConnection.uncFilePath, 'api/uploadFiletoServer', conflictStrategy, true)
                        .then((response) => {
                                var successFile = response.file;
                                var file = this.files.find(x => x.file.name === successFile.name);
                                var index = this.files.indexOf(file);
                                if (index > -1) {
                                    file.isProcessed = true;
                                    file.status = "Uploaded";
                                    file.donePercent = 100;
                                    file.backgroundColor = '#1195c6';
                                    console.warn("file " + file.file.name + " removed from array"); /* Not sure why this warning is here [TS 16/11/20] */
                                }
                        }, (error) => {
                            var errorFile = error.config.data.file;
                            var file = this.files.find(x => x.file.name === errorFile.name);
                            file.isProcessed = true;
                            file.status = "Error";
                            file.donePercent = 100;
                            file.backgroundColor = "#F2672E";
                            console.error("file " + file.file.name + " error occurred");
                        }).finally(() => {
                            if (this.files.filter(f => f.isProcessed === false).length > 0) {
                                this.uploadFilesToServer();
                            }
                            else {
                                this.isUploading = false;
                                this.updateSessionStorage();
                            }
                        });
                }
            }
        }
        retryFile(fileInfo){
            Object.assign(fileInfo, {
                file: fileInfo.file,
                status: "Pending",
                isProcessed: false,
                donePercent: 0,
                backgroundColor: '#1195c6',
                fileSize: OmcFunc.formatBytes(fileInfo.file.size)
            })
        }
        isInvalid(key) {
            var valid = this.validate();
            if (key) {
                return this.errors[key];
            }
            return !valid;
        }

        validate() {
            this.errors = {};
            let valid = true;
            let fileCount = this.files?.length;

            if (!this.selectedDataConnection) {
                this.errors.dataConnection = "Please select the managed data connection to which the files will be uploaded:";
                valid = false;
            }
            if (this.renameFileIfExist && this.renamingStrategy === 'custom' && !this.customNamingStrategy) {
                this.errors.customRename = "Please enter a custom name"
                valid = false;
            }
            if (fileCount === 0 || this.isUploading) {
                valid = false;
            }
            else if (fileCount > 0 && (this.files.filter(f => f.isProcessed === true).length === fileCount)) {
                valid = false;
            }

            return valid;
        };

        errorMessageFor() {
            this.validate();
            return this.errors;
        };


        onConflictStrategyChange() {
            if (!this.renameFileIfExist) {
                this.renamingStrategy = null;
                this.onRenamingStrategyChange()
            } else {
                this.renamingStrategy = this.lang.options.renamingStrategy.date.value;
            }
        }

        onRenamingStrategyChange() {
            if (this.renamingStrategy !== this.lang.options.renamingStrategy.custom.value) {
                this.customNamingStrategy = null;
            }
        }
    }
}

angular
    .module('omc')
    .component(fileUploaderComponent.selector, fileUploaderComponent)