import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import * as angular from 'angular'

@Injectable({
    providedIn: 'root'
  })
export class CsvFormattingService {
    quot = '"';
    regQuote = new RegExp(this.quot, 'g');

    constructor() {}

    quote(val) {
        if (!val) {
            return this.quot + this.quot;
        }
        try {
            return this.quot + this.escapeQuotes(val.trim()) + this.quot;
        }
        catch (ex) {
            return this.quot + this.escapeQuotes(val.toString().trim()) + this.quot;
        }
    }

    escapeQuotes(str) {
        return str.replace(this.regQuote, this.quot + this.quot);
    }

    toCsv(data, fields, descFn) {
        let rtn = this.makeHeaderRow(fields, descFn);
        rtn += this.convertToCsv(data, fields);
        return rtn;
    }

    makeHeaderRow(fields, descFn) {
        let line = "";
        for (let val of fields) {
            let valDesc = descFn ? descFn(val) : val;
            if (line !== '') {
                line += ',';
            }
            line += this.quote(valDesc);
        }
        return line + '\r\n';
    }

    convertToCsv(array, fields) {
        let str = '';
        for (const item of array) {
            str += this.writeLine(item, fields);
        }
        return str;
    }

    writeLine(item, fields) {
        let line = '';
        for (const item1 of fields) {
            if (line !== '') {
                line += ',';
            }
            line += this.quote(item[item1]);
        }
        return line + '\r\n';
    }
}
angular
    .module('omc')
    .factory('csvFormattingService', downgradeInjectable(CsvFormattingService))
