(()=>{
    angular.module('omc').directive('titleFromContent', contentTitleDirective)
    function contentTitleDirective($interpolate){
        return {
            restrict: "A",
            link: function ($scope, $element, iAttrs) {
                $element.on('mouseover',()=>{
                    let innerText = $interpolate($element.text().trim().replace(/\s+/g,' '))($scope)
                    iAttrs.$set('title',innerText)
                })
            }
        }
    }
})()