import { Inject, Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { RootScope, StateService } from '../../ajs-upgraded-providers';
import * as angular from 'angular';

/* TODO: needs to be rewritten when moving to angular router system [TM 13/07/22] */
@Injectable()
export class RedirectionService {
    constructor(@Inject(StateService) private state, @Inject(RootScope) private rootScope) {}

    redirectToLogin(nextState=undefined) {
        //authentication is not required on login/reset password
        if (this.state.current.name === 'login' || this.state.current.name === 'resetPassword') {
            // already at login
            return;
        }
        nextState = nextState || this.state.current.name;

        let homeParamObj = null;
        if (nextState === 'homeNotification') {
            if (this.state.params.id) {
                homeParamObj = { next: nextState, id: this.state.params.id };
            }
        }
        let url = null;
        if (homeParamObj) {
            url = this.state.href('login', homeParamObj) || this.state.href();
        } else {
            url = this.state.href('login', { next: nextState }) || this.state.href();
        }

        window.location.href = window.location.origin + '/' + url;
        window.location.reload();
    }

    redirectToDefault() {
        this.state.go('home');
        this.rootScope.$on('$stateChangeSuccess', () => {
            window.location.reload();
        });
    }
}
angular.module('omc').factory('redirectionService', downgradeInjectable(RedirectionService));
