import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import * as angular from 'angular';
import { DataService } from './data.service';

@Injectable({
    providedIn: 'root'
  })
export class UiActionsService {
    _uiActions = null;
    requestSent = false;

    constructor(private dataService: DataService) {}

    uiActions(force) {
        return new Promise((resolve) => {
            if (this._uiActions && !force) {
                resolve(this._uiActions);
            } else {
                if (!this.requestSent) {
                    this.requestSent = true;
                    this.dataService.getData('uiActionGroups', 'uiActionGroups', force).then((data: Array<any>) => {
                        resolve((this._uiActions = data.reduce((prev, cur) => [...prev, ...cur.actions], [])));
                        this.requestSent = false;
                    });
                }
            }
        });
    }

    getPermissionsForRoute(routeName, force) {
        return new Promise((resolve) => {
            this.uiActions(force).then((perms: Array<any>) => {
                let rtn = (perms || []).find((x) => x.routeName === routeName);
                if (!rtn) {
                    console.log('No permissions for ' + routeName);
                }
                resolve(rtn);
            });
        });
    }

    getPermissionsForName(permissionName, force = false) {
        return new Promise((resolve) => {
            this.uiActions(force).then((perms: Array<any>) => {
                resolve((perms || []).find((x) => x.name === permissionName));
            });
        });
    }
}

angular.module('omc').factory('uiActionsService', downgradeInjectable(UiActionsService));
