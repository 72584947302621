import * as angular from 'angular';
import { OmcComponent } from 'shared/models/omc.types';

class Choice {
    id: any;
    name: any;
    sDate: any;
    eDate: any;
    checked: any;
    children: any;
    constructor(id, name, isChecked, children) {
        this.id = id;
        this.name = name.Name;
        this.sDate = name.StartDate;
        this.eDate = name.EndDate;
        this.checked = isChecked;
        this.children = children || [];
    }
}

let pageFiltersComponent: OmcComponent = {
    selector: `pageFiltersComponent`,
    template: require('./page-filters.component.html'),
    bindings: {
        onApplyFilters: '&?',
    },
    controller: class PageFiltersController implements angular.IController {
        onApplyFilters: (obj: { changes: any }) => any;
        filterCount = 0;
        currentDataSet = {};
        loaded = false;
        selectedCollections = [];
        collections = [];
        treeNodes = [];
        filteredTasks = [];
        advancedFilters = null;

        multiSelectSettings = {
            checkBoxes: true,
            dynamicTitle: true,
            showUncheckAll: true,
            showCheckAll: true,
        };

        filterTypes = ['Date Created', 'Effective Date'];
        selectedFilterType = this.filterTypes[0];

        filterValues: any = {
            taskTypeId: 0,
            taskNameId: 0,
            dateType: this.filterTypes[0],
            collections: [],
            dates: [],
        };
        dialogService: any;
        lang: any;

        taskTypes: any;
        createdBy: any;
        createdByUser: any;
        selectedTaskType: any;
        selectedTask: any;
        tasks: any;
        id: any;
        name: any;
        sDate: any;
        eDate: any;
        checked: any;
        children: any;
        this: any;
        nodes: any[];
        Choice: any;

        constructor(
            private $rootScope,
            private currentUserService,
            private taskTypeService,
            private collectionsService,
            private dataService,
            private pageFiltersService,
            private modelsService,
            private omcDialogService,
            language
        ) {
            this.dialogService = omcDialogService;
            this.lang = language;
        }

        $onInit() {
            this.LoadFilterDirective();
            this.$rootScope.$on('serverEvent:ArtefactServerEvent', this.onArtefactServerEvent.bind(this));
            this.$rootScope.$on('serverEvent:TaskTypeServerEvent', this.onTaskTypeStatusChangeServerEvent.bind(this));
            this.$rootScope.$on('refresh:filterDates', this.loadDates.bind(this));
        }

        taskTypeChanged() {
            this.selectTasksBasedOnSelectedTaskType();
        }

        showAdvFilters() {
            this.advancedFilters = {
                createdBy: this.filterValues.createdBy,
                ownerId: this.filterValues.createdByUser,
                defaultOwner: this.currentUserService.user,
                collections: this.collections.filter((x) => this.filterValues.collections.find((c) => c === x.id)),
                owner: {},
                status: false,
                notes: false,
                usedInJobs: false,
            };
            this.omcDialogService.open('advanced-page-filters', { title: 'Advanced Filter Options' });
        }

        onTaskTypeStatusChangeServerEvent(event, payload: any) {
            if (payload && payload.data && this.taskTypes) {
                if (!Array.isArray(payload.data)) {
                    for (var i = 0; i < this.taskTypes.length; i++) {
                        if (payload.data.description === this.taskTypes[i].value) {
                            this.taskTypes[i].isDisabled = payload.data.isDisabled;
                            return;
                        }
                    }
                } else {
                    for (var j = 0; j < payload.data.length; j++) {
                        for (var i = 0; i < this.taskTypes.length; i++) {
                            if (payload.data.description === this.taskTypes.value) {
                                this.taskTypes.isDisabled = payload.data.isDisabled;
                            }
                        }
                    }
                }
            }
        }

        onArtefactServerEvent(event, payload) {
            var load = payload.data;
            if (load) {
                var task = {};
                if (load.isNew) {
                    task = this.filteredTasks.find((x) => x.name === load.name);
                    if (task === undefined) {
                        this.filteredTasks.push(load);
                    }
                } else {
                    task = this.filteredTasks.find((x) => x.id === load.id);
                    if (task) {
                        var indexOf = this.filteredTasks.indexOf(task);
                        if (indexOf >= 0) {
                            this.filteredTasks[indexOf] = load;
                        }
                    }
                }
            }
        }

        countAdvFilters() {
            this.filterCount = this.filterValues.collections.length;
            if (this.createdBy) this.filterCount = this.filterCount + 1;
        }

        applyAdvancedFilters() {
            this.selectedCollections = this.advancedFilters.collections;
            this.filterValues.collections = this.selectedCollections.map((e) => e.id);
            this.createdBy = this.advancedFilters.createdBy;
            this.createdByUser = this.advancedFilters.owner;
            this.omcDialogService.close('advanced-page-filters');
            this.countAdvFilters();
        }

        LoadFilterDirective() {
            this.pageFiltersService.getFilters(true).then((data) => {
                //Init the Filter Values
                this.filterValues.taskTypeId = data.taskTypeId;
                this.filterValues.taskNameId = data.taskNameId;
                this.filterValues.dateType = data.dateType;
                this.filterValues.collections = data.collections;
                this.filterValues.createdByUser = data.createdByUser;
                this.createdBy = this.filterValues.createdBy = data.createdBy;
                this.createdByUser = { id: this.filterValues.createdByUser };
                this.filterValues.dates = data.dates;

                var forced = this.pageFiltersService.getForceDataRefreshRequired();
                Promise.all([this.loadTasks(forced), this.loadDates(forced), this.loadCollections(forced)]).then(() => {
                    this.onApplyBtnClick();
                    if (forced) {
                        this.pageFiltersService.setForceDataRefreshRequired(false);
                    }
                });
            });
        }

        SaveFilters() {
            this.filterValues.taskTypeId = this.selectedTaskType?.key;
            this.filterValues.taskNameId = this.selectedTask?.id;
            this.filterValues.createdByUser = this.createdByUser && this.createdByUser.id;
            this.filterValues.createdBy = this.createdBy;
            this.filterValues.dates = angular.copy(this.nodes);

            this.pageFiltersService.setFilters(this.filterValues).then({});
        }

        getSelectedDates(tree) {
            var dates = [];
            for (var y = 0; y < tree.length; y++) {
                if (tree[y].children != null && tree[y].children.length > 0) {
                    for (var q = 0; q < tree[y].children.length; q++) {
                        var qtr = tree[y].children[q];
                        if (qtr.children != null && qtr.children.length > 0) {
                            var selectedMonths = qtr.children.filter((node) => {
                                return node.checked;
                            });
                            if (selectedMonths.length > 0) {
                                selectedMonths = selectedMonths.map((e) => {
                                    return { sDate: e.sDate, eDate: e.eDate };
                                });
                                dates = dates.concat(selectedMonths);
                            }
                        }
                    }
                }
            }
            return dates;
        }

        loadCollections(force) {
            return new Promise((resolve, reject) => {
                this.collectionsService.getCollections(force).then(
                    (collections) => {
                        collections.map((x) => {
                            x.id = x.id;
                            x.label = x.name;
                            x.checked = this.filterValues.collections.find((c) => c === x.id) ? true : false;
                        });
                        this.collections = collections;
                        resolve(collections);
                    },
                    (error) => {
                        reject(error);
                    }
                );
            });
        }

        loadTasks(force) {
            return new Promise<void>((resolve, reject) => {
                Promise.all([
                    this.modelsService.getModelsForPicker(force),
                    this.taskTypeService.defaultTaskTypes(force),
                ]).then(
                    (data) => {
                        this.tasks = data[0];
                        this.taskTypes = data[1];
                        this.selectedTaskType = this.taskTypes.find((x) => x.key === this.filterValues.taskTypeId);
                        this.selectTasksBasedOnSelectedTaskType();
                        this.selectedTask = this.filteredTasks.find((x) => x.id === this.filterValues.taskNameId);
                        resolve();
                    },
                    (error) => {
                        reject(error);
                    }
                );
            });
        }

        loadDates(force) {
            return new Promise((resolve, reject) => {
                this.dataService.getData('allEntityDates', 'allEntityDates', force).then(
                    (data) => {
                        this.PrepareFilterData(data.filterDataDates, this.filterValues.dates);
                        resolve(data.filterDataDates);
                    },
                    (error) => {
                        reject(error);
                    }
                );
            });
        }

        selectTasksBasedOnSelectedTaskType() {
            if (this.selectedTaskType.key == 0) {
                //All Task Types
                this.filteredTasks = angular.copy(this.tasks);
                this.filteredTasks.unshift({ id: 0, name: 'All Task Names' });
                this.selectedTask = this.filteredTasks[0]; //this.filteredTasks.find(x=>x.isDefault)
            } else {
                this.filteredTasks = angular.copy(this.tasks.filter((e) => e.taskTypeId === this.selectedTaskType.key));
                this.filteredTasks.unshift({ id: 0, name: 'All Task Names' });
                //if(this.filteredTasks.find(x=>x.isDefault) != undefined){
                //    this.selectedTask = this.filteredTasks.find(x=>x.isDefault);
                //}else
                if (this.filteredTasks.length > 0) {
                    this.selectedTask = this.filteredTasks[0];
                }
            }
        }

        PrepareFilterData(fdata, initData) {
            this.treeNodes = [];
            var dateList = [];
            var id = 0;
            var defaultChecked = true;

            var yLength = fdata.length;
            for (var y = 0; y < yLength; y++) {
                //Get the year
                var year = fdata[y];
                var yearChecked = defaultChecked;
                var yearInit = initData.find((x) => x.name === year.Name);
                var qtrInit;
                var qtrList = [];

                if (yearInit) {
                    yearChecked = yearInit.checked;
                }

                //Get the Quarters
                var qLength = year.Quarters.length;
                for (var q = 0; q < qLength; q++) {
                    var qtr = year.Quarters[q];

                    //Get the persisted value for this qtr
                    var qtrChecked = defaultChecked;
                    if (yearInit) {
                        qtrInit = yearInit.children.find((x) => x.name === qtr.Name);
                        if (qtrInit) qtrChecked = qtrInit.checked;
                    }
                    var mnthList = [];

                    //Get the months
                    var mLength = qtr.Months.length;
                    for (var m = 0; m < mLength; m++) {
                        var mnthChecked = defaultChecked;
                        if (qtrInit) {
                            var mnthInit = qtrInit.children.find((x) => x.name === qtr.Months[m].Name);
                            if (mnthInit) {
                                mnthChecked = mnthInit.checked;
                            }
                        }

                        id = id + 1;
                        var month = new Choice(id, qtr.Months[m], mnthChecked, []);
                        mnthList.push(month);
                    }

                    id = id + 1;
                    var qtrN = new Choice(id, qtr, qtrChecked, mnthList);
                    qtrList.push(qtrN);
                }

                id = id + 1;
                var yearN = new Choice(id, year, yearChecked, qtrList);
                dateList.push(yearN);
            }

            this.nodes = dateList;
            //this.nodes = this.filterValues.dates;
        }

        onApplyBtnClick() {
            if (this.loaded) this.SaveFilters();
            this.loaded = true;
            const selectedNodes = this.getSelectedDates(this.nodes);
            this.onApplyFilters({
                changes: {
                    treeDates: selectedNodes,
                    selectedCollections: this.filterValues.collections,
                    selectedTask: this.selectedTask,
                    selectedTaskType: this.selectedTaskType,
                    selectedFilterType: this.selectedFilterType,
                    createdBy: this.createdBy,
                    createdByUser: this.createdByUser,
                },
            });

            this.countAdvFilters();
        }
    },
};

angular.module('omc').component(pageFiltersComponent.selector, pageFiltersComponent);
