import * as angular from 'angular'
import { OmcComponent } from 'shared/models/omc.types'
import { EditReportsDialogData } from '../edit-report/edit-report.component';

let userReportsComponent: OmcComponent = {
    selector: `userReportsComponent`,
    template: require("./user-reports.component.html"),
    bindings: {

    },
    controller: class UserReportsController implements angular.IController {
        gridHelper: any;
        model: any;
        gridData: any;
        userReports: any;

        selectedReport: any;
        reportDialogData: EditReportsDialogData;
        dialogService: any;
        lang: any;
        dialogId = 'edit-user-report';
        constructor(
            private gridHelperServiceFactory,
            private gridSettingsService,
            private dataService,
            private $state,
            private generalDialogService,
            omcDialogService,
            language,
        ) {
            this.dialogService = omcDialogService
            this.lang = language
        }

        $onInit() {
            this.initGrid();
            
            (<any>window).omc.currentVm = this;
            (<any>window).omc.currentVm.refresh = () => {
                this.$state.go(this.$state.current, {}, { reload: true });
                this.getUserReports(true);
            };
            this.getUserReports(true);
        }

        initGrid() {
            this.gridHelper = this.gridHelperServiceFactory.gridHelper('userReportsController', '#user-reports-grid', this, this.getColumnDefinition());
            this.gridHelper.gridData.gridId = 'users-reports-grid';
            this.gridSettingsService.initialiseGridSettings({
                id: this.gridHelper.gridData.gridId,
                name: 'UserReports-Grid',
                columnsInfo: []
            });
            this.gridHelper.registerRowsRenderingComplete(() => {
                if (this.model && this.model.name && this.model.name) {
                    var filterRows = this.gridData.data.filter(x => x.name === this.model.name);
                    this.gridHelper.setSelected(filterRows[0]);
                    this.model = null;
                }
            });
            this.gridData = this.gridHelper.gridData;
        }

        showUserProfile(id:string|number){
            this.generalDialogService.userDetails(id)
        }

        canRemoveDefault() {
            var selectedReport = this.gridHelper.selectedValues()[0];
            if (selectedReport) {
                return selectedReport.isDefault;
            }
            return false;
        }

        isCurrentReportValid() {
            if ((this.gridHelper.selectedValues().length > 0) && (this.gridHelper.selectedValues()[0].url !== null)) {
                return true;
            }
            return false;
        }

        previewReport() {
            if (this.gridHelper.selectedValues()[0].isOpenNewWindow) {
                window.open(this.gridHelper.selectedValues()[0].url, "_blank");
            } else {
                window.open(this.gridHelper.selectedValues()[0].url, "_self");
            }

        }

        canSetDefault() {
            var selectedReport = this.gridHelper.selectedValues()[0];
            if (selectedReport) {
                return !selectedReport.isDefault;
            }
            return true;
        }

        removeDefault() {
            this.gridHelper.selectedValues().length && this.gridHelper.selectedValues()[0].removeDefault().then(() => {
                this.getUserReports(true);
            });

        }

        gridRowSelected(item) {
            return this.gridHelper.selectedValues().find(x => x === item);
        }

        setDefault() {
            this.gridHelper.selectedValues().length && this.gridHelper.selectedValues()[0].setDefault().then(() => {
                this.getUserReports(true);
            });
        }        

        getUserReports(force) {

            this.dataService.getData("Report", "Report", force).then((data) => {
                this.userReports = data;
                this.userReports.map(x => x.collectionsName = x.collections.map(c => c.name).join(", "));
                this.gridHelper.dataReloaded(this.userReports);
                this.model = this.gridHelper.selectedValues()[0];
            }, (error) => {
                console.log(error);
            });
        }

        getColumnDefinition() {
            return [{
                name: 'id',
                displayName: '',
                cellTemplate: '<div class="grid-row-vertical-bar" ng-class="{\'grid-row-selected\': grid.appScope.gridRowSelected(row.entity) }" data-id="{{row.entity.id}}" role="button" tabindex="0"></div>',
                width: this.gridSettingsService.columnWidths.toggleSelect,
                pinnedLeft: true,
                cellClass: "omc-select-column"
            }, {
                name: "name",
                displayName: "Report Name",
                cellTemplate: `<div class="ui-grid-cell-contents" ng-class="{'default-icon': row.entity.isUserDefault}"><a href="#" ng-click="grid.appScope.reportDialog(row.entity).view(); $event.preventDefault();">{{row.entity.name}}</a></div>`,
                width: this.gridSettingsService.columnWidths.default
            }, {
                name: "lastRun",
                displayName: "Last Run Date",
                cellFilter: 'omcDate',
                width: this.gridSettingsService.columnWidths.default
            }, {
                name: "collectionsName",
                displayName: "Collection(s)",
                width: this.gridSettingsService.columnWidths.default
            }, {
                name: "notes",
                displayName: "Notes",
                width: this.gridSettingsService.columnWidths.notes
            }, {
                name: "createdOn",
                displayName: "Date Created",
                cellFilter: 'omcDate',
                width: this.gridSettingsService.columnWidths.default
            }, {
                name: "createdById",
                displayName: "Create By",
                width: this.gridSettingsService.columnWidths.default,
                cellTemplate: `
                <div class="ui-grid-cell-contents">
                    <a href="#" 
                        ng-click="grid.appScope.showUserProfile(row.entity.createdById); $event.preventDefault();">
                        {{row.entity.createdById}}
                    </a>
                </div>
                `,
            }];
        }

        reportDialog(report?){
            report = report || this.gridHelper.selectedValues()[0];
            let vm = this;
            let dialogData = new EditReportsDialogData({
                isSystemReport: false
            })
            return {
                add: add,
                edit: edit,
                view: view,
            }
            function add(){
                report = { collections: [] }
                dialogData.assign({
                    usedNames: vm.userReports.map(x => x.name),
                    isAdd: true,
                    isEdit: true,
                    selectDefaultCollection: true,
                })
                open('New Report')
            }
            function edit(){
                dialogData.assign({
                    usedNames: vm.userReports.filter(x=>x.id !== report.id).map(x => x.name),
                    isEdit: true,
                })
                open('Edit Report')
            }
            function view(){
                open('Report')
            }
            function open(title:string = ''){
                vm.selectedReport = angular.copy(report);
                vm.reportDialogData = dialogData
                vm.dialogService.open(vm.dialogId, {title: title})
            }
        }

        saveReport() {
            this.dataService.postData(this.selectedReport, "Report", "Report", true).then((data) => {
                this.dialogService.close(this.dialogId)
                this.userReports = data;
                this.userReports.forEach(x => x.collectionsName = x.collections.map(c => c.name).join(", "));
                this.gridHelper.dataReloaded(this.userReports);
            }, (error) => {
                console.log(error);
            });
        }
    }
}

angular
    .module('omc')
    .component(userReportsComponent.selector, userReportsComponent)