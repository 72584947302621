import * as angular from 'angular'
import { OmcComponent } from 'shared/models/omc.types'
import { HasIdAndName, TaskTypeView, RecordSampleSettingsView, TaskModelView, RunControlTableView, TableSetOptions, DataConnectionOptions, BasisSetOptions, TaskView } from "../../../shared/models/task-view.model";

let editJobTaskComponent: OmcComponent = {
    selector: `editJobTaskComponent`,
    template: require("./edit-job-task.component.html"),
    bindings: {
        task: "=",
        formData: "=",
    },
    controller: class EditJobTaskController implements angular.IController {
        errors: any;
        taskLang: any;
        fileLang: any;
        options: { schemas:any, taskTypes: any; taskModels: any; tableSets: any; basisSets: any; dataSets: any; bases: any; dataConnections: any; dataSamples: unknown[]; runControlTables: any; };
        task: TaskView;
        formData: any;
        genLang: any;

        constructor(
            private taskTypeService, 
            private dataService,
            private typeCheckerService,
            private modelsService,
            private $scope,
            language,
        ){
            this.taskLang = language.taskForm
            this.genLang = language.general
            this.fileLang = language.fileRenamingForm
            
        }
        
        $onInit(){
            this.options = {
                taskTypes: null,
                taskModels: null,
                tableSets: null,
                basisSets: null,
                dataSets: null,
                bases: null,
                dataConnections: null,
                dataSamples: Object.values(this.taskLang.options.dataSamples),
                runControlTables: null,
                schemas: null,
            }
            if(this.task && this.formData) {
                this.task = new TaskView(this.task);
                this.loadDropdownOptions()
            }
        }
        
        onChangeTaskType(){
            this.task.model = null;
            this.options.taskModels = null;
            this.onChangeTaskModel()
        }

        onChangeTaskModel(){
            this.task.tableSet = null;
            this.task.basisSet = null;
            this.task.basis = null;
            this.task.dataSet = null;
            this.task.runControlTable = null;
            this.options.tableSets = null;
            this.options.basisSets = null;
            this.options.bases = null;
            this.options.dataSets = null;
            this.options.runControlTables = null;
            if (this.task.model) {
                this.task.name = this.task.model.name
                if(this.task.isStoredProcedure()){
                    this.updateStoredProcedureTaskModel();
                    return
                }
            }
            this.loadDropdownOptions();
            
        }
        onChangeTableSet(){
            this.task.runControlTable = null;
            this.options.runControlTables = null;
            this.loadDropdownOptions()
        }
        onChangeBasisSet(){
            this.task.basis = null;
            this.options.bases = null;
            this.loadDropdownOptions()
        }
        onChangeConnection(){
            this.task.schema = null;
            this.options.schemas = null;
            this.loadDropdownOptions();
        }
        onConflictStrategyChange(){
            if (this.task.isOverwriteResults) {
                this.task.renamingStrategy = null;
            } else {
                this.task.renamingStrategy = this.fileLang.options.renamingStrategy.date.value;
            }
            this.onRenamingStrategyChange();
        }

        onRenamingStrategyChange() {
            if (this.task.renamingStrategy !== this.fileLang.options.renamingStrategy.custom.value) {
                this.task.customNamingStrategy = null
            }
        }

        isInvalid(key){
            var valid = this.validate();
            this.formData.isValid = valid
            if (key) {
                return this.errors[key];
            }
            return !valid;
        };
        errorMessageFor(){
            this.validate();
            return this.errors;
        }

        validate() {
            this.errors = {
            };
            let isValid = true;
            let task:TaskView = this.task;
            if (task?.taskType &&
                task.isCommandLine() &&
                task.model) {
                return isValid;
            }
        
            if(!task?.taskType){
                this.errors.taskType = "Please specify a Task Type";
                isValid = false;
            }
            if(!task?.model){
                this.errors.taskName = "Please specify a Task name";
                isValid = false;
            }

            if(task && !task.isStoredProcedure() && !task.isCommandLine()){
                if (!task?.dataSet) {
                    this.errors.dataSet = "Please specify a Dataset";
                    isValid = false;
                }
    
                if (!task?.tableSet) {
                    this.errors.tableSet = "Please specify a Tableset";
                    isValid = false;
                }
    
                if (!task?.basisSet) {
                    this.errors.basisSet = "Please specify a Basis Set";
                    isValid = false;
                }
    
                if (!task?.basis) {
                    this.errors.basis = "Please specify a Basis";
                    isValid = false;
                }
    
                if (!task?.outputDataConnection) {
                    this.errors.dataConnectionId = "Please specify a data connection for job results";
                    isValid = false;
                }
    
                if (this.task.hasOutputDatabaseConnection() && !task?.schema) {
                    this.errors.schema = "Specify schema";
                    isValid = false;
                }
    
                if (!task?.taskType) {
                    this.errors.taskType = "Please specify a task type";
                    isValid = false;
                }
    
                if (!task?.name) {
                    this.errors.taskName = "Please specify a task name";
                    isValid = false;
                }
                if (!task?.isOverwriteResults) {
                    if (!task?.renamingStrategy) {
                        this.errors.renamingStrategy = "Please select a renaming strategy"
                        isValid = false;
                    }
                    if (!task?.customNamingStrategy
                        && task?.renamingStrategy === this.fileLang.options.renamingStrategy.custom.value) {
                        this.errors.customNamingStrategy = "Please specify a string for renaming"
                        isValid = false;
                    }
                }
            }
            return isValid;
        }

        arrayEmpty(arr) {
            return !this.typeCheckerService.isArrayNotEmpty(arr)
        }
    

        loadDropdownOptions() {
            // Task Types
            if (!this.options.taskTypes) {
                this.updateTaskTypes()
                return;
                // set defaults
            }
            if (!this.task || !(this.task.taskType && this.task.taskType.key)) return;
    
            // Task Models aka Task Name
            if (!this.options.taskModels) {
                this.updateTaskModels(this.task.taskType.key)
                return;
            }
            if (!this.task.model) return;
    
            // Table Sets, Basis Sets, Bases, Datasets
            let options = ['tableSets', 'basisSets', 'dataSets'];
            if (options.some(o => !this.options[o]) && this.task.model.id) {
                this.updateTaskOptionsByModelId(this.task.model.id)
            }
            // Bases
            if (!this.options.bases && this.options.basisSets && this.task.basisSet) {
                let matchedBasisSet = this.options.basisSets.find(b => b.id === this.task.basisSet.id)
                this.options.bases = matchedBasisSet ? matchedBasisSet.bases : [];
            }
            // Run Control Tables
            if (!this.options.runControlTables && this.options.tableSets && this.task.tableSet) {
                let matchedTableSet = this.options.tableSets.find(b => b.id === this.task.tableSet.id)
                this.options.runControlTables = matchedTableSet ? this.extendRunControlTableOptions(matchedTableSet.runControlTables) : [];
            }
            // Results Data Connections
            if (this.arrayEmpty(this.options.dataConnections)) {
                this.updateDataConnections()
            } else if (this.arrayEmpty(this.options.schemas) && this.task.hasOutputDatabaseConnection()){
                this.updateSchemas()
            }
    
            this.updateDefaults()
        }
    
        updateDefaults() {
            if (this.arrayEmpty(this.options.taskTypes)) return;
            // Task Type
            if (!this.task.taskType ||!this.task.taskType.key) {
                this.task.taskType = this.options.taskTypes[0]
            }
            // Task Model aka Task Name
            if (this.arrayEmpty(this.options.taskModels)) return;
            // Table Set
            if (optionNotSelected(this.options.tableSets, this.task.tableSet)) {
                this.task.tableSet = this.options.tableSets[0];
            }
            // Basis Set
            if (optionNotSelected(this.options.basisSets, this.task.basisSet)) {
                this.task.basisSet = this.options.basisSets[0];
            }
            // Basis
            if (optionNotSelected(this.options.bases, this.task.basis)) {
                this.task.basis = this.options.bases[0];
            }
            // Dataset
            if (optionNotSelected(this.options.dataSets, this.task.dataSet)) {
                let langOptions = this.taskLang.options.dataset
                let taskTypeId = this.task.taskType.key
                let datasetOptions = this.options.dataSets           
                if (taskTypeId === 1){
                    this.task.dataSet = datasetOptions.find(dataset=>[0,1].every(id=>id !== dataset.id));
                } else if (taskTypeId === 2){
                    this.task.dataSet = datasetOptions.find(dataset => dataset.id === langOptions.internalGroupProjection.id);
                } else if (taskTypeId === 3){
                    this.task.dataSet = datasetOptions.find(dataset => dataset.id === langOptions.internalDataConversion.id);
                } 
                // If no dataset could be selected, select first option
                if(!this.task.dataSet){
                    this.task.dataSet = datasetOptions[0]
                }
            }
            // Dataset Sampling Strategy
            if (optionNotSelected(this.options.dataSamples, this.task.recordSampleSettings.recordSample)) {
                this.task.recordSampleSettings = new RecordSampleSettingsView() // Should I be resetting the settings?
                this.task.recordSampleSettings.recordSample = this.options.dataSamples[0];
            }
            // Results Data Connection
            if (optionNotSelected(this.options.dataConnections, this.task.outputDataConnection)) {
                this.task.outputDataConnection = this.options.dataConnections.find(x=>x.isDefault);
            } else if(this.options.dataConnections && this.task.outputDataConnection){
                this.task.outputDataConnection = this.options.dataConnections.find(x => x.id === this.task.outputDataConnection.id)
            }
            if (optionNotSelected(this.options.schemas, this.task.schema) && this.task.hasOutputDatabaseConnection()) {
                this.task.schema = null;
            }
            // Renaming Strategy
            if (this.task.isOverwriteResults === null || this.task.isOverwriteResults === undefined ){
                this.task.isOverwriteResults = true;
                this.onConflictStrategyChange();
            }
            // Run Control Table
            if (this.arrayEmpty(this.options.runControlTables)){
                this.task.hasRunControlTable = false
            } else {
                this.task.hasRunControlTable = true
            }
            if (optionNotSelected(this.options.runControlTables, this.task.runControlTable)) {
                this.task.runControlTable = this.options.runControlTables[0];
            } 
            /** Checks if the currently selected option is not available in the dropdown, or not selected */
            function optionNotSelected(options, item) {
                return Array.isArray(options) && options.length > 0 && (!item || ( item.hasOwnProperty('id') ? options.findIndex(obj => obj.id === item.id) === -1 : !options.includes(item)));
            }
            
        }
    
        updateTaskTypes() {
            this.taskTypeService.getTaskTypes().then((data) => {
                this.options.taskTypes = data.map(d => new TaskTypeView(d));
                this.updateDefaults()
                this.loadDropdownOptions();
            });
        }
    
        updateTaskModels(key) {
            let url = "getModelsByTaskType?id=" + key;
             this.dataService.getData(url, url, true).then((models) => {
                this.options.taskModels = models.map(model => new TaskModelView(model));
                this.updateDefaults()
                this.loadDropdownOptions();
            })
        }

        updateStoredProcedureTaskModel(){
            this.modelsService.getModelById(this.task.model.id, true).then((taskModel)=>{
                this.task.storedProcedureName = taskModel.storedProcedureName
                this.task.storedProcedureParams = taskModel.storedProcedureParams ?? [];
                this.task.storedProcedureDatabase = taskModel.storedProcedureDatabase ?? null;
                this.task.storedProcedureDataConnection = taskModel.storedProcedureDataConnection
                this.task = this.task;
            })
        }
    
        updateTaskOptionsByModelId(modelId) {
            let url = "getTaskOptionsByModel?id=" + modelId;
            this.dataService.getData(url, url, true).then((data) => {
                this.options.tableSets = data.tableSets && data.tableSets.map(t => new TableSetOptions(t))
                this.options.dataSets = data.dataSets && data.dataSets.map(d => new HasIdAndName(d))
                this.options.basisSets = data.basisSets && data.basisSets.map(t => new BasisSetOptions(t))
                this.extendDataSetOptions(this.options.dataSets)
                this.updateDefaults()
                this.loadDropdownOptions();
            })
           
        }
    
        updateDataConnections(): Promise<void> {
            let url = 'dataConnection?skipCurrentUserFilter=true'
            return this.dataService.getData(url, url, true).then((dataConnections) => {
                console.log(`received dataConnections`,dataConnections)
                this.options.dataConnections = dataConnections
                    .filter(dc => dc.status)
                    .map(d=>new DataConnectionOptions(d));
                this.updateDefaults()
            });
    
        }
        updateSchemas() {
            if(this.task.outputDataConnection.connectionType === 0 && (this.task?.outputDataConnection?.id ?? false)){
                const url = 'getSchemaReferences?connectionId=' + this.task.outputDataConnection.id;
                this.dataService.getData(url, url, true, false).then((schemas) => {
                    this.options.schemas = schemas;
                    this.updateDefaults()
                });
            }
        }
        /** Adds additional options to runControlTables */
        extendRunControlTableOptions(runControlTables) {
            if (!this.arrayEmpty(runControlTables)) {
                let options = [];
                options = runControlTables.map(table => new RunControlTableView(table))
                options.unshift(this.taskLang.options.runControlTable.definedFromTask)
                return options
            }
            return []
        }
        /** Adds additional options to dataSets based on taskType and other factors*/
        extendDataSetOptions(dataSets) {
            let taskTypeKey = this.task.taskType.key
            let langDataSets = this.taskLang.options.dataset;
            if (dataSets && taskTypeKey) {
                if (dataSets.filter(x => x.id === 0).length === 0) {
                    if (taskTypeKey === 2) {
                        dataSets.unshift(langDataSets.internalGroupProjection);
                    } else if (taskTypeKey === 3) {
                        dataSets.unshift(langDataSets.internalDataConversion);
                    }
                }
                if (
                    // selected task is not first task
                    !this.formData.isFirstTask                                                            
                    // selected task is not Command line or SQL task
                    && ![4, 5].some(key => key === taskTypeKey)
                    // dataset options does not have previous results option
                    && dataSets.filter(x => x.id === langDataSets.usePreviousResults.id).length === 0   
                ) {
                    dataSets.unshift(langDataSets.usePreviousResults);
                }
            }
        }

    }
}

angular
    .module('omc')
    .component(editJobTaskComponent.selector, editJobTaskComponent)