import * as angular from 'angular';
import { Component, Inject } from "@angular/core";
import { downgradeComponent } from "@angular/upgrade/static";
import { pathToApp } from '../../../core/constants/global-constants';
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DefaultDialogConfig } from '../../../shared/models/dialog-data.model';

@Component({
    selector: 'editDefaultDataConnectionComponent',
    templateUrl: pathToApp + 'admin/data-connection/edit-default-data-connection/edit-default-data-connection.component.html',
})
export class EditDefaultDataConnectionComponent {
    constructor(@Inject(MAT_DIALOG_DATA) public matDialogData: DefaultDialogConfig) {}
}
angular.module('omc').directive(
    'editDefaultDataConnectionComponent',
    downgradeComponent({
        component: EditDefaultDataConnectionComponent
    })
)