angular
    .module('omc')
    .factory(
        'gridHelperBuilderServiceLegacy',
        function ($rootScope, gridSettingsService, uiGridConstants, currentUserService, gridHelperFunctionService) {
            return {
                GridHelper: function () {
                    var _gridApi;
                    var _$grid;
                    var rowClickCallBack;
                    var rowsRenderingComplete;

                    var self = {
                        grid: null,
                        initialise: initialise,
                        gridData: {},
                        setOnClick: setOnClick,
                        onClick: onClick,
                        registerRowClickCallBack: function (callback) {
                            rowClickCallBack = callback;
                        },
                        registerRowsRenderingComplete: function (callback) {
                            rowsRenderingComplete = callback;
                        },
                        selectedValues: selectedValues,
                        toggleSelected: toggleSelected,
                        dataReloaded: dataReloaded,
                        setSelected: setSelected,
                        isSelected: function (id) {
                            console.error("Call to deprecated function 'isSelected'");
                            return selectedValues().find((x) => x.id.toString() === id.toString());
                        },
                        registerRowsProcessor: function (callBack) {
                            self.registerRowsProcessorFunc = callBack;
                        },
                        clearSelectedValues: function () {
                            _gridApi.selection.clearSelectedRows();
                        },
                        refresh: refresh,
                        onRerender: onRerender,
                    };

                    return self;

                    function gridRowSelected(entity) {
                        return selectedValues().find((x) => x === entity);
                    }
                    function refresh() {
                        _gridApi?.grid?.refresh();
                        _gridApi?.core?.notifyDataChange(uiGridConstants.dataChange.ALL);
                    }
                    function selectedValues() {
                        return _gridApi?.selection.getSelectedRows() ?? [];
                    }

                    function initialise(gridName, appScopeProvider, columnDefs) {
                        self.gridData = gridSettingsService.defaultGridOptions();
                        self.gridData.appScopeProvider = appScopeProvider;
                        if (appScopeProvider && !appScopeProvider.gridRowSelected) {
                            appScopeProvider.gridRowSelected = gridRowSelected;
                        }
                        self.gridData.columnDefs = columnDefs;

                        currentUserService.getUserSettings().then(() => {
                            //self.gridData.gridId = self.gridData.gridId;
                            gridHelperFunctionService.setSortOrder(self.gridData);
                        });
                        gridSettingsService.setParentHeight();
                        self.gridData.onRegisterApi = onRegisterApi;

                        _$grid = $(gridName);
                        return self.gridData;
                    }

                    function setSelectedRows(rows) {
                        _gridApi.selection.clearSelectedRows();
                        rows.forEach(function (x) {
                            _gridApi.selection.selectRow(x);
                        });
                    }

                    function onRerender() {
                        if (!window.resizeSetForGrid) {
                            $(window).on('resize', resize);
                            window.resizeSetForGrid = true;
                        }
                        resize();
                        setDefaultSelected();
                        if (rowsRenderingComplete) {
                            rowsRenderingComplete();
                        }

                        _gridApi && _gridApi.core && _gridApi.core.handleWindowResize();
                        // Note: the following line has been commented out as this was causing issues on iOS 13
                        //       (OMC-806) and since I can find no real reason why this should be necessary here,
                        //       there's no point in making it platform specific. Of course if this causes other
                        //       issues, please confirm it still works on the iPads and/or make the necessary
                        //       accommodations - rfs 08.01.20
                        //_gridApi && _gridApi.core && _gridApi.core.notifyDataChange(uiGridConstants.dataChange.ALL);

                        fixWonkyOmcSelectColumn();
                    }

                    function fixWonkyOmcSelectColumn() {
                        $('.omc-select-column')
                            .closest('[ui-grid-viewport]')
                            .css('overflow', '')
                            .css('overflow-y', 'hidden');
                    }

                    function dataReloaded(data, appScope) {
                        self.gridData.data = data;
                        gridHelperFunctionService.setPinnedRows(self.gridData);
                        setDefaultSelected();
                        appScope && (self.gridData.appScope = self.gridData);
                    }

                    function setDefaultSelected() {
                        if (!_gridApi) return;
                        var selectedRows = selectedValues();
                        if (selectedRows.length === 0) {
                            _gridApi.selection.selectRowByVisibleIndex(0);
                        } else {
                            setSelectedRows(selectedRows);
                        }
                    }

                    function resize() {
                        gridSettingsService.setParentHeight();
                        gridSettingsService.setScrollBar(_$grid, self.gridData);
                    }

                    function rowClick(row, $event) {
                        if ($event && !$event.fromRowChanged && !$event.ctrlKey) {
                            _gridApi.selection.clearSelectedRows();
                            setSelected(row.entity);
                            if (rowClickCallBack) rowClickCallBack(row.entity);
                            return;
                        }
                    }

                    function setSelected(entity) {
                        _gridApi.selection.selectRow(entity, { fromRowChanged: true });
                    }

                    function setOnClick(allVals) {
                        console.error("Call to deprecated function 'setOnClick'");
                        if (!allVals) {
                            console.error('setOnClick: allVals not populated! ');
                        }
                        self.allValues = allVals;

                        $('.ui-grid-row').off('keypress click');
                        $('.ui-grid-row').on('keypress click', onClick);
                    }

                    function toggleSelected(entity, $event) {
                        _gridApi.selection.toggleRowSelection(entity, { fromRowChanged: true });
                        $event.stopPropagation();
                    }

                    function onRegisterApi(api) {
                        _gridApi = api;
                        _gridApi.grid.toggleRowPinned = gridHelperFunctionService.toggleRowPinnedFn(
                            self.gridData.gridId
                        ).bind(gridHelperFunctionService);
                        _gridApi.core.on.rowsRendered(null, function () {
                            onRerender();
                        });
                        _gridApi.selection.on.rowSelectionChanged(null, rowClick);

                        _gridApi.core.on.sortChanged(null, (gridHelperFunctionService.sortChanged).bind(gridHelperFunctionService));

                        _gridApi.pinning.on.columnPinned(null, (col, e) => {
                            if (self && self.gridData && self.gridData.gridId) {
                                gridHelperFunctionService.columnPinned(col, e, self.gridData.gridId).bind(gridHelperFunctionService);
                            }
                        });
                        //   self.registerRowsProcessorFunc && _gridApi.grid.registerRowsProcessor(self.registerRowsProcessorFunc);
                    }

                    //// LEGACY FROM HERE ON
                    function onClick(e) {
                        console.error("Call to deprecated function 'onClick'");
                        var $this = $(this);
                        $rootScope.$apply(function () {
                            if (e.target.nodeName.toLowerCase() === 'a' || e.target.getAttribute('ng-click')) {
                                return;
                            }

                            const $rows = $('.ui-grid-row');
                            const currentClickedRow = $this.index();
                            const leftVal = e.pageX - $this.offset().left;
                            const cntrlIsPressed = e.ctrlKey;
                            const shiftIsPressed = e.shiftKey;

                            if (
                                leftVal < gridSettingsService.columnWidths.toggleSelect &&
                                e.target.nodeName.toLowerCase() !== 'a'
                            ) {
                                toggleValue($this);
                            } else {
                                if (cntrlIsPressed && !shiftIsPressed) {
                                    toggleValue($this);
                                } else if (!cntrlIsPressed && shiftIsPressed) {
                                    setSelectedValues($rows, self.previousClickedRow, currentClickedRow);
                                } else if (cntrlIsPressed && shiftIsPressed) {
                                    console.log('Control and Shift Key Pressed');
                                } else {
                                    setSelectedValues($rows, currentClickedRow, currentClickedRow);
                                }
                            }
                            if (!shiftIsPressed) {
                                self.previousClickedRow = $this.index();
                            }
                        });
                        $('.ui-grid-row').removeClass('parent-row-selected');
                        $('.grid-row-selected').each(function () {
                            $(this).parent().parent().parent('.ui-grid-row').addClass('parent-row-selected');
                        });
                    }

                    function setSelectedValues($rows, start, end) {
                        console.error("Call to deprecated function 'setSelectedValues'");
                        if (start > end) {
                            [end, start] = [start, end];
                        }

                        var sel = $rows.slice(start, end + 1);
                        self.selectedValues = [];
                        sel.map(function (index, item) {
                            addToSelectedValues($(item));
                        });
                    }

                    function addToSelectedValues($this) {
                        console.error("Call to deprecated function 'addToSelectedValues'");
                        var id = $this.find('[data-id]').attr('data-id');
                        if (!id) {
                            console.error('No Id', $this);
                        }
                        var item = self.allValues.find((x) => x.id.toString() === id.toString());
                        self.selectedValues.push(item);
                    }

                    function toggleValue($this) {
                        console.error("Call to deprecated function 'toggleValue'");
                        var id = $this.find('[data-id]').attr('data-id');
                        var index = self.selectedValues.findIndex((x) => x.id.toString() === id.toString());

                        if (index === -1) {
                            self.selectedValues.push(self.allValues.find((x) => x.id.toString() === id.toString()));
                        } else {
                            self.selectedValues.splice(index, 1);
                        }
                    }
                },
            };
        }
    );
