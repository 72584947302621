import * as angular from 'angular';
import { OmcComponent } from "shared/models/omc.types";

let jobSettingsComponent: OmcComponent = {
    selector: 'jobSettingsComponent',
    template: require('./job-settings.component.html'),
    controller: class JobSettingsController implements angular.IController {
        definedPriorities: { key: number, value: string }[];
        selectedJobType = null;       // the selected JobType object 
        editingJobTypeData = null;    // the JobType dialog meta data  
        selectedRolePriority = null;  // the selected role priority 
        RolePriorityDialogData = null;// the role priority dialog meta data
        gridHelperRolePriority: any;
        gridHelperJobType: any;
        settings: any = {};
        jobTypeStatuses: any;

        constructor(
            private jobSettingsService,
            private $timeout,
            private gridSettingsService,
            private jobsService,
            private gridHelperServiceFactory,
            private omcDialogService,
            private generalDialogService,
        ) { }

        $onInit() {
            this.initRolePriorityGrid()
            this.initJobTypeGrid()
            this.initWindowSizeListeners()
            this.settings.jobTypes = [];
            this.jobTypeStatuses = this.jobsService.jobTypeStatuses;
            const jobPriorityL = { key: 0, value: "Lowest" };
            const jobPriorityBN = { key: 1000, value: "Below Normal" };
            const jobPriorityN = { key: 2000, value: "Normal" };
            const jobPriorityAN = { key: 3000, value: "Above Normal" };
            const jobPriorityH = { key: 4000, value: "Highest" };
            const jobPriorityMap = {
                lowest: 0,
                belowNormal: 1000,
                normal: 2000,
                aboveNormal: 3000,
                highest: 4000
            };

            this.definedPriorities = [jobPriorityL, jobPriorityBN, jobPriorityN, jobPriorityAN, jobPriorityH];
            this.getData()
        }

        getData() {
            this.jobSettingsService.getSettings(true).then((data) => {
                this.settings = data;
                data.forEach(x => {
                    x.priorityDesc = this.definedPriorities.find(c => c.key == x.priority).value
                });
                this.gridHelperRolePriority.dataReloaded(data);
                this.loadJobTypes();
            });

        }
        
        loadJobTypes() {
            this.jobsService.getJobTypes(true).then((data) => {
                data.map(x => x.statusVal = this.jobTypeStatuses.find(c => c.id === x.status));
                this.settings.jobTypes = data;
                this.gridHelperJobType.dataReloaded(this.settings.jobTypes);
            });
        }

        initWindowSizeListeners() {
            $(document).ready(() => {
                this.calculateHeight();
            });
            this.$timeout(() => {
                if ($('.auto-vertical-scroll').length > 0) {
                    this.autoVerticalScroll();
                    $(window).on('resize', () => {
                        this.autoVerticalScroll();
                        this.calculateHeight();
                    });
                }
            });
        }

        initRolePriorityGrid() {
            this.gridHelperRolePriority = this.gridHelperServiceFactory.gridHelper('jobPrioritiesController', '#job-priorities-grid', this, this.columnDefsRolePriority());
            this.gridHelperRolePriority.gridData.gridId = 'job-priorities-grid';
            this.gridSettingsService.initialiseGridSettings({
                id: this.gridHelperRolePriority.gridData.gridId,
                name: 'JobPriorities-Grid',
                columnsInfo: []
            });

            this.gridHelperRolePriority.gridData.modifierKeysToMultiSelect = false;
            this.gridHelperRolePriority.gridData.multiSelect = false;

            this.gridHelperRolePriority.registerRowsRenderingComplete( ()=> {
                this.setSelectedRolePriorityInGrid()
            });
            this.gridHelperRolePriority.registerRowClickCallBack( (entity) =>{
                this.selectedRolePriority = entity;
            });

        }

        setSelectedRolePriorityInGrid() {
            if(!this.selectedRolePriority) return;
            let selected = this.gridHelperRolePriority?.gridData?.data?.find(x => x.id === this.selectedRolePriority.id);
            if (selected) {
                this.gridHelperRolePriority.setSelected(selected);
            }
        }

        initJobTypeGrid() {
            this.gridHelperJobType = this.gridHelperServiceFactory.gridHelper('platformAdminControllerJT', '#job-types-grid', this, this.columnDefsJobTypes());
            this.gridHelperJobType.gridData.gridId = 'job-types-grid';
            this.gridSettingsService.initialiseGridSettings({
                id: this.gridHelperJobType.gridData.gridId,
                name: 'JobTypes-Grid',
                columnsInfo: []
            });
            this.gridHelperJobType.gridData.modifierKeysToMultiSelect = false;
            this.gridHelperJobType.gridData.multiSelect = false;

            this.gridHelperJobType.registerRowsRenderingComplete(()=> {
                this.setSelectedJobTypeInGrid()
            });
            this.gridHelperJobType.registerRowClickCallBack((entity) => {
                this.selectedJobType = entity;
            });
        }
        setSelectedJobTypeInGrid() {
            if(!this.selectedJobType) return;
            let selected = this.gridHelperJobType?.gridData?.data?.find(x => x.name === this.selectedJobType.name);
            if (selected) {
                this.gridHelperJobType.setSelected(selected);
            }
        }

        showUserProfile(id: string | number) {
            this.generalDialogService.userDetails(id)
        }

        isJobTypeSelected() {
            return this.gridHelperJobType.selectedValues().length === 1;
        }

        isRolePrioritySelected() {
            return this.gridHelperRolePriority.selectedValues().length === 1
        };

        gridRowSelected(gridHelper, item) {
            return gridHelper.selectedValues().find(x => x === item);
        };

        closeDialog(id) {
            this.omcDialogService.close(id)
        }
        setDefaultJobType() {
            if (!this.isJobTypeSelected()) return;
            let selected = this.gridHelperJobType.selectedValues()[0]
            selected.setAppDefault().then(() => this.loadJobTypes());
        }

        autoVerticalScroll() {
            this.$timeout(() => {
                let availableHeight = $(window).height() - 150;
                $('.auto-vertical-scroll').height(availableHeight + "px");
                $('#job-priorities-grid').height(availableHeight / 2 + "px");
                $('#platform-admin-grid').height(availableHeight / 2 + "px");
            });
        }

        calculateHeight() {
            var winHeight = $(window).height();
            var contentAreaHeight = winHeight - 40 - 24 - 68 - 20; //-header height - footer height - label height
            var divHeight = contentAreaHeight + 'px';
            $('#GridsArea').height(divHeight);
        }

        newJobType() {
            this.selectedJobType = {
                description: '',
                name: '',
                statusVal: this.jobTypeStatuses[0],
                status: 0,
                jobApprovalRuleKeyId: 0
            };
            let usedNames = this.settings.jobTypes.map(x => x.name)
            this.editingJobTypeData = {
                isEdit: true,
                isValid: false,
                hasChanged: true,
                usedNames: usedNames
            }
            this.omcDialogService.open('edit-job-type', { title: 'Add Job Type' })
        }

        viewJobType() {
            if (!this.isJobTypeSelected()) return;
            this.selectedJobType = angular.copy(this.gridHelperJobType.selectedValues()[0]);
            if (!this.selectedJobType.jobApprovalRuleKeyId) {
                this.selectedJobType.jobApprovalRuleKeyId = 0;
            }
            let usedNames = this.settings.jobTypes
                .filter(x => x.id !== this.selectedJobType.id)
                .map(x => x.name)
            this.editingJobTypeData = {
                isEdit: false,
                isValid: true,
                hasChanged: false,
                usedNames: usedNames
            }
            this.omcDialogService.open('edit-job-type', { title: 'Job Type' })
        }

        editJobType() {
            if (!this.isJobTypeSelected()) return;

            this.selectedJobType = angular.copy(this.gridHelperJobType.selectedValues()[0]);
            if (!this.selectedJobType.jobApprovalRuleKeyId) {
                this.selectedJobType.jobApprovalRuleKeyId = 0;
            }
            let usedNames = this.settings.jobTypes
                .filter(x => x.id !== this.selectedJobType.id)
                .map(x => x.name)
            this.editingJobTypeData = {
                isEdit: true,
                isValid: true,
                hasChanged: true, // TODO: add change detection in editJobDirective [TS 13/05/20]
                usedNames: usedNames
            }
            this.omcDialogService.open('edit-job-type', { title: 'Edit Job Type' })
        }

        saveJobType(jobType) {
            if (jobType.statusVal) {
                jobType.status = jobType.statusVal.id;
            }
            this.jobsService.saveJobViewModel(jobType).then(() => {
                this.loadJobTypes()
                this.omcDialogService.close('edit-job-type')
            });
        }

        editRolePriority() {
            if (!this.isRolePrioritySelected()) return;
            this.selectedRolePriority = angular.copy(this.gridHelperRolePriority.selectedValues()[0])
            console.log(`editing`, this.selectedRolePriority)
            this.RolePriorityDialogData = {
                isEdit: true,
                isValid: true,
                hasChanged: true, // TODO: add change detection in editJobPriorities [TS 13/05/20]
                definedPriorities: this.definedPriorities,
            }
            this.omcDialogService.open("edit-job-priority", { title: 'Edit Role Priority' });
        }

        saveRolePriority() {
            let role = this.selectedRolePriority
            if (role && role.id) {
                this.jobSettingsService.saveSettings(role).then(() => {
                    this.getData()
                    this.omcDialogService.close('edit-job-priority')
                });
            }
        }

        columnDefsRolePriority() {
            return [
                {
                    name: 'id',
                    displayName: '',
                    cellTemplate: `
                    <div class="grid-row-vertical-bar" 
                        ng-click="grid.appScope.gridHelperRolePriority.toggleSelected(row.entity, $event)" 
                        ng-class="{\'grid-row-selected\': grid.appScope.gridRowSelected(grid.appScope.gridHelperRolePriority,row.entity) }" 
                        role="button" 
                        tabindex="0">
                    </div>`,
                    width: this.gridSettingsService.columnWidths.toggleSelect,
                    pinnedLeft: true,
                    cellClass: "omc-select-column"
                },
                {
                    name: 'roleName',
                    displayName: 'Role',
                    width: this.gridSettingsService.columnWidths.default
                },
                {
                    name: "priorityDesc",
                    displayName: 'Priority',
                    width: this.gridSettingsService.columnWidths.default
                },
                {
                    name: "maximumNoOfThreads",
                    displayName: 'Max No of Threads',
                    width: this.gridSettingsService.columnWidths.default
                },
                {
                    name: "defaultNoOfThreads",
                    displayName: 'Default No of Threads',
                    width: this.gridSettingsService.columnWidths.default
                },
                {
                    name: "maximumNoOfWorkers",
                    displayName: 'Max No of Workers',
                    width: this.gridSettingsService.columnWidths.default
                },
                {
                    name: "defaultNoOfWorkers",
                    displayName: 'Default No of Workers',
                    width: this.gridSettingsService.columnWidths.default
                }
            ];
        }

        columnDefsJobTypes() {
            return [
                {
                    name: 'id',
                    displayName: '',
                    cellTemplate: `
                    <div 
                        class="grid-row-vertical-bar" 
                        ng-click="grid.appScope.gridHelperJobType.toggleSelected(row.entity, $event)" 
                        ng-class="{\'grid-row-selected\': grid.appScope.gridRowSelected(grid.appScope.gridHelperJobType, row.entity) }" 
                        role="button"
                         tabindex="0">
                    </div>`,
                    width: this.gridSettingsService.columnWidths.toggleSelect,
                    pinnedLeft: true,
                    cellClass: "omc-select-column"
                },
                {
                    name: 'name',
                    displayName: 'Name',
                    cellTemplate: `
                        <div class ="ui-grid-cell-contents"  ng-class ="{'deleted-row-text': !row.entity.status, 'default-icon': row.entity.isAppDefault, 'from-model':row.entity.createdAutomatically } ">
                            <a href="#" ng-click="grid.appScope.viewJobType(); $event.preventDefault();">{{row.entity.name}}</a>
                        </div>
                    `,
                    width: this.gridSettingsService.columnWidths.default
                },
                {
                    name: "description", displayName: 'Description',
                    width: this.gridSettingsService.columnWidths.default
                },
                {
                    name: 'statusVal.name',
                    displayName: 'Status',
                    width: this.gridSettingsService.columnWidths.default
                },
                {
                    name: 'jobApprovalRule.description',
                    displayName: 'Job Approval Rule',
                    width: this.gridSettingsService.columnWidths.default
                },
                {
                    name: "dateCreated",
                    displayName: 'Date Created',
                    headerCellClass: 'DateCreated',
                    cellFilter: 'omcDate', width: this.gridSettingsService.columnWidths.dateTime
                },
                {
                    name: "createdById",
                    displayName: 'Created By',
                    headerCellClass: 'Created-By-Name',
                    width: this.gridSettingsService.columnWidths.userName,
                    cellTemplate: `
                    <div class="ui-grid-cell-contents">
                        <a href="#" 
                            ng-click="grid.appScope.showUserProfile(row.entity.createdById); $event.preventDefault();">
                            {{row.entity.createdById}}
                        </a>
                    </div>
                    `,
                }
            ];
        }
    }
}

angular
    .module('omc')
    .component(jobSettingsComponent.selector, jobSettingsComponent)
