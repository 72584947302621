import { OmcFunc } from "../../shared/functions/omc.functions";
import * as angular from 'angular'
import { OmcComponent } from '../../shared/models/omc.types'

let remoteFileBrowserComponent: OmcComponent = {
    selector: `remoteFileBrowserComponent`,
    template: require("./remote-file-browser.component.html"),
    bindings: {
        filePath: '=',
        formData: "=",
    },
    controller: class RemoteFileBrowserController implements angular.IController {
        formData: any;
        filePath: any;
        loaded: any;
        remotePath: any;
        tree: any;
        selectedNode: any;
        
        constructor(
            private dataService, 
            private fileBrowserService
        ){}
        
        $onInit(){
            this.loaded = OmcFunc.isSet(this.filePath,true) && OmcFunc.isSet(this.formData,true);
            
            if(this.loaded) {
                this.remotePath = this.formData.serverLocation;
    
                this.tree = this.fileBrowserService.createTree({
                    extensions: this.formData.filterFileExtensions,
                    treeOptions: {
                        allowDeselect: false
                    }
                })
                
                this.tree.load().then(tree =>{
                    // bring default data connection to top
                    tree.initSort({sortFn:{folders: (a,b) => {
                        return a.isDefaultDataInputPath ? -1 : 0;
                    }}})
                })
            }
            
        }

        $onDestroy(){
            /* This used to be inside the callback for $el.on('$destroy')
            and now this is called for the scope destroy 
            which may behave differently [TS 14/12/20] */
            this.tree && this.tree.suspendRequests()
        }

        expandNode(node, expanded, $path){
            if (expanded) {
                this.tree.getNodeChildren(node)
            }
        }

        showSelected(node, $path){
            if(node.entityType === 0){
                this.selectedNode = node;
                let stringPath = this.fileBrowserService.extractFullPath($path)
                this.filePath = stringPath;
                this.formData.isValid = this.validate().isValid()
            }
        };

        // Might not be needed as no UI element is currently checking for validity 
        // leaving in case that changes [TS 10/06/20]
        isInvalid(key){
            var errors = this.validate();
            this.formData.isValid = errors.isValid();
            if (key) {
                return errors[key];
            }
            return !errors.isValid();
        };

        validate() {
            var hasErrors = false;
            var rtn = { isValid: () => !hasErrors };
            if (!this || !OmcFunc.isSet(this.selectedNode)) {
                hasErrors = true;
            }
            return rtn;
        }
    }
}

angular
    .module('omc')
    .component(remoteFileBrowserComponent.selector, remoteFileBrowserComponent)