import * as angular from "angular";
import { OmcComponent } from "../../../shared/models/omc.types";

let editJobTypeComponent: OmcComponent = {
    selector: "editJobTypeComponent",
    template: require("./edit-job-type.component.html"),
    bindings: {
        jobType: "=",
        formData: "=",
    },
    controller: class EditJobTypeController implements angular.IController {
        formData: any;
        jobType: any;
        jobTypeIsActive: any;

        constructor(
            private jobsService
        ) { }
        
        $onInit() {
            if (this.jobType) {
                this.jobTypeIsActive = this.jobType.statusVal.id === this.jobsService.jobTypeStatusActive.id;
            }
        }
        
        isInvalid(key) {
            var errors = this.validate();
            if (this.formData) {
                this.formData.isValid = errors.isValid
            }
            if (key) {
                return errors[key];
            }
            return !errors.isValid;
        }

        errorMessageFor() {
            return this.validate();
        }

        setStatus() {
            if (this.jobType) {
                if (this.jobTypeIsActive) {
                    this.jobType.statusVal = this.jobsService.jobTypeStatusActive;
                } else {
                    this.jobType.statusVal = this.jobsService.jobTypeStatusSuspended;
                }
            }
        }

        validate() {
            var rtn: any = {
                isValid: true
            };
            if (this.formData && this.formData.isEdit) {
                if (!this.jobType || !this.jobType.name) {
                    rtn.name = "Please specify a value for name";
                    rtn.isValid = false;
                } else if (this.formData.usedNames.includes(this.jobType.name)) {
                    rtn.name = "Name is already in use, please use a different name";
                    rtn.isValid = false;
                }
                if (this.jobType && !this.jobType.jobApprovalRuleKeyId && this.jobType.name) {
                    if (this.jobType.jobApprovalRuleKeyId !== 0) {
                        rtn.jobApprovalRuleKey = "Please select job approval rule";
                        rtn.isValid = false;
                    }
                }
            }
            return rtn;
        }
    }
}

angular
    .module('omc')
    .component(editJobTypeComponent.selector, editJobTypeComponent)