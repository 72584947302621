angular
    .module('omc')
    .config(function ($stateProvider, $urlRouterProvider) {
    console.log('module Config')

    /*  [TM 22/08/22] */
    /* TODO: leaving untill all third party dependencies that use q have been removed, then to be removed along with current file after migration[TM 22/08/22] */
    // $qProvider.errorOnUnhandledRejections(false);
    $urlRouterProvider.otherwise('home');

    $stateProvider
        .state('login', {
            url: '/login/?next&id',
            template: `<login-component></login-component>`,
        })

        .state('loggedIn', {
            template: `<logged-in-component></logged-in-component>`,
        })

        .state('resetPassword',{
                url: '/reset-password/?t',
                template: `<reset-password-component></reset-password-component>`,
                requiresPermission: false
        })
        
        .state('administration', {
            parent: "loggedIn",
            url: '/administration',
            template: `<admin-component></admin-component>`,
            title: "Administration"
        })

        .state('platform-settings', {
            url: '/platform-settings',
            template: `<platform-settings-component></platform-settings-component>`,
            requiresPermission: true,
            parent: 'administration',
            permissionName: 'admin-view-platform-settings',
            title: "Administration"
        })

        .state('job-settings', {
            url: '/job-settings',
            template: `<job-settings-component></job-settings-component>`,
            requiresPermission: true,
            parent: 'administration',
            permissionName: 'admin-view-job-settings',
            title: "Administration"
        })

        .state('services-settings', {
            url: '/services-settings',
            template: `<services-settings-component></services-settings-component>`,
            parent: 'administration',
            requiresPermission: true,
            permissionName: 'admin-view-services-settings',
            title: "Administration"
            //title: "Services"
        })

        .state('service-account-settings', {
            url: '/service-account-settings',
            template: `<services-settings-component></services-settings-component>`,
            parent: 'administration',
            requiresPermission: true,
            permissionName: 'admin-view-services-settings',
            title: "Administration"
            //title: "Services"
        })

        .state('data-connections', {
            url: '/data-connections',
            template: `<dataconnection-component></dataconnection-component>`,
            parent: 'administration',
            requiresPermission: true,
            permissionName: 'admin-view-managed-data',
            title: "Administration"
        })

        .state('collections', {
            url: '/collections',
            template: `<collections-component></collections-component>`,
            parent: 'administration',
            requiresPermission: true,
            permissionName: 'admin-view-collections',
            title: "Administration"
        })

        .state('audit-entry', {
            url: '/audit-entry',
            template: `<audit-entry-component></audit-entry-component>`,
            parent: 'administration',
            requiresPermission: true,
            permissionName: 'admin-view-audit',
            title: "Administration"

        })
        .state('task-types', {
            url: '/task-types',
            template: "<task-types-component></task-types-component>",
            parent: 'administration',
            requiresPermission: true,
            permissionName: 'admin-view-task-types',
            title: "Administration"
        })
        .state('file-uploader', {
            url: '/file-uploader',
            template: `<file-uploader-component></file-uploader-component>`,
            parent: 'administration',
            requiresPermission: true,
            permissionName: 'admin-view-file-uploader',
            title: "Administration"
        })
        .state('file-management', {
            url: '/file-management',
            template: `<file-management-component></file-management-component>`,
            parent: 'administration',
            requiresPermission: true,
            permissionName: 'admin-view-file-management',
            title: "Administration"
        })

        .state('job-Templates', {
            url: '/job-Templates',
            template: `<job-templates-component></job-templates-component>`,
            parent: 'administration',
            requiresPermission: true,
            permissionName: 'admin-view-job-templates',
            title: "Administration"
        })
        .state('tasks-view', {
            parent: "loggedIn",
            url: '/tasks',
            template: '<tasks-component></tasks-component>',
            requiresPermission: true,
            permissionName: 'models-view',
            title: "Tasks"
        })

        .state('jobs-view', {
            parent: "loggedIn",
            url: '/jobs',
            template: `<jobs-component></jobs-component>`,
            requiresPermission: true,
            permissionName: 'jobs-view',
            title: "Jobs"
        })

        .state('job-details', {
            parent: "loggedIn",
            url: '/jobs/:id',
            template: `<jobs-component></jobs-component>`,
            requiresPermission: true,
            permissionName: 'jobs-view',
            title: "Jobs"
        })

        .state('home', {
            parent: "loggedIn",
            url: '/home',
            template: "<home-component></home-component>",
            requiresPermission: false,
            title: "Home"
        })

        .state('homeNotification', {
            parent: "loggedIn",
            url: '/home/?id',
            template: `<home-component></home-component>`,
            requiresPermission: false,
            title: "Home"
        })

        .state('tableSets', {
            parent: "loggedIn",
            url: '/tableSets',
            template: `<table-set-component></table-set-component>`,
            requiresPermission: true,
            permissionName: 'tables-view',
            title: "Tables"
        })

        .state('bases', {
            parent: "loggedIn",
            url: '/bases',
            template:'<bases-component></bases-component>',
            requiresPermission: true,
            permissionName: 'basis-view',
            title: "Bases"
        })

        .state('reports', {
            parent: "loggedIn",
            url: '/reports',
            template: `<reports-component></reports-component>`,
            requiresPermission: true,
            permissionName: 'results-view',
            title: "Reports"
        })

        .state('system-dashboard', {
            url: '/systemDashboard',
            template: `<system-dashboard-component></system-dashboard-component>`,
            requiresPermission: true,
            parent: 'reports',
            permissionName: 'admin-view-platform-settings',
            title: "Reports"
        })

        .state('system-reports', {
            url: '/systemReports',
            template: '<system-reports-component></system-reports-component>',
            requiresPermission: true,
            parent: 'reports',
            permissionName: 'admin-view-platform-settings',
            title: "Reports"
        })

        .state('user-reports', {
            url: '/userReports',
            template: '<user-reports-component></user-reports-component>',
            requiresPermission: true,
            parent: 'reports',
            permissionName: 'admin-view-platform-settings',
            title: "Reports"
        })

        .state('dataSets', {
            parent: "loggedIn",
            url: '/dataSets',
            template: `<datasets-component></datasets-component>`,
            requiresPermission: true,
            permissionName: 'datasets-view',
            title: "Datasets"
        })

        .state('activity', {
            parent: "loggedIn",
            url: '/activity',
            template: "<activity-monitor-component></activity-monitor-component>",
            requiresPermission: true,
            permissionName: 'activity-view',
            title: "Activity"
        })
});