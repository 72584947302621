import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import * as angular from 'angular';
import { ApiService } from './api.service';
import { SerialisationService } from './serialisation.service';

@Injectable({
    providedIn: 'root'
  })
export class DefaultItemService {
    settingsLevel = [
        { key: 'application', id: 1 },
        { key: 'user', id: 0 },
    ];
    defaultItems = [];
    appDefaultItems = [];
    emptyDefaultItems = [];
    applicationLevelKeys = [
        'dataConnection',
        'collection',
        'jobTypes',
        'JobTemplates',
        'dataConnectionInputs',
        'dataConnectionResults',
    ]; //Add the key here if you want to apply application level settings.
    requestSent = false;

    constructor(private apiService: ApiService, private serialisationService: SerialisationService) {}

    getSettingLevel(key) {
        if (this.applicationLevelKeys.find((x) => x === this.getDefaultKey(key))) return this.settingsLevel[0];

        return this.settingsLevel[1];
    }

    refreshDefaults(force, key) {
        if (key) {
            return this.refreshAppDefaults(force, key);
        }
    }
    
    ongoingRequest: Promise<any>;
    refreshAppDefaults(force, key) {
        if (!force && Object.keys(this.defaultItems).length > 0) {
            return Promise.resolve(this.appDefaultItems);
        }

        // If a request is already in progress, return the existing Promise
        if (this.requestSent) {
            return this.ongoingRequest;
        }

        const url = 'GetAllDefaultItems';
        this.requestSent = true;

        this.ongoingRequest = new Promise((resolve, reject) => {
            this.apiService.get(url, null, false)
                .then((result: any[]) => {
                    this.defaultItems = [];
                    this.appDefaultItems = [];
                    result.forEach(item => {
                        if (item.userId === '-1') {
                            this.appDefaultItems[item.itemName] = item.itemValue;
                        } else {
                            this.defaultItems[item.itemName] = item.itemValue;
                        }
                    });
                    resolve(this.appDefaultItems);
                })
                .finally(() => {
                    this.requestSent = false;
                    this.ongoingRequest = null;
                });
        });

        return this.ongoingRequest;
    }

    isDefault(key, item) {
        let itemKey = this.getDefaultKey(key);
        return this.defaultItems[itemKey] && this.defaultItems[itemKey] == item.id;
    }

    isAppDefault(key, item) {
        let itemKey = this.getDefaultKey(key);
        return this.appDefaultItems[itemKey] && this.appDefaultItems[itemKey] == item.id;
    }

    setDefaults(key, results) {
        let itemKey = this.getDefaultKey(key);
        if (results && results.length > 0 && Array.isArray(results)) {
            results.map((x) => this.setIsDefault(itemKey, x, results));
        }
    }

    clearDefaults(key, results) {
        this.defaultItems = [];
        this.appDefaultItems = [];
    }

    getDefaultKey(key) {
        if (key === 'modelsSimple') {
            return 'models';
        }
        if (key.search('collection') === 0) {
            return 'collection';
        }
        if (key.search('dataConnection') === 0) {
            return 'dataConnection';
        }
        if (key.search('jobTypes') === 0) {
            return 'jobTypes';
        }
        if (key.search('JobTemplates') === 0) {
            return 'JobTemplates';
        }
        if (key.search('dataConnectionInputs') === 0) {
            return 'dataConnectionInputs';
        }
        if (key.search('dataConnectionResults') === 0) {
            return 'dataConnectionResults';
        }
        return key;
    }

    setIsDefault(key, item, results) {
        let itemKey = this.getDefaultKey(key);
        let settingLevel = this.getSettingLevel(itemKey);

        if (
            item.id === undefined ||
            (this.defaultItems.indexOf(itemKey) !== -1 && this.appDefaultItems.indexOf(itemKey) !== -1)
        ) {
            return;
        }

        //If it is an application level setting, then override it with the user level setting. Otherwise Application level will be used
        if (settingLevel.key === 'application') {
            if (this.defaultItems[itemKey] != null) item.isDefault = this.defaultItems[itemKey] == item.id;
            else item.isDefault = this.appDefaultItems[itemKey] == item.id;
        } else {
            item.isDefault = this.defaultItems[itemKey] == item.id;
        }

        item.isUserDefault = this.defaultItems[itemKey] == item.id;
        item.isAppDefault = this.appDefaultItems[itemKey] == item.id;

        item.setDefault = () => {
            let promise = this.setDefault(itemKey, item);
            this.setDefaults(itemKey, results);
            return promise;
        };
        item.setAppDefault = () => {
            return this.setAppDefault(itemKey, item).then((response) => {
                this.setDefaults(itemKey, results);
                return response;
            });
        };
        item.removeDefault = () => {
            return this.removeDefault(itemKey, item);
        };

        if (itemKey === 'dataConnection') {
            item.isDataConnInputDefault = this.appDefaultItems['dataConnectionInputs'] == item.id;
            item.isDataConnResultsDefault = this.appDefaultItems['dataConnectionResults'] == item.id;

            item.setOtherDefault = (type) => {
                this.setAppDefault(type, item);
                this.setDefaults(type, results);
            };
            item.removeOtherDefault = (type) => {
                return this.removeDefault(type, item);
            };
        }
    }

    setDefault(key, item) {
        this.defaultItems[this.getDefaultKey(key)] = item.id;
        let url = 'setDefaultItem';
        let value = { ItemValue: item.id, ItemName: key };
        let valStr = this.serialisationService.serialiseJsonGraph(value);
        this.defaultItems = [];
        this.appDefaultItems = [];
        return this.apiService.post(url, valStr);
    }

    removeDefault(key, item) {
        this.defaultItems[this.getDefaultKey(key)] = item.id;
        let url = 'removeDefaultItem';
        let value = { ItemValue: item.id, ItemName: key };
        let valStr = this.serialisationService.serialiseJsonGraph(value);
        this.defaultItems = [];
        this.appDefaultItems = [];
        return this.apiService.post(url, valStr);
    }

    setAppDefault(key, item) {
        this.appDefaultItems[this.getDefaultKey(key)] = item.id;
        let url = 'setAppDefaultItem';
        let value = { ItemValue: item.id, ItemName: key };
        let valStr = this.serialisationService.serialiseJsonGraph(value);
        this.defaultItems = [];
        this.appDefaultItems = [];
        return this.apiService.post(url, valStr);
    }
}
angular.module('omc').factory('defaultItemService', downgradeInjectable(DefaultItemService));
