var modules = [
    'breeze.angular',
    'checklist-model',
    'ngFileUpload',
    'ui.bootstrap',
    'ui.grid',
    'ui.grid.autoResize',    
    'ui.grid.cellNav',
    'ui.grid.edit',
    'ui.grid.moveColumns',    
    'ui.grid.pinning',
    'ui.grid.resizeColumns',
    'ui.grid.selection',
    'ui.grid.grouping',
    'cp.ngConfirm',
    'ui.router',
    'angularjs-dropdown-multiselect',
    'treeControl',
    'omcDialog',
    'iconRegistryModule'
];
angular.module('omc', modules);


/* Not sure what the purpose of this block [TS 29/09/20] */
$(document).ready(function () {
    var getGridOptionId = '';
    $('.ui-grid-column-menu-button, .ui-grid-header-cell-primary-focus').on('keydown click', function (e) {
        if (e.which === 13) {
            $(this).click();
        }
        if ($(this).hasClass('ui-grid-column-menu-button')) {
            getGridOptionId = '#' + $(this).attr('id');
        }
    });
    $(document).on('keydown', function (e) {
        if (e.which === 27) {  
            if($('.omc-modal').length > 0){
                $('.close-modal').click();
                $('.focus-keyboard-enter').focus();
            } else if (!$('.omc-dropdown-menu').hasClass('hidden')) {
                $('.saluser').click();
            } else if ($('.ui-grid-menu').length > 0) {
                $(getGridOptionId).click();
                getGridOptionId = '';
            }
        }
    });
    setTimeout(function () {
        $('main a, .omc-dropdown-menu a').on('keydown', function (e) {
            if (e.which === 13) {
                $('main a, .omc-dropdown-menu a').removeClass('focus-keyboard-enter');
                $(this).addClass('focus-keyboard-enter');
            }
        });
    },200);
});