import * as angular from 'angular'
import { OmcComponent } from 'shared/models/omc.types';

export class ModalDialogController implements angular.IController {

    constructor(
        private $scope,
        private modalDialogService
    ) { }

    $onInit() {
        this.$scope.modalDialogService = this.modalDialogService;
    }
}

const ModalDialogComponent: OmcComponent = {
    selector: `modalDialogComponent`,
    template: require('./modal-dialog.component.html'),
    bindings: {

    },
    controller: ModalDialogController
}

angular
    .module('omc')
    .component(ModalDialogComponent.selector, ModalDialogComponent)
