import * as angular from 'angular'
import { OmcComponent } from 'shared/models/omc.types'

let loggedInComponent: OmcComponent = {
    selector: `loggedInComponent`,
    template: require("./logged-in.component.html"),
    bindings: {

    },
    controller: class LoggedInController implements angular.IController {
        showGlobalSpinner: ()=>boolean;
        applicationVersionWithoutBuild: any;
        buildDescription: any;
        appBuildVersion: any;

        zoomLevels: { title: string; value: string; }[];
        zoomLevel: any;

        constructor(
            private $state,
            private currentUserService,
            private $rootScope,
            globalSpinnerService,
            private appEnvironmentDataService,
        ) {
            this.showGlobalSpinner = globalSpinnerService.showSpinner
        }

        $onInit() {
            this.$rootScope.$on("serverEvent:ISConfigChanged", () => {
                console.log("Logging out user");
                this.currentUserService.serverLogOut();
            });
            this.appEnvironmentDataService.getApplicationVersionWithoutBuild().subscribe(x => {this.applicationVersionWithoutBuild = x});
            this.appEnvironmentDataService.getBuildDescription().subscribe(x => {this.buildDescription = x})
            this.appEnvironmentDataService.getAppBuildVersion().subscribe(x => {this.appBuildVersion = x})

            this.zoomLevels = [
                { title: "small", value: "user-zoom-small" }, 
                { title: "medium", value: "user-zoom-medium" }, 
                { title: "large", value: "user-zoom-large" }
            ]

            this.zoomLevel = this.zoomLevels[1];

            this.currentUserService.updateApplicationTheme();
        }

        userZoom() {
            return this.zoomLevel.value;
        }
        showBackLink() {
            return this.$state.current.name !== "home"
        }
        title() {
            return this.$state.current.title;
        }
    }
}

angular
    .module('omc')
    .component(loggedInComponent.selector, loggedInComponent)