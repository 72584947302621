import { ChangePasswordComponent } from './user/change-password/change-password.component';
import { ChangeCompanyComponent } from './user/change-company/change-company.component';
import { NotificationDetailsComponent } from './user/user-status/notification-details/notification-details.component';
import { UserDetailsComponent } from './user/user-details/user-details.component';
import { SendCustomNotificationComponent } from './user/user-status/send-notification/send-custom-notification.component';
import { ResetPasswordComponent } from './user/reset-password/reset-password.component';
import { ConfirmDialogComponent } from './shared/dialogs/confirm-dialog/confirm-dialog.component';
import { EnumSelectorComponent } from './shared/components/enum-selector/enum-selector.component';
import { NavLinkComponent } from './shared/components/nav-link/nav-link.component';
import { ArtefactListComponent } from './shared/dialogs/batch-confirm-dialog/artefact-list/artefact-list.component';
import { BatchConfirmDialogComponent } from './shared/dialogs/batch-confirm-dialog/batch-confirm-dialog.component';
import { ProgressBarComponent } from './shared/components/progress-bar/progress-bar.component';
import { UserDetailsDialogComponent } from './shared/dialogs/user-details-dialog/user-details-dialog.component';
import { JobProgressComponent } from './shared/components/job-progress/job-progress.component';
import { EditDefaultDataConnectionComponent } from './admin/data-connection/edit-default-data-connection/edit-default-data-connection.component';
import { OmcLogoSpinnerComponent } from './shared/components/spinner/omc-logo-spinner.component';
import { CollectionSelectorComponent } from './shared/components/collection-selector/collection-selector.component';
import { EditCollectionComponent } from './admin/collections/edit-collection/edit-collection.component';
import { UserSelectComponent } from './shared/components/user-select/user-select.component';
import { SigninHistoryComponent } from './user/user-status/signin-history/signin-history.component';
import { CollectionsComponent } from './admin/collections/collections.component';
import { PrimeGridComponent } from './shared/components/prime-grid/prime-grid.component';

export const ANG_DOWNGRADED_COMPONENTS = [
  ChangeCompanyComponent, 
  NotificationDetailsComponent,
  ChangePasswordComponent,
  UserDetailsComponent,
  SendCustomNotificationComponent,
  ResetPasswordComponent,
  ConfirmDialogComponent,
  EnumSelectorComponent,
  NavLinkComponent,
  ArtefactListComponent,
  BatchConfirmDialogComponent,
  ProgressBarComponent,
  UserDetailsDialogComponent,
  JobProgressComponent,
  EditDefaultDataConnectionComponent,
  OmcLogoSpinnerComponent,
  CollectionSelectorComponent,
  EditCollectionComponent,
  UserSelectComponent,
  SigninHistoryComponent,
  CollectionsComponent,
  PrimeGridComponent,
];
