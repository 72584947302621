import * as angular from 'angular'
import { OmcComponent } from 'shared/models/omc.types';

let adminComponent: OmcComponent = {
    selector: `adminComponent`,
    template: require("./admin.component.html"),
    bindings: {
        
    },
    controller: class AdminController implements angular.IController {
        constructor(
            private uiActionsService, 
            private currentUserService, 
            private redirectionService, 
            private $state
        ){}
        
        $onInit(){
            if (this.$state.current.parent !== 'administration') {
                var allStates = this.$state.get();
                var adminChildren = [];
                allStates.map(x=> {
                    if (x.parent === "administration") {
                        adminChildren.push(x);
                    }
                });
        
                this.currentUserService.getCurrentUser().then((user)=> {
                    if (user) {
                        this.uiActionsService.uiActions().then((actions)=> {
                            var state = adminChildren.find(a => actions.find(x => !x.disabled && a.permissionName === x.name));
                            var route = state && state.name;
                            this.$state.go(route || "default");
                        });
                    }
                    else {
                        this.redirectionService.redirectToLogin('default');
                    }
                });
            }
        }
    }
}

angular
    .module('omc')
    .component(adminComponent.selector, adminComponent)