import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { DataService } from '../../services/data.service';
import * as angular from 'angular';

@Injectable({
    providedIn: 'root'
  })
export class PageFiltersService {
    forceDataRefreshRequired = false;

    constructor(private dataService: DataService) {}

    getFilters(force) {
        return this.dataService.getData('filters', 'filters', force);
    }

    setFilters(data) {
        return this.dataService.putData(data, 'filters', 'filters', false);
    }

    setForceDataRefreshRequired(value) {
        this.forceDataRefreshRequired = value;
    }

    getForceDataRefreshRequired() {
        return this.forceDataRefreshRequired;
    }
}
angular.module('omc').factory('pageFiltersService', downgradeInjectable(PageFiltersService));
