import { Component, DoCheck, Input, OnInit } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import * as angular from 'angular';
import { pathToApp } from '../../../core/constants/global-constants';

@Component({
    selector: `jobProgressComponent`,
    templateUrl: pathToApp + 'shared/components/job-progress/job-progress.component.html',
})
export class JobProgressComponent implements OnInit, DoCheck {
    showProgress: any;
    lastDonePercent: number | null = null;
    @Input() job: any;
    constructor() {}

    ngOnInit() {
        this.onChangeJobDonePercent(this.job.jobDonePercent);
    }

    ngDoCheck() {
        if (this.job.jobDonePercent !== this.lastDonePercent) {
            this.onChangeJobDonePercent(this.job.jobDonePercent);
            this.lastDonePercent = this.job.jobDonePercent;
        }
    }

    onChangeJobDonePercent(val) {
        this.showProgress = typeof val === 'number';
    }
}
angular.module('omc').directive(
    'jobProgressComponent',
    downgradeComponent({
        component: JobProgressComponent,
        inputs: ['job'],
    })
);
