import { Component, Inject, Input, OnInit } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import * as angular from 'angular';
import { StateService } from '../../../ajs-upgraded-providers';
import { DateTimeFormat } from '../../../core/constants/global-constants';
import { ModalDialogService } from '../../../shared/dialogs/modal-dialog/modal-dialog.service';
import { DataService } from '../../../shared/services/data.service';

@Component({
    selector: 'notificationDetailsComponent',
    templateUrl: 'WebApp/src/app/user/user-status/notification-details/notification-details.component.html',
})
export class NotificationDetailsComponent implements OnInit {
    @Input() notification: any;
    @Input() formData: any;

    longDateFormat = DateTimeFormat.longDateFormat;

    isLoaded: any;
    job: any;
    notificationType: any;
    notificationMessage: any;
    approvalsRequired: any;

    constructor(
        private modalDialogService: ModalDialogService,
        private dataService: DataService,
        @Inject(StateService) private state
    ) {}

    ngOnInit() {
        this.createNotification();
    }

    redirectToJob() {
        this.modalDialogService.hide();
        this.state.go('job-details', { id: this.job.id });
    }

    createNotification() {
        this.isLoaded = !!(this.notification && this.formData);
        if (!this.isLoaded) return;

        this.job = {};

        if (this.formData.isJobNotification) this.notificationType = this.notification.notificationType.longDescription;
        else this.notificationType = this.notification.notificationType.description;

        var jobName = '';
        var jobStatus = '';
        this.notificationMessage = this.notification.message;
        if (this.formData.isJobNotification) {
            var stateIndex = this.notification.message.indexOf('State: ');
            if (stateIndex >= 0) jobStatus = this.notification.message.substr(stateIndex + 6).trim();
            var jobNameIndex = this.notification.message.indexOf('Job Name: ');
            if (jobNameIndex >= 0 && stateIndex > 0 && stateIndex > jobNameIndex)
                jobName = this.notification.message.substring(jobNameIndex + 9, stateIndex - 1).trim();
            this.notificationMessage = 'The job ' + jobName + ' is in the state ' + jobStatus;
        }
        this.notification.jobName = jobName;
        this.notification.status = jobStatus;

        if (this.formData.isApprovalNotification) {
            var notificationId = this.notification.notificationId;
            this.getJobNotification(notificationId);
        }
        this.validate(this.formData.approvalMessage);
    }

    getJobNotification(notificationId: any) {
        this.dataService
            .getData(
                'getJobByNotificationId?notificationId=' + notificationId,
                'getJobByNotificationId?notificationId=' + notificationId,
                false
            )
            .then((job) => {
                this.job = job;
                this.approvalsRequired =
                    this.job.approvalsRequired - this.job.approvers.filter((c) => c.isApproved === true).length;
                if (this.approvalsRequired < 0) this.approvalsRequired = 0;
            });
    }

    validate(approvalMessage: string) {
        if (this.formData.isApprovalRequest) {
            this.formData.isValid = approvalMessage && approvalMessage.length > 0;
        } else {
            this.formData.isValid = true;
        }
    }
}

angular.module('omc').directive(
    'notificationDetailsComponent',
    downgradeComponent({
        component: NotificationDetailsComponent,
        inputs: ['notification', 'formData'],
    })
);
