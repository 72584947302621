import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import * as angular from 'angular';
import { CurrentUserService } from './current-user.service';
import { JobSettingsService } from './job-settings.service';
import { PlatformSettingsService } from './platform-settings.service';

@Injectable({
    providedIn: 'root'
  })
export class ParallelThreadsHelperService {
    constructor(
        private platformSettingsService: PlatformSettingsService,
        private currentUserService: CurrentUserService,  
        private jobSettingsService: JobSettingsService
        ) {}

    getNumberOfParallelThreads() {
        return new Promise((resolve) => {
            this.platformSettingsService.getSettings(true).then((data: {maximumNoOfThreads: any}) => {
                const maxThreadsAllowed = data.maximumNoOfThreads;
                const noOfThreadsNumber = Array.apply(null, Array(maxThreadsAllowed)).map((_, i) => {
                    return i + 1;
                });

                const noOfThreads = noOfThreadsNumber.map((e) => {
                    return {
                        id: e,
                        value: e,
                        isDisabled: false,
                        isDefault: false,
                    };
                });
                resolve(noOfThreads);
            });
        });
    }

    getUsersNumberOfParallelThreads() {
        let currentUserRole;

        return new Promise((resolve) => {
            this.getNumberOfParallelThreads().then((noOfThreads: Array<any>) => {
                this.currentUserService.getCurrentUser(false).then((user: {roles: Array<any>}) => {
                    if (user.roles.length > 0) {
                        currentUserRole = user.roles[0].roleName;
                        this.jobSettingsService.getSettings(true).then((data: Array<any>) => {
                            const allowedThreads = data.find((x) => x.roleCode === currentUserRole);
                            if (allowedThreads) {
                                noOfThreads.forEach((thread) => {
                                    if (thread.value > allowedThreads.maximumNoOfThreads) thread.isDisabled = true;
                                    if (thread.value === allowedThreads.defaultNoOfThreads) thread.isDefault = true;
                                });
                            }
                            resolve(noOfThreads);
                        });
                    }
                });
            });
        });
    }
}

angular.module('omc').factory('parallelThreadsHelperService', downgradeInjectable(ParallelThreadsHelperService));
