import * as moment from 'moment';
import * as angular from 'angular';

angular.module('omc').filter('omcDate', function () {
    return function (value, format) {
        if (!value || value === '0001-01-01T00:00:00') {
            return '';
        }

        format = format || 'DD/MM/YYYY HH:mm:ss';

        return moment.utc(value).local().format(format);
    };
});
