import * as angular from 'angular';
import { PlatformSettingsService } from './platform-settings.service';
import { downgradeInjectable } from '@angular/upgrade/static';
import { Inject, Injectable } from '@angular/core';
import { RootScope } from '../../ajs-upgraded-providers';
import { ApiService } from './api.service';

@Injectable()
export class ActivityMonitorService {
    rawData = {};
    totalTimeFrameToDisplay = 300;

    constructor(
        @Inject(RootScope) private rootScope,
        private platformSettingsService: PlatformSettingsService,
        private apiService: ApiService
    ) {}

    addData(key, data) {
        this.update(key, data);
    }

    refreshActivityMonitor() {
        this.apiService.post('refreshActivityMonitor', null);
    }

    getValues(key) {
        return this.rawData[key];
    }

    update(key, data) {
        this.platformSettingsService
            .getSettings(false)
            .then((settings: { serviceMonitorUpdateFrequencySeconds: any }) => {
                if (settings.serviceMonitorUpdateFrequencySeconds) {
                    const numberOfDataPoints =
                        this.totalTimeFrameToDisplay / settings.serviceMonitorUpdateFrequencySeconds;

                    const labels = Array(numberOfDataPoints).fill('');
                    labels[0] = this.totalTimeFrameToDisplay + 's';
                    labels[numberOfDataPoints - 1] = '0';

                    if (Array.isArray(data)) {
                        this.rawData[key] = data;
                    } else {
                        this.rawData[key] = this.rawData[key] || { values: Array(numberOfDataPoints - 1).fill(0) };
                        this.rawData[key].values.push(data);

                        const itemsToRemove = this.rawData[key]
                            ? this.rawData[key].values.length - numberOfDataPoints
                            : 0;
                        this.rawData[key].values.splice(0, itemsToRemove);
                        this.rawData[key].labels = labels;
                    }
                    this.rootScope.$emit('activityMonitorServiceUpdate:' + key, this.rawData[key]);
                }
            });
    }
}
angular.module('omc').factory('activityMonitorService', downgradeInjectable(ActivityMonitorService));
