import { Component, Inject, OnInit } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import * as angular from 'angular';
import { RootScope } from '../../ajs-upgraded-providers';
import { pathToApp } from '../../core/constants/global-constants';
import { columnWidths } from '../../core/constants/grid.constants';
import { ColDefinition, ColumnType } from '../../core/interfaces/col-definitions.interface';
import { ArtefactDialogData } from '../../shared/models/dialog-data.model';
import { DataService } from '../../shared/services/data.service';
import { MatDialogService } from '../../shared/services/mat-dialog.service';

@Component({
    selector: `collectionsComponent`,
    templateUrl: pathToApp + 'admin/collections/collections.component.html',
})
export class CollectionsComponent implements OnInit {
    public lang: any;
    public dialogService: any;

    selectedValues: Array<any> = [];

    collectionActive = { id: 1, name: 'Active' };
    collectionSuspended = { id: 0, name: 'Suspended' };
    collections: any[];
    CollectionStatuses: any[];
    selectedCollection: any;
    collectionDialogData: any;
    columns: ColDefinition[] = [
        { field: 'name', header: 'Name', width: columnWidths.default, type: ColumnType.name },
        { field: 'description', header: 'Description', width: columnWidths.default },
        { field: 'status', header: 'Status', width: columnWidths.default },
        { field: 'dateCreated', header: 'Date Created', width: columnWidths.dateTime, type: ColumnType.date },
        { field: 'createdById', header: 'Created By', width: columnWidths.default, type: ColumnType.createdBy },
    ];

    constructor(
        private dataService: DataService,
        @Inject(RootScope) private rootScope,
        private matDialogService: MatDialogService
    ) {}

    ngOnInit() {
        this.initialise();
        this.getCollections(true);
    }

    initialise() {
        this.collections = [];
        this.CollectionStatuses = [this.collectionActive, this.collectionSuspended];

        this.rootScope.$on('serverEvent:CollectionServerEvent', (event, payload) => {
            if (payload.forceRefresh) {
                this.getCollections(true);
            } else {
                var collection = payload.data;
                if (Array.isArray(collection)) {
                    collection.map((x) => this.updateCollection(x));
                } else {
                    this.updateCollection(collection);
                }
            }
        });

        (<any>window).omc.currentVm = this;
        (<any>window).omc.currentVm.refresh = () => this.getCollections(true);
    }

    getCollections(force = false) {
        this.dataService.getData('collection', 'collection', force, false).then((collections: Array<any>) => {
            collections.map((x) => {
                x.userName = x.userId;
                x.password = x.encryptedPassword;
            });
            collections.map((x) => (x.statusVal = this.CollectionStatuses.find((c) => c.id === x.status)));
            this.collections = collections.map((x) =>
                x.status === this.collectionActive.id
                    ? { ...x, status: this.collectionActive.name }
                    : { ...x, status: this.collectionSuspended.name }
            );
            this.selectedValues = [];
        });
    }

    saveCollection() {
        let url = 'collection';
        this.dataService.putData(this.selectedCollection, url, url, false, false).then(() => this.getCollections());
    }

    updateCollection(collection) {
        var modifiedCollection = collection;
        modifiedCollection.status =
            modifiedCollection.status === this.collectionActive.id
                ? this.collectionActive.name
                : this.collectionSuspended.name;
        var idx = this.collections.findIndex((x) => x.id === collection.id);
        this.collections = [];
        // The update collection mechanism is broken and needs to be reviewed in a future iteration - ts 17.02.23
        //if (idx > -1) {
            this.getCollections(true);
        //} else {
            //this.getCollections(true);
        //}
    }

    editsDisabled(): boolean {
        return this.selectedValues.length !== 1;
    }

    deleteDisabled(): boolean {
        return this.selectedValues.length === 0;
    }

    showUserProfile(id) {
        this.matDialogService.openUserDetailsDialog(id.createdById);
    }
    setDefault() {
        if (this.selectedValues.length > 0) {
            this.selectedValues[0].setAppDefault();
        }
        this.getCollections(true);
    }

    openEditDialog(title: string = 'Collection', entry?) {
        const collectionToDisplay = entry || this.selectedCollection;
        this.matDialogService
            .openEditCollectionsDialog(collectionToDisplay, this.collectionDialogData, title)
            .subscribe((data) => {
                this.selectedCollection = data.collection;
                if (data.collection) {
                    this.saveCollection();
                }
            });
    }

    collectionDialog(id: string | number) {
        let selected;
        let ctrl = this;

        if (id) {
            selected = this.collections.find((col) => col.id === id);
        } else {
            selected = this.selectedValues[0];
        }
        this.selectedCollection = { ...selected };

        let dialogData = new ArtefactDialogData({
            usedNames: this.collections.map((c) => c.name),
        });
        return {
            view: view,
            edit: edit,
            add: add,
        };
        function view(entry) {
            ctrl.collectionDialogData = dialogData;
            ctrl.openEditDialog(undefined, entry);
        }
        function edit() {
            dialogData.assign({
                isEdit: true,
                usedNames: ctrl.collections.filter((c) => c.id !== selected.id).map((c) => c.name),
            });
            ctrl.collectionDialogData = dialogData;
            ctrl.openEditDialog('Edit Collection');
        }
        function add() {
            selected = {
                description: '',
                name: '',
                statusVal: ctrl.collectionActive,
                status: 1,
            };
            dialogData.assign({ isAdd: true, isEdit: true });
            ctrl.selectedCollection = { ...selected };
            ctrl.collectionDialogData = dialogData;
            ctrl.openEditDialog('Add Collection');
        }
    }
}
angular.module('omc').directive(
    'collectionsComponent',
    downgradeComponent({
        component: CollectionsComponent,
    })
);
