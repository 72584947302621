import * as angular from 'angular'
import { OmcComponent } from 'shared/models/omc.types'

let reportsComponent: OmcComponent = {
    selector: `reportsComponent`,
    template: require("./reports.component.html"),
    bindings: {
        
    },
    controller: class ReportsController implements angular.IController {
        constructor(
        	private uiActionsService,
            private currentUserService,
            private redirectionService,
            private $state,
        ){
            
        }
        
        $onInit(){
            if (this.$state.current.parent !== 'reports') {
                var allStates = this.$state.get();
                var adminChildren = [];
                allStates.map(x => {
                    if (x.parent === "reports") {
                        adminChildren.push(x);
                    }
                });
        
                this.currentUserService.getCurrentUser().then((user) => {
                    if (user) {
                        this.uiActionsService.uiActions().then((actions) => {
                            var state = adminChildren.find(a => actions.find(x => !x.disabled && a.permissionName === x.name));
                            var route = state && state.name;
                            this.$state.go(route || "default");
                        });
                    }
                    else {
                        this.redirectionService.redirectToLogin('default');
                    }
                });
            }
        }
        
        
        
    }
}

angular
    .module('omc')
    .component(reportsComponent.selector, reportsComponent)