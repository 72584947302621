import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { deepEquals } from '../../helpers/ajs.helpers';
import * as angular from 'angular';

@Injectable({
    providedIn: 'root'
  })
export class GlobalSpinnerService {
    requests = {};
    showSpinnerBool = false;
    spinnerTimeout: NodeJS.Timeout; // used to debounce the spinner to prevent flickering [TS 07/07/20]
    showSpinner = () => {
        return this.showSpinnerBool;
    };

    constructor() {}

    removeSpinner(url) {
        this.requests[url] -= 1;
        if (this.requests[url] === 0) {
            delete this.requests[url];
        }
        if (deepEquals(this.requests, {})) {
            this.setSpinner(false);
        }
    }

    addSpinner(url) {
        const count = this.requests[url] || 0;
        this.requests[url] = count + 1;
        if (
            url !== 'notifications' &&
            url !== 'ClearAllNotifications' &&
            url !== 'GetAllDefaultItems' &&
            url !== 'updateHomeTotals'
        ) {
            this.setSpinner(true);
        }
    }

    setSpinner(val) {
        if (this.spinnerTimeout) {
            clearTimeout(this.spinnerTimeout);
        }
        if (val) {
            this.showSpinnerBool = !!val;
            $(document.body).addClass('loading');
            $('#global-spinner').fadeIn('100 ease-out');
        } else {
            this.spinnerTimeout = setTimeout(() => {
                this.showSpinnerBool = !!val;
                $('#global-spinner').fadeOut('200 ease-in', () => {
                    $(document.body).removeClass('loading');
                });
            }, 300);
        }
    }
}
angular.module('omc').factory('globalSpinnerService', downgradeInjectable(GlobalSpinnerService));
