import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import * as angular from 'angular';
import { DataService } from './data.service';
import { FileUploadService } from './file-upload.service';

@Injectable({
    providedIn: 'root'
  })
export class ModelsService {
    constructor(private dataService: DataService, private fileUploadService: FileUploadService) {}

    getModels(force) {
        return this.dataService.getData('models', 'models', force);
    }

    getAllModels(force) {
        return this.dataService.getData(
            'allModels?skipCurrentUserFilter=true',
            'allModels?skipCurrentUserFilter=true',
            force
        );
    }

    getModelsForPicker(force, globalSpinner = true) {
        return this.dataService.getData('modelsSimple', 'modelsSimple', force, globalSpinner);
    }

    put(data, force) {
        return this.dataService.putData(data, 'models', 'models', force);
    }

    deleteModel(data) {
        return this.dataService.putData(data, 'deleteModel', 'deleteModel', false);
    }

    restoreModel(data) {
        return this.dataService.putData(data, 'restoreModel', 'restoreModel', false);
    }

    getModelById(id, force) {
        return this.dataService.getData('getModelById?id=' + id, 'getModelById?id=' + id, force);
    }

    uploadModel(model, globalSpinner = true) {
        return this.fileUploadService.uploadFile(model, 'uploadModel', globalSpinner);
    }

    uploadTableDataFile(model) {
        return this.fileUploadService.uploadFile(model, 'uploadTableDataFile');
    }
}

angular.module('omc').factory('modelsService', downgradeInjectable(ModelsService));
