import * as angular from 'angular'
import { OmcFunc } from "../../shared/functions/omc.functions";
import { OmcComponent } from '../../shared/models/omc.types'

let editDatasetComponent: OmcComponent = {
    selector: `editDatasetComponent`,
    template: require("./edit-dataset.component.html"),
    bindings: {
        dataset: '=',
        formData: '=',
    },
    controller: class EditDatasetController implements angular.IController {

        userSettings: any = {};
        tasksGridHelper;

        usedByGridApi;
        usedByJobGridData: any;
        auditGridApi;
        auditGridData: any;
        //datasetTasksGridApi;

        lastFilenameChecked = "";
        lastIsValidPath = false;
        loadedDataset;
        auditPermission = true;
        selectedFileType = [];
        allDataSetsNames = [];
        filePathDialogData = {}      // Dialog Data for file path navigator
        currentFilePath = '';        // the current filepath for the file path navigator
        fileTypeOptions: any;
        dialogService: any;
        display: any;
        formData: any;
        dataset: any;
        datasetTasksData: any;
        filteredDataConnections = [];
        dataConnections: any;
        tableReferences: any;
        excelReferences: any;
        taskTypes: any;
        filteredTasks: any;
        selectedTask: any;
        tasks: any;
        selectedTaskType: any;
        loaded: boolean;
        lang: any;
        schemas: any;

        constructor(
            private modelsService,
            private taskTypeService,
            private dataService,
            private gridHelperServiceFactory,
            private gridSettingsService,
            private auditService,
            private uiActionsService,
            private usedByService,
            private $rootScope,
            private currentUserService,
            private formHelperService,
            private language,
            private omcDialogService,
            private filterService,
            private messagingService,
        ) {
            this.fileTypeOptions = language.dataSets.options.fileTypes
            this.dialogService = omcDialogService
            this.lang = language;
        }

        $onInit() {
            this.loaded = !!(this.formData && this.dataset)
            if (this.loaded) {
                this.uiActionsService.getPermissionsForName('admin-view-audit', true).then((perm) => {
                    this.auditPermission = perm && !perm.disabled;
                });
    
                this.initUsedByGrid()
                this.initAuditGrid()
                this.initDatasetTasksGrid()
    
    
                this.currentUserService
                    .getUserSettings(true, false)
                    .then(settings => this.userSettings = settings);

                this.showTab(this.formData.display)
                this.getAllDatasetNames().then((data) => {
                    this.allDataSetsNames = data;
                    if (this.formData.isCopy) {
                        this.dataset.name = OmcFunc.renameCopy(this.dataset.name, data.map(x => x.name))
                    }
                });
                this.initTasks();

                this.dataset.dataSetTasks = this.dataset.dataSetTasks ?? [];
                this.dataset.effectiveDate = this.dataset.effectiveDate ?? new Date();

                if (!this.currentUserService.user.userSettings.showDeletedObjects) {
                    this.dataset.dataSetTasks = this.dataset.dataSetTasks.filter(x => !x.model.isDeleted);
                }
                this.setLoadedDataset()
                if (this.formData.isCopy || this.formData.isAdd) {
                    this.formData.hasChanged = true;
                }
                this.tasksGridHelper.dataReloaded(this.dataset.dataSetTasks);
                this.$rootScope.$on("serverEvent:TaskTypeServerEvent", this.onTaskTypeStatusChangeServerEvent.bind(this));

                this.updateExcelReferences();
                this.fillDropdownOptions()
            }
        }
        initTasks() {
            Promise.all([
                this.modelsService.getModelsForPicker(true, false),
                this.taskTypeService.getTaskTypes(true, false)
            ]).then(([tasks, taskTypes]) => {
                //Task Types related changes
                this.taskTypes = taskTypes;
                this.selectedTaskType = this.taskTypes[0];
                this.selectedTask = { id: 0 };
                this.tasks = [];
                this.filteredTasks = [];
                if (this.dataset && this.dataset.taskTypeId) {
                    this.selectedTaskType = this.taskTypes.find(x => x.key === this.dataset.taskTypeId);
                }
                this.dataset.taskTypeId = this.selectedTaskType.key;
                if (this.dataset && this.dataset.modelId) {
                    this.selectedTask = tasks.find(x => x.id === this.dataset.modelId);
                } else if (tasks.find(x => x.isDefault)) {
                    this.selectedTask = tasks.find(x => x.isDefault);
                    this.dataset.modelId = this.selectedTask.id;
                }
                this.tasks = tasks;
                this.filteredTasks = this.tasks.filter(e => e.taskTypeId === this.selectedTaskType.key);
                this.refreshTasks(true);
            });
        }

        async fillDropdownOptions() {
            this.getDataConnections().then((dataConnections) => {
                this.dataConnections = dataConnections;
                this.filterConnections(this.dataConnections, this.dataset.dataInputTypeId);
            });
            if (this.dataset.dataConnectionId) {
                await this.getSchemasFromDb()
                await this.getTablesFromDb()
                if (this.dataset.table) {
                    this.updateSizeDesc()
                }
                
            }
            return Promise.resolve()
        }

        initUsedByGrid() {
            this.usedByJobGridData = this.gridSettingsService.defaultGridOptions();
            this.usedByJobGridData.onRegisterApi = (api) => {
                this.usedByGridApi = api;
            };
            this.usedByJobGridData.appScopeProvider = this;
            this.usedByJobGridData.columnDefs = this.usedByService.usedByColumnDefs();
        }

        initAuditGrid() {
            this.auditGridData = this.gridSettingsService.defaultGridOptions();
            this.auditGridData.onRegisterApi = (api) => {
                this.auditGridApi = api;
            };
            this.auditGridData.appScopeProvider = this;
            this.auditGridData.columnDefs = this.auditService.auditLogColumnDefs();
        }

        initDatasetTasksGrid() {
            this.tasksGridHelper = this.gridHelperServiceFactory.gridHelper('datasetTasksGrid', '#dataset-tasks-grid');
            this.tasksGridHelper.gridData.modifierKeysToMultiSelect = false;
            this.tasksGridHelper.gridData.multiSelect = false;
            this.datasetTasksData = this.tasksGridHelper.gridData;
            this.datasetTasksData.appScopeProvider = this;

            this.datasetTasksData.columnDefs = [
                {
                    name: "taskType.description", displayName: 'Task Type', width: 250
                },
                {
                    name: "isDeleted",
                    displayName: '',
                    cellTemplate: `<span class="ui-grid-cell-contents" ng-class="{'deleted-icon': row.entity.model.isDeleted}"></span>`,
                    width: this.gridSettingsService.columnWidths.icon
                },
                {
                    name: "model.name",
                    displayName: "Task Name",
                    cellTemplate: `<span class="ui-grid-cell-contents" ng-class="{'deleted-row-text': row.entity.model.isDeleted }">{{row.entity.model.name}}</span>`,
                    width: 200
                }
            ]
        }

        datasetTaskSelected() {
            return this.tasksGridHelper?.selectedValues()?.[0];
        }

        showTab(display) {
            var datasetId;
            this.display = display;
            switch (display) {
                case "":
                case null:
                case undefined:
                    this.display = 'properties'
                    break;
                case "linkedTasks":
                    this.tasksGridHelper.refresh();
                    break;
                case "usedBy":
                    if (!this.formData.isAdd && this.dataset.id) {
                        datasetId = this.dataset.id;
                        this.getDatasetInfo(datasetId).then((data) => {
                            data.usedBy.forEach(x => x.taskType = this.taskTypeService.getTaskTypeValueById(x.taskType));
                            this.usedByJobGridData.data = data.usedBy;
                            this.usedByGridApi.core.refresh();
                        });
                    }
                    break;
                case "audits":
                    if (!this.formData.isAdd && this.dataset.id) {
                        datasetId = this.dataset.id;
                        this.getDatasetInfo(datasetId).then((data) => {
                            this.auditGridData.data = data.auditEntries;
                            this.auditGridApi.core.refresh();
                        });
                    }
                    break;
                default:
                    break;
            }
        }

        filterConnections(dataConnections, dsType) {
            let platformTypeId = -1;
            if (dsType === 4) {
                platformTypeId = 1;
            }
            if (dsType === 5) {
                platformTypeId = 0;
            }

            this.filteredDataConnections = dataConnections &&
                dataConnections.length > 0 &&
                dataConnections.filter(dc => dc.connectionType === platformTypeId);
        }
        getSchemasFromDb() {
            if (this.dataset.dataConnectionId ?? false) {
                const url = 'getSchemaReferences?connectionId=' + this.dataset.dataConnectionId;
                return this.dataService.getData(url, url, true, false).then((schemas) => {
                    this.schemas = schemas;
                    this.dataset.schema = this.dataset.schema ?? null;
                });
            }
            return Promise.reject()
        }

        getTablesFromDb() {
            if (this.dataset.dataConnectionId ?? false) {
                const url = 'getTableReferences?connectionId=' + this.dataset.dataConnectionId + '&schemaName=' + (this.dataset?.schema ?? '');
                return this.dataService.getData(url, url, true, false).then((references) => {
                    this.tableReferences = references;
                });
            }
            return Promise.reject()
        }

        updateExcelReferences() {
            // if input type is 'excel'
            if (this.dataset.dataInputTypeId === this.fileTypeOptions.excel.id) {
                let url = 'excelReferences?path=' + escape(this.dataset.filePath)
                this.dataService.getData(url, url, true, false).then((data) => {
                    this.excelReferences = data;
                    if (data.includes(this.dataset.reference)) {
                        this.dataset.reference = this.dataset.reference;
                    } else {
                        this.dataset.reference = '';
                    }
                });
            }
        }

        updateSizeDesc() {
            if (!this.dataset.filePath || !this.dataset.dataConnectionId || !this.dataset.table) return;
            let url = `dataSetSizeDesc?filePath=${escape(this.dataset.filePath)}&connectionId=${this.dataset.dataConnectionId}&schemaName=${this.dataset.schema ?? ''}&tableName=${this.dataset.table}`
            this.dataService.getData(url, url, true, false).then((data) => {
                this.dataset.sizeDesc = data;
            });
        }

        openBrowseDialog() {
            this.currentFilePath = this.dataset.filePath
            if (!OmcFunc.isSet(this.currentFilePath, true)) {
                this.messagingService.displayError(null, 'There is no set default input connection.')
                return
            }
            this.filePathDialogData = {
                isValid: !this.isInvalid('filePath'),
                filterFileExtensions: this.selectedFileType,
                serverLocation: this.formData.defaultDataInputPath,
            }
            this.omcDialogService.open('dataset-file-path-navigator', { title: 'Select Dataset' })
        }

        onTaskTypeChanged() {
            this.filteredTasks = this.tasks.filter(e => e.taskTypeId === this.selectedTaskType.key);
            this.refreshTasks(true);
            if (this.filteredTasks.length > 0) {
                this.selectedTask = this.filteredTasks[0];
                this.onTaskChanged();
            }
        };

        onTaskChanged() {
            if (this.filteredTasks.length > 0) {
                this.dataset.modelId = this.selectedTask.id;
                this.dataset.taskTypeId = this.selectedTaskType.key;
            }
        };

        disableRemoveTask() {
            var selectedTask = this.datasetTaskSelected()
            if (selectedTask && selectedTask.model && this.formData.isUsed) {
                var filteredTask = this.dataset.usedBy
                    .find(x => x.taskName === selectedTask.model.name);
                if (filteredTask && filteredTask.state !== "New") {
                    return true;
                }
                return false;
            }
            return !selectedTask || !this.formData.isEdit || !this.formData.canEdit;
        };

        disableAddTask() {
            return !this.selectedTaskType ||
                this.selectedTaskType === null ||
                !this.selectedTask ||
                this.selectedTask === null ||
                (this.selectedTask && this.selectedTask.id === 0)
        };

        addTaskToDataset() {
            this.dataset.dataSetTasks.push({
                taskType: {
                    id: this.selectedTaskType.key,
                    name: this.selectedTaskType.value,
                    description: this.selectedTaskType.value,
                    isDisabled: this.selectedTaskType.isDisabled
                },
                model: this.selectedTask,
                taskTypeId: this.selectedTaskType.key,
                modelId: this.selectedTask.id
            });

            this.tasksGridHelper.dataReloaded(this.dataset.dataSetTasks);
            this.refreshTasks();
        };

        removeTaskFromDataset() {
            var selectedTask = this.datasetTaskSelected()
            var index = this.dataset.dataSetTasks.findIndex(task => task.model.id === selectedTask.model.id);
            if (index > -1) {
                this.dataset.dataSetTasks.splice(index, 1);
            }
            this.refreshTasks();
        };

        refreshTasks(isApplyFilter = false) {
            var selectedTaskTypeTasks = this.tasks.filter(e => e.taskTypeId === this.selectedTaskType.key);
            this.filteredTasks =
                selectedTaskTypeTasks
                    .filter(x => !this.dataset.dataSetTasks.map(m => m.model.id).includes(x.id));
            if (isApplyFilter) {
                if (this.userSettings &&
                    this.userSettings.applyTaskFilter &&
                    (this.formData.filterValues.selectedTaskType.key === 0 ||
                        this.selectedTaskType.key === this.formData.filterValues.selectedTaskType.key)) {
                    this.filteredTasks = this.filterService.filterApplyOnTasks(this.filteredTasks, this.formData.filterValues);
                }
            }
            if (this.dataset && this.dataset.modelId) {
                this.selectedTask = this.filteredTasks.find(x => x.id === this.dataset.modelId);
            } else if (this.tasks.find(x => x.isDefault)) {
                this.selectedTask = this.filteredTasks.find(x => x.isDefault);
                this.dataset.modelId = this.selectedTask.id;
            }
        };

        errorMessageFor() {
            return this.validate();
        };

        referenceRequired() {
            return this.dataset.dataInputTypeId === this.fileTypeOptions.excel.id;
        }
        referenceDisabled() {
            return !this.formData.isEdit || !this.referenceRequired();
        };
        isSqlServerData() {
            return this.dataset.dataInputTypeId === this.fileTypeOptions.sqlServer.id;
        };
        isTextFileData() {
            return this.dataset.dataInputTypeId === this.fileTypeOptions.tabDelimitedText.id;
        };
        dataSetTypeChange() {
            let fileTypeId = this.dataset.dataInputTypeId
            let isDataConnection = this.isDataConnection(fileTypeId)
            if (!isDataConnection) {
                this.dataset.filePath = this.formData.defaultDataInputPath;
                this.dataset.dataConnectionId = null;
            } else {
                this.dataset.reference = "";
                this.dataset.filePath = "";
                this.dataset.schema = "";
                this.dataset.table = "";
                this.tableReferences = "";
            }

            if (fileTypeId !== this.fileTypeOptions.excel.id) {
                this.dataset.reference = "";
                this.dataset.schema = "";
                this.dataset.table = "";
                this.tableReferences = "";
            }

            switch (fileTypeId) {
                case this.fileTypeOptions.excel.id:
                    this.selectedFileType = this.fileTypeOptions.excel.extensions;
                    break;
                case this.fileTypeOptions.commaDelimitedText.id:
                    this.selectedFileType = this.fileTypeOptions.commaDelimitedText.extensions;
                    break;
                case this.fileTypeOptions.tabDelimitedText.id:
                    this.selectedFileType = this.fileTypeOptions.tabDelimitedText.extensions;
                    break;
                default:
                    this.selectedFileType = null;
            }

            this.filterConnections(this.dataConnections, fileTypeId);
        };

        dataConnectionChanged() {
            if (this.dataset.dataConnectionId) {
                if (this.filteredDataConnections.length === 0) {
                    return;
                }
                this.dataset.filePath = this.filteredDataConnections.find(d => d.id === this.dataset.dataConnectionId).name;
                this.getSchemasFromDb().then(()=>{
                    this.getTablesFromDb()
                })
            }
        };

        isDataConnection(fileType?) {
            if (this.fileTypeOptions) {
                let fileTypeId = fileType ?? this.dataset.dataInputTypeId;
                if (fileTypeId) {
                    return (<any>Object.values(this.fileTypeOptions).find((x: any) => x.id === fileTypeId)).isDataConnection
                }
            }
            return false
        }

        isFileTypeSelected() {
            return this.dataset && OmcFunc.isSet(this.dataset.dataInputTypeId)
        }

        updateFilePath() {
            this.dataset.filePath = this.currentFilePath;
            this.omcDialogService.close('dataset-file-path-navigator')
        }

        setLoadedDataset(dataSet?) {
            this.loadedDataset = angular.copy((dataSet || this.dataset))
            this.formData.hasChanged = false;
        }

        getAllDatasetNames() {
            let url = 'getAllDataSetNames'
            return this.dataService.getData(url, url, true, false)
        }

        getDatasetInfo(datasetId: number | string) {
            let url = 'datasetInfo?id=' + datasetId;
            return this.dataService.getData(url, url, true, false)
        }
        getDataConnections(force = false) {
            let url = 'dataConnection?skipCurrentUserFilter=true'
            return this.dataService.getData(url, url, force, false)
        }

        isInvalid(key) {
            var errors = this.validate();
            this.checkIfDatasetChanged()
            this.formData.isValid = errors.isValid()
            if (key) {
                return errors[key];
            }
            return !errors.isValid();
        };

        onTaskTypeStatusChangeServerEvent(event, payload: any) {
            if (payload && payload.data && this.taskTypes) {
                var i;
                if (!Array.isArray(payload.data)) {
                    for (i = 0; i < this.taskTypes.length; i++) {
                        if (payload.data.description === this.taskTypes[i].value) {
                            this.taskTypes[i].isDisabled = payload.data.isDisabled;
                            return;
                        }
                    }
                } else {
                    for (var j = 0; j < payload.data.length; j++) {
                        for (i = 0; i < this.taskTypes.length; i++) {
                            if (payload.data.description === this.taskTypes[i].value) {
                                this.taskTypes[i].isDisabled = payload.data.isDisabled;
                            }
                        }
                    }
                }
            }
        }
        
        checkIfDatasetChanged() {
            if (!!this.loadedDataset && this.formData.hasChanged === false) { // Only run if dataset has not changed yet
                let editedDataset = this.dataset
                let arrayProperties = { 'dataSetTasks': ['id'] }
                let primitiveProps = ['name', 'effectiveDate', 'filePath', 'dataConnectionId', 'schema', 'table', 'reference', 'sample', 'nthRecord', 'startingRange', 'endingRange', 'targetCount', 'notes'];
                if (this.formHelperService.haveTheseObjectsChanged(editedDataset, this.loadedDataset, primitiveProps, arrayProperties)) {
                    this.formData.hasChanged = true;
                    return
                }
                let selectedCollections = this.dataset.collections || [];
                let loadedCollections = this.loadedDataset.collections || [];
                if (this.formHelperService.haveTheseArraysChanged(selectedCollections, loadedCollections, ['id'])) {
                    this.formData.hasChanged = true;
                    return
                }
            }
        }

        validate() {
            var hasErrors = false;
            var rtn: { isValid: () => boolean, [s: string]: any } = { isValid: () => !hasErrors };
            if (this.formData.isEdit) {
                if (!this.dataset || !this.dataset.name) {
                    rtn.name = "Please specify a value for name";
                    hasErrors = true;
                }
                if (!this.dataset || !this.isFileTypeSelected()) {
                    rtn.type = "Please specify a type";
                    hasErrors = true;
                }
                if (!this.dataset.collections || this.dataset.collections.length === 0) {
                    rtn.collection = "Please select at least one collection for this dataset.";
                    hasErrors = true;
                }
                if (this.isDataConnection()) {
                    if (!this.dataset?.table) {
                        rtn.table = "Please select table";
                        hasErrors = true;
                    }
                    if (!this.dataset?.dataConnectionId) {
                        rtn.dataConnectionId = "Please specify a value for Data Source";
                        this.dataset.sizeDesc = "";
                        hasErrors = true;
                    }
                }
                else {
                    if (!this.dataset || !this.dataset.filePath) {
                        rtn.filePath = "Please specify a value for Filename";
                        hasErrors = true;
                    }
                    else {
                        var isValidPath = this.lastIsValidPath;
                        if (this.lastFilenameChecked !== this.dataset.filePath && !!this.dataset.filePath) {
                            let url = 'checkPathIsValid?filename=' + escape(this.dataset.filePath)
                            this.dataService.postData(null, url, url, false, false).then((response) => {
                                isValidPath = response.result === "Valid path";
                                if (isValidPath) {
                                    this.updateExcelReferences();
                                    this.updateSizeDesc();
                                } else {
                                    this.excelReferences = []
                                    this.dataset.sizeDesc = ''
                                }
                                this.lastIsValidPath = isValidPath;
                            });
                            this.lastFilenameChecked = this.dataset.filePath;
                        }
                        if (!isValidPath) {
                            rtn.filePath = "Please specify a valid value for Filename";
                            this.dataset.sizeDesc = "";
                            hasErrors = true;
                        }
                    }
                }
                if (!this.referenceDisabled()) {
                    if (!this.dataset.reference || this.dataset.reference === "") {
                        rtn.reference = "Please select a Reference";
                        hasErrors = true;
                    }
                }
                if (this.dataset && this.dataset.name) {
                    if (this.allDataSetsNames.find(t =>
                        t.name === this.dataset.name && t.id !== this.dataset.id)) {
                        rtn.name = "Name is already in use, please use a different name";
                        hasErrors = true;
                    }
                }
                if (!this.dataset.dataSetTasks || this.dataset.dataSetTasks.length === 0) {
                    rtn.dataSetTasks = "Please add one or more tasks to dataset.";
                    hasErrors = true;
                }
            }
            return rtn;
        }
    }
}

angular
    .module('omc')
    .component(editDatasetComponent.selector, editDatasetComponent)