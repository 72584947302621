import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import * as angular from 'angular';
import { TypeCheckerService } from './type-checker.service';

@Injectable({
    providedIn: 'root'
  })
export class FormHelperService {
    constructor(private typeCheckerService: TypeCheckerService) {}

    /**
     * Compare two objects to see if there are changes between them, given a list of any properties
     *
     * Return boolean if compared objects are different
     * @param {Object} obj1 first object for comparison
     * @param {Object} obj2 second object for comparison
     * @param {string[]} props string array of properties to compare between the objects. can be object arrays {}[] or primitives
     * @param {object} arrayProps an object with key as the array's property name, and the value as array of properties to compare e.g. {'tasks': ['id','taskId']}
     */
    haveTheseObjectsChanged(obj1, obj2, props = [], arrayProps = {}) {
        obj1 = (typeof obj1 === 'object' && obj1) || {};
        obj2 = (typeof obj2 === 'object' && obj2) || {};
        props = this.typeCheckerService.isStringArray(props) ? props : [];
        arrayProps = typeof arrayProps === 'object' ? arrayProps : {};

        if (this.haveTheseValuesChanged(obj1, obj2, props)) return true;
        let arrayProp;
        for (arrayProp in arrayProps) {
            if (obj1[arrayProp] !== obj2[arrayProp]) {
                let comparisonProps = this.typeCheckerService.isArray(arrayProps[arrayProp])
                    ? arrayProps[arrayProp]
                    : [arrayProps[arrayProp]];
                if (
                    this.typeCheckerService.isObjectArrayWithProps(obj1[arrayProp], comparisonProps) &&
                    this.typeCheckerService.isObjectArrayWithProps(obj2[arrayProp], comparisonProps)
                ) {
                    if (this.haveTheseArraysChanged(obj1[arrayProp], obj2[arrayProp], comparisonProps)) {
                        console.log(`Change Detected: ${arrayProp}`, obj1[arrayProp], obj2[arrayProp]);
                        return true;
                    }
                } else {
                    console.log(
                        `Change Detected: ${arrayProp} not array with props ${comparisonProps.join(', ')}`,
                        obj1[arrayProp],
                        obj2[arrayProp]
                    );
                    return true;
                }
            }
        }
        return false;
    }

    /**
     * Compare two objects to see if there are changes between them, given a list of properties with primitive values
     *
     * Return boolean if compared objects are different
     * @param {Object} obj1 first object for comparison
     * @param {Object} obj2 second object for comparison
     * @param {string[]} primitiveValues string array of property names to compare between the objects
     */
    haveTheseValuesChanged(obj1, obj2, primitiveValues) {
        obj1 = (!!obj1 && typeof obj1 === 'object' && obj1) || {};
        obj2 = (!!obj2 && typeof obj2 === 'object' && obj2) || {};
        primitiveValues = this.typeCheckerService.isStringArray(primitiveValues) ? primitiveValues : [];

        let propName;
        for (propName of primitiveValues) {
            if (obj1[propName] !== obj2[propName]) {
                console.log(`Change Detected: ${propName}`, obj1[propName], obj2[propName]);
                return true;
            }
        }
        return false;
    }

    haveTheseArraysChanged(arr1, arr2, comparisonProperties) {
        arr1 = this.typeCheckerService.isArray(arr1) ? arr1 : [];
        arr2 = this.typeCheckerService.isArray(arr2) ? arr2 : [];
        comparisonProperties = this.typeCheckerService.isStringArray(comparisonProperties) ? comparisonProperties : [];
        return !this.areArraysSame(arr1, arr2, comparisonProperties);
    }

    numericalFormOptions(min, max = null) {
        let options = [];
        max = max || min;
        let i;
        for (i = min; i <= max; i++) {
            options.push({
                id: i,
                value: i,
                isDisabled: false,
                isDefault: false,
            });
        }
        return options;
    }

    /** Will return true if arrays have the same objects given property names for comparison, false if different
     * @param {array} arr1  - first array to compare
     * @param {array} arr2  - second array to compare
     * @param {string|string[]} props
     * - property name or array of property names to compare with. These properties must have a primitive value in both
     * arrays objects (string/boolean/number)
     */
    areArraysSame(arr1, arr2, props) {
        if (!Array.isArray(arr1)) {
            console.error('first parameter must be an array');
            return false;
        }
        if (!Array.isArray(arr2)) {
            console.error('second parameter must be an array');
            return false;
        }
        if (!(typeof props === 'string' || this.typeCheckerService.isStringArray(props))) {
            console.error('third parameter must be a string or array of strings');
            return false;
        }
        if (arr1.length !== arr2.length) {
            return false;
        }
        if (Array.isArray(props)) {
            return arr1.every((obj1) => {
                return arr2.some((obj2) => {
                    return props.every((p) => {
                        return obj1[p] === obj2[p];
                    });
                });
            });
        } else {
            let prop = props;
            arr1 = arr1.map((x) => x[prop]);
            arr2 = arr2.map((x) => x[prop]);
            let val;
            for (val of arr1) {
                if (!arr2.includes(val)) {
                    return false;
                }
            }
        }
        return true;
    }
}
angular.module('omc').factory('formHelperService', downgradeInjectable(FormHelperService));
