// 664
import * as angular from 'angular';
import { OmcComponent } from 'shared/models/omc.types';
import { OmcFunc } from '../../shared/functions/omc.functions';

let runJobComponent: OmcComponent = {
    selector: `runJobComponent`,
    template: require('./run-job.component.html'),
    bindings: {
        jobs: '=',
        formData: '=',
        jobExecution: '=',
    },
    controller: class RunJobController implements angular.IController {
        jobs: any[];
        formData: any;
        jobExecution: any;

        taskGridApi;
        historyData;
        users;
        gridHelper;
        lang: any;
        gridHelperService: any;
        jobPriorities: any[];
        selectedJob: any;
        inputsLoaded: boolean;
        FileCount: number;
        selectedTask: any;
        jobArtefactAuditTrailDetailReportUrl: string;
        jobTemplates: any[];
        availableUsersSelected: any[];
        recipientsSelected: any[];
        executionHistoryId: any;
        totalSize: any;
        gridHelperResultsData: any;
        logLevels: any;
        gridTasks: any;
        gridTaskRecords: any;
        noOfSplitsOptions: any;
        noOfWorkersOptions: any;
        workerConfigurations: any;

        constructor(
            private $rootScope,
            private jobExecutionService,
            private $timeout,
            private gridHelperServiceFactory,
            private parallelThreadsHelperService,
            private messagingService,
            private currentUserService,
            private usersService,
            private platformSettingsService,
            private gridSettingsService,
            private downloadService,
            private dataService,
            private jobsService,
            private language,
            private omcDialogService,
            private formHelperService,
            private jobSettingsService
        ) {
            this.lang = language.runJob;
            this.gridHelperService = gridHelperServiceFactory.gridHelper('runJob');
        }

        $onInit() {
            this.getJobTemplates();
            this.initializeGrid();
            this.initialise();
        }

        initialise() {
            this.jobPriorities = [];
            const jobPriorityL = { key: 0, value: 'Lowest' };
            const jobPriorityBN = { key: 1000, value: 'Below Normal' };
            const jobPriorityN = { key: 2000, value: 'Normal' };
            const jobPriorityAN = { key: 3000, value: 'Above Normal' };
            const jobPriorityH = { key: 4000, value: 'Highest' };
            const jobPriorityMap = {
                lowest: 0,
                belowNormal: 1000,
                normal: 2000,
                aboveNormal: 3000,
                highest: 4000,
            };

            this.$timeout(() => {
                this.jobPriorities = [jobPriorityL, jobPriorityBN, jobPriorityN, jobPriorityAN, jobPriorityH].filter(
                    (e) => e.key <= this.formData.maxRolePriority
                );
                if (this.jobExecution.jobPriority === undefined && this.selectedJob) {
                    this.jobExecution.jobPriority = this.selectedJob.priority;
                }
                if (this.formData.maxRolePriority < this.jobExecution.jobPriority && this.formData.maxRolePriority) {
                    this.jobExecution.jobPriority = this.formData.maxRolePriority;
                }
            });

            this.inputsLoaded = !!(this.jobs && this.formData && this.jobExecution);
            if (!this.inputsLoaded) return;

            this.FileCount = 0;
            this.selectedJob = this.jobs[0];
            this.selectedTask = null; // selected task for view-job-task
            this.jobExecution = this.jobExecution || {};
            this.jobExecution.run = this.run.bind(this);
            this.jobArtefactAuditTrailDetailReportUrl = '';

            this.setNumberOfThreadsOptions();

            this.jobTemplates = [];
            this.users = [];
            this.jobExecution.availableUsers = [];
            this.availableUsersSelected = [];
            this.jobExecution.jobRunSubscribers = this.jobExecution.jobRunSubscribers || [];
            this.recipientsSelected = [];

            if (this.formData.audit && this.formData.audit.artefactId) {
                this.executionHistoryId = this.formData.audit.artefactId;
            } else {
                this.executionHistoryId = this.formData.lastJobExeId;
            }

            if (this.selectedJob && this.selectedJob.tasks) {
                this.setTasksForView();
            }
            if (this.executionHistoryId) {
                let jobExHistoryId = this.executionHistoryId;
                let url = 'ResultsHistory?id=' + jobExHistoryId;
                this.dataService.getData(url, url, true).then((data) => {
                    this.FileCount = data.files.length;
                    this.totalSize = OmcFunc.formatBytes(data.totalSize);
                    this.gridHelperResultsData.data = data.files;
                    if (this.FileCount === 0) {
                        this.formData.canDownload = false;
                    } else {
                        this.formData.canDownload = true;
                    }
                });
                this.dataService
                    .getData('JobExecutionLog?id=' + jobExHistoryId, 'JobExecutionLog?id=' + jobExHistoryId, true)
                    .then((data) => {
                        this.selectedJob.logs = data;
                    });
            }

            if (this.formData.display !== '') {
                this.formData.display = this.formData.display;
            }
        }

        changedJob() {
            if (this.formData.isEdit) {
                this.setTasksForView();
                if (this.jobs.length > 0) {
                    /*Update the label for multiple jobs*/
                    var currentJob = this.jobs.findIndex((i) => i.id === this.selectedJob.id) + 1; //Since this is 0 base we add one
                    $('#jobRunNameForMultiple')[0].innerText =
                        'Name (Job ' + currentJob + ' of ' + this.jobs.length + ')';
                }
            }
        }

        toggleAvailableSelected(user) {
            this.toggleInArray(user, this.availableUsersSelected);
        }
        toggleRecipientsSelected(user) {
            this.toggleInArray(user, this.recipientsSelected);
        }

        anySelectedJobsRunning() {
            return this.jobs.some((job) => this.jobsService.isRunning(job));
        }

        taskSelectedAndEnabled() {
            return this.selectedTask && this.selectedTask.isEnabled;
        }

        displayTasks() {
            this.formData.display = 'tasks';
        }

        displaySettings() {
            if (!this.logLevels) {
                this.dataService.getData('getLogLevels', 'getLogLevels', true).then((data) => {
                    this.logLevels = data;
                });
            }
            this.retrievePlatformSettings();
            this.formData.display = 'settings';
        }

        displayResults() {
            this.retrievePlatformSettings();
            this.formData.display = 'results';
        }

        displayAlerting() {
            if (!this.users || this.users.length === 0) {
                this.usersService.getUsers(true).then((data) => {
                    this.users = data;
                    this.initJobSubscribers();
                    this.availableUsersSelected = [];
                    this.recipientsSelected = [];
                });
            }
            this.formData.display = 'alerting';
        }

        downloadTaskLog(task) {
            var filename = task.name + '_TaskRunLog.txt';
            this.downloadService.downloadTaskExecutionLog(task.taskId, filename);
        }

        downloadFile(job) {
            this.downloadService.downloadResultsHistoryFile(job.id, job.fileName, job.filePath);
        }

        setTasksForView() {
            this.selectedTask = this.selectedJob.tasks && this.selectedJob.tasks[0];
            this.gridTasks.data = this.selectedJob.tasks;
            if (this.gridTasks.data && this.gridTasks.data.length > 0) {
                this.onSelectTask(this.gridTasks.data[0]);
            }
            if (this.executionHistoryId && this.jobExecution && this.jobExecution.jobExTaskRecords) {
                this.gridTaskRecords.data = this.jobExecution.jobExTaskRecords;
            }
        }

        setNumberOfThreadsOptions() {
            this.parallelThreadsHelperService.getUsersNumberOfParallelThreads(true).then((data) => {
                this.formData.noOfThreadsOptions = data;
                this.formData.noOfThreadsOptions.unshift({ id: 0, value: '<use threads defined in task>' });

                if (!OmcFunc.isSet(this.jobExecution.noOfParallelThreads)) {
                    var defaultThread = data.filter((x) => x.isDefault === true);
                    this.jobExecution.noOfParallelThreads = defaultThread.length > 0 ? defaultThread[0].id : 0;
                }

                if (this.jobExecution.noOfParallelThreads >= 0) {
                    var selectedThread = this.formData.noOfThreadsOptions.find(
                        (x) => x.id === this.jobExecution.noOfParallelThreads
                    );
                    if (selectedThread === null) {
                        selectedThread = {
                            id: this.jobExecution.noOfParallelThreads,
                            value: this.jobExecution.noOfParallelThreads,
                            isDisabled: false,
                        };
                        this.formData.noOfThreadsOptions.push(selectedThread);
                    }
                    this.jobExecution.noOfParallelThreads = selectedThread;
                }
            });
        }

        initJobSubscribers() {
            var subscribedUserIds = this.jobExecution.jobRunSubscribers.map((x) => x.userId);
            if (subscribedUserIds.length > 0) {
                this.jobExecution.jobRunSubscribers = angular.copy(
                    this.users.filter((x) => subscribedUserIds.includes(x.userId))
                );
            }

            this.jobExecution.availableUsers = angular.copy(
                this.users
                    .filter((x) => !subscribedUserIds.includes(x.userId))
                    .filter((u) => u.isActive && u.team && u.team.isActive)
            );

            var forRemove = [];
            for (var i = 0; i < this.jobExecution.availableUsers.length; i++) {
                if (
                    this.jobExecution.availableUsers[i].roles &&
                    this.jobExecution.availableUsers[i].roles.find((x) => x.application === 'OMC') === undefined
                ) {
                    forRemove.push(this.jobExecution.availableUsers[i]);
                }
            }

            for (var j = 0; j < forRemove.length; j++) {
                var user = this.jobExecution.availableUsers.find((x) => x.userId === forRemove[j].userId);
                if (user !== undefined) {
                    var index = this.jobExecution.availableUsers.indexOf(user);
                    this.jobExecution.availableUsers.splice(index, 1);
                }
            }
            this.jobExecution.availableUsers = angular.copy(this.jobExecution.availableUsers);

            if (
                this.jobExecution &&
                this.jobExecution.availableUsers &&
                this.jobExecution.jobRunSubscribers &&
                this.currentUserService.user.userSettings &&
                this.currentUserService.user.userSettings.sendAlertsToMe
            ) {
                var userToSendAlert = this.jobExecution.availableUsers.filter(
                    (x) => x.id === this.currentUserService.user.id
                )[0];
                if (userToSendAlert) {
                    var indexOf = this.jobExecution.availableUsers.indexOf(userToSendAlert);
                    this.jobExecution.availableUsers.splice(indexOf, 1);
                    var selectedUser = this.jobExecution.jobRunSubscribers.filter(
                        (y) => y.id === userToSendAlert.id
                    )[0];
                    if (!selectedUser) {
                        this.jobExecution.jobRunSubscribers.push({ userId: userToSendAlert.userId });
                    }
                }
            }
        }

        retrievePlatformSettings() {
            if (
                !this.noOfSplitsOptions ||
                !this.noOfWorkersOptions ||
                !this.jobArtefactAuditTrailDetailReportUrl ||
                !this.workerConfigurations
            ) {
                Promise.all([
                    this.platformSettingsService.getSettings(true),
                    this.jobSettingsService.getSettingsForCurrentUser(),
                    this.getJobTemplates(),
                ]).then(([platformSettings, userRoleSettings, jobTemplates]) => {
                    var selectedJobTemplate = jobTemplates.find((x) => x.id === this.selectedJob.jobTemplateId);
                    if (selectedJobTemplate) {
                        if (this.formData.isEdit === true) {
                            this.jobExecution.verifyDatasetChecksum = selectedJobTemplate.verifyDatasetChecksum;
                            this.jobExecution.abortLimit = selectedJobTemplate.abortLimit;
                            this.jobExecution.writeLimit = selectedJobTemplate.writeLimit;
                            this.jobExecution.logLevel = selectedJobTemplate.logLevelId;
                            this.jobExecution.workerConfigurationName = selectedJobTemplate.workerConfigurationName;
                            this.jobExecution.distributionStrategyId = selectedJobTemplate.distributionStrategyId;
                            this.jobExecution.aggregateWorkerOutput = selectedJobTemplate.aggregateWorkerOutput;
                            this.jobExecution.noOfSplits = selectedJobTemplate.noOfSplits;

                            if (selectedJobTemplate.noOfWorkers > userRoleSettings.maximumNoOfWorkers) {
                                this.jobExecution.noOfWorkers = userRoleSettings.defaultNoOfWorkers;
                            } else {
                                this.jobExecution.noOfWorkers = selectedJobTemplate.noOfWorkers;
                            }
                        }
                    }
                    this.jobArtefactAuditTrailDetailReportUrl =
                        platformSettings.jobArtefactAuditTrailDetailReportUrl +
                        '?JobId=' +
                        this.selectedJob.id +
                        '&JobExecutionHistoryId=' +
                        this.executionHistoryId; //executionHistoryId latest Id

                    this.noOfSplitsOptions = this.formHelperService.numericalFormOptions(
                        1,
                        platformSettings.maximumNoOfSplits
                    );

                    if (this.jobExecution.noOfSplits >= 0) {
                        var selectedSplit = this.noOfSplitsOptions.find((x) => x.id === this.jobExecution.noOfSplits);
                        if (!OmcFunc.isSet(selectedSplit)) {
                            selectedSplit = {
                                id: this.jobExecution.noOfSplits,
                                value: this.jobExecution.noOfSplits,
                                isDisabled: false,
                            };
                        }
                        if (this.formData.isEdit === false) {
                            this.noOfSplitsOptions.push(selectedSplit);
                        }
                        this.jobExecution.noOfSplits = selectedSplit;
                    }

                    let platformMaxWorkers = platformSettings.maximumNoOfWorkers;
                    let roleMaxWorkers = userRoleSettings.maximumNoOfWorkers;
                    let maxWorkers = Math.min(platformMaxWorkers, roleMaxWorkers);
                    this.noOfWorkersOptions = this.formHelperService.numericalFormOptions(1, maxWorkers);

                    if (this.jobExecution.noOfWorkers >= 0) {
                        this.jobExecution.noOfWorkers =
                            this.noOfWorkersOptions.find((x) => x.id === this.jobExecution.noOfWorkers) ||
                            this.noOfWorkersOptions[maxWorkers - 1];
                    }

                    this.getWorkersConfigOptions(platformSettings).then((workerConfigData) => {
                        this.workerConfigurations = workerConfigData;
                        if (!this.jobExecution.workerConfigurationName) {
                            if (this.formData.isEdit) {
                                this.jobExecution.workerConfigurationName = platformSettings.workerConfigDefault;
                            }
                        } else if (
                            !this.workerConfigurations.find((x) => x.Name === this.jobExecution.workerConfigurationName)
                        ) {
                            let flaggedName = this.jobExecution.workerConfigurationName + ' [Removed]';
                            this.workerConfigurations.push({
                                Name: flaggedName,
                                disabled: true,
                            });
                            this.jobExecution.workerConfigurationName = flaggedName;
                        }
                    });
                });
            }
        }

        getJobTemplates() {
            if (!this.jobTemplates) {
                return this.dataService
                    .getData('JobTemplates', 'JobTemplates?skipCurrentUserFilter=false', true)
                    .then((templates) => {
                        this.jobTemplates = templates;
                        return this.jobTemplates;
                    });
            } else {
                return Promise.resolve(this.jobTemplates);
            }
        }

        getWorkersConfigOptions(platformSettings) {
            const url = 'getWorkersConfigs?workerConfigurationLocation=' + platformSettings.workerConfigLocation;
            return this.dataService.getData(url, url, true).then((workerConfigData) => {
                return workerConfigData.map((data) => data.Configuration);
            });
        }

        moveUserArray(user, arrayFrom, arrayTo) {
            var idx = arrayFrom.findIndex((x) => x === user);
            if (idx > -1) {
                arrayFrom.splice(idx, 1);
                arrayTo.push(user);
            }
        }

        recipientsAddSelected(user) {
            if (user && !this.recipientsSelected.includes(user)) {
                this.availableUsersSelected.push(user);
            }

            this.availableUsersSelected.forEach((user) =>
                this.moveUserArray(user, this.jobExecution.availableUsers, this.jobExecution.jobRunSubscribers)
            );
            this.recipientsSelected = [];
            this.availableUsersSelected = [];
        }

        recipientsRemoveSelected(user) {
            if (user && !this.recipientsSelected.includes(user)) {
                this.recipientsSelected.push(user);
            }
            this.recipientsSelected.forEach((user) =>
                this.moveUserArray(user, this.jobExecution.jobRunSubscribers, this.jobExecution.availableUsers)
            );
            this.recipientsSelected = [];
            this.availableUsersSelected = [];
        }

        toggleInArray(user, array) {
            var idx = array.findIndex((x) => x === user);
            if (idx === -1) {
                array.push(user);
            } else {
                array.splice(idx, 1);
            }
        }
        jobRunningAlert() {
            if (!this.jobs) return;
            let plural = this.jobs.length > 1;
            let title = 'Cannot run Job' + (plural ? 's' : '');
            let msg = 'The job' + (plural ? 's are' : ' is') + ' already running';
            this.messagingService.displayMessage(title, msg, this.messagingService.messageLevels.warning);
        }

        run(dialogId) {
            if (!this.selectedJob || this.jobs.length <= 0) {
                this.messagingService.displayMessage('No Job selected');
                return;
            }

            if (this.anySelectedJobsRunning()) {
                this.jobRunningAlert();
                return;
            }

            this.selectedJob.hasJustBeenStarted = true;

            if (!this.logLevels) {
                this.dataService.getData('getLogLevels', 'getLogLevels', true).then((logLevels) => {
                    this.logLevels = logLevels;
                    this.checkUsersAndLaunchJobs();
                    this.omcDialogService.close(dialogId);
                });
            } else {
                this.checkUsersAndLaunchJobs();
                this.omcDialogService.close(dialogId);
            }
        }

        checkUsersAndLaunchJobs() {
            if (!this.users || this.users.length === 0) {
                this.usersService.getUsers(true).then((data) => {
                    this.users = data;
                    this.launchJobs();
                });
            } else {
                this.launchJobs();
            }
        }

        launchJobs() {
            this.initJobSubscribers();
            this.jobExecutionService.getDistributionMethods().then((distributionMethods) => {
                this.jobExecution.distributionMethod = distributionMethods[0];
                this.jobExecutionService.getResourcePools().then((servicesSettings) => {
                    this.jobExecution.resourcePool = servicesSettings[0];
                    this.jobExecutionService.getBurstCapacityPools().then((burstCapacityPools) => {
                        this.jobExecution.burstCapacityPool = burstCapacityPools[0];

                        var jobEx = this.jobExecution;
                        if (jobEx.noOfParallelThreads) jobEx.noOfParallelThreads = jobEx.noOfParallelThreads.value;
                        if (jobEx.noOfSplits) jobEx.noOfSplits = jobEx.noOfSplits.value;
                        if (jobEx.noOfWorkers) jobEx.noOfWorkers = jobEx.noOfWorkers.value;
                        jobEx.distributionMethodId = jobEx.distributionMethod.id;
                        jobEx.resourcePoolId = jobEx.resourcePool.id;
                        jobEx.burstCapacityPoolId = jobEx.burstCapacityPool.id;

                        jobEx.jobRunTasks = [];
                        this.selectedJob.tasks.forEach((task) => {
                            jobEx.jobRunTasks.push({
                                taskId: task.id,
                                runControlFilter: task.runControlFilter,
                            });
                        });

                        for (var i = 0; i < this.jobs.length; i++) {
                            jobEx.jobId = this.jobs[i].id;
                            jobEx.startTime = new Date();
                            this.jobExecutionService.put(jobEx);
                        }
                        this.$rootScope.$emit(
                            'runJobStarted',
                            this.jobs.map((job) => job.id)
                        );
                    });
                });
            });
        }

        initializeGrid() {
            this.gridHelper = this.gridHelperServiceFactory.gridHelper('tasksGrid', '#tasks-grid');
            this.gridHelper.gridData.modifierKeysToMultiSelect = false;
            this.gridHelper.gridData.multiSelect = false;
            this.gridHelper.gridData.enableSorting = false;
            this.gridHelper.gridData.enableColumnMenus = false;
            this.gridTasks = this.gridHelper.gridData;
            this.gridTasks.appScopeProvider = this;
            this.gridTasks.columnDefs = [
                {
                    name: 'sNo',
                    displayName: '#',
                    width: this.gridSettingsService.columnWidths.edit,
                    //sort: { sortPriority: 1, direction: 'asc' }
                },
                {
                    name: 'model.name',
                    displayName: 'Task Name',
                    width: this.gridSettingsService.columnWidths.default,
                },
                {
                    name: 'taskType.name',
                    displayName: 'Task Type',
                    width: this.gridSettingsService.columnWidths.default,
                },
            ];
            this.gridTasks.onRegisterApi = (api) => {
                this.taskGridApi = api;
                this.taskGridApi.selection.on.rowSelectionChanged(null, this.rowClick.bind(this));
            };
            /* Grid Task Records for the <run-job-component> Results dialog grid [TM 03/08/21] */
            this.gridTaskRecords = this.gridSettingsService.defaultGridOptions();
            this.gridTaskRecords.enableSorting = false;
            this.gridTaskRecords.enableColumnMenus = false;
            this.gridTaskRecords.appScopeProvider = this;
            this.gridTaskRecords.columnDefs = [
                {
                    name: 'name',
                    displayName: 'Task Name',
                    width: this.gridSettingsService.columnWidths.default,
                    /* Shows name in <a> tag if !isStoredProcedure or else just displays name [TM 03/08/21] */
                    cellTemplate:
                        '<div class="ui-grid-cell-contents"><a ng-if="!row.entity.isStoredProcedure" href="#" ng-click="grid.appScope.downloadTaskLog(row.entity); $event.preventDefault();">{{row.entity.name}}</a> <span ng-if="row.entity.isStoredProcedure">{{row.entity.name}}</span></div>',
                },
                {
                    name: 'download',
                    displayName: 'Run Report',
                    width: 110,
                    /* Shows Downloadable Report if !isStoredProcedure or else just displays N/A [TM 03/08/21] */
                    cellTemplate:
                        '<div class="ui-grid-cell-contents"><a ng-if="!row.entity.isStoredProcedure" href="#" ng-click="grid.appScope.downloadTaskRunReport(row.entity); $event.preventDefault();">Download</a> <span ng-if="row.entity.isStoredProcedure">N/A</span> </div>',
                },
                {
                    name: 'recordsProcessed',
                    displayName: 'Records Processed',
                    width: this.gridSettingsService.columnWidths.medium,
                    /* Shows Records Processed if !isStoredProcedure or else just displays N/A [TM 03/08/21] */
                    cellTemplate:
                        '<div class="ui-grid-cell-contents"> <span>{{ row.entity.isStoredProcedure ? "N/A" : row.entity.recordsProcessed  }}</span> </div>',
                },
            ];

            //#region Results Grid

            this.gridHelperResultsData = this.gridSettingsService.defaultGridOptions();
            this.gridHelperResultsData.enableSorting = false;
            this.gridHelperResultsData.enableColumnMenus = false;
            this.gridHelperResultsData.appScopeProvider = this;
            this.gridHelperResultsData.columnDefs = [
                {
                    name: 'displayName',
                    displayName: 'Name',
                    width: this.gridSettingsService.columnWidths.fileName,
                    cellTemplate: `<div class="ui-grid-cell-contents"><span 
                                    ng-class="{
                                      'textFile-icon' : row.entity.fileName.substring(row.entity.fileName.lastIndexOf('.') + 1, row.entity.fileName.length)==='txt',
                                      'otherFile-icon' : row.entity.fileName.substring(row.entity.fileName.lastIndexOf('.') + 1, row.entity.fileName.length)!=='txt'}"></span>
                                        <a href="#" ng-click="grid.appScope.downloadFile(row.entity); $event.preventDefault();">{{row.entity.displayName}}</a></div>`,
                },
                {
                    name: 'dateModified',
                    displayName: 'Date Modified',
                    width: this.gridSettingsService.columnWidths.dateTime,
                },
                {
                    name: 'fileSize',
                    displayName: 'File Size',
                    width: this.gridSettingsService.columnWidths.fileSize,
                },
            ];
            //#endregion
        }

        rowClick(row, $event) {
            if ($event && ($event.fromRowChanged || $event.ctrlKey)) {
                return;
            }
            this.onSelectTask(row.entity);
        }

        onSelectTask(selectedTask) {
            if (selectedTask && this.selectedTask && selectedTask.id === this.selectedTask.id) return;
            this.selectedTask = this.selectedJob.tasks.find((task) => task.id === selectedTask.id);
            let rowEntityIndex = this.gridTasks.data.findIndex((task) => task.id === selectedTask.id);
            this.taskGridApi.selection.selectRow(this.gridTasks.data[rowEntityIndex]);
        }

        downloadAll() {
            this.jobsService.getJobById(this.selectedJob.id, true).then((job) => {
                for (var i = 0; i < job.jobExecutionHistory.length; i++) {
                    if (job.jobExecutionHistory[i].id === this.executionHistoryId) {
                        this.downloadService.downloadResults(
                            job.jobExecutionHistory[i].emServiceJobId,
                            job.description + '_' + job.dateCreated + '.zip',
                            'zip'
                        );
                        break;
                    }
                }
            });
        }

        copyLogs() {
            if (this.jobs && this.selectedJob && this.selectedJob.logs) {
                var copyText = <HTMLTextAreaElement>document.getElementById('jobLogsTextArea');
                copyText.disabled = false;
                copyText.select();
                document.execCommand('copy');
                if (window.getSelection) {
                    if (window.getSelection().empty) {
                        window.getSelection().empty();
                    } else if (window.getSelection().removeAllRanges) {
                        window.getSelection().removeAllRanges();
                    }
                    /* <IE9 compatibility [TS 13/01/21] */
                } else if ((<any>document).selection) {
                    (<any>document).selection.empty();
                }
                copyText.disabled = true;
            }
        }

        downloadLogs() {
            if (this.jobs && this.selectedJob && this.selectedJob.logs) {
                this.downloadService.downloadFile(
                    this.selectedJob.logs,
                    this.selectedJob.description + '_' + this.jobExecution.emServiceJobId,
                    '    text/plain'
                );
            }
        }

        downloadTaskRunReport(task) {
            var filename = task.name + '_TaskRunReport.txt';
            this.downloadService.downloadTaskRunReport(task.taskId, filename);
        }
    },
};

angular.module('omc').component(runJobComponent.selector, runJobComponent);
