import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import * as angular from 'angular';

@Injectable({
    providedIn: 'root'
  })
export class TypeCheckerService {
    constructor() {}

    isArray(array) {
        return array !== undefined && array !== null && Array.isArray(array);
    }

    isArrayNotEmpty(array) {
        return this.isArray(array) && array.length > 0;
    }

    isStringArray(array) {
        return this.isArrayNotEmpty(array) && array.every((v) => typeof v === 'string');
    }

    isPrimitive(v) {
        return (
            typeof v === 'string' ||
            typeof v === 'boolean' ||
            typeof v === 'number' ||
            typeof v === undefined ||
            typeof v === null
        );
    }

    isObjectArray(array) {
        return this.isArrayNotEmpty(array) && array.every((el) => typeof el === 'object');
    }

    isObjectArrayWithProps(array, props) {
        props = this.isStringArray(props) ? props : [];
        return this.isObjectArray(array) && array.every((el) => props.every((prop) => el.hasOwnProperty(prop)));
    }
}
angular.module('omc').factory('typeCheckerService', downgradeInjectable(TypeCheckerService));
