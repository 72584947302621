import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { downgradeComponent } from '@angular/upgrade/static';
import * as angular from 'angular';
import { DateTimeFormat, pathToApp } from '../../core/constants/global-constants';

@Component({
    selector: "user-details-component",
    templateUrl: pathToApp + 'user/user-details/user-details.component.html',
})
export class UserDetailsComponent implements OnInit, OnChanges {
    selectedRowCode: string;
    rolesData: any;
    avatar: SafeResourceUrl = '/webapp/assets/icons/profile.png';
    longDateFormat = DateTimeFormat.longDateFormat;
    model = {
        display: '',
    };
    @Input() user: any;

    constructor(private _sanitizer: DomSanitizer) {}

    ngOnInit() {
        this.rolesData = this.user.roles;
        this.selectedRowCode = '';
    }

    ngOnChanges(changes: SimpleChanges) {
        this.updateProfilePicture(changes.user.currentValue);
    }

    updateProfilePicture(user) {
        if (user.profilePictureBase64) {
            this.avatar = this._sanitizer.bypassSecurityTrustResourceUrl(
                'data:image/jpg;base64,' + user.profilePictureBase64
            );
        }
    }

    changeTabTo(tab) {
        this.model.display = tab;
    }
}

angular.module('omc').directive(
    'user-details-component',
    downgradeComponent({
        component: UserDetailsComponent,
        inputs: ['user'],
    })
);
