import * as angular from 'angular';
import { Component, Inject } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { pathToApp } from '../../../core/constants/global-constants';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
    selector: 'app-confirm-dialog',
    templateUrl: pathToApp + 'shared/dialogs/confirm-dialog/confirm-dialog.component.html',
})
export class ConfirmDialogComponent {

    constructor(
        public dialogRef: MatDialogRef<ConfirmDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data
      ) {}
}
angular.module('omc')
    .directive('confirmDialogComponent', downgradeComponent({
        component: ConfirmDialogComponent,
    })
);
