export const dialogRefactoredValues = {
    hasBackdrop: true,
    disableClose: true,
    closeOnNavigation: true,
    autoFocus: false,
    minWidth: '400px',
    maxWidth: '90vw',
    maxHeight: '90vh',
    panelClass: 'custom-dialog-styles',
    data: {}
}

export const buttons = {
    OK: 'OK',
    CANCEL: 'Cancel',
    CONFIRM: 'Confirm',
    SAVE: 'Save',
    RESTORE: 'Restore',
    DELETE: 'Delete'
}
